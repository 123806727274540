import { gql } from '@apollo/client';

export default class CompanyQuery {
    static GET_COMPANY = gql`
        query getCompany {
            getCompany {
                companyCode
                companyDbName
                companyName
                companyAddress
                companyCity
                companyState
                companyZip
                companyCountry
                companyPhone
                companyFax
                companyWebsite
                companyContact
                companyEin
                companyEmail
                carfaxId
                secureCloseDmsguid
                secureCloseEnabled
                companyTimeZoneId
                companyTimezone
                companyLogo
            }
        }
    `;
}
