/* eslint-disable react/no-children-prop */
import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    IconButton, Badge, Popover, makeStyles, Button,
    Typography, Divider, ListItem, ListItemIcon, ListItemText, List,
} from '@material-ui/core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { isEmpty, isFinite } from 'lodash';
import { modules } from 'utils/enum/modules';
import MessageUtils from 'utils/MessageUtils';
import CallIcon from '@material-ui/icons/Call';
import InboxIcon from '@material-ui/icons/Inbox';
import TodayIcon from '@material-ui/icons/Today';
import If from 'components/widgets/conditional/If';
import { EventColors } from 'utils/enum/ActivitiesEnum';
import { icons } from 'components/modules/lead/LeadHelper';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { ReactComponent as SMSIcon } from 'assets/crm/sms.svg';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

// Services
import { FetchPolicy, NOTIFICATION_TYPE } from 'utils/enum/Core';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import NotificationQuery from 'services/graphQL/query/core/NotificationQuery';
import UserNotificationQuery from 'services/graphQL/query/core/UserNotificationQuery';
import UserNotificationMutation from 'services/graphQL/mutate/core/UserNotificationMutation';
import NotificationSubscription from 'services/graphQL/subscription/core/NotificationSubscription';
import UserNotificationSubscription from 'services/graphQL/subscription/core/UserNotificationSubscription';

const useStyles = makeStyles((theme) => ({
    popover: {
        minHeight: 60,
        width: 360,
        maxHeight: '90%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        borderRadius: theme.spacing(1),
    },
    notificationList: {
        overflow: 'auto',
    },
    bodyMessage: {
        position: 'relative',
        width: '100%',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    textPrimary: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
    messageUnread: {
        width: 8,
        height: 8,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(24, 144, 255)',
    },
    divider: {
        borderStyle: 'dashed',
        borderWidth: '0px 0px thin',
        borderColor: 'rgba(145, 158, 171, 0.24)',
    },
    footerItem: {
        color: 'rgb(145, 158, 171)',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
    },
    textMessage: {
        fontWeight: 400,
        color: 'rgb(99, 115, 129)',
        margin: theme.spacing(0.5, 0),
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 20px',
    },
    containerSMSNotification: {
        fontSize: '14px',
        textDecoration: 'none',
        color: '#959595',
        borderBottom: '1px solid #959595',
        display: 'block',
        padding: theme.spacing(1.5, 0.8),
    },
    smsNotificationHeader: {
        fontWeight: 'bold',
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '& svg g path': {
            fill: '#959595',
        },
    },
    smsNotificationBody: {
        fontSize: '13px',
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    alignCenter: {
        textAlign: 'center',
    },
    todayIcon: {
        color: EventColors.SCHEDULED.backgroundColor,
    },
    callIcon: {
        color: EventColors.CONFIRMED.color,
    },
    taskIcon: {
        color: EventColors.SHOW.color,
    },
    link: {
        textDecoration: 'none',
        color: 'initial',
    },
}));
const initState = {
    notificationCount: 0,
    records: [],
};
const TYPE = {
    COMMUNICATION: 'COMMUNICATION',
    NOTIFICATION: 'NOTIFICATION',
};

const ACTION_TYPE = {
    ADD_NOTIFICATION: 'addNotification',
    SET_NOTIFICATION: 'setNotification',
    UPDATE_NOTIFICATION: 'updateNotification',
    SET_NOTIFICATION_COUNT: 'setNotificationCount',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_NOTIFICATION_COUNT:
        return update(state, {
            notificationCount: { $set: action.payload },
        });
    case ACTION_TYPE.SET_NOTIFICATION:
        return update(state, {
            records: { $set: action.payload },
        });
    case ACTION_TYPE.ADD_NOTIFICATION:
        return update(state, {
            records: { $unshift: [action.payload] },
        });
    case ACTION_TYPE.UPDATE_NOTIFICATION:
        const record = action.payload;
        const index = state.records.findIndex((item) => item.notificationId === record.notificationId);

        if (index >= 0) {
            const recordsUpdated = update(state.records, { [index]: { $set: record } });

            return update(state, {
                records: { $set: recordsUpdated },
            });
        }

        return state;
    default:
        return state;
    }
};

const NotificationBadge = ({ type }) => {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, dispatch] = useReducer(reducer, initState);
    const { data: subscriptionNotificationData } = useSubscription(NotificationSubscription.NOTIFICATION_UPDATED, { variables: { type } });
    const { data: subscriptionData } = useSubscription(UserNotificationSubscription.USER_NOTIFICATION_COUNT_UPDATED, { variables: { type } });
    const [markUserNotificationAsRead] = useMutation(UserNotificationMutation.MARK_USER_NOTIFICATION_AS_READ_BY_NOTIFICATION_ID, { variables: { type } });
    const { data } = useQuery(UserNotificationQuery.GET_UNREAD_USER_NOTIFICATION_COUNT,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY, variables: { type } });
    const { data: notificationData, refetch } = useQuery(NotificationQuery.GET_NOTIFICATION_BY_USER,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY, variables: { type } });
    const [markUserNotificationByTypeAsRead, { loading: updating }] = useMutation(UserNotificationMutation.MARK_USER_NOTIFICATION_BY_TYPE_AS_READ,
        { variables: { type } });
    const emptyList = state.records.length === 0;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const onError = () => {
        ModalUtils.errorMessage(null, MessageUtils.getGenericError('updating', 'Notification'));
    };

    const markAllAsRead = async () => {
        try {
            const response = await markUserNotificationByTypeAsRead();

            if (response?.data?.markUserNotificationByTypeAsRead) {
                refetch();
                setAnchorEl(null);
            } else {
                onError();
            }
        } catch (ex) {
            onError();
        }
    };

    const getIcon = (value) => {
        switch (value?.toUpperCase()) {
        case NOTIFICATION_TYPE.SMS_RECEIVED:
            return <SMSIcon />;
        case NOTIFICATION_TYPE.EMAIL_RECEIVED:
            return icons.email;
        case NOTIFICATION_TYPE.REMINDER_EVENT:
            return <TodayIcon fontSize="small" className={classes.todayIcon} />;
        case NOTIFICATION_TYPE.REMINDER_CALL:
            return <CallIcon fontSize="small" className={classes.callIcon} />;
        case NOTIFICATION_TYPE.REMINDER_TASK:
            return <AssignmentTurnedInIcon fontSize="small" className={classes.taskIcon} />;
        default:
            return null;
        }
    };

    const onClick = async (record) => {
        try {
            const { opportunityId } = record.data;

            if (!record.userNotification.read) {
                await markUserNotificationAsRead({ variables: { notificationId: record.notificationId } });
            }
            setAnchorEl(null);
            history.push(`/${modules.OPPORTUNITIES}/${opportunityId}`, record);
        } catch (ex) {
            onError();
        }
    };

    useEffect(() => {
        if (isFinite(subscriptionData?.userNotificationCountUpdated)) {
            dispatch({
                type: ACTION_TYPE.SET_NOTIFICATION_COUNT,
                payload: subscriptionData.userNotificationCountUpdated,
            });
        }
    }, [subscriptionData]);

    useEffect(() => {
        const result = subscriptionNotificationData?.notificationUpdated;
        if (!isEmpty(result)) {
            if (result.type === SubscriptionActionType.ADDED) {
                dispatch({ type: ACTION_TYPE.ADD_NOTIFICATION, payload: result.notification });
                return;
            }

            if (result.type === SubscriptionActionType.UPDATED) {
                dispatch({ type: ACTION_TYPE.UPDATE_NOTIFICATION, payload: result.notification });
            }
        }
    }, [subscriptionNotificationData]);

    useEffect(() => {
        if (isFinite(data?.getUnreadUserNotificationCount)) {
            dispatch({
                type: ACTION_TYPE.SET_NOTIFICATION_COUNT,
                payload: data.getUnreadUserNotificationCount,
            });
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(notificationData?.getNotificationByUser)) {
            dispatch({
                type: ACTION_TYPE.SET_NOTIFICATION,
                payload: notificationData.getNotificationByUser,
            });
        }
    }, [notificationData]);

    const title = type?.toUpperCase() === TYPE.COMMUNICATION ? 'Messages' : 'Notifications';
    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <Badge
                    badgeContent={state.notificationCount}
                    color="secondary"
                    children={type?.toUpperCase() === TYPE.COMMUNICATION ? <MailOutlineOutlinedIcon /> : <NotificationsOutlinedIcon />}
                />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popover,
                }}
            >
                <div className={classes.header}>
                    <Typography variant="h5">{title}</Typography>
                </div>
                <Divider className={classes.divider} />
                <List className={classes.notificationList}>
                    {state.records.map((item, index) => {
                        const record = JSON.parse(item?.data || '{}');
                        const createdOn = DateUtils.fromNow(item.createdOn);
                        const realizationDate = DateUtils.format(record.realizationDate, DateFormat.DATETIME_WITHOUT_SECONDS);
                        const titleItem = type?.toUpperCase() === TYPE.COMMUNICATION
                            ? `You have new message from ${record.customerName}`
                            : `You have a ${record?.type?.toLowerCase() || ''} reminder`;
                        const bodyMessage = type?.toUpperCase() === TYPE.COMMUNICATION
                            ? record.message
                            : `The ${record?.type?.toLowerCase() ?? ''} with ${record.customerName} start at ${realizationDate}`;

                        return (
                            <RouterLink to={`/${modules.OPPORTUNITIES}/${record?.opportunityId}`} className={classes.link} key={index}>
                                <ListItem
                                    button
                                    key={index}
                                    onClick={() => onClick({ ...item, data: record })}
                                >
                                    <ListItemIcon>
                                        {getIcon(item.type)}
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.textPrimary }}
                                        primary={(
                                            <>
                                                <div className={classes.bodyMessage}>
                                                    <Typography variant="h6">
                                                        {titleItem}
                                                    </Typography>
                                                    <Typography className={classes.textMessage}>{bodyMessage}</Typography>
                                                </div>
                                                <div>
                                                    {!item.userNotification?.read && <span className={classes.messageUnread} />}
                                                </div>
                                            </>
                                        )}
                                        secondary={(
                                            <Typography className={classes.footerItem}>
                                                <AccessTimeIcon
                                                    fontSize="small"
                                                />
                                                <span>{createdOn}</span>
                                            </Typography>
                                        )}
                                    />
                                </ListItem>
                            </RouterLink>
                        );
                    })}
                    <If condition={emptyList}>
                        <ListItem>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ }}
                                primary={(
                                    <div>
                                        <Typography className={classes.alignCenter}>
                                            {`You don't have ${title}`}
                                        </Typography>
                                    </div>
                                )}
                            />
                        </ListItem>
                    </If>
                </List>
                <Divider className={classes.divider} />
                <div className={classes.footer}>
                    <Button onClick={markAllAsRead} disabled={updating || emptyList}>Mark all as read</Button>
                </div>
            </Popover>
        </div>
    );
};

NotificationBadge.propTypes = {
    type: PropTypes.string.isRequired,
};

export { TYPE };
export default NotificationBadge;
