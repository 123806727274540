import { DataSort } from 'utils/enum/Core';

export const INITIAL_STATE = {
    isPrinting: false,
    showFormsDialog: false,
    showWarrantyTemplatesDialog: false,
    params: {
        lots: null,
        sort: {
            field: 'stockNumber',
            dir: DataSort.DESC,
        },
        active: true,
    },
    table: {
        records: [],
    },
    selectedForms: [],
    warranties: [],
    forms: [],
    search: '',
};

export const ACTION_TYPES = {
    TOGGLE_PRINTING: 'togglePrinting',
    TOGGLE_FORMS_DIALOG: 'toggleFormsDialog',
    TOGGLE_WARRANTIES_DIALOG: 'toggleWarrantiesDialog',
    SET_PARAMS: 'setParams',
    SET_SEARCH: 'setSearch',
    SET_TABLE: 'setTable',
    SET_WARRANTIES: 'setWarranties',
    SET_FORMS: 'setForms',
    REFRESH_FORMS: 'refreshForms',
    SET_SELECTED_FORMS: 'setSelectedForms',
    SET_SELECTED_VEHICLE: 'setSelectedVehicle',
    CHANGE_VEHICLE_WARRANTY: 'changeVehicleWarranty',
};

const StickersDialogReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.TOGGLE_PRINTING: {
        return { ...state, isPrinting: !state.isPrinting };
    }
    case ACTION_TYPES.TOGGLE_FORMS_DIALOG: {
        return { ...state, showFormsDialog: !state.showFormsDialog };
    }
    case ACTION_TYPES.TOGGLE_WARRANTIES_DIALOG: {
        return { ...state, showWarrantyTemplatesDialog: !state.showWarrantyTemplatesDialog };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return { ...state, params: action.value };
    }
    case ACTION_TYPES.SET_SEARCH: {
        const { search, selectedVehicles } = action.value;

        return {
            ...state,
            search,
            table: { ...state.table, records: selectedVehicles },
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        const { records, keep } = action.value;
        if (!keep) {
            return { ...state, table: { records } };
        }

        const previousRecords = state.table.records;
        const filtered = records
            .filter((record) => !previousRecords.some((pr) => pr.stockNumber === record.stockNumber));
        const allVehicles = [...previousRecords, ...filtered];
        return { ...state, table: { records: allVehicles } };
    }
    case ACTION_TYPES.SET_WARRANTIES: {
        return { ...state, warranties: action.value };
    }
    case ACTION_TYPES.SET_FORMS: {
        return { ...state, forms: action.value };
    }
    case ACTION_TYPES.REFRESH_FORMS: {
        const { selectedForms } = state;
        const updatedList = action.value;

        return {
            ...state,
            showFormsDialog: false,
            forms: updatedList,
            selectedForms: selectedForms
                .filter((form) => updatedList.some((item) => item.id === form.id)),
        };
    }
    case ACTION_TYPES.SET_SELECTED_FORMS: {
        const { selectedForms } = state;
        const { form, isChecked } = action.value;
        const index = selectedForms.findIndex((selected) => selected.id === form.id);

        if (index !== -1 && !isChecked) {
            return {
                ...state,
                selectedForms: selectedForms.filter((selected) => selected.id !== form.id),
            };
        }

        return { ...state, selectedForms: [...selectedForms, form] };
    }
    case ACTION_TYPES.SET_SELECTED_VEHICLE: {
        const { table: { records } } = state;
        const { vehicle, isChecked } = action.value;
        const index = records
            .findIndex((record) => record.stockNumber === vehicle.stockNumber);

        if (index !== -1) {
            records[index].selected = isChecked;

            return {
                ...state,
                table: {
                    records,
                },
            };
        }

        return state;
    }
    case ACTION_TYPES.CHANGE_VEHICLE_WARRANTY: {
        const { table: { records } } = state;
        const { vehicle, warranty } = action.value;
        const index = records
            .findIndex((record) => record.stockNumber === vehicle.stockNumber);

        if (index !== -1) {
            records[index].warranty = warranty;

            return {
                ...state,
                table: {
                    records,
                },
            };
        }

        return state;
    }
    default:
        return state;
    }
};

export default StickersDialogReducer;
