import React, { useContext, useState, useEffect } from 'react';
import {
    Tooltip, makeStyles, Menu, MenuItem,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {
    Settings as SettingsIcon,
    PowerSettingsNew as PowerSettingsNewIcon,
    DriveEtaOutlined as DriveEtaOutlinedIcon,
    PeopleOutline as PeopleOutlineIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import avatarUser from 'assets/user/userDefault.jpg';
import {
    Link,
    useHistory,
    withRouter,
} from 'react-router-dom';
import { ReactComponent as DealsOutlined } from 'assets/deals.svg';
import { ReactComponent as TextsmsOutlined } from 'assets/chat.svg';
import { ReactComponent as LeadOutlined } from 'assets/lead.svg';
import { ReactComponent as FilterOutlined } from 'assets/general/filter.svg';
import { ReactComponent as CollectionOutlined } from 'assets/general/collection.svg';
import { ReactComponent as PartOutlined } from 'assets/general/part.svg';
import { ReactComponent as ServiceOutlined } from 'assets/general/service.svg';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import PlaylistPlayOutlinedIcon from '@material-ui/icons/PlaylistPlayOutlined';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { modules as appModule } from 'utils/enum/modules';
import MenuSettingKey from 'utils/enum/UserSettingEnum';
import {
    AccountBalanceOutlinedIcon, ArrowBackIosIcon, ArrowForwardIosIcon,
    DashboardOutlinedIcon, FiberManualRecordOutlinedIcon, HomeOutlinedIcon,
    AssessmentOutlinedIcon,
    DeskingIcon, TvOutlinedIcon,
    AppraisalsIcon, BIIcon, BrushOutlinedIcon, AssignmentOutlinedIcon,
} from 'components/icons';
import client from 'services/apollo/ApolloClient';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { isEmpty } from 'lodash';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import {
    ProSidebar, SidebarHeader, SidebarFooter, SidebarContent,
    Menu as MenuSidebar, MenuItem as MenuItemSidebar, SubMenu,
} from 'react-pro-sidebar';
import UserContext from 'components/context/UserContext';
import clsx from 'clsx';

import 'components/layout/menu.scss';
import StringUtils from 'lib/StringUtils';
import { useMutation } from '@apollo/client';
import UserMutation from 'services/graphQL/mutate/core/UserMutation';
import ModalUtils from 'utils/ModalUtils';

const ownStyle = makeStyles((theme) => ({
    menuCollapsed: {
        margin: '5px 25px',
        cursor: 'pointer',
    },
    menuOpened: {
        textAlign: 'right',
        margin: '5px 10px',
        cursor: 'pointer',
    },
    mobileMenuShow: {
        zIndex: '1111',
    },
    menuActive: {
        backgroundColor: theme.palette.background.steelBlue,
        '& div > span > span > svg > g > g > *': {
            stroke: theme.palette.text.white,
        },
        '& div > span > span > svg > g > g > g > *': {
            stroke: theme.palette.text.white,
        },
        '& div svg path': {
            fill: theme.palette.text.white,
        },
    },
    subMenuActive: {
        '& > div > span': {
            color: theme.palette.text.white,
        },
        '& > div > span > span > svg > g > g > *': {
            stroke: theme.palette.text.white,
        },
        '& > div > span > span > svg > g > g > g > *': {
            stroke: theme.palette.text.white,
        },
    },
    navHeaderClass: {
        display: 'flex',
        fontSize: '12px',
        padding: theme.spacing(1.8, 0),
        maxHeight: '70px',
        color: '#fff',
        borderBottom: 'none !important',
        backgroundColor: theme.palette.border.chambray,
    },
    userAvatar: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '38px',
        height: '38px',
        cursor: 'pointer',
    },
    icon: {
        '& > g > g > *': {
            stroke: theme.palette.border.logan,
        },
        '& > g > g > g > *': {
            stroke: theme.palette.border.logan,
        },
    },
    contactContainer: {
        margin: theme.spacing(1, 0),
        '& span': {
            margin: '0px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '12px',
            lineHeight: 1.5,
            fontWeight: 'bold',
        },
        '& > span:nth-child(1)': {
            fontSize: '15px',
        },
    },
    customIcon: {
        display: 'block',
        width: '24px',
        height: '24px',
        '& path, & g path': {
            fill: theme.palette.border.logan,
        },
    },
    supportIcon: {
        fill: theme.palette.background.lightGreen,
    },
}));

const {
    REACT_APP_SUPPORT_PHONE_NUMBER,
    REACT_APP_WEEK_SUPPORT_HOURS,
} = process.env;
const NavMenu = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const keyStore = new KeyStore();
    const isSidebarOpened = keyStore.isSidebarOpened();
    const { externalOpen, setToogleMobile } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSidebar, setOpenSidebar] = useState(isSidebarOpened);
    const [selected, setSelected] = useState();

    const CRM_MESSAGE_CENTER_READ = keyStore.hasPermission(Permission.CRM_MESSAGE_CENTER_READ);
    const CRM_MAILBOX_READ = keyStore.hasPermission(Permission.CRM_MAILBOX_READ);
    const [onLogout, { loading }] = useMutation(UserMutation.LOGOUT);

    const meteorToken = keyStore.getMeteorToken();

    const { userInformation: userData, userSettings } = useContext(UserContext);
    const classes = {
        ...ownStyle(),
    };

    const { menuSettings = [] } = userSettings ?? {};
    const getUserInformation = (record) => {
        if (!isEmpty(record)) {
            const result = [`${record.firstName} ${record.lastName}`, record.username, record.defaultLot];
            return <>{result.map((item, index) => <div key={`selected-${index}`}>{item}</div>)}</>;
        }

        return '';
    };

    const hideChat = () => {
        const chat = window.LiveChatWidget;
        if (chat) {
            chat.call('hide');
        }
    };

    const logOut = async () => {
        try {
            hideChat();

            await onLogout();
            client.resetStore();
            keyStore.clear();
            history.push('/login');
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const urlBelongsToLocation = (url) => {
        if (history.location.pathname.toLowerCase().includes(appModule.SETTINGS.toLowerCase())) {
            return url?.toLowerCase().includes(appModule.SETTINGS.toLowerCase());
        }

        if (history.location.pathname.toLowerCase().includes(appModule.ACCOUNTING.toLowerCase())) {
            return url?.toLowerCase().includes(appModule.ACCOUNTING.toLowerCase());
        }

        if (history.location.pathname.toLowerCase().includes(appModule.INVENTORY.toLowerCase())) {
            return history.location.pathname.toLowerCase() === url?.toLowerCase();
        }

        return history.location.pathname.includes(url);
    };

    const openInNewTab = menuSettings.find((s) => s.key === MenuSettingKey.OPENING_BEHAVIOR)?.value ?? 'true';
    const createSideBar = (items, comeFromParent) => items.filter((c) => c.access).map((item) => {
        if (item?.children?.length > 0) {
            const haveAnyChildrenActive = item.children?.find((c) => c.id === selected || urlBelongsToLocation(c.to));
            return isSidebarOpened ? (
                <SubMenu
                    key={item.id}
                    title={item.text}
                    icon={item.icon}
                    open={haveAnyChildrenActive}
                    className={clsx({ [classes.subMenuActive]: haveAnyChildrenActive })}
                >
                    {createSideBar(item.children, true)}
                </SubMenu>
            )
                : (
                    <Tooltip title={item.text} key={item.id}>
                        <SubMenu
                            icon={item.icon}
                            open={haveAnyChildrenActive}
                            className={clsx({ [classes.subMenuActive]: haveAnyChildrenActive })}
                        >
                            {createSideBar(item.children, true)}
                        </SubMenu>
                    </Tooltip>
                );
        }
        return (
            <Tooltip title={isSidebarOpened || comeFromParent ? '' : item.text} key={item.id}>
                <MenuItemSidebar
                    icon={item.icon}
                    onClick={() => {
                        if (item.onClick) { item.onClick(); return; }
                        if (StringUtils.isEmpty(item.toExternal) && openInNewTab !== 'true') setSelected(item.id);
                    }}
                    active={item.id === selected || urlBelongsToLocation(item.to)}
                    className={clsx({ [classes.menuActive]: item.id === selected || urlBelongsToLocation(item.to) })}
                >
                    {item.text}
                    {item.to && <Link to={item.to} {...(openInNewTab === 'true' ? { target: '_blank' } : {})} />}
                    {item.toExternal && <Link to={{ pathname: item.toExternal }} target="_blank" />}
                </MenuItemSidebar>
            </Tooltip>
        );
    });

    const saveStatusSidebar = (value) => {
        keyStore.setSidebarCollapsed(value);
        setOpenSidebar(value);
    };

    const getCurrentItemSelected = (items) => items.filter((c) => c.access).forEach((item) => {
        if (item?.children?.length > 0) {
            getCurrentItemSelected(item.children);
        }
        if (urlBelongsToLocation(item.to)) {
            setSelected(item.id);
        }
    });

    const openChat = () => {
        if (!userData) return;

        const {
            firstName,
            lastName,
            company: {
                companyName,
            },
        } = userData;
        const chat = window.LiveChatWidget;
        if (chat) {
            chat.init();
            chat.call('maximize');
            chat.call('set_customer_name', `${firstName} ${lastName} (${companyName})`);
        }
    };

    const menuTree = [
        {
            text: 'Home',
            to: '/home',
            icon: <HomeOutlinedIcon />,
            access: true,
            id: 'm1',
        },
        {
            text: 'Business Intelligence',
            to: null,
            icon: <BIIcon className={classes.customIcon} />,
            access: true,
            id: 'm2',
            children: [
                {
                    text: 'Dashboard',
                    to: `/${appModule.DASHBOARD}`,
                    icon: <DashboardOutlinedIcon />,
                    access: true,
                    id: 'm2-1',
                },
                {
                    text: 'Custom Reports',
                    to: `/${appModule.CUSTOM_REPORTS}`,
                    icon: <AssignmentOutlinedIcon />,
                    access: true,
                    id: 'm2-2',
                },
                {
                    text: 'Rules',
                    to: `/${appModule.RULES}`,
                    icon: <BIIcon className={classes.customIcon} />,
                    access: true,
                    id: 'm2-3',
                },
                {
                    text: 'Queries',
                    to: `/${appModule.QUERIES}`,
                    icon: <PlaylistPlayOutlinedIcon />,
                    access: keyStore.hasPermission(Permission.QUERIES_WRITE),
                    id: 'm2-4',
                },
            ],
        },
        {
            text: 'Inventory',
            to: '/inventory',
            icon: <DriveEtaOutlinedIcon />,
            access: keyStore.hasPermission(Permission.INVENTORY_READ),
            id: 'm3',
        },
        {
            text: 'Payment Calls',
            to: null,
            icon: <DeskingIcon className={classes.customIcon} />,
            access: true,
            id: 'm15',
            children: [
                {
                    text: 'Payment Calls',
                    to: `/${appModule.INVENTORY}/payment-calls`,
                    icon: <DeskingIcon className={classes.customIcon} />,
                    access: true,
                    id: 'm15-1',
                },
                {
                    text: 'Appraisals',
                    to: `/${appModule.INVENTORY}/appraisals`,
                    icon: <AppraisalsIcon />,
                    access: keyStore.hasPermission(Permission.INVENTORY_APPRAISALS_READ),
                    id: 'm15-2',
                },
            ],
        },
        {
            text: 'Deals',
            to: '/deals',
            icon: <DealsOutlined className={classes.icon} />,
            access: keyStore.hasPermission(Permission.SALES_READ),
            id: 'm4',
        },
        {
            text: 'CRM',
            to: null,
            icon: <LeadOutlined />,
            access: true,
            id: 'm5',
            children: [
                {
                    text: 'Message Center',
                    to: `/${appModule.COMMUNICATION}`,
                    icon: <ForumOutlinedIcon />,
                    access: CRM_MESSAGE_CENTER_READ || CRM_MAILBOX_READ,
                    id: 'm5-1',
                },
                {
                    text: 'Opportunities',
                    to: `/${appModule.OPPORTUNITIES}`,
                    icon: <FilterOutlined className={classes.customIcon} />,
                    access: keyStore.hasPermission(Permission.CRM_OPPORTUNITY_READ),
                    id: 'm5-2',
                },
                {
                    text: 'Calendar',
                    to: `/${appModule.CALENDAR}`,
                    icon: <CalendarTodayOutlinedIcon />,
                    access: keyStore.hasPermission(Permission.CRM_OPPORTUNITY_READ),
                    id: 'm5-3',
                },
                {
                    text: 'Chat',
                    to: '/conversations',
                    icon: <TextsmsOutlined className={classes.icon} />,
                    access: keyStore.hasPermission(Permission.CRM_CONVERSATION_READ),
                    id: 'm5-4',
                },
                {
                    text: 'Customers',
                    to: '/customers',
                    icon: <PeopleOutlineIcon />,
                    access: keyStore.hasPermission(Permission.CUSTOMER_READ),
                    id: 'm5-5',
                },
                {
                    text: 'Floor Log',
                    to: '/floorlog',
                    icon: <TvOutlinedIcon />,
                    access: keyStore.hasPermission(Permission.CRM_OPPORTUNITY_READ),
                    id: 'm5-6',
                },
            ],
        },
        {
            text: 'Collections',
            icon: <CollectionOutlined className={classes.customIcon} />,
            access: keyStore.hasPermission(Permission.COLLECTION_READ),
            id: 'm17',
            toExternal: `${process.env.REACT_APP_COLLECTION_HTTP}/login-hash?token=${meteorToken}`,
        },
        {
            text: 'Accounting',
            to: `/${appModule.ACCOUNTING}`,
            icon: <AccountBalanceOutlinedIcon />,
            access: keyStore.hasPermission(Permission.ACCOUNTING_READ),
            id: 'm7',
        },
        {
            text: 'Parts',
            to: `/${appModule.PARTS}`,
            icon: <PartOutlined className={classes.customIcon} />,
            access: keyStore.hasPermission(Permission.PARTS_READ),
            id: 'm14',
        },
        {
            text: 'Service',
            to: `/${appModule.SERVICE}`,
            icon: <ServiceOutlined className={classes.customIcon} />,
            access: keyStore.hasPermission(Permission.SERVICE_READ),
            id: 'm16',
        },
        {
            text: 'Payroll',
            to: `/${appModule.PAYROLL}`,
            icon: <AssignmentIndOutlinedIcon />,
            access: keyStore.hasPermission(Permission.PAYROLL_READ),
            id: 'm8',
        },
        {
            text: 'Reports',
            to: null,
            icon: <AssessmentOutlinedIcon />,
            access: keyStore.hasPermission(Permission.REPORTS_INVENTORY_READ),
            id: 'm10',
            children: [
                {
                    text: 'Recon',
                    to: `/${appModule.REPORTS}/recon`,
                    icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
                    access: keyStore.hasPermission(Permission.REPORTS_INVENTORY_READ),
                    id: 'm10-1',
                },
                {
                    text: 'Appraisal History',
                    to: `/${appModule.REPORTS}/appraisal-history`,
                    icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
                    access: keyStore.hasPermission(Permission.REPORTS_INVENTORY_READ),
                    id: 'm10-2',
                },
                {
                    text: 'Sales Tax',
                    to: `/${appModule.REPORTS}/sales-tax`,
                    icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
                    access: keyStore.hasPermission(Permission.REPORTS_FINANCIALS_READ),
                    id: 'm10-3',
                },
                {
                    text: 'Inventory Tax',
                    to: `/${appModule.REPORTS}/inventory-tax`,
                    icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
                    access: keyStore.hasPermission(Permission.REPORTS_FINANCIALS_READ),
                    id: 'm10-4',
                },
            ],
        },
        {
            text: 'Preferences',
            to: `/${appModule.PREFERENCES}`,
            icon: <BrushOutlinedIcon />,
            access: true,
            id: 'm19',
        },
        {
            text: 'Settings',
            to: `/${appModule.SETTINGS}`,
            icon: <SettingsIcon />,
            access: keyStore.hasPermission(Permission.SETTINGS_READ) || keyStore.hasPermission(Permission.USER_SETTINGS_READ),
            id: 'm11',
        },
        {
            text: 'Support',
            icon: <ForumOutlinedIcon className={classes.supportIcon} />,
            access: true,
            id: 'm18',
            onClick: () => openChat(),
        },
    ];

    useEffect(() => {
        getCurrentItemSelected(menuTree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ProSidebar
            collapsed={!isMobile && !openSidebar}
            toggled={externalOpen}
            onToggle={() => setToogleMobile(!externalOpen)}
            breakPoint="lg"
            className={classes.mobileMenuShow}
        >
            <SidebarHeader className={classes.navHeaderClass}>
                <Avatar
                    aria-controls="basic-menu"
                    alt={userData?.username}
                    src={userData?.userPicture ?? avatarUser}
                    className={classes.userAvatar}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                />
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem onClick={() => logOut()} disabled={loading}>
                        <PowerSettingsNewIcon title="Logout" />
                        Logout
                    </MenuItem>
                </Menu>
                {((openSidebar && !isMobile) || (externalOpen && isMobile)) && <div>{getUserInformation(userData)}</div>}
            </SidebarHeader>
            <SidebarContent className="d-flex-column-align-space-between">
                <MenuSidebar
                    iconShape="circle"
                    popperArrow
                    innerSubMenuArrows
                >
                    {createSideBar(menuTree)}
                </MenuSidebar>
                {openSidebar && (
                    <div className={clsx('d-flex-column-center', classes.contactContainer)}>
                        <span>Support Hours:</span>
                        <span>{REACT_APP_WEEK_SUPPORT_HOURS}</span>
                        <span>{REACT_APP_SUPPORT_PHONE_NUMBER}</span>
                    </div>
                )}
            </SidebarContent>
            <SidebarFooter>
                { !isMobile && openSidebar && (
                    <div className={classes.menuOpened}>
                        <ArrowBackIosIcon onClick={() => saveStatusSidebar(false)} />
                    </div>
                ) }
                { !isMobile && !openSidebar && (
                    <div className={classes.menuCollapsed}>
                        <ArrowForwardIosIcon onClick={() => saveStatusSidebar(true)} />
                    </div>
                ) }
            </SidebarFooter>
        </ProSidebar>
    );
};

NavMenu.propTypes = {
    externalOpen: PropTypes.bool.isRequired,
    setToogleMobile: PropTypes.func.isRequired,
};

export default withRouter(NavMenu);
