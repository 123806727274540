import React, { useReducer, useEffect, useContext } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    findIndex, orderBy, concat,
} from 'lodash';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import Filter from 'components/widgets/Filter';
import UserContext from 'components/context/UserContext';
import InputSearch from 'components/widgets/InputSearch';
import MessageCenterCustomerContext from 'components/context/MessageCenterCustomerContext';

// Material Ui
// import FilterListIcon from '@material-ui/icons/FilterList';
import { List, makeStyles, Typography } from '@material-ui/core';

// HTTP
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useSubscription } from '@apollo/client';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import MessageItem from 'components/modules/messageCenter/list/MessageItem';
import MessageCenterQuery from 'services/graphQL/query/crm/MessageCenterQuery';
import MessageCenterSubscription from 'services/graphQL/subscription/crm/MessageCenterSubscription';

const useStyles = makeStyles((theme) => ({
    containerSidebar: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.primary,
    },
    inputSearchContainer: {
        display: 'flex',
        padding: theme.spacing(2, 3),
        flexDirection: 'column',
        '& input': {
            background: 'transparent',
            paddingRight: theme.spacing(7),
        },
        '& input:focus': {
            background: 'transparent',
        },
    },
    box: {
        width: 320,
        minWidth: 320,
        padding: theme.spacing(3),
        overflow: 'auto',
        paddingTop: 0,
    },
    list: {
        padding: 0,
    },
    filterListIcon: {
        marginRight: theme.spacing(0.5),
    },
    label: {
        fontSize: '15px',
        fontWeight: 500,
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.text.boulderGray,
    },
}));

const initState = {
    records: [],
    start: 0,
    totalCount: 0,
    search: '',
    lots: [],
};

const ACTION_TYPES = {
    SET_START: 'setStart',
    ON_SEARCH: 'onSearch',
    LOAD_MORE: 'loadMore',
    ADD_RECORD: 'addRecord',
    RECORD_UPDATED: 'recordUpdated',
    ON_CLEAR_SEARCH: 'onClearSearch',
    ON_CHANGE_VALUE: 'onChangeValue',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.LOAD_MORE:
        const record = action.payload;
        const newRecords = concat(state.records, record.data);
        return update(state, {
            records: { $set: newRecords },
            totalCount: { $set: record.totalCount },
        });
    case ACTION_TYPES.SET_START:
        return update(state, {
            start: { $set: action.payload },
        });
    case ACTION_TYPES.ADD_RECORD:
        return update(state, {
            records: { $unshift: [action.payload] },
            totalCount: { $set: state.totalCount + 1 },
        });
    case ACTION_TYPES.RECORD_UPDATED:
        const recordUpdated = action.payload;
        const index = findIndex(state.records, { smsChatId: recordUpdated.smsChatId });

        if (index >= 0) {
            const result = orderBy(update(state.records, {
                [index]: {
                    lastUpdated: { $set: recordUpdated.lastUpdated },
                    sms: {
                        status: { $set: recordUpdated.sms.status },
                        direction: { $set: recordUpdated.sms.direction },
                    },
                },
            }), ['lastUpdated'], ['desc']);

            return { ...state, records: result };
        }

        return state;
    case ACTION_TYPES.ON_SEARCH:
        return update(state, {
            search: { $set: action.value },
            totalCount: { $set: 0 },
            records: { $set: [] },
        });
    case ACTION_TYPES.ON_CHANGE_VALUE:
        return update(state, {
            [action.field]: { $set: action.value },
            totalCount: { $set: 0 },
            start: { $set: 0 },
            records: { $set: [] },
        });
    case ACTION_TYPES.ON_CLEAR_SEARCH:
        return update(state, {
            search: { $set: '' },
        });
    default:
        return state;
    }
};
const MessageCenterList = ({ conversationId }) => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    const { setCustomer } = useContext(MessageCenterCustomerContext);
    const [state, dispatch] = useReducer(reducer, initState);
    const input = {
        paging: {
            start: state.start,
            limit: 50,
        },
        lots: state.lots.map((item) => item.value),
        search: state.search,
    };
    const {
        loading, data, error,
    } = useQuery(MessageCenterQuery.GET_SMS_CHAT, { variables: input, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const { data: subscriptionData } = useSubscription(MessageCenterSubscription.SMS_CHAT_UPDATED);
    const availableLot = (userInformation?.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }

        if (!loading && data?.getSMSChat) {
            dispatch({
                type: ACTION_TYPES.LOAD_MORE,
                payload: data.getSMSChat,
            });
        }
    }, [loading, data, error]);

    useEffect(() => {
        if (subscriptionData) {
            const { smsChatUpdated } = subscriptionData;
            if (smsChatUpdated?.type?.toUpperCase() === SubscriptionActionType.CREATED) {
                dispatch({
                    type: ACTION_TYPES.ADD_RECORD,
                    payload: smsChatUpdated.smsChat,
                });
            }

            if (smsChatUpdated?.type?.toUpperCase() === SubscriptionActionType.UPDATED) {
                dispatch({
                    type: ACTION_TYPES.RECORD_UPDATED,
                    payload: smsChatUpdated.smsChat,
                });
            }
        }
    }, [subscriptionData]);

    useEffect(() => {
        if (conversationId != null) {
            const selectedRecord = state.records.find((item) => item.smsChatId === conversationId);
            if (selectedRecord) setCustomer({ ...selectedRecord?.customer, opportunity: { leadCode: selectedRecord.sms?.opportunity?.leadCode } });
        }
    }, [conversationId, state.records, setCustomer]);

    const loadMore = () => {
        const start = state.records.length;
        dispatch({
            type: ACTION_TYPES.SET_START,
            payload: start,
        });
    };

    const onSearch = (value) => {
        if (state.search !== value) {
            dispatch({ type: ACTION_TYPES.ON_SEARCH, value });
        }
    };
    const onClearSearch = () => {
        dispatch({ type: ACTION_TYPES.ON_CLEAR_SEARCH });
    };

    const onChangeValue = (value, field) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VALUE,
            field,
            value,
        });
    };

    return (
        <div
            className={classes.containerSidebar}
        >
            <div className={classes.inputSearchContainer}>
                <div>
                    <div className={clsx('d-flex-align-baseline', classes.lotContainer)}>
                        <Typography
                            variant="h5"
                            className={classes.label}
                        >
                            Lot:
                        </Typography>
                        <Filter
                            showTooltip
                            useInternalSearch
                            emptyRecord="All"
                            maxWidthLabel={220}
                            records={availableLot}
                            onClearFilter={() => onChangeValue([], 'lots')}
                            applyFilter={(record) => onChangeValue(record, 'lots')}
                        />
                    </div>
                </div>
                <InputSearch
                    size="sm"
                    forceSearch
                    initialSearch={state.search}
                    customClasses={classes.search}
                    onSearch={onSearch}
                    executeWhenClearButton={onClearSearch}
                />
            </div>
            <InfiniteScroll
                load={loading}
                loadMore={loadMore}
                className={classes.box}
                loadAtScrollPercent={70}
                totalRecord={state.totalCount}
                lengthRecord={state.records.length}
            >
                <List className={classes.list}>
                    {state.records.map((item, index) => <MessageItem record={item} key={index} conversationId={conversationId} />)}
                </List>
            </InfiniteScroll>
        </div>
    );
};

MessageCenterList.propTypes = {
    conversationId: PropTypes.string,
};

MessageCenterList.defaultProps = {
    conversationId: '',
};

export default MessageCenterList;
