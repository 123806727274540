import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Bootstrap
import { Tabs, Tab } from 'react-bootstrap';

// Components and Others
import PropTypes from 'prop-types';
import TabStyles from 'styles/widgets/tabs/TabsStyles';
import { DealTabsTitle } from 'utils/enum/DealEnum';
import TabContainer from 'components/widgets/tabs/TabContainer';
import FormsTab from 'components/modules/deals/read/form/FormsTab';
import HistoryTab from 'components/modules/deals/read/notes/HistoryTab';
import DealTab from 'components/modules/deals/read/dealTab/DealTab';
import BuyerTab from 'components/modules/deals/read/buyer/BuyerTab';
import VehiclesTab from 'components/modules/deals/read/vehicle/VehiclesTab';
import DealTabsContainer from 'components/containers/deals/read/dealTab/DealTabsContainer';
import FilesManager from 'components/widgets/FilesManager';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import CreditDetail from 'components/modules/crm/read/credit/CreditDetail';
import { DOCUMENT_REFERENCE_TYPE } from 'utils/enum/Core';

const styles = (theme) => TabStyles.tabPanel(theme);

class DealTabs extends Component {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_VEHICLE_READ = keyStore.hasPermission(Permission.SALES_DEAL_VEHICLE_READ);
        this.SALES_DEAL_CUSTOMER_READ = keyStore.hasPermission(Permission.SALES_DEAL_CUSTOMER_READ);
        this.SALES_DEAL_FORMS_READ = keyStore.hasPermission(Permission.SALES_DEAL_FORMS_READ);
        this.DEALS_DOCUMENTS_READ = keyStore.hasPermission(Permission.DEALS_DOCUMENTS_READ);
        this.DEALS_DOCUMENTS_WRITE = keyStore.hasPermission(Permission.DEALS_DOCUMENTS_WRITE);
        this.DEALS_DOCUMENTS_DELETE = keyStore.hasPermission(Permission.DEALS_DOCUMENTS_DELETE);

        this.setTabTitle = this.setTabTitle.bind(this);
        this.graphqlClient = new GraphQLClient();
        this.state = {
            stockNumber: 0,
        };
    }

    componentDidMount() {
        const { accountNumber } = this.props;

        this.graphqlClient
            .query(DealsQuery.GET_DEAL_VEHICLES, { accountNumber })
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) return;

                const vehicle = data?.getDealVehicles?.vehicle;
                if (vehicle && vehicle?.stockNumber) {
                    this.setState({ stockNumber: vehicle?.stockNumber });
                }
            });
    }

    setTabTitle(title, key) {
        const { editingMode } = this.props;
        const asterisk = editingMode.tabName === key ? '*' : '';

        return `${title} ${asterisk}`;
    }

    render() {
        const { stockNumber } = this.state;
        const {
            props: {
                classes, accountNumber, onChangeEditingMode, editingMode,
                clientId, setClientId, setLotName, lotName,
            },
        } = this;
        const titleDeal = this.setTabTitle('Deal', DealTabsTitle.DEAL);
        const titleBuyer = this.setTabTitle('Buyer', DealTabsTitle.BUYER);
        const titleVehicle = this.setTabTitle('Vehicles', DealTabsTitle.VEHICLE);

        return (
            <TabContainer>
                <Tabs
                    id="controlled-tab-example"
                    mountOnEnter
                    className={`${classes.borderContainer} ${classes.navbar}`}
                >
                    <Tab className={classes.tab} eventKey={0} mountOnEnter title={titleDeal}>
                        <DealTab
                            clientId={clientId}
                            setLotName={setLotName}
                            editingMode={editingMode}
                            setClientId={setClientId}
                            accountNumber={accountNumber}
                            onChangeEditingMode={onChangeEditingMode}
                        />
                    </Tab>
                    {this.SALES_DEAL_CUSTOMER_READ && (
                        <Tab className={classes.tab} eventKey={1} mountOnEnter title={titleBuyer}>
                            <BuyerTab
                                clientId={clientId}
                                editingMode={editingMode}
                                accountNumber={accountNumber}
                                onChangeEditingMode={onChangeEditingMode}
                            />
                        </Tab>
                    )}
                    {this.SALES_DEAL_VEHICLE_READ && (
                        <Tab className={classes.tab} eventKey={2} mountOnEnter title={titleVehicle}>
                            <VehiclesTab
                                clientId={clientId}
                                editingMode={editingMode}
                                accountNumber={accountNumber}
                                onChangeEditingMode={onChangeEditingMode}
                            />
                        </Tab>
                    )}
                    {this.SALES_DEAL_FORMS_READ && (
                        <Tab className={classes.tab} eventKey={3} mountOnEnter title="Forms">
                            <FormsTab
                                accountNumber={accountNumber}
                                lotName={lotName}
                            />
                        </Tab>
                    )}
                    <Tab className={classes.tab} eventKey={4} mountOnEnter title="Credit">
                        <CreditDetail recordId={accountNumber} recordType="DEAL" lotName={lotName} />
                    </Tab>
                    {this.DEALS_DOCUMENTS_READ && (
                        <Tab className={classes.tab} eventKey={5} mountOnEnter title="Documents">
                            <FilesManager
                                referenceId={accountNumber}
                                associatedReferenceId={stockNumber}
                                referenceType={DOCUMENT_REFERENCE_TYPE.DEALS}
                                maxFileSize={6291456} // 6MB
                                allowedFiles="image/png, image/jpeg, application/pdf"
                                pullDocumentsQuery={DealsQuery.PULL_AVAILABLE_DEAL_DOCUMENTS}
                                getDocumentUploadSignedURL={DealsQuery.GET_DEAL_DOCUMENT_UPLOAD_SIGNED_URL}
                                getDocumentSecureURL={DealsQuery.GET_DEAL_DOCUMENT_SECURE_URL}
                                createDocumentsMutation={DealsMutate.CREATE_DEAL_DOCUMENTS}
                                getAssociatedDocumentUploadSignedURL={InventoryQuery.GET_DOCUMENT_UPLOAD_SIGNED_URL}
                                createAssociatedDocumentsMutation={InventoryMutation.CREATE_DOCUMENTS}
                                editDocumentNameMutation={DealsMutate.EDIT_DEAL_DOCUMENT_NAME}
                                deleteMutation={DealsMutate.DELETE_DEAL_DOCUMENT}
                                canWrite={this.DEALS_DOCUMENTS_WRITE}
                                canDelete={this.DEALS_DOCUMENTS_DELETE}
                            />
                        </Tab>
                    )}
                    <Tab className={classes.tab} eventKey={6} mountOnEnter title="History">
                        <HistoryTab
                            dealId={accountNumber}
                        />
                    </Tab>
                </Tabs>
            </TabContainer>
        );
    }
}

DealTabs.propTypes = {
    accountNumber: PropTypes.number.isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
        tabName: PropTypes.string,
    }).isRequired,
    lotName: PropTypes.string,
    onChangeEditingMode: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    clientId: PropTypes.string.isRequired,
    setClientId: PropTypes.func.isRequired,
    setLotName: PropTypes.func.isRequired,
};

DealTabs.defaultProps = {
    lotName: null,
};

export default withStyles(styles)(DealTabsContainer(DealTabs));
