import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    AutoSizer,
    Table,
    Column,
    defaultTableRowRenderer,
    SortIndicator,
} from 'react-virtualized';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Draggable from 'react-draggable';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';
import Loading from 'components/widgets/Loading';
import If from 'components/widgets/conditional/If';
import StringUtils from 'lib/StringUtils';
import AdvancedColumnFilter from 'components/widgets/AdvancedColumnFilter';

// icons
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

import 'react-virtualized/styles.css';
import 'styles/virtualTable.scss';

const useStyles = makeStyles((theme) => ({
    containerTable: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.text.white,
        '& .ReactVirtualized__Table__headerTruncatedText': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        '& .ReactVirtualized__Table__sortableHeaderIcon': {
            height: '20px',
            width: '20px',
            marginTop: '-4px',
        },
        '& .ReactVirtualized__Table__headerColumn': {
            position: 'relative',
        },
        '& .ReactVirtualized__Table__headerRow': {
            overflow: 'initial !important',
        },
    },
    noRows: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.waterloo,
        fontSize: '12px',
    },
    lazyLoading: {
        position: 'absolute',
        bottom: '0',
        backgroundColor: theme.palette.background.white,
        width: '100%',
        opacity: '0.8',
        overflow: 'hidden',
    },
    filterContainer: {
        top: '6px',
        width: '19px',
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        paddingTop: '2px',
        paddingBottom: '2px',
        justifyContent: 'center',
        right: '4px',
        backgroundColor: theme.palette.background.silverChaliceLight,
        '& svg': {
            width: '13px',
            height: '15px',
            fill: theme.palette.text.white,
        },
    },
    filtered: {
        backgroundColor: `${theme.palette.background.red} !important`,
    },
    helper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        border: `0.5px solid ${theme.palette.border.ghost}`,
        backgroundColor: theme.palette.background.white,
        '& > span': {
            display: 'none',
        },
        '& .ReactVirtualized__Table__headerTruncatedText': {
            fontSize: '12px',
            fontStyle: 'italic',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            '& > svg, & > div': {
                display: 'none',
            },
        },
    },
}));

const SortableTable = SortableContainer(Table);
const SortableRow = SortableElement((props) => defaultTableRowRenderer(props));

const SortableHeader = SortableElement(({ children, ...props }) => React.cloneElement(children, props));
const SortableHeaderRowRenderer = SortableContainer(
    ({ className, columns, style }) => (
        <div className={className} role="row" style={style}>
            {React.Children.map(columns, (column, index) => (
                <SortableHeader index={index}>{column}</SortableHeader>
            ))}
        </div>
    ),
);

const VirtualTable = forwardRef((props, ref) => {
    const distanceInPixels = 5;
    const classes = useStyles();

    const {
        customTableContainerStyle,
        columns,
        data,
        totalRecords,
        loadMore,
        width,
        height,
        headerHeight,
        rowHeight,
        loading,
        sort,
        sortBy,
        sortDirection,
        rowStyleFormat,
        areRowsDraggable,
        areHeadersDraggable,
        useAdvancedFeatures,
        sortRow,
        filters,
        loadFilterValues,
        resetFilters,
        applyFilters,
        columnsData,
        onRowDoubleClick,
        onColumnSorted,
        onColumnResized,
        customRowRenderer,
        className,
    } = props;

    const [tableWidth, setTableWidth] = useState(width);
    const [tableWidthBackUp, setTableWidthBackUp] = useState(width);
    const [currentWidth, setCurrentWidth] = useState();
    const [tableColumns, setTableColumns] = useState();
    const [isResizingColumn, setIsResizingColumn] = useState(false);
    const [filter, setFilter] = useState({
        display: false,
        column: null,
    });

    const onScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
        const bottomReached = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
        if (!totalRecords || !loadMore) return;

        if (bottomReached && data.length < totalRecords && !loading) {
            loadMore();
        }
    };

    const toggleFilterBox = (column) => {
        if (
            !StringUtils.isEmpty(filter.column)
            && !StringUtils.isEmpty(column)
            && filter.column !== column
            && filter.display
        ) {
            setFilter({
                display: true,
                column,
            });

            return;
        }

        setFilter({
            display: !column ? false : !filter.display,
            column,
        });
    };

    const getNewColumnWidth = (modifiedWidth, deltaX) => Math.round(modifiedWidth + deltaX);

    useImperativeHandle(ref, () => ({
        closeFilterBox() {
            toggleFilterBox();
        },
    }));

    const rowRenderer = (properties) => (areRowsDraggable
        ? <SortableRow {...properties} />
        : ((customRowRenderer && customRowRenderer(properties)) || defaultTableRowRenderer(properties)));

    const customHeaderRenderer = (headerRenderProp) => {
        const {
            dataKey,
            label,
            sortBy: sb,
            sortDirection: sd,
        } = headerRenderProp;

        const tableWithoutFilters = !loadFilterValues && !resetFilters && !applyFilters;
        const filterItem = filters.find((cd) => cd.columnName === dataKey);
        const isFilteredByValue = Array.isArray(filterItem?.values);
        const columnData = columnsData.find((cd) => cd.column === dataKey);

        const currentColumn = columns.find((column) => column.dataKey === dataKey);
        const { filterEnabled } = currentColumn;
        return (
            <React.Fragment key={dataKey}>
                <div className="ReactVirtualized__Table__headerTruncatedText">
                    {label}
                    {sb === dataKey && (
                        <SortIndicator sortDirection={sd} />
                    )}
                    {loadFilterValues && filterEnabled && (
                        <>
                            <div
                                data-is-filter="true"
                                className={
                                    clsx(
                                        classes.filterContainer,
                                        (isFilteredByValue && filterItem.values.length > 0 && filterItem.values.length !== columnData?.records?.length)
                                        || (!isFilteredByValue && Object.keys(filterItem?.values ?? {}).length > 0) ? classes.filtered : '',
                                    )
                                }
                                onClick={(e) => {
                                    toggleFilterBox(dataKey);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <KeyboardArrowDownOutlinedIcon />
                            </div>
                            <If condition={filter.display && filter.column === dataKey}>
                                <AdvancedColumnFilter
                                    column={filter.column}
                                    columns={columns}
                                    filters={filters}
                                    loadFilterValues={loadFilterValues}
                                    resetFilters={resetFilters}
                                    applyFilters={applyFilters}
                                    columnsData={columnsData}
                                    toggleFilterBox={toggleFilterBox}
                                    useAdvancedFeatures={useAdvancedFeatures}
                                    position="absolute"
                                    containerTop={33}
                                    containerLeft={-11}
                                />
                            </If>
                        </>
                    )}
                </div>
                <Draggable
                    axis="x"
                    defaultClassName="DragHandle"
                    defaultClassNameDragging="DragHandleActive"
                    position={{ x: 0 }}
                    onDrag={(event, { deltaX }) => {
                        setIsResizingColumn(true);

                        const newWidth = getNewColumnWidth(currentWidth[dataKey], deltaX);
                        if (newWidth >= 40) setCurrentWidth({ ...currentWidth, [dataKey]: newWidth });
                    }}
                    onStop={(_, { deltaX }) => setTimeout(() => {
                        setIsResizingColumn(false);
                        onColumnResized(dataKey, getNewColumnWidth(currentWidth[dataKey], deltaX));
                    }, 500)}
                    zIndex={999}
                >
                    <span className="DragHandleIcon">{tableWithoutFilters ? '⋮' : '\xa0\xa0'}</span>
                </Draggable>
            </React.Fragment>
        );
    };

    const headerRowRenderer = (params) => (
        <SortableHeaderRowRenderer
            {...params}
            axis="x"
            lockAxis="x"
            distance={isResizingColumn ? 5000 : 10}
            helperClass={classes.helper}
            onSortEnd={({ oldIndex, newIndex }) => onColumnSorted(oldIndex, newIndex)}
        />
    );

    useEffect(() => {
        const columnWidth = {};

        columns.forEach((col) => {
            if (currentWidth && currentWidth[col.dataKey]) {
                columnWidth[col.dataKey] = currentWidth[col.dataKey];
            } else {
                columnWidth[col.dataKey] = col.width;
            }
        });
        setCurrentWidth(columnWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, filter]);

    useEffect(() => {
        if (!isEmpty(currentWidth)) {
            const newColumns = columns.map((col) => (
                <Column
                    {...col}
                    key={col.dataKey}
                    width={currentWidth[col.dataKey]}
                    headerRenderer={col.headerRenderer ?? customHeaderRenderer}
                />
            ));

            setTableColumns(newColumns);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWidth]);

    useEffect(() => {
        if (width > 0) setTableWidth(width); setTableWidthBackUp(width);
    }, [width]);

    useEffect(() => {
        if (tableWidth && tableColumns?.length > 0) {
            const calculatedWidth = Math.round(tableColumns.reduce((a, b) => a + (b?.props?.width ?? 0), 0));
            if (calculatedWidth !== tableWidth) {
                if (calculatedWidth >= tableWidthBackUp) setTableWidth(calculatedWidth);
                if (calculatedWidth < tableWidthBackUp) setTableWidth(tableWidthBackUp);
            }
        }
    }, [tableColumns, tableWidth, tableWidthBackUp]);

    return (
        <div
            className={clsx(classes.containerTable, customTableContainerStyle)}
        >
            <AutoSizer>
                {(autoSizerProps) => (
                    <>
                        {areRowsDraggable && (
                            <SortableTable
                                axis="y"
                                distance={distanceInPixels}
                                overscanRowCount={10}
                                width={tableWidth > 0 ? tableWidth : autoSizerProps.width}
                                height={height > 0 ? height : autoSizerProps.height}
                                headerHeight={headerHeight}
                                rowHeight={rowHeight}
                                rowCount={data.length}
                                rowGetter={({ index }) => data[index]}
                                noRowsRenderer={() => {
                                    if (loading) {
                                        return (
                                            <Loading className="loading-table" />
                                        );
                                    }

                                    return (
                                        <div className={classes.noRows}>
                                            No rows found
                                        </div>
                                    );
                                }}
                                {...(!isResizingColumn ? { sort } : {})}
                                {...(!isResizingColumn ? { sortBy } : {})}
                                {...(!isResizingColumn ? { sortDirection } : {})}
                                {...(areHeadersDraggable ? { headerRowRenderer } : {})}
                                className={className}
                                onScroll={onScroll}
                                rowStyle={rowStyleFormat}
                                rowRenderer={rowRenderer}
                                onSortEnd={sortRow}
                                onRowDoubleClick={onRowDoubleClick}
                            >
                                {tableColumns}
                            </SortableTable>
                        )}
                        {!areRowsDraggable && (
                            <Table
                                overscanRowCount={10}
                                autoContainerWidth
                                width={tableWidth > 0 ? tableWidth : autoSizerProps.width}
                                height={height > 0 ? height : autoSizerProps.height}
                                headerHeight={headerHeight}
                                rowHeight={rowHeight}
                                rowCount={data.length}
                                rowGetter={({ index }) => data[index]}
                                noRowsRenderer={() => {
                                    if (loading) {
                                        return (
                                            <Loading className="loading-table" />
                                        );
                                    }

                                    return (
                                        <div className={classes.noRows}>
                                            No rows found
                                        </div>
                                    );
                                }}
                                {...(!isResizingColumn ? { sort } : {})}
                                {...(!isResizingColumn ? { sortBy } : {})}
                                {...(!isResizingColumn ? { sortDirection } : {})}
                                {...(areHeadersDraggable ? { headerRowRenderer } : {})}
                                className={className}
                                onScroll={onScroll}
                                rowStyle={rowStyleFormat}
                                onRowDoubleClick={onRowDoubleClick}
                                rowRenderer={rowRenderer}
                            >
                                {tableColumns}
                            </Table>
                        )}
                    </>
                )}
            </AutoSizer>
            <If condition={!loadMore ? false : data.length > 0 && loading}>
                <div className={classes.lazyLoading}>
                    <Loading className="loading-table" />
                </div>
            </If>
        </div>
    );
});

VirtualTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    height: PropTypes.number,
    width: PropTypes.number,
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number || PropTypes.func,
    loading: PropTypes.bool.isRequired,
    sort: PropTypes.func,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    totalRecords: PropTypes.number,
    loadMore: PropTypes.func,
    customTableContainerStyle: PropTypes.string,
    rowStyleFormat: PropTypes.func,
    areRowsDraggable: PropTypes.bool,
    areHeadersDraggable: PropTypes.bool,
    useAdvancedFeatures: PropTypes.bool,
    sortRow: PropTypes.func,
    filters: PropTypes.array,
    loadFilterValues: PropTypes.func,
    resetFilters: PropTypes.func,
    applyFilters: PropTypes.func,
    columnsData: PropTypes.array,
    onRowDoubleClick: PropTypes.func,
    onColumnSorted: PropTypes.func,
    onColumnResized: PropTypes.func,
    customRowRenderer: PropTypes.func,
    className: PropTypes.string,
};

VirtualTable.defaultProps = {
    columns: [],
    data: [],
    width: 0,
    height: 0,
    headerHeight: 30,
    rowHeight: 40,
    sort: undefined,
    sortBy: '',
    sortDirection: 'ASC',
    totalRecords: undefined,
    loadMore: undefined,
    customTableContainerStyle: '',
    rowStyleFormat: undefined,
    areRowsDraggable: false,
    areHeadersDraggable: false,
    useAdvancedFeatures: false,
    sortRow: undefined,
    filters: [],
    loadFilterValues: undefined,
    resetFilters: undefined,
    applyFilters: undefined,
    columnsData: [],
    onRowDoubleClick: () => null,
    onColumnSorted: () => null,
    onColumnResized: () => null,
    customRowRenderer: undefined,
    className: '',
};

export default VirtualTable;
