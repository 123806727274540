import DateUtils from 'lib/DateUtils';
import { cloneDeep, remove, map } from 'lodash';
import { ProductType, DealType, DealStatus } from 'utils/enum/DealEnum';

export default class DealMap {
    static mapSave(record) {
        if (record) {
            const { product = {}, vendor = {} } = record;

            return {
                dealId: record.dealId,
                type: record.type,
                productId: product.productId,
                vendorId: vendor.vendorId,
                price: record.price,
                cost: record.cost,
                term: record.term,
                policyNumber: record.policyNumber.trim(),
                addToSellingPrice: record.addToSellingPrice,
                dealProductId: record.dealProductId,
            };
        }

        return {};
    }

    static mapDealStructure(record) {
        if (record) {
            const lockDeal = record.dealType === DealType.BHPH && record.status === DealStatus.Accounting;

            return {
                assigned: record.assigned,
                balanceDue: record.balanceDue,
                buyRate: record.buyRate,
                cashDownPayment: record.cashDownPayment,
                dealType: record.dealType,
                status: record.status,
                discount: record.discount,
                discountOverride: record.discountOverride,
                financeAmount: record.financeAmount,
                firstPaymentDue: new Date(DateUtils.getOnlyDate(record.firstPaymentDue)),
                firstPaymentDueDays: record.firstPaymentDueDays,
                interestRate: record.interestRate,
                paymentAmount: record.paymentAmount,
                paymentFrequency: record.paymentFrequency,
                registerOutState: record.registerOutState,
                reserveAmount: record.reserveAmount,
                reserveOverride: record.reserveOverride,
                salesPrice: record.salesPrice,
                salesTax: record.salesTax,
                soldDate: new Date(DateUtils.getOnlyDate(record.soldDate)),
                tagPlate: record.tagPlate,
                tagPlateType: record.tagPlateType,
                taxableAmount: record.taxableAmount,
                term: record.term,
                totalDeferredDownPayment: record.totalDeferredDownPayment,
                totalFees: record.totalFees,
                totalProductsPrice: record.totalProductsPrice,
                totalTradeAllowance: record.totalTradeAllowance,
                totalTradePayOff: record.totalTradePayOff,
                dealerFee: record.dealerFee,
                inspectionFee: record.inspectionFee,
                tagAgencyFee: record.tagAgencyFee,
                userField1: record.userField1,
                userField2: record.userField2,
                userField3: record.userField3,
                userField4: record.userField4,
                userField5: record.userField5,
                userField6: record.userField6,
                userField7: record.userField7,
                registration: record.registration,
                title: record.title,
                tags: record.tags,
                totalTitleRegistration: record.totalTitleRegistration,
                taxExempt: record.taxExempt || false,
                salesTaxOverride: record.salesTaxOverride,
                inventoryTaxOverride: record.inventoryTaxOverride,
                inventoryTaxAmount: record.dealerInventoryTax,
                state: record.state || '',
                stockNumber: record.stockNumber,
                county: record.county,
                city: record.city,
                country: record.country,
                lotName: record.lotName,
                floorPlanCost: record.floorPlanCost,
                isResidualPercent: Boolean(Number(record.isResidualPercent)),
                isUpFrontLeaseTaxes: record.isUpFrontLeaseTaxes,
                mbi: record.mbi,
                msrp: record.msrp,
                leaseOveragePerMile: record.leaseOveragePerMile,
                plannedAnnualMileage: record.plannedAnnualMileage,
                contractAnnualMileage: record.contractAnnualMileage,
                terminationFee: record.terminationFee,
                leaseEndingOverage: record.leaseEndingOverage,
                rebates: record.rebates,
                reservePercent: record.reservePercent,
                residualPercent: record.residualPercent,
                residualValue: record.residualValue,
                docStamp: record.docStamp,
                financeCompany: record.financeCompany,
                tiPayoff1: record.tiPayoff1,
                tiPayoff2: record.tiPayoff2,
                bankOriginAmount: record.bankOriginAmount,
                regZAPR: record.regZAPR,
                loanContractId: record.loanContractId,
                portfolioId: record.portfolioId,
                maxRate: record.maxRate,
                clientId: lockDeal ? 'Locked' : record.clientId,
                postedDate: record.postedDate,
                netDue: record.netDue,
                salesTaxRate: record.salesTaxRate,
                overrideTaxRate: record.salesTaxRate > 0,
                finalPaymentDue: new Date(DateUtils.getOnlyDate(record.finalPaymentDue)),
                finalPaymentAmount: record.finalPaymentAmount,
                paymentOverride: record.paymentOverride,
                minimumPaymentOverride: record.minimumPaymentOverride,
                paymentOverridePercent: record.paymentOverridePercent,
                leadSource: record.leadSource,
                financeCharge: record.originalFinanceCharge,
                balanceDueOverride: record.balanceDueOverride,
                balanceDueOverrideAmount: record.balanceDueOverrideAmount || record.balanceDue,
                leasePaymentTax: record.monthlyLeaseTax,
                leasePaymentAmount: record.paymentAmount,
                grossCapitalizedCost: record.totalGrossCapitalizedCost,
                cashDueAtSigning: record.leaseTotalDueAtSigning,
                backEndTax: record.backEndTax,
            };
        }

        return {};
    }

    static mapDealPreview(record, newRecord) {
        if (record && newRecord) {
            const dealStructure = record;

            dealStructure.salesPrice = newRecord.price;
            dealStructure.totalProductsPrice = newRecord.totalProductsPrice;
            dealStructure.taxableAmount = newRecord.taxableAmount;
            dealStructure.salesTax = newRecord.salesTax ? newRecord.salesTax.taxAmount : 0;
            dealStructure.salesTaxOverride = newRecord.salesTax ? newRecord.salesTax.taxOverride : false;
            dealStructure.taxExempt = newRecord.salesTax ? newRecord.salesTax.taxExempt : false;
            dealStructure.inventoryTaxAmount = newRecord.salesTax ? newRecord.salesTax.inventoryTaxAmount : 0;
            dealStructure.inventoryTaxOverride = newRecord.salesTax ? newRecord.salesTax.inventoryTaxOverride : false;
            dealStructure.balanceDue = newRecord.balanceDue;
            dealStructure.financeAmount = newRecord.finance ? newRecord.finance.amountFinanced : 0;
            dealStructure.docStamp = newRecord.finance ? newRecord.finance.docStamp : 0;
            dealStructure.interestRate = newRecord.maxRate > 0 && dealStructure.maxRate !== newRecord.maxRate ? newRecord.maxRate : newRecord.interestRate;
            dealStructure.regZAPR = newRecord.regZAPR;
            dealStructure.maxRate = newRecord.maxRate;
            dealStructure.buyRate = newRecord.buyRate;
            dealStructure.totalFees = newRecord.totalFees;
            dealStructure.reserveAmount = newRecord.reserve ? (newRecord.reserve.amount || 0) : 0;
            dealStructure.paymentAmount = newRecord.payment || 0;
            dealStructure.discount = newRecord.discount ? (newRecord.discount.amount || 0) : 0;
            dealStructure.discountOverride = newRecord.discount ? (newRecord.discount.override || false) : false;
            dealStructure.term = newRecord.term || 0;
            dealStructure.finalPaymentDue = newRecord.finalPaymentDue ? new Date(DateUtils.getOnlyDate(newRecord.finalPaymentDue)) : null;
            dealStructure.finalPaymentAmount = newRecord.finalPaymentAmount || 0;
            dealStructure.minimumPaymentOverride = newRecord.minimumPaymentOverride || 0;
            dealStructure.paymentOverridePercent = newRecord.paymentOverridePercent || 0;
            dealStructure.financeCharge = newRecord.finance.originalFinanceCharge || 0;
            dealStructure.balanceDueOverride = newRecord.balanceDueOverride || '';
            dealStructure.balanceDueOverrideAmount = newRecord.balanceDueOverrideAmount || 0;
            dealStructure.cashDownPayment = newRecord.cashDownPayment || 0;
            dealStructure.capCostReduction = newRecord.capCostReduction || 0;
            dealStructure.leasePaymentAmount = newRecord.leasePaymentAmount || 0;
            dealStructure.leasePaymentTax = newRecord.leasePaymentTax || 0;
            dealStructure.grossCapitalizedCost = newRecord.grossCapitalizedCost || 0;
            dealStructure.cashDueAtSigning = newRecord.cashDueAtSigning || 0;
            dealStructure.backEndTax = newRecord.backEndTax || 0;

            return dealStructure;
        }

        return {};
    }

    static mapDealRecap(record) {
        if (record) {
            return {
                purchasedPrice: record.purchasedPrice,
                pack: record.pack,
                repairs: record.repairs,
                estimatedFloorPlanCost: record.estimatedFloorPlanCost,
                weOwe: record.weOwe,
                vehicleCost: record.vehicleCost,
                vehicleGross: record.vehicleGross,
                fees: record.fees,
                tradeEquity: record.tradeEquity,
                lenderDiscount: record.lenderDiscount,
                frontEndGross: record.frontEndGross,
                salesRep1Commission: record.salesRep1,
                salesRep2Commission: record.salesRep2,
                bdcAgentCommission: record.bdcAgent,
                salesManagerCommission: record.salesManager,
                financeManagerCommission: record.financeManager,
                vsc: record.vsc,
                gap: record.gap,
                products: record.products,
                reserve: record.reserve,
                backEndGross: record.backEndGross,
                totalDealProfit: record.totalDealProfit,
                totalCommissions: record.totalCommissions,
                salesPerson1Employee: record.salesPerson1Employee,
                salesPerson2Employee: record.salesPerson2Employee,
                financeManagerEmployee: record.financeManagerEmployee,
                salesManagerEmployee: record.salesManagerEmployee,
                bdcAgentEmployee: record.bdcAgentEmployee,
                commission1Override: record.commission1Override,
                commission2Override: record.commission2Override,
                salesManagerOverride: record.salesManagerOverride,
                bdcAgentOverride: record.bdcAgentOverride,
                financeCommissionOverride: record.financeCommissionOverride,
            };
        }
        return {};
    }

    static mapRecapPreview(record, newRecord) {
        if (record && newRecord) {
            const recapData = record;

            recapData.vehicleGross = newRecord.vehicleGross;
            recapData.fees = newRecord.fees;
            recapData.tradeEquity = newRecord.tradeEquity;
            recapData.lenderDiscount = newRecord.lenderDiscount;
            recapData.vsc = newRecord.vsc;
            recapData.gap = newRecord.gap;
            recapData.products = newRecord.products;
            recapData.reserve = newRecord.reserve;
            recapData.salesRep1Commission = newRecord.commissions.salesRep1Commission;
            recapData.salesRep2Commission = newRecord.commissions.salesRep2Commission;
            recapData.bdcAgentCommission = newRecord.commissions.bdcAgentCommission;
            recapData.salesManagerCommission = newRecord.commissions.salesManagerCommission;
            recapData.financeManagerCommission = newRecord.commissions.financeManagerCommission;
            recapData.backEndGross = newRecord.backEndGross;
            recapData.frontEndGross = newRecord.frontEndGross;
            recapData.totalDealProfit = newRecord.totalDealProfit;
            recapData.totalCommissions = newRecord.totalCommissions;

            return recapData;
        }

        return {};
    }

    static mapInputPreview(dealStructure) {
        const downPayment = (dealStructure.cashDownPayment + dealStructure.totalDeferredDownPayment) || 0;

        if (dealStructure) {
            return {
                buyRate: dealStructure.buyRate,
                state: dealStructure.state || '',
                contractAnnualMileage: dealStructure.contractAnnualMileage,
                city: dealStructure.city || '',
                country: dealStructure.country || '',
                countyTaxName: dealStructure.county || '',
                dealType: dealStructure.dealType,
                dealerFee: dealStructure.dealerFee || 0,
                discount: dealStructure.discount,
                discountOverride: dealStructure.discountOverride,
                cashDownPayment: dealStructure.cashDownPayment,
                downPayment,
                financeCompany: dealStructure.financeCompany || '',
                firstPaymentDue: dealStructure.firstPaymentDue,
                floorPlanCost: dealStructure.floorPlanCost || 0,
                inspectionFee: dealStructure.inspectionFee || 0,
                interestRate: dealStructure.interestRate,
                isResidualPercent: dealStructure.isResidualPercent,
                isUpFrontLeaseTaxes: dealStructure.isUpFrontLeaseTaxes,
                leaseOveragePerMile: dealStructure.leaseOveragePerMile,
                lotName: dealStructure.lotName,
                mbi: dealStructure.mbi,
                msrp: dealStructure.msrp,
                paymentFrequency: dealStructure.paymentFrequency,
                plannedAnnualMileage: dealStructure.plannedAnnualMileage,
                rebates: dealStructure.rebates,
                regZAPR: dealStructure.regZAPR || 0,
                registration: dealStructure.registration,
                reserveAmount: dealStructure.reserveAmount,
                reserveOverride: dealStructure.reserveOverride,
                residualPercent: dealStructure.residualPercent,
                residualValue: dealStructure.residualValue,
                salesPrice: dealStructure.salesPrice,
                salesmanPrice: dealStructure.salesPrice,
                soldDate: dealStructure.soldDate,
                stockNumber: dealStructure.stockNumber,
                tagAgencyFee: dealStructure.tagAgencyFee,
                tagCost: dealStructure.tagCost || 0,
                tags: dealStructure.tags || 0,
                term: dealStructure.term,
                title: dealStructure.title,
                totalWeOwe: dealStructure.totalWeOwe || 0,
                userField1: dealStructure.userField1 || 0,
                userField2: dealStructure.userField2 || 0,
                userField3: dealStructure.userField3 || 0,
                userField4: dealStructure.userField4 || 0,
                userField5: dealStructure.userField5 || 0,
                userField6: dealStructure.userField6 || 0,
                userField7: dealStructure.userField7 || 0,
                loanContractId: dealStructure.loanContractTemplateId,
                originationFee: dealStructure.bankOriginAmount,
                paymentOverride: dealStructure.paymentOverride,
                paymentAmount: dealStructure.paymentAmount,
                balanceDueOverride: dealStructure.balanceDueOverride || '',
                balanceDueOverrideAmount: dealStructure.balanceDueOverrideAmount || 0,
                registerOutState: dealStructure.registerOutState || false,
                backEndTax: dealStructure.backEndTax || 0,
            };
        }

        return {};
    }

    static mapSalesTaxInputPreview(dealStructure) {
        if (dealStructure) {
            return {
                taxExempt: dealStructure.taxExempt,
                taxOverride: dealStructure.salesTaxOverride,
                taxAmount: dealStructure.salesTax,
                inventoryTaxOverride: dealStructure.inventoryTaxOverride,
                inventoryTaxAmount: dealStructure.inventoryTaxAmount,
                salesTaxRate: dealStructure.salesTaxRate,
            };
        }

        return {};
    }

    static mapToUpdate(record) {
        if (record) {
            const downPayment = (record.cashDownPayment + record.totalDeferredDownPayment) || 0;

            const newRecord = {
                assigned: record.assigned,
                balanceDue: record.balanceDue,
                balanceDueOverride: record.balanceDueOverride,
                balanceDueOverrideAmount: record.balanceDueOverrideAmount,
                buyRate: record.buyRate,
                cashDownPayment: record.cashDownPayment,
                dealType: record.dealType,
                discount: record.discount,
                discountOverride: record.discountOverride,
                financeAmount: record.financeAmount,
                firstPaymentDue: DateUtils.getOnlyDate(record.firstPaymentDue),
                firstPaymentDueDays: record.firstPaymentDueDays,
                interestRate: record.interestRate,
                paymentAmount: record.paymentAmount,
                paymentOverride: record.paymentOverride,
                paymentFrequency: record.paymentFrequency,
                registerOutState: record.registerOutState,
                reserveAmount: record.reserveAmount,
                reserveOverride: record.reserveOverride,
                salesPrice: record.salesPrice,
                salesmanPrice: record.salesPrice,
                taxExempt: record.taxExempt,
                salesTax: record.salesTax,
                salesTaxOverride: record.salesTaxOverride,
                dealerInventoryTax: record.inventoryTaxAmount,
                inventoryTaxOverride: record.inventoryTaxOverride,
                soldDate: DateUtils.getOnlyDate(record.soldDate),
                tagPlate: record.tagPlate,
                tagPlateType: record.tagPlateType,
                taxableAmount: record.taxableAmount,
                term: record.term,
                downPayment,
                totalDeferredDownPayment: record.totalDeferredDownPayment,
                totalFees: record.totalFees,
                totalProductsPrice: record.totalProductsPrice,
                totalTradeIn: record.totalTradeIn,
                dealerFee: record.dealerFee,
                userField1: record.userField1,
                userField2: record.userField2,
                userField3: record.userField3,
                userField4: record.userField4,
                userField5: record.userField5,
                userField6: record.userField6,
                userField7: record.userField7,
                financeCompany: record.financeCompany,
                registration: record.registration,
                tagAgencyFee: record.tagAgencyFee,
                inspectionFee: record.inspectionFee,
                mbi: record.mbi,
                title: record.title,
                tags: record.tags,
                totalTitleRegistration: record.totalTitleRegistration,
                bankOriginAmount: record.bankOriginAmount,
                regZAPR: record.regZAPR,
                portfolioId: record.portfolioId,
                loanContractId: record.loanContractId,
                salesTaxRate: record.salesTaxRate,
                leadSource: record.leadSource,
                residualValue: record.residualValue,
                contractAnnualMileage: record.contractAnnualMileage,
                terminationFee: record.terminationFee,
                leaseEndingOverage: record.leaseEndingOverage,
                plannedAnnualMileage: record.plannedAnnualMileage,
                leaseOveragePerMile: record.leaseOveragePerMile,
            };

            return newRecord;
        }

        return {};
    }

    static mapSaveWeOwe(record) {
        if (record) {
            return {
                dealNumber: record.dealNumber,
                labor: record.labor,
                nameOfItem: record.nameOfItem,
                part: record.part,
                quantity: record.quantity,
                weOweId: record.weOweId,
            };
        }

        return {};
    }

    static mapListDeferredDownPayment(record) {
        if (record) {
            return {
                deferredDownPaymentId: record.deferredDownPaymentId,
                paymentAmount: record.paymentAmount,
                paymentDate: DateUtils.isValid(record.paymentDate) ? new Date(DateUtils.getOnlyDate(record.paymentDate)) : '',
                paymentNumber: record.paymentNumber,
            };
        }

        return {};
    }

    static mapDeferredDownPaymentCreateDeal(record, index) {
        if (record) {
            return {
                paymentNumber: index,
                amount: record.paymentAmount,
                dueDate: DateUtils.getOnlyDate(record.paymentDate),
            };
        }

        return {};
    }

    static mapProduct(record) {
        const productList = [];
        const productReg = new RegExp(`^${ProductType.PRODUCT}(\\s[0-9]+)?$`, 'i');
        const backUp = cloneDeep(record);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < record.length; i++) {
            if (productReg.test(record[i].productType)) {
                productList.push(...record[i].vendor);

                remove(backUp, { productType: record[i].productType });
            }
        }
        const product = {
            productType: ProductType.PRODUCT,
            vendor: productList,
        };

        backUp.push(product);

        return backUp;
    }

    static mapDetail(record) {
        const {
            deal,
            deferredPayments,
        } = record;

        const currentDealStructure = DealMap.mapDealStructure(deal);
        const currentDeferredPayments = map(deferredPayments, (item) => DealMap.mapListDeferredDownPayment(item));

        if (record) {
            return {
                ...record,
                deal: currentDealStructure,
                deferredPayments: currentDeferredPayments || [],
            };
        }

        return {};
    }

    static mapCommissions(commissions) {
        return {
            salesPersonId1: commissions.salespersonId1,
            commissions1: commissions.commissions1,
            commission1Override: commissions.commission1Override,
            salesPerson1Paid: commissions.salesPerson1Paid,
            salesPersonId2: commissions.salespersonId2,
            commissions2: commissions.commissions2,
            commission2Override: commissions.commission2Override,
            salesPerson2Paid: commissions.salesPerson2Paid,
            bdcAgentId: commissions.bdcAgentId,
            bdcAgentCommission: commissions.bdcAgentCommission,
            bdcAgentOverride: commissions.bdcAgentOverride,
            bdcAgentPaid: commissions.bdcAgentPaid,
            salesManagerId: commissions.salesManagerId,
            salesManagerCommission: commissions.salesManagerCommission,
            salesManagerOverride: commissions.salesManagerOverride,
            salesManagerPaid: commissions.salesManagerPaid,
            financeManagerId: commissions.financeManagerId,
            financeCommissions: commissions.financeCommissions,
            financeCommissionOverride: commissions.financeCommissionOverride,
            financeManagerPaid: commissions.financeManagerPaid,
        };
    }

    static mapUserCommission(user) {
        if (!user) return '';

        return `${user.firstName || ''} ${user.lastName || ''}`;
    }

    static mapTradeIn(record) {
        const { payOffGoodTill } = record;

        return {
            ...record,
            payOffGoodTill: payOffGoodTill ? new Date(payOffGoodTill) : null,
        };
    }

    static mapDealVehicles(data) {
        const { deal, tradeIns, vehicle } = data;
        const dealVehicles = {
            deal,
            tradeIns: map(tradeIns, (item) => this.mapTradeIn(item)),
            vehicle,
        };
        return dealVehicles;
    }

    static mapLeadSource(record) {
        if (record && record?.length > 0) {
            return record.map((item) => ({
                label: item.leadSource,
                value: item.leadSource,
            }));
        }

        return [];
    }
}
