import React, { Component } from 'react';

// Components and Others
import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LotSettingsContainer from 'components/containers/settings/lots/LotSettingsContainer';
import { Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import PhoneInput from 'components/widgets/form/PhoneInput';
import {
    Grid, Tooltip,
} from '@material-ui/core';
import Select from 'components/widgets/Select';
import CompanyImage from 'components/widgets/settings/CompanyImage';
import { isEmpty } from 'lodash';
import Dropzone from 'react-dropzone';
import InputNumber from 'components/widgets/InputNumber';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import LotSettingsStyles from 'styles/modules/settings/LotSettingsStyles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';

import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import VendorUtils from 'utils/VendorUtils';
import LotUtils from 'utils/LotUtils';
import TimeZoneUtil from 'lib/TimeZoneUtil';
import { LotSettingSchema } from 'utils/schema/settings/LotSettingSchema';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DatePicker from 'react-datepicker';

const styles = (theme) => LotSettingsStyles.listStyles(theme);

class LotSettings extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SETTINGS_GENERAL_WRITE = keyStore.hasPermission(Permission.SETTINGS_GENERAL_WRITE);
    }

    async onAddImages(files) {
        const {
            props: {
                onUploadFile,
            },
        } = this;

        onUploadFile(files[0]);
    }

    getStates() {
        const fields = VendorUtils.getVendorStates();
        return fields.map((item) => ({ value: item, label: item }));
    }

    getLotTypes() {
        const fields = LotUtils.getLotTypes();
        return fields.map((item) => ({ value: item, label: item }));
    }

    getTimezones() {
        const fields = TimeZoneUtil.getTimeZoneIds();
        return fields.map((item) => ({ value: item, label: item }));
    }

    openFileDialog() {
        const { current } = this.uploader;
        if (current) current.click();
    }

    render() {
        const {
            props: {
                classes, record, onChangeValue,
                onEnableDecoder, onSave, availableLots, currentLotName, lotWhitelistedIp,
            },
        } = this;

        const isValidData = isValidSchema(LotSettingSchema, record);
        const { isValid, errors } = isValidData;
        const lots = (availableLots || []).map((item) => ({ value: item.lotName, label: item.lotName }));

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Select
                            className={classes.selectLot}
                            name="currentLotName"
                            value={currentLotName}
                            placeholder="Select Lot"
                            options={lots}
                            onChange={onChangeValue}
                        />
                    )}
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.saveButton)}
                            startIcon={<SaveIcon />}
                            disabled={!isValid}
                            onClick={() => onSave()}
                        >
                            Save Info
                        </Button>
                    )}
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Dropzone
                            multiple={false}
                            accept="image/*"
                            onDrop={(acceptedFiles) => this.onAddImages(acceptedFiles)}
                            className={classes.dragzone}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                className={clsx(classes.button, classes.editButton)}
                                startIcon={<CloudUploadOutlined />}
                            >
                                Upload Picture
                            </Button>
                        </Dropzone>
                    )}
                </div>
                <Grid container spacing={1} className={classes.box}>
                    <Grid container spacing={1} className={classes.border}>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Form.Group as={Col}>
                                <Form.Label>Lot Name</Form.Label>
                                <Form.Control
                                    value={record.lotName}
                                    type="text"
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>DBA Name</Form.Label>
                                <Form.Control
                                    value={record.dbaName}
                                    type="text"
                                    onChange={(e) => onChangeValue('dbaName', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    className={isValidField(errors, 'lotAddress') ? 'invalid-field' : ''}
                                    value={record.lotAddress}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotAddress', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    className={isValidField(errors, 'lotCity') ? 'invalid-field' : ''}
                                    value={record.lotCity}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotCity', e.target.value)}
                                />
                            </Form.Group>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Form.Group as={Col}>
                                <Form.Label>State</Form.Label>
                                <Select
                                    name="lotState"
                                    value={record.lotState}
                                    options={this.getStates()}
                                    placeholder="Select"
                                    className={isValidField(errors, 'lotState') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Zip</Form.Label>
                                <NumberFormat
                                    value={record.lotZip}
                                    className={isValidField(errors, 'lotZip') ? 'form-control invalid-field' : 'form-control'}
                                    placeholder="Placeholder"
                                    format="#####"
                                    onFocus={onEnableDecoder}
                                    onValueChange={({ value }) => onChangeValue('lotZip', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Phone</Form.Label>
                                <PhoneInput
                                    value={record.lotPhone}
                                    className={isValidField(errors, 'lotPhone') ? 'invalid-field' : ''}
                                    onChange={(value) => onChangeValue('lotPhone', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Fax</Form.Label>
                                <PhoneInput
                                    value={record.lotFax}
                                    onChange={(value) => onChangeValue('lotFax', value)}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.imageContainer}>
                            <Grid container className={classes.companyImage}>
                                <CompanyImage
                                    image={record.pictureUrl}
                                    width="100%"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={classes.border}>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Form.Group as={Col}>
                                <Form.Label>Lot Type</Form.Label>
                                <Select
                                    name="lotType"
                                    value={record.lotType}
                                    options={this.getLotTypes()}
                                    placeholder="Select"
                                    className={isValidField(errors, 'lotType') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Active Since</Form.Label>
                                <DatePicker
                                    selected={isEmpty(record.activeSince) ? '' : moment(record.activeSince, 'YYYY-MM-DD').toDate()}
                                    size="sm"
                                    name="activeSince"
                                    className="form-control"
                                    wrapperClassName={classes.wrapperDate}
                                    placeholderText="mm/dd/yyyy"
                                    popperContainer={CalendarContainer}
                                    onChange={(value) => onChangeValue('activeSince', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Contact Person</Form.Label>
                                <Form.Control
                                    value={record.lotContactPerson}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotContactPerson', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Time Zone</Form.Label>
                                <Select
                                    name="lotTimeZoneId"
                                    value={record.lotTimeZoneId}
                                    options={this.getTimezones()}
                                    placeholder="Select"
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Dealer License #</Form.Label>
                                <Form.Control
                                    value={record.lotDln}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotDln', e.target.value)}
                                />
                            </Form.Group>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Form.Group as={Col}>
                                <Form.Label>Tax Registration #</Form.Label>
                                <Form.Control
                                    value={record.lotTaxReg}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotTaxReg', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>MVR</Form.Label>
                                <Form.Control
                                    value={record.lotMvr}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotMvr', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>MV Number</Form.Label>
                                <Form.Control
                                    value={record.lotMvNumber}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotMvNumber', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>State Registration</Form.Label>
                                <Form.Control
                                    value={record.lotStateRegistration}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotStateRegistration', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    <Tooltip title="Only numbers, semicolons and periods allowed" placement="top">
                                        <span>IP Addresses for TimeClock</span>
                                    </Tooltip>
                                </Form.Label>
                                <Form.Control
                                    value={lotWhitelistedIp}
                                    type="text"
                                    onChange={(e) => onChangeValue('lotWhitelistedIp', e.target.value)}
                                />
                            </Form.Group>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Form.Group as={Col}>
                                <Form.Label>Disposition Fee</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.dispositionFee}
                                    onChange={(value) => onChangeValue('dispositionFee', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Early Termination Fee</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.earlyTerminationFee}
                                    onChange={(value) => onChangeValue('earlyTerminationFee', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Puchase Option Fee</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.purchaseOptionFee}
                                    onChange={(value) => onChangeValue('purchaseOptionFee', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inventory Tax</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={10}
                                    value={record.inventoryTax}
                                    onChange={(value) => onChangeValue('inventoryTax', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Pack Cost</Form.Label>
                                <InputNumber
                                    className={isValidField(errors, 'packCost') ? 'invalid-field' : ''}
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.packCost}
                                    onChange={(value) => onChangeValue('packCost', value)}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Form.Group as={Col}>
                                <Form.Check
                                    onChange={(e) => onChangeValue('bhphInHouse', e.target.checked)}
                                    checked={record.bhphInHouse}
                                    className={classes.alignCheckBox}
                                    inline
                                    label="BHPH is in-house"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Check
                                    onChange={(e) => onChangeValue('noTaxOnRoLabor', e.target.checked)}
                                    checked={record.noTaxOnRoLabor}
                                    className={classes.alignCheckBox}
                                    inline
                                    label="No Tax on RO Labor"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Check
                                    onChange={(e) => onChangeValue('active', e.target.checked)}
                                    checked={record.active}
                                    className={classes.alignCheckBox}
                                    inline
                                    label="Active"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={clsx(classes.border, classes.fees)}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Dealer Fee</Form.Label>
                                <InputNumber
                                    className={isValidField(errors, 'dealerFee') ? 'invalid-field' : ''}
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.dealerFee}
                                    onChange={(value) => onChangeValue('dealerFee', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayDealerFee', e.target.checked)}
                                    checked={record.displayDealerFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('dealerFeeTaxable', e.target.checked)}
                                    checked={record.dealerFeeTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('dealerFeeCapitalized', e.target.checked)}
                                    checked={record.dealerFeeCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Acquisition Fee</Form.Label>
                                <InputNumber
                                    value={record.mbi}
                                    className={isValidField(errors, 'mbi') ? 'invalid-field' : ''}
                                    placeholder="0"
                                    decimalScale={2}
                                    onChange={(value) => onChangeValue('mbi', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayAcquisitionFee', e.target.checked)}
                                    checked={record.displayAcquisitionFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('mbiTaxable', e.target.checked)}
                                    checked={record.mbiTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('mbiCapitalized', e.target.checked)}
                                    checked={record.mbiCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Tag Agency Fee</Form.Label>
                                <InputNumber
                                    className={isValidField(errors, 'tagAgencyFee') ? 'invalid-field' : ''}
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.tagAgencyFee}
                                    onChange={(value) => onChangeValue('tagAgencyFee', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayTagAgencyFee', e.target.checked)}
                                    checked={record.displayTagAgencyFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('tagAgencyFeeTaxable', e.target.checked)}
                                    checked={record.tagAgencyFeeTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('tagAgencyFeeCapitalized', e.target.checked)}
                                    checked={record.tagAgencyFeeCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Tag Fee</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.tagFee}
                                    onChange={(value) => onChangeValue('tagFee', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayTagFee', e.target.checked)}
                                    checked={record.displayTagFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('tagFeeTaxable', e.target.checked)}
                                    checked={record.tagFeeTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('tagFeeCapitalized', e.target.checked)}
                                    checked={record.tagFeeCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Registration Fee</Form.Label>
                                <InputNumber
                                    className={isValidField(errors, 'registrationFee') ? 'invalid-field' : ''}
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.registrationFee}
                                    onChange={(value) => onChangeValue('registrationFee', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayRegistrationFee', e.target.checked)}
                                    checked={record.displayRegistrationFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('registrationFeeTaxable', e.target.checked)}
                                    checked={record.registrationFeeTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('registrationFeeCapitalized', e.target.checked)}
                                    checked={record.registrationFeeCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Title Fee</Form.Label>
                                <InputNumber
                                    className={isValidField(errors, 'titleFee') ? 'invalid-field' : ''}
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.titleFee}
                                    onChange={(value) => onChangeValue('titleFee', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayTitleFee', e.target.checked)}
                                    checked={record.displayTitleFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('titleFeeTaxable', e.target.checked)}
                                    checked={record.titleFeeTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('titleFeeCapitalized', e.target.checked)}
                                    checked={record.titleFeeCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Inspection Fee</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.inspectionFee}
                                    onChange={(value) => onChangeValue('inspectionFee', value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayInspectionFee', e.target.checked)}
                                    checked={record.displayInspectionFee}
                                    inline
                                    label="Display"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('inspectionFeeTaxable', e.target.checked)}
                                    checked={record.inspectionFeeTaxable}
                                    inline
                                    label="Taxable"
                                />
                                <Form.Check
                                    onChange={(e) => onChangeValue('inspectionFeeCapitalized', e.target.checked)}
                                    checked={record.inspectionFeeCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Service Contract</Form.Label>
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('serviceContractCapitalized', e.target.checked)}
                                    checked={record.serviceContractCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>GAP Insurance</Form.Label>
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('gapCapitalized', e.target.checked)}
                                    checked={record.gapCapitalized}
                                    inline
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Products</Form.Label>
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('productsTaxable', e.target.checked)}
                                    checked={record.productsTaxable}
                                    inline
                                    label="Taxable"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={clsx(classes.border, classes.customFee)}>
                        <Grid item xs={3}>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>User Fee Amount</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>1:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField1}
                                    onChange={(value) => onChangeValue('userField1', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>2:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField2}
                                    onChange={(value) => onChangeValue('userField2', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>3:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField3}
                                    onChange={(value) => onChangeValue('userField3', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>4:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField4}
                                    onChange={(value) => onChangeValue('userField4', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>5:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField5}
                                    onChange={(value) => onChangeValue('userField5', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>6:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField6}
                                    onChange={(value) => onChangeValue('userField6', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.labelUserField}>7:</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.userField7}
                                    onChange={(value) => onChangeValue('userField7', value)}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={7}>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Label className={classes.label}>Field Name</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField1Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField1Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField1', e.target.checked)}
                                    checked={record.displayUserField1}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField1Taxable', e.target.checked)}
                                    checked={record.userField1Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField1Capitalized', e.target.checked)}
                                    checked={record.userField1Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField2Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField2Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField2', e.target.checked)}
                                    checked={record.displayUserField2}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField2Taxable', e.target.checked)}
                                    checked={record.userField2Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField2Capitalized', e.target.checked)}
                                    checked={record.userField2Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField3Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField3Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField3', e.target.checked)}
                                    checked={record.displayUserField3}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField3Taxable', e.target.checked)}
                                    checked={record.userField3Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField3Capitalized', e.target.checked)}
                                    checked={record.userField3Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField4Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField4Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField4', e.target.checked)}
                                    checked={record.displayUserField4}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField4Taxable', e.target.checked)}
                                    checked={record.userField4Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField4Capitalized', e.target.checked)}
                                    checked={record.userField4Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField5Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField5Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField5', e.target.checked)}
                                    checked={record.displayUserField5}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField5Taxable', e.target.checked)}
                                    checked={record.userField5Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField5Capitalized', e.target.checked)}
                                    checked={record.userField5Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField6Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField6Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField6', e.target.checked)}
                                    checked={record.displayUserField6}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField6Taxable', e.target.checked)}
                                    checked={record.userField6Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField6Capitalized', e.target.checked)}
                                    checked={record.userField6Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.inline}>
                                <Form.Control
                                    value={record.userField7Name}
                                    type="text"
                                    onChange={(e) => onChangeValue('userField7Name', e.target.value)}
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('displayUserField7', e.target.checked)}
                                    checked={record.displayUserField7}
                                    label="Display"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField7Taxable', e.target.checked)}
                                    checked={record.userField7Taxable}
                                    label="Taxable"
                                />
                                <Form.Check
                                    className={classes.leftSpace}
                                    onChange={(e) => onChangeValue('userField7Capitalized', e.target.checked)}
                                    checked={record.userField7Capitalized}
                                    label="Capitilized"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

LotSettings.propTypes = {
    classes: PropTypes.object.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    currentLotName: PropTypes.string.isRequired,
    availableLots: PropTypes.array,
    lotWhitelistedIp: PropTypes.string,
    record: PropTypes.shape({
        active: PropTypes.bool,
        activeSince: PropTypes.string,
        bhphInHouse: PropTypes.bool,
        dbaName: PropTypes.string,
        dealerFee: PropTypes.number,
        dealerFeeCapitalized: PropTypes.bool,
        dealerFeeEditable: PropTypes.bool,
        dealerFeeTaxable: PropTypes.bool,
        displayAcquisitionFee: PropTypes.bool,
        displayDealerFee: PropTypes.bool,
        displayInspectionFee: PropTypes.bool,
        displayRegistrationFee: PropTypes.bool,
        displayTagAgencyFee: PropTypes.bool,
        displayTagFee: PropTypes.bool,
        displayTitleFee: PropTypes.bool,
        displayUserField1: PropTypes.bool,
        displayUserField2: PropTypes.bool,
        displayUserField3: PropTypes.bool,
        displayUserField4: PropTypes.bool,
        displayUserField5: PropTypes.bool,
        displayUserField6: PropTypes.bool,
        displayUserField7: PropTypes.bool,
        dispositionFee: PropTypes.number,
        earlyTerminationFee: PropTypes.number,
        gapCapitalized: PropTypes.bool,
        inspectionFee: PropTypes.number,
        inspectionFeeCapitalized: PropTypes.bool,
        inspectionFeeTaxable: PropTypes.bool,
        inventoryTax: PropTypes.number,
        lotAddress: PropTypes.string,
        lotCity: PropTypes.string,
        lotContactPerson: PropTypes.string,
        lotDln: PropTypes.string,
        lotFax: PropTypes.string,
        lotId: PropTypes.number,
        lotMvNumber: PropTypes.string,
        lotMvr: PropTypes.string,
        lotName: PropTypes.string,
        lotPhone: PropTypes.string,
        lotState: PropTypes.string,
        lotStateRegistration: PropTypes.string,
        lotTaxReg: PropTypes.string,
        lotTimeZone: PropTypes.number,
        lotTimeZoneId: PropTypes.string,
        lotType: PropTypes.string,
        lotZip: PropTypes.string,
        mbi: PropTypes.number,
        mbiCapitalized: PropTypes.bool,
        mbiTaxable: PropTypes.bool,
        noTaxOnRoLabor: PropTypes.bool,
        overridePassword: PropTypes.string,
        packCost: PropTypes.number,
        pictureUrl: PropTypes.string,
        productsTaxable: PropTypes.bool,
        purchaseOptionFee: PropTypes.number,
        registrationFee: PropTypes.number,
        registrationFeeCapitalized: PropTypes.bool,
        registrationFeeTaxable: PropTypes.bool,
        serviceContractCapitalized: PropTypes.bool,
        tagAgencyFee: PropTypes.number,
        tagAgencyFeeCapitalized: PropTypes.bool,
        tagAgencyFeeTaxable: PropTypes.bool,
        tagFee: PropTypes.number,
        tagFeeCapitalized: PropTypes.bool,
        tagFeeTaxable: PropTypes.bool,
        titleFee: PropTypes.number,
        titleFeeCapitalized: PropTypes.bool,
        titleFeeTaxable: PropTypes.bool,
        userField1: PropTypes.number,
        userField1Capitalized: PropTypes.bool,
        userField1Name: PropTypes.string,
        userField1Taxable: PropTypes.bool,
        userField2: PropTypes.number,
        userField2Capitalized: PropTypes.bool,
        userField2Name: PropTypes.string,
        userField2Taxable: PropTypes.bool,
        userField3: PropTypes.number,
        userField3Capitalized: PropTypes.bool,
        userField3Name: PropTypes.string,
        userField3Taxable: PropTypes.bool,
        userField4: PropTypes.number,
        userField4Capitalized: PropTypes.bool,
        userField4Name: PropTypes.string,
        userField4Taxable: PropTypes.bool,
        userField5: PropTypes.number,
        userField5Capitalized: PropTypes.bool,
        userField5Name: PropTypes.string,
        userField5Taxable: PropTypes.bool,
        userField6: PropTypes.number,
        userField6Capitalized: PropTypes.bool,
        userField6Name: PropTypes.string,
        userField6Taxable: PropTypes.bool,
        userField7: PropTypes.number,
        userField7Capitalized: PropTypes.bool,
        userField7Name: PropTypes.string,
        userField7Taxable: PropTypes.bool,
    }),
};

LotSettings.defaultProps = {
    record: {},
    availableLots: [],
    lotWhitelistedIp: '',
};

export default withRouter(withStyles(styles)(LotSettingsContainer(LotSettings)));
