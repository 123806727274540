export default class DynamicColor {
    static getClass() {
        const number = Math.floor(Math.random() * (6)) + 1;

        return `avatarColor${number}`;
    }

    static getStyles(theme) {
        const { palette: { listColor } } = theme;
        const data = {};

        listColor.forEach((item, index) => {
            const name = `avatarColor${index + 1}`;
            data[name] = { backgroundColor: item };
        });

        return data;
    }
}
