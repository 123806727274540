import React, {
    useEffect,
    useContext,
    useReducer,
} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Grid, FormControl, FormControlLabel, Radio, RadioGroup,
    fade,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import Select from 'components/widgets/Select';
import { isEmpty } from 'lodash';
import Jodit from 'components/widgets/editor/JoditEditor';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import { Col, Form } from 'react-bootstrap';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';
import DealsSettingsMutate from 'services/graphQL/mutate/DealsSettingsMutate';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';
import InputNumber from 'components/widgets/InputNumber';

const joditConfiguration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 300,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    toolbarButtonSize: 'small',
    buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'ul', 'ol',
        'indent', 'outdent', 'left', 'font', 'fontsize', 'paragraph', 'classSpan', 'brush',
        'image', 'link', 'source',
    ],
};

const ACTION_TYPES = {
    ON_CHANGE_LOT: 'onChangeLot',
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    ON_CHANGE_DISCLOSURE: 'onChangeDisclosure',
    ON_CHANGE_GLOBAL_DEALER_ID: 'OnChangeGlobalDealerId',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.SET_RECORD_CHANGED:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.ON_CHANGE_LOT:
        return update(state, {
            lotId: { $set: action.payload },
        });
    case ACTION_TYPES.ON_CHANGE_DISCLOSURE:
        return update(state, {
            disclosure: { $set: action.payload },
        });
    case ACTION_TYPES.ON_CHANGE_GLOBAL_DEALER_ID:
        return update(state, {
            globalDealerId: { $set: action.payload },
        });
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    subLabel: {
        fontSize: '0.875rem',
    },
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
    paddingLeft25: {
        paddingLeft: '25px',
    },
    input: {
        fontSize: '14px',
    },
    customTabsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    alignCheckBox: {
        marginBottom: '10px',
        alignItems: 'baseline',
    },
    dealerId: {
        '& > div': {
            display: 'flex',
            '& > input': {
                width: '150px',
                marginRight: '5px',
            },
        },
    },
    editor: {
        '& > div:nth-child(2)': {
            border: `solid 1px ${theme.palette.border.mercury}`,
        },
    },
}));

const DealsGeneralSettings = ({ writable }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation = {} } = useContext(UserContext);
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, {
        lotId: keyStore.getSelectedLot().lotId,
        record: {
            internetPrice: true,
            stickerPrice: false,
            minimumPrice: false,
            docStampPercentage: 0,
            leaveDealsInQuote: 0,
            defaultTerm: 0,
            defaultInterestRate: 0,
            defaultPaymentFrequency: 'Monthly',
            salesTaxMaximumEnabled: false,
            salesTaxMaximumValue: 0,
            allowTransferWithoutDownpayment: false,
            defaultFinanceCalculation: '',
        },
        disclosure: null,
        globalDealerId: null,
    });

    const availableLots = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));
    const {
        record,
        lotId,
        disclosure,
        globalDealerId,
    } = state;

    const {
        data: menuSettingsData,
        loading: loadingMenuSettings,
        error: errorLoadingMenuSettings,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.MENU,
            key: [LotDefaultskey.MENU_DISCLOSURE, LotDefaultskey.FI_GLOBAL_DEALER_ID],
            lotName: ALL_LOTS,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getSettings, { loading }] = useLazyQuery(DealsSettingsQuery.DEALS_SETTINGS_GENERAL, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: response.getDealSettingsGeneral || {},
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (errorLoadingMenuSettings) {
            ModalUtils.errorMessage(errorLoadingMenuSettings?.graphQLErrors);
            return;
        }

        if (!loadingMenuSettings) {
            const data = menuSettingsData?.getSettings;
            if (ArrayUtils.isNotEmpty(data)) {
                const disclosureValue = data.find((x) => x.key === LotDefaultskey.MENU_DISCLOSURE)?.value;
                const globalId = data.find((x) => x.key === LotDefaultskey.FI_GLOBAL_DEALER_ID)?.value;

                dispatch({
                    type: ACTION_TYPES.ON_CHANGE_DISCLOSURE,
                    payload: disclosureValue,
                });

                if (globalId) {
                    dispatch({
                        type: ACTION_TYPES.ON_CHANGE_GLOBAL_DEALER_ID,
                        payload: globalId,
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMenuSettings, errorLoadingMenuSettings]);

    useEffect(() => {
        let { lotName } = keyStore.getSelectedLot();
        if (!isEmpty(availableLots)) {
            const currentLot = availableLots.find((obj) => obj.value === state.lotId);
            if (!isEmpty(currentLot)) {
                lotName = currentLot.label;
            }
        }

        if (lotName) {
            getSettings({
                variables: {
                    lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lotId]);

    const [saveMenuSetting, { loading: isSavingMenuSetting }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Menu setting saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the menu setting');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const onSaveGlobalDealerId = () => {
        saveMenuSetting({
            variables: {
                category: LotsCategory.MENU,
                key: LotDefaultskey.FI_GLOBAL_DEALER_ID,
                value: String(globalDealerId),
                lotName: ALL_LOTS,
                critical: false,
            },
        });
    };

    const onSaveDisclosure = () => {
        saveMenuSetting({
            variables: {
                category: LotsCategory.MENU,
                key: LotDefaultskey.MENU_DISCLOSURE,
                value: disclosure,
                lotName: ALL_LOTS,
                critical: false,
            },
        });
    };

    const [saveGeneralSettings, { loading: isSavingSettings }] = useMutation(DealsSettingsMutate.SAVE_DEALS_SETTINGS_GENERAL, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Deal General Settings saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the Deal General Settings');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const saveSettings = () => {
        const currentLot = availableLots.find((obj) => obj.value === lotId);

        if (currentLot) {
            saveGeneralSettings({
                variables: {
                    input: { ...record, defaultFinanceCalculation: record.defaultFinanceCalculation || 'term' },
                    lotName: currentLot.label,
                },
            });
        }
    };

    const onChange = async (field, value) => {
        if (field === 'disclosure') {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_DISCLOSURE,
                payload: value,
            });

            return;
        }

        if (field === 'globalDealerId') {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_GLOBAL_DEALER_ID,
                payload: value,
            });

            return;
        }

        if (field === 'overrideSalesPrice') {
            const currentRecord = { ...record };
            switch (value) {
            case 0:
                currentRecord.internetPrice = true;
                currentRecord.stickerPrice = false;
                currentRecord.minimumPrice = false;
                break;
            case 1:
                currentRecord.internetPrice = false;
                currentRecord.stickerPrice = true;
                currentRecord.minimumPrice = false;
                break;
            case 2:
                currentRecord.internetPrice = false;
                currentRecord.stickerPrice = false;
                currentRecord.minimumPrice = true;
                break;
            default: return;
            }

            dispatch({
                type: ACTION_TYPES.SET_RECORD_CHANGED,
                value: currentRecord,
            });
            return;
        }

        if (value === record[field]) return;
        const currentRecord = { ...record };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangeLot = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_LOT,
            payload: value,
        });
    };

    const getOverrideSalesPrice = () => {
        let index = 0;
        if (record.internetPrice) {
            index = 0;
        } else if (record.stickerPrice) {
            index = 1;
        } else if (record.minimumPrice) {
            index = 2;
        }

        return index;
    };

    const shouldDisableButton = () => ((loading || isSavingSettings));
    const saveButtonText = () => ((isSavingSettings) ? 'Saving' : 'Save');

    return (
        <>
            <Grid container className={classes.box}>
                <Grid item sm={5} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Lot</Form.Label>
                                <Select
                                    size="sm"
                                    name="lotId"
                                    value={state.lotId}
                                    placeholder="Select"
                                    options={availableLots}
                                    onChange={onChangeLot}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid item lg={8} md={10} xs={12}>
                        <Form.Group as={Col}>
                            <Form.Label>No override sales price</Form.Label>
                            <FormControl component="fieldset">
                                <RadioGroup row value={getOverrideSalesPrice()} onChange={(e, val) => onChange('overrideSalesPrice', Number(val))}>
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio color="primary" />}
                                        label="Internet Price"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label="Sticker Price"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio color="primary" />}
                                        label="Minimum Price"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Form.Group>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Doc Stamp #</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={4}
                                    value={record.docStampPercentage}
                                    onChange={(value) => onChange('docStampPercentage', value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Leave Deals In Quote For</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={0}
                                    value={record.leaveDealsInQuote}
                                    onChange={(value) => onChange('leaveDealsInQuote', value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Default Term</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={0}
                                    value={record.defaultTerm}
                                    onChange={(value) => onChange('defaultTerm', value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Default Interest Rate</Form.Label>
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.defaultInterestRate}
                                    onChange={(value) => onChange('defaultInterestRate', value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Default Frequency</Form.Label>
                                <Select
                                    name="defaultPaymentFrequency"
                                    value={record.defaultPaymentFrequency}
                                    options={[
                                        {
                                            value: 'Monthly',
                                            label: 'Monthly',
                                        },
                                        {
                                            value: 'Semi-Monthly',
                                            label: 'Semi-Monthly',
                                        },
                                        {
                                            value: 'Weekly',
                                            label: 'Weekly',
                                        },
                                        {
                                            value: 'Bi-Weekly',
                                            label: 'Bi-Weekly',
                                        },
                                    ]}
                                    placeholder="Select"
                                    maxMenuHeight={140}
                                    onChange={onChange}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Default Calculation</Form.Label>
                                <Select
                                    name="defaultFinanceCalculation"
                                    value={record.defaultFinanceCalculation}
                                    options={[
                                        {
                                            value: 'term',
                                            label: 'Term',
                                        },
                                        {
                                            value: 'paymentAmount',
                                            label: 'Payment Amount',
                                        },
                                    ]}
                                    placeholder="Select"
                                    maxMenuHeight={140}
                                    onChange={onChange}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Check
                                    onChange={(e) => onChange('salesTaxMaximumEnabled', e.target.checked)}
                                    checked={record.salesTaxMaximumEnabled}
                                    name="salesTaxMaximumEnabled"
                                    className={classes.alignCheckBox}
                                    inline
                                    label="Sales Tax Maximum"
                                />
                                <InputNumber
                                    placeholder="0"
                                    decimalScale={2}
                                    value={record.salesTaxMaximumValue}
                                    onChange={(value) => onChange('salesTaxMaximumValue', value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={8} md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Check
                                    onChange={(e) => onChange('allowTransferWithoutDownpayment', e.target.checked)}
                                    checked={record.allowTransferWithoutDownpayment}
                                    className={classes.alignCheckBox}
                                    inline
                                    label="Allow Transfer Without Downpayment Collection"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <If condition={writable}>
                        <Grid container>
                            <Grid item lg={8} md={10} xs={12}>
                                <Form.Group className={classes.customTabsSave} as={Col}>
                                    <Button
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        disabled={shouldDisableButton()}
                                        onClick={saveSettings}
                                    >
                                        {saveButtonText()}
                                    </Button>
                                </Form.Group>
                            </Grid>
                        </Grid>
                    </If>
                </Grid>
                <Grid item sm={7} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Form.Group as={Col} className={classes.dealerId}>
                                <Form.Label>F&I Express Global Dealer ID</Form.Label>
                                <div>
                                    <InputNumber
                                        placeholder={0}
                                        removeDecimalScale
                                        value={globalDealerId}
                                        onChange={(value) => onChange('globalDealerId', value)}
                                    />
                                    <If condition={writable}>
                                        <Button
                                            disabled={!globalDealerId || isSavingMenuSetting}
                                            className={classes.containedSecondaryInfo}
                                            size="small"
                                            onClick={onSaveGlobalDealerId}
                                        >
                                            Save
                                        </Button>
                                    </If>
                                </div>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Form.Group as={Col} className={classes.editor}>
                                <Form.Label>Menu Disclosure</Form.Label>
                                <Jodit
                                    config={joditConfiguration}
                                    value={disclosure || ''}
                                    onChange={(newContent) => onChange('disclosure', newContent)}
                                />
                            </Form.Group>
                            <If condition={writable}>
                                <Form.Group className={classes.customTabsSave} as={Col}>
                                    <Button
                                        disabled={!disclosure || isSavingMenuSetting}
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        onClick={onSaveDisclosure}
                                    >
                                        Save
                                    </Button>
                                </Form.Group>
                            </If>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

DealsGeneralSettings.propTypes = {
    writable: PropTypes.bool.isRequired,
};

export default DealsGeneralSettings;
