import React, { useEffect, useReducer, useState } from 'react';

// Material UI
import { Tab, Nav } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

// Bootstrap
import BackButton from 'components/widgets/BackButton';
import OpportunityDetail from 'components/modules/crm/read/detail/OpportunityDetail';

// Utils
import clsx from 'clsx';
import printJS from 'print-js';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import { useParams, useLocation } from 'react-router';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import { modules } from 'utils/enum/modules';
import { FetchPolicy } from 'utils/enum/Core';
import FormMap from 'services/mapData/FormMap';
import Permissions from 'utils/enum/Permissions';
import HistoryTab from 'components/modules/crm/read/history/HistoryTab';
import CreditDetail from 'components/modules/crm/read/credit/CreditDetail';
import { automatedResponseStatus } from 'utils/enum/OpportunityEnum';
import { OpportunityStatus } from 'components/modules/crm/enum/Opportunity';
import ProspectDetail from 'components/modules/crm/read/prospect/ProspectDetail';
import CustomFieldDialog from 'components/modules/deals/create/forms/CustomFieldDialog';
import OpportunityStatusForm from 'components/modules/crm/dialogs/OpportunityStatusForm';
import PurchaseWorksheetDialog from 'components/modules/crm/dialogs/PurchaseWorksheetDialog';

// Services
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '& .tab-content': {
            overflow: 'hidden',
            flex: 1,
        },
    },
    tab: {
        height: '100%',
    },
    historyTab: {
        padding: '20px 10px',
        backgroundColor: theme.palette.background.default,
    },
    navLink: {
        textDecoration: 'none',
        border: 'none !important',
        borderBottom: '2px solid transparent !important',
        color: `${theme.palette.text.minsk} !important`,
        fontWeight: '500',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '&.active': {
            borderBottom: `2px solid ${theme.palette.background.fruitSalad} !important`,
        },
    },
    nav: {
        border: 'none',
    },
    leadCode: {
        width: 92,
        '& span': {
            color: theme.palette.text.butterflyBush,
            fontWeight: 'bold',
        },
    },
    mainButton: {
        marginRight: theme.spacing(2),
    },
    soldButton: {
        color: theme.palette.success.light,
        borderColor: theme.palette.success.light,
        marginLeft: '10px',
    },
    lostButton: {
        color: theme.palette.error.light,
        borderColor: theme.palette.error.light,
    },
    headerBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        height: '40px',
    },
    purpleBanner: {
        color: theme.palette.text.white,
        fontWeight: 500,
        backgroundColor: theme.palette.background.mediumPurple,
        '& > label': {
            marginRight: 40,
        },
    },
    ...ButtonStyles.getStyle(theme),
}));

const keyStore = new KeyStore();
const ACTION_TYPE = {
    TOGGLE_DIALOG: 'toggleDialog',
    SET_CUSTOM_FIELD: 'setCustomField',
    TOGGLE_STATUS_DIALOG: 'openStatusDialog',
    TOGGLE_PURCHASE_DIALOG: 'togglePurchaseDialog',
    SET_CUSTOM_FIELD_PURCHASE: 'setCustomFieldPurchase',
    SET_AUTOMATED_RESPONSE_STATUS: 'setAutomatedResponseStatus',
};

const initState = {
    openDialog: false,
    customField: {},
    openPurchaseDialog: false,
    customFieldPurchase: {},
    automatedResponseStatus: null,
};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
    case ACTION_TYPE.TOGGLE_DIALOG:
        return update(state, {
            openDialog: { $set: payload },
        });
    case ACTION_TYPE.SET_CUSTOM_FIELD:
        return update(state, {
            openDialog: { $set: true },
            customField: { $set: payload },
        });
    case ACTION_TYPE.TOGGLE_STATUS_DIALOG:
        return update(state, {
            statusAction: { $set: payload.action },
            openStatusDialog: { $set: payload.open },
        });
    case ACTION_TYPE.SET_CUSTOM_FIELD_PURCHASE:
        return update(state, {
            openPurchaseDialog: { $set: true },
            customFieldPurchase: { $set: payload },
        });
    case ACTION_TYPE.TOGGLE_PURCHASE_DIALOG:
        return update(state, {
            openPurchaseDialog: { $set: payload },
        });
    case ACTION_TYPE.SET_AUTOMATED_RESPONSE_STATUS:
        return update(state, {
            automatedResponseStatus: { $set: payload },
        });
    default:
        return initState;
    }
};

const Opportunity = () => {
    const { id } = useParams();
    const location = useLocation();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const [opportunity, setOpportunity] = useState({});

    const {
        data: statusData,
        loading: loadingStatus,
        error: errorLoadingStatus,
        refetch: refetchAutomatedResponseStatus,
    } = useQuery(OpportunityQuery.GET_OPP_AUTOMATED_RESPONSE_STATUS, {
        variables: {
            crmId: id,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getCustomField, { data, loading }] = useLazyQuery(
        OpportunityQuery.GET_CUSTOM_FIELD_PROPOSAL,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY },
    );
    const [getCustomFieldPurchase, { data: dataPurchase, loading: loadingPurchase }] = useLazyQuery(
        OpportunityQuery.GET_CUSTOM_FIELD_PURCHASE_WORKSHEET,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY },
    );
    const [printProposal, { loading: loadingPrint }] = useMutation(OpportunityMutation.PRINT_PROPOSAL_FORM);
    const [printPurchase, { loading: loadingPrintPurchase }] = useMutation(OpportunityMutation.PRINT_PURCHASE_WORKSHEET_FORM);
    const [printTestDrive, { loading: loadingPrintTestDrive }] = useMutation(OpportunityMutation.PRINT_TEST_DRIVE_FORM);
    const [printCreditApp, { loading: loadingCreditApp }] = useMutation(OpportunityMutation.PRINT_CREDIT_APP_FORM);
    const CRM_OPPORTUNITY_WRITE = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_WRITE);

    const toggleDialog = (value) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_DIALOG,
            payload: value,
        });
    };

    const toggleStatusDialog = (action) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_STATUS_DIALOG,
            payload: {
                open: !state.openStatusDialog,
                action,
            },
        });
    };

    const togglePurchaseDialog = (value) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_PURCHASE_DIALOG,
            payload: value,
        });
    };

    const onClickProposal = () => {
        const selectedLot = keyStore.getSelectedLot();
        getCustomField({ variables: { opportunityId: id, lotId: selectedLot.lotId } });
    };

    const onClickPurchaseWorksheet = () => {
        const selectedLot = keyStore.getSelectedLot();
        getCustomFieldPurchase({ variables: { opportunityId: id, lotId: selectedLot.lotId } });
    };

    const onPrintPurchase = async (record) => {
        try {
            const selectedLot = keyStore.getSelectedLot();
            const input = { ...record };
            delete input.userField1Name;
            delete input.userField2Name;
            delete input.originalSalesTax;
            delete input.dealerFeeEditable;

            const result = await printPurchase({
                variables: {
                    lotId: selectedLot.lotId,
                    opportunityId: id,
                    form: input,
                },
            });

            if (!StringUtils.isEmpty(result.data?.printPurchaseWorksheetForm)) {
                printJS({
                    printable: result.data.printPurchaseWorksheetForm,
                    type: 'pdf',
                    showModal: false,
                    onLoadingEnd: () => togglePurchaseDialog(false),
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    const onPrintProposal = async (records) => {
        try {
            const selectedLot = keyStore.getSelectedLot();
            const result = await printProposal({
                variables: {
                    lotId: selectedLot.lotId,
                    opportunityId: id,
                    form: FormMap.mapCustomField(records)[0],
                },
            });

            if (!StringUtils.isEmpty(result.data?.printProposalForm)) {
                printJS({
                    printable: result.data.printProposalForm,
                    type: 'pdf',
                    showModal: false,
                    onLoadingEnd: () => toggleDialog(false),
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    const onClickTestDrive = async () => {
        try {
            const result = await printTestDrive({
                variables: {
                    opportunityId: id,
                },
            });

            if (!StringUtils.isEmpty(result.data?.printTestDriveForm)) {
                printJS({
                    printable: result.data.printTestDriveForm,
                    type: 'pdf',
                    showModal: false,
                    onLoadingEnd: () => toggleDialog(false),
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    const onClickCreditApp = async () => {
        try {
            const result = await printCreditApp({
                variables: {
                    opportunityId: id,
                },
            });

            if (!StringUtils.isEmpty(result.data?.printCreditApp)) {
                printJS({
                    printable: result.data.printCreditApp,
                    type: 'pdf',
                    showModal: false,
                    onLoadingEnd: () => toggleDialog(false),
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    useEffect(() => {
        if (loading) return;

        if (data?.getCustomFieldProposal) {
            dispatch({
                type: ACTION_TYPE.SET_CUSTOM_FIELD,
                payload: data?.getCustomFieldProposal,
            });
        }
    }, [data, loading]);

    // useEffect to load custom field to purchase worksheet form
    useEffect(() => {
        if (loadingPurchase) return;

        if (dataPurchase?.getCustomFieldPurchaseWorksheet) {
            dispatch({
                type: ACTION_TYPE.SET_CUSTOM_FIELD_PURCHASE,
                payload: dataPurchase?.getCustomFieldPurchaseWorksheet,
            });
        }
    }, [dataPurchase, loadingPurchase]);

    useEffect(() => {
        if (errorLoadingStatus) {
            ModalUtils.errorMessage(errorLoadingStatus?.graphQLErrors);
            return;
        }

        if (!loadingStatus) {
            const response = statusData?.getOpportunityAutomatedResponseStatus;
            if (response) {
                const { status, automatedResponseId } = response;
                dispatch({
                    type: ACTION_TYPE.SET_AUTOMATED_RESPONSE_STATUS,
                    payload: {
                        status,
                        automatedResponseId,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStatus, errorLoadingStatus]);

    const tabs = [
        {
            title: 'Detail',
            component: <OpportunityDetail
                canWrite={CRM_OPPORTUNITY_WRITE}
                setOpportunity={setOpportunity}
                arStatus={state.automatedResponseStatus ?? {}}
                refetch={refetchAutomatedResponseStatus}
            />,
        },
        {
            title: 'Prospect',
            component: <ProspectDetail />,
        },
        {
            title: 'Credit',
            component: <CreditDetail recordId={opportunity.leadCode} recordType="CRM" lotId={opportunity.lotId} />,
        },
        {
            title: 'History',
            component: <HistoryTab opportunityId={id} className={classes.historyTab} />,
        },
    ];

    const { isFloorLog = false } = location?.state ?? {};
    return (
        <div className={classes.container}>
            {state.automatedResponseStatus?.status === automatedResponseStatus.RUNNING && (
                <div className={clsx(classes.headerBanner, classes.purpleBanner)}>
                    Automated follow up is set up for this opportunity
                </div>
            )}
            <Tab.Container
                defaultActiveKey={0}
            >
                <div className="d-flex-center-space-between">
                    <div className="d-flex-center">
                        <BackButton route={isFloorLog ? `/${modules.FLOORLOG}` : `/${modules.OPPORTUNITIES}`} />
                        <div className={clsx('d-flex-justify-center-align-center', classes.leadCode)}>
                            {!opportunity.leadCode && <Skeleton variant="rect" width={92} height={18} />}
                            <span>{opportunity.leadCode}</span>
                        </div>
                        <Nav variant="tabs" className={classes.nav}>
                            {tabs.map((item, index) => (
                                <Nav.Item key={index}>
                                    <Nav.Link className={classes.navLink} eventKey={index}>{item.title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </div>
                    {opportunity.status === OpportunityStatus.OPEN && CRM_OPPORTUNITY_WRITE && (
                        <div>
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.lostButton}
                                onClick={() => toggleStatusDialog(OpportunityStatus.LOST)}
                            >
                                Mark as Lost
                            </Button>
                        </div>
                    )}
                    <div>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, classes.mainButton)}
                            variant="contained"
                            onClick={onClickProposal}
                            disabled={loadingPrint}
                        >
                            Proposal
                        </Button>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, classes.mainButton)}
                            variant="contained"
                            onClick={onClickTestDrive}
                            disabled={loadingPrintTestDrive}
                        >
                            Test Drive Agreement
                        </Button>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, classes.mainButton)}
                            variant="contained"
                            onClick={onClickPurchaseWorksheet}
                            disabled={loadingPrintPurchase}
                        >
                            Purchase Worksheet
                        </Button>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, classes.mainButton)}
                            variant="contained"
                            onClick={onClickCreditApp}
                            disabled={loadingCreditApp}
                        >
                            Credit App
                        </Button>
                    </div>
                </div>
                <Tab.Content>
                    {tabs.map((item, index) => (
                        <Tab.Pane className={classes.tab} eventKey={index} mountOnEnter unmountOnExit key={index}>
                            {item.component}
                        </Tab.Pane>
                    ))}
                </Tab.Content>
            </Tab.Container>
            {state.openDialog
                && (
                    <CustomFieldDialog
                        maxWidth="sm"
                        title="Proposal"
                        open={state.openDialog}
                        textPrimaryButton="Ok"
                        showCommonName={false}
                        loading={loadingPrint}
                        className="custom-field-row-form"
                        onClose={() => toggleDialog(false)}
                        onClickPrimaryButton={onPrintProposal}
                        records={FormMap.mapData([state.customField])}
                    />
                )}
            {state.openStatusDialog
                && (
                    <OpportunityStatusForm
                        crmId={id}
                        action={state.statusAction}
                        onClose={toggleStatusDialog}
                    />
                )}
            {state.openPurchaseDialog
                && (
                    <PurchaseWorksheetDialog
                        maxWidth="sm"
                        open={state.openPurchaseDialog}
                        textPrimaryButton="Ok"
                        showCommonName={false}
                        loading={loadingPrintPurchase}
                        className="custom-field-row-form"
                        onClose={() => togglePurchaseDialog(false)}
                        onClickPrimaryButton={onPrintPurchase}
                        records={state.customFieldPurchase}
                    />
                )}
        </div>
    );
};

export default Opportunity;
