/* eslint no-param-reassign: "error" */
import React from 'react';
import InventoryHelper from 'utils/InventoryHelper';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { v1 as uuid } from 'uuid';

const VehicleImage = (record) => {
    const {
        image,
        bodyStyle,
        width,
        height,
        ...props
    } = record;
    const src = !StringUtils.isEmpty(image) ? image : InventoryHelper.getThumbnail(bodyStyle);

    return (
        <img
            alt=""
            src={`${src}?version=${uuid()}`}
            height={height}
            width={width}
            {...props}
            onError={(event) => {
                event.target.src = InventoryHelper.getThumbnail(bodyStyle);
            }}
        />
    );
};

VehicleImage.propTypes = {
    image: PropTypes.string,
    bodyStyle: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

VehicleImage.defaultProps = {
    image: null,
    height: '100%',
    width: '100%',
    bodyStyle: 'sedan',
};

export default VehicleImage;
