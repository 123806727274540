export default class TabStyles {
    static tabContainer() {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                '& .tab-content': {
                    overflow: 'hidden',
                    height: '100%',
                },
                '& .tab-content > div > div': {
                    height: '100%',
                },
            },
        });
    }

    static tabPanel(theme = {}) {
        return ({
            tabPanel: {
                background: theme.palette.background.white,
                flex: 1,
                height: '100%',
                overflow: 'hidden',
            },
            box: {
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
            },
            tab: {
                height: '100%',
            },
            historyTab: {
                padding: '20px 10px',
                backgroundColor: theme.palette.background.default,
            },
            navbar: {
                '& > a': {
                    color: theme.palette.text.link,
                    'text-decoration': 'none',
                    'background-color': 'transparent',
                    'line-height': '1.5',
                },
            },
            borderContainer: {
                '& + div': {
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                    marginLeft: '0.5px',
                },
            },
        });
    }
}
