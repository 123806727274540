import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles,
    useTheme,
    useMediaQuery,
    Button,
} from '@material-ui/core';
import {
    PRINTING_DOCUMENT_TYPE,
    PRINTING_DOCUMENT_SOURCE_TYPE,
    PAGE_FORMAT,
    PAGE_ORIENTATION,
} from 'utils/enum/General';
import {
    PRINTABLE_TYPE,
} from 'utils/enum/BusinessIntelligenceEnum';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { useLazyQuery } from '@apollo/client';
import GeneralQuery from 'services/graphQL/query/core/GeneralQuery';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import NumberUtils from 'lib/NumberUtils';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import JournalDetailMap from 'services/mapData/JournalDetailMap';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import GeneralUtils from 'utils/GeneralUtils';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import { AccountingSubModules } from 'utils/enum/AccountingEnum';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';

// Icons
import { PrintOutlinedIcon, ListAltSharpIcon } from 'components/icons';
import StringUtils from 'lib/StringUtils';

const basicStyles = makeStyles((theme) => AccountingStyles.basicStyles(theme));
const searchStyles = makeStyles((theme) => AccountingStyles.searchStyles(theme));
const columnStyles = makeStyles((theme) => AccountingStyles.columnStyles(theme));
const dateCalendarStyles = makeStyles((theme) => AccountingStyles.dateCalendarStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));

const ACTION_TYPES = {
    SET_TABLE_RECORDS: 'setTableRecords',
    SET_PAGING: 'setPaging',
    SET_STATE_VALUES: 'setStateValues',
};

const journalDetailReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE_RECORDS:
        return {
            ...state,
            table: {
                records: action.records,
                totalCount: action.totalCount,
            },
            search: action.clearSearch ? '' : state.search,
        };
    case ACTION_TYPES.SET_PAGING:
        return { ...state, paginate: { ...state.paginate, init: action.value } };
    case ACTION_TYPES.SET_STATE_VALUES:
        return { ...state, ...action.value };
    default:
        return state;
    }
};

const GeneralJournalDetailList = ({ journalId, printingData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...searchStyles(),
        ...columnStyles(),
        ...dateCalendarStyles(),
        ...basicStyles(),
        ...containerStyles(),
    };

    const initialState = {
        paginate: {
            init: 0,
            limit: 100,
            ignoreLimit: false,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        search: '',
        useDateRange: true,
        fromDate: new Date(DateUtils.subtractAndFormatUTC(new Date(), 3, 'months')),
        toDate: new Date(),
    };

    const [state, dispatch] = useReducer(journalDetailReducer, initialState);

    const { paginate, table } = state;

    const [loadJournalDetailFromList, { loading, error, refetch }] = useLazyQuery(
        AccountingGLQuery.GET_ACCOUNTING_GL_LIST_FOR_GENERAL_JOURNAL, {
            onCompleted: (res) => {
                const { records } = table;
                const { getAccountingGLList: { data, totalCount } } = res;
                const mapValues = JournalDetailMap.glLines(data);
                records.push(...mapValues);
                dispatch({
                    type: ACTION_TYPES.SET_TABLE_RECORDS,
                    records,
                    totalCount,
                });
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage(null, errorMessage);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const [printDocument, { loading: printingDocument }] = useLazyQuery(GeneralQuery.PRINT_DOCUMENT, {
        onCompleted: (response) => {
            const result = response.printDocument;
            if (result) {
                if (result?.data?.startsWith('Request failed')) {
                    ModalUtils.errorMessage(null, 'Error printing report due to too many records');
                    return;
                }

                const {
                    documentType,
                    data: documentOutput,
                } = result;

                if (documentType === PRINTING_DOCUMENT_TYPE.PDF) {
                    BIHelper.printChart(PRINTABLE_TYPE.PDF, {
                        url: documentOutput,
                    });
                }

                if (documentType === PRINTING_DOCUMENT_TYPE.SPREADSHEET) {
                    const { referenceNumber } = printingData;
                    const name = `General Journals - ${referenceNumber} - ${DateUtils.format(new Date(), DateFormat.SHORT_DATE_WITH_DASHES)}.xlsx`;
                    GeneralUtils.downloadFile(documentOutput, name);
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!isEmpty(journalId)) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE_RECORDS,
                records: [],
                totalCount: 0,
                clearSearch: true,
            });

            loadJournalDetailFromList({
                variables: {
                    paginate: {
                        ...paginate,
                        init: 0,
                    },
                    filter: {
                        journalId,
                    },
                },
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journalId, error]);

    const loadMore = () => {
        const currentPage = table?.records?.length || 0;
        dispatch({ type: ACTION_TYPES.SET_PAGING, value: currentPage });
        refetch();
    };

    const onFilterChange = (param, value) => {
        if ((param === 'fromDate' || param === 'toDate') && DateUtils.getOnlyDate(state[param]) === DateUtils.getOnlyDate(value)) return;
        if (state[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                [param]: value,
            },
        });
    };

    const printDetails = (documentType) => {
        const { records } = table;
        let {
            createdBy = '',
            referenceNumber = '',
            postedDate = '',
            memo = '',
        } = printingData;

        const formattedRecords = records.map((record) => ({
            'Account #': record.accountDescription,
            Debit: NumberUtils.applyCurrencyFormat(record.debit),
            Credit: NumberUtils.applyCurrencyFormat(record.credit),
            'Control #': record.controlNumber,
            'Lot Name': record.lotName,
            Memo: record.memo,
        }));

        createdBy = !StringUtils.isEmpty(createdBy) ? `${createdBy}, ` : '';
        referenceNumber = !StringUtils.isEmpty(referenceNumber) ? `${referenceNumber}, ` : '';
        postedDate = postedDate ? `${DateUtils.getOnlyDate(postedDate)}, ` : '';
        memo = !StringUtils.isEmpty(memo) ? `${memo}` : '';

        const additionalInfo = `${referenceNumber}${createdBy}${postedDate}${memo}`;
        const title = documentType === PRINTING_DOCUMENT_TYPE.PDF
            ? `<div style="font-size: 10px;">General Journal ${additionalInfo}</div>`
            : `General Journal ${additionalInfo}`;
        printDocument({
            variables: {
                title,
                source: {
                    content: JSON.stringify(formattedRecords),
                    type: PRINTING_DOCUMENT_SOURCE_TYPE.RECORDS,
                    options: {
                        pageFormat: PAGE_FORMAT.LETTER,
                        paseOrientation: PAGE_ORIENTATION.LANDSCAPE,
                        border: 15,
                    },
                },
                type: documentType,
            },
        });
    };

    useEffect(() => {
        if (state.search) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE_RECORDS,
                records: [],
                totalCount: 0,
            });
            loadJournalDetailFromList({
                variables: {
                    paginate: {
                        ...state.paginate,
                        init: 0,
                        ignoreLimit: true,
                    },
                    filter: {
                        search: state.search,
                        subModule: AccountingSubModules.JOURNALS,
                        useDateRange: state.useDateRange,
                        fromDate: state.fromDate,
                        toDate: state.toDate,
                    },
                },
            });
        }

        if (!state.search) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE_RECORDS,
                records: [],
                totalCount: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search, state.useDateRange, state.fromDate, state.toDate]);

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Account #',
            minWidth: 180,
            width: 180,
            id: 'accountNumber',
            accessor: 'accountDescription',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Post Date',
            minWidth: 90,
            width: 90,
            id: 'postDate',
            accessor: 'postDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Debit',
            accessor: 'debit',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Credit',
            accessor: 'credit',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Control #',
            id: 'controlNumber',
            accessor: 'controlNumber',
            minWidth: 120,
            width: 120,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Reference #',
            minWidth: 120,
            width: 120,
            id: 'referenceNumber',
            accessor: 'referenceNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Lot Name',
            id: 'lotName',
            accessor: 'lotName',
            minWidth: 120,
            width: 120,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
    ];

    return (
        <div className={classes.flexContainer}>
            <Header>
                <div className={classes.buttonSpacing}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={state.search || ''}
                        onSearch={(val) => onFilterChange('search', val)}
                    />
                    <Form.Group controlId="formBasicCheckboxBottom" className={classes.alignContent}>
                        <Form.Check
                            checked={state.useDateRange}
                            type="checkbox"
                            label="Search between dates"
                            className={classes.labelFont}
                            onChange={(e) => onFilterChange('useDateRange', e.target.checked)}
                        />
                    </Form.Group>
                    {state.useDateRange
                    && (
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={state.fromDate}
                            maxDate={state.toDate}
                            onChange={(value) => onFilterChange('fromDate', value)}
                            placeholderText="mm/dd/yyyy"
                        />
                    )}
                    {state.useDateRange
                    && (
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={state.toDate}
                            minDate={state.fromDate}
                            onChange={(value) => onFilterChange('toDate', value)}
                            placeholderText="mm/dd/yyyy"
                        />
                    )}
                </div>
                <div className={classes.printingButtons}>
                    <Button
                        onClick={() => printDetails(PRINTING_DOCUMENT_TYPE.PDF)}
                        variant="outlined"
                        startIcon={<PrintOutlinedIcon />}
                        disabled={loading || printingDocument || table?.records?.length === 0}
                        size="small"
                    >
                        Print
                    </Button>
                    <Button
                        onClick={() => printDetails(PRINTING_DOCUMENT_TYPE.SPREADSHEET)}
                        variant="outlined"
                        startIcon={<ListAltSharpIcon />}
                        disabled={loading || printingDocument || table?.records?.length === 0}
                        size="small"
                    >
                        XLS
                    </Button>
                </div>
            </Header>
            <div className={clsx(classes.bottomTableHeight, classes.overFlowAuto)}>
                <Table
                    rowSelected
                    cursor="default"
                    className="-highlight"
                    load={loading}
                    loadMore={loadMore}
                    totalRecords={table?.totalCount}
                    data={table?.records}
                    columns={columns}
                />
            </div>
        </div>
    );
};

GeneralJournalDetailList.propTypes = {
    journalId: PropTypes.string.isRequired,
    printingData: PropTypes.object.isRequired,
};

export default GeneralJournalDetailList;
