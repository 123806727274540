import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles,
    Button,
} from '@material-ui/core';
import StringUtils from 'lib/StringUtils';
import If from 'components/widgets/conditional/If';
import Jodit from 'components/widgets/editor/JoditEditor';
import ButtonStyles from 'styles/theme/Button';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DescriptionTabStyle from 'styles/modules/inventory/read/DescriptionTabStyle';

const useStyles = makeStyles((theme) => DescriptionTabStyle.getStyle(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const JoditConfig = {
    autofocus: true,
    allowResizeX: false,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    placeholder: 'Start typing the description',
};

const DescriptionTab = ({
    canWrite,
    savingDescription,
    onDescriptionChange,
    onDescriptionSave,
    onGenerateDescription,
    toggleDescriptionMode,
    isEditMode,
    description,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };

    return (
        <div className={classes.main}>
            <div className={clsx('d-flex', classes.header)}>
                Vehicle Description
                <If condition={!isEditMode}>
                    <div>
                        <Button
                            disabled={!canWrite}
                            onClick={toggleDescriptionMode}
                            className={clsx(classes.containedInfo, classes.actionButton)}
                        >
                            Edit
                        </Button>
                        <Button
                            disabled={!canWrite}
                            onClick={onGenerateDescription}
                            className={clsx(classes.containedInfo, classes.actionButton)}
                        >
                            Generate Description
                        </Button>
                    </div>
                </If>
                <If condition={isEditMode}>
                    <div>
                        <Button
                            disabled={StringUtils.isEmpty(description) || savingDescription}
                            onClick={onDescriptionSave}
                            className={clsx(classes.containedInfo, classes.actionButton)}
                            endIcon={<SaveOutlinedIcon />}
                        />
                        <Button
                            onClick={toggleDescriptionMode}
                            className={clsx(classes.containedError, classes.actionButton)}
                            endIcon={<CloseOutlinedIcon />}
                        />
                    </div>
                </If>
            </div>
            <If condition={isEditMode}>
                <Jodit
                    value={description}
                    config={JoditConfig}
                    onChange={(newContent) => onDescriptionChange(newContent)}
                />
            </If>
            <If condition={!isEditMode}>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={classes.descriptionContainer}
                />
            </If>
        </div>
    );
};

DescriptionTab.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    description: PropTypes.string,
    onDescriptionChange: PropTypes.func.isRequired,
    onDescriptionSave: PropTypes.func.isRequired,
    onGenerateDescription: PropTypes.func.isRequired,
    toggleDescriptionMode: PropTypes.func.isRequired,
    savingDescription: PropTypes.bool,
    canWrite: PropTypes.bool.isRequired,
};

DescriptionTab.defaultProps = {
    savingDescription: false,
    description: '',
};

export default DescriptionTab;
