/* eslint-disable react/prop-types */
/* eslint no-param-reassign: ["error", { "props": false }] */
import React, {
    useEffect, useReducer, useContext,
} from 'react';
import { cloneDeep } from 'lodash';
import numeral from 'numeral';
import { useQuery, useApolloClient } from '@apollo/client';
import { Link as RouteLink, useLocation, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import clsx from 'clsx';

// Material UI
import {
    makeStyles,
    FormLabel,
    CircularProgress,
    Grid,
    Button,
    Tooltip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Components
import SettingsIcon from '@material-ui/icons/Settings';
import getColumns from 'components/modules/inventory/list/InventoryListColumns';
import If from 'components/widgets/conditional/If';
import UserContext from 'components/context/UserContext';
import TableSettingsDialog from 'components/modules/inventory/create/dialogs/TableSettingsDialog';
import StickersDialog from 'components/modules/inventory/read/StickersDialog';
import CardList from 'components/widgets/CardList';
import VirtualTable from 'components/widgets/VirtualTable';
import Header from 'components/widgets/Header';
import InputSearch from 'components/widgets/InputSearch';
import LotFilter from 'components/modules/inventory/list/LotFilter';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import ColumnSettingsQuery from 'services/graphQL/query/columnSettings/ColumnSettingsQuery';
import ColumnSettingsMutation from 'services/graphQL/mutate/columnSettings/ColumnSettingsMutation';

// Reactbootstrap
import { Form } from 'react-bootstrap';

// Utils
import useFilterActions from 'components/modules/inventory/hooks/useFilterActions';
import MapInventoryData from 'services/mapData/MapInventoryData';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';
import TableUtils from 'utils/TableUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ArrayUtils from 'lib/ArrayUtils';
import { modules } from 'utils/enum/modules';

import {
    DataSort,
    FetchPolicy,
    Tables,
    ALL_LOTS,
} from 'utils/enum/Core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const useStyles = makeStyles((theme) => InventoryListStyle.inventoryList(theme));
const initState = {
    params: {
        sort: {
            dir: DataSort.DESC,
            field: 'stockNumber',
        },
        active: true,
        lots: [ALL_LOTS],
        filters: [],
    },
    table: {
        records: [],
        backup: [],
    },
    search: {
        inputKey: TableUtils.generateUUID(),
        value: '',
    },
    flags: {
        toggleStickersDialog: false,
        toggleTableSettingsDialog: false,
    },
    tableIncome: {
        columns: [],
        columnSettings: null,
        customFields: null,
        columnsData: [],
        availableLots: undefined,
    },
    tableWrapperWidth: 0,
};

const ACTION_TYPES = {
    CHANGE_ALL_FIELDS: 'changeAllFields',
    ENABLE_ROW: 'enableRow',
    CHANGE_QUICK_NOTE: 'changeQuickNote',
    SET_MAX_TABLE_WIDTH: 'setMaxTableWidth',
};

const replaceQuickNote = (records, stock, quickNote) => cloneDeep(records).map((i) => {
    if (i.stock === stock) i.quickNote = quickNote; i.editable = false;
    return i;
});

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_MAX_TABLE_WIDTH:
        return {
            ...state,
            tableWrapperWidth: action.payload,
        };
    case ACTION_TYPES.CHANGE_ALL_FIELDS:
        const { table } = action.payload;
        if (table) {
            const { backup } = table;
            if (!backup) table.backup = state.table.backup;
        }

        return {
            ...state,
            ...action.payload,
        };
    case ACTION_TYPES.ENABLE_ROW:
        const enableIndex = action?.payload?.index;
        const enable = action?.payload?.enable;

        const tableClone = cloneDeep(state.table);
        const { records: items } = tableClone;
        items[enableIndex].editable = enable;

        return {
            ...state,
            table: tableClone,
        };
    case ACTION_TYPES.CHANGE_QUICK_NOTE:
        const { table: { records, backup } } = state;

        if (Array.isArray(records) && records.length > 0) {
            const changeIndex = action?.payload?.index;
            const quickNote = action?.payload?.quickNote;
            const vehicle = records[changeIndex];
            const { stock } = vehicle;

            return {
                ...state,
                table: {
                    records: replaceQuickNote(records, stock, quickNote),
                    backup: replaceQuickNote(backup, stock, quickNote),
                },
            };
        }

        return state;
    default:
        return state;
    }
};

const renderNewButton = (hasPermission) => {
    if (hasPermission) {
        return (
            <RouteLink to={{ pathname: `/${modules.INVENTORY}/create` }}>
                <Button variant="contained" color="primary">
                    New
                </Button>
            </RouteLink>
        );
    }

    return null;
};

const InventoryList = () => {
    const client = useApolloClient();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        loadLocalFilterValues,
        resetFilters: resetLocalFilters,
        applyFilters: applyLocalFilters,
        filterLocalData,
    } = useFilterActions();

    const history = useHistory();
    const location = useLocation();
    const regexDetail = /^(\/inventory\/[0-9]{1,})$/;
    const isDetail = regexDetail.test(location.pathname);
    const { userInformation } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, cloneDeep(initState));

    const {
        params,
        table,
        search,
        flags,
        tableIncome,
        tableWrapperWidth,
    } = state;

    const updateColumnsData = (value) => {
        const clone = cloneDeep(tableIncome);
        clone.columnsData = value;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                tableIncome: clone,
            },
        });
    };

    const updateColumnSettings = (value) => {
        const clone = cloneDeep(tableIncome);
        clone.columnSettings = value;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                tableIncome: clone,
            },
        });
    };

    const parameters = { ...params, filters: null };
    if (!StringUtils.isEmpty(search.value)) parameters.search = search.value;

    const shownColumns = tableIncome.columns.filter((column) => column.show);
    let columnsToRequest = shownColumns.map((column) => column.dataKey);
    if (columnsToRequest.includes('extColor')) columnsToRequest.push('intColor');
    if (columnsToRequest.includes('quickNote')) columnsToRequest.push('stockNumber');

    if (columnsToRequest.includes('vin')) {
        columnsToRequest = [
            ...columnsToRequest,
            'stockNumber',
            'imageCounter',
            'make',
            'model',
            'trim',
            'year',
            'drivetrain',
            'wholesale',
            'placeAd',
        ];
    }

    if (isMobile) {
        columnsToRequest = [
            'stockNumber',
            'thumbnail',
            'style',
            'year',
            'make',
            'model',
            'miles',
            'age',
            'reconStatus',
            'lotName',
            'vehicleLocation',
        ];
    }

    columnsToRequest = columnsToRequest.sort((a, b) => a.localeCompare(b));
    const {
        data: vehiclesData,
        loading: vehiclesLoading,
        error: vehiclesError,
    } = useQuery(InventoryQuery.getPullInventorySchema(columnsToRequest.length > 0 ? [...new Set(columnsToRequest)] : ['stockNumber']), {
        variables: {
            ...parameters,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        skip: params.lots.some((lot) => lot === ALL_LOTS)
        || tableIncome.customFields === null
        || tableIncome.columnSettings === null
        || columnsToRequest.length === 0,
    });

    const {
        data: columnSettingsData,
        loading: columnSettingsLoading,
        error: columnSettingsError,
    } = useQuery(ColumnSettingsQuery.GET_COLUMN_SETTINGS_BY_TABLE, {
        variables: {
            module: modules.INVENTORY,
            tableName: Tables.INVENTORY_MAIN_LIST,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: tableIncome.customFields === null,
    });

    const {
        data: inventoryMetaData,
        loading: inventoryMetaDataLoading,
        error: inventoryMetaDataError,
    } = useQuery(InventoryQuery.GET_INVENTORY_METADATA, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: !userInformation,
    });

    const maxTableWidth = document.getElementById('stocksContainer')?.offsetWidth;
    useEffect(() => {
        if (maxTableWidth > 0) {
            dispatch({
                type: ACTION_TYPES.SET_MAX_TABLE_WIDTH,
                payload: maxTableWidth,
            });
        }
    }, [maxTableWidth]);

    useEffect(() => {
        if (columnSettingsError) {
            ModalUtils.errorMessage(columnSettingsError?.graphQLErrors);
            return;
        }

        if (!columnSettingsLoading && columnSettingsData && !tableIncome.columnSettings) {
            const { getColumnSettingsByTable } = columnSettingsData;
            updateColumnSettings(getColumnSettingsByTable);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnSettingsLoading, columnSettingsError]);

    useEffect(() => {
        if (inventoryMetaDataError) {
            ModalUtils.errorMessage(inventoryMetaDataError?.graphQLErrors);
            return;
        }

        if (!inventoryMetaDataLoading && inventoryMetaData) {
            const { getInventoryMetadata } = inventoryMetaData;

            const clone = cloneDeep(tableIncome);
            clone.customFields = getInventoryMetadata;

            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    tableIncome: clone,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryMetaDataLoading, inventoryMetaDataError]);

    useEffect(() => {
        if (location?.state?.fromDetail) {
            history.replace();

            const {
                state: locationState,
            } = location;

            const searchClone = cloneDeep(search);
            searchClone.inputKey = TableUtils.generateUUID();
            searchClone.value = locationState.search;

            const tableIncomeClone = cloneDeep(tableIncome);
            tableIncomeClone.columnsData = [];

            const paramsClone = cloneDeep(params);
            paramsClone.filters = [];
            paramsClone.active = locationState.status;

            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    search: searchClone,
                    params: paramsClone,
                    tableIncome: tableIncomeClone,
                    table: {
                        records: [],
                    },
                },
            });
        }
        // eslint-disable-next-line
    }, [location?.state]);

    useEffect(() => {
        if (userInformation) {
            const lots = userInformation?.lots;
            if (lots && lots.length > 0) {
                const tableIncomeClone = cloneDeep(tableIncome);
                tableIncomeClone.availableLots = lots;

                const paramsClone = cloneDeep(params);
                paramsClone.lots = lots.map((lot) => lot.lotName);

                dispatch({
                    type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                    payload: {
                        params: paramsClone,
                        tableIncome: tableIncomeClone,
                    },
                });

                return;
            }

            const paramsClone = cloneDeep(params);
            paramsClone.lots = [];
            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    params: paramsClone,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    const onSearch = (searchValue) => {
        const searchClone = cloneDeep(search);
        searchClone.value = searchValue;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                search: searchClone,
                table: {
                    records: [],
                },
            },
        });
    };

    useEffect(() => {
        if (vehiclesError) {
            ModalUtils.errorMessage(vehiclesError?.graphQLErrors);
            return;
        }

        if (!vehiclesLoading && vehiclesData) {
            let records = [];
            const vehicles = vehiclesData.pullInventory;

            vehicles.forEach((item) => {
                records.push(MapInventoryData.mapList(item));
            });

            records = records.map((vehicle) => {
                const clone = { ...vehicle };
                clone.stocks = [...new Set(records.map((record) => record.stock))];
                return clone;
            });

            const tableClone = cloneDeep(table);
            // eslint-disable-next-line no-use-before-define
            tableClone.records = filterData(params.filters, null, records);
            tableClone.backup = records;
            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    table: tableClone,
                },
            });

            if (records.length === 1) {
                const { stock } = records[0];
                history.push(`/${modules.INVENTORY}/${stock}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesLoading, vehiclesError]);

    const enableRow = (index, enable = false) => {
        dispatch({
            type: ACTION_TYPES.ENABLE_ROW,
            payload: {
                index,
                enable,
            },
        });
    };

    const onChangeQuickNote = (index, quickNote) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_QUICK_NOTE,
            payload: {
                index,
                quickNote,
            },
        });
    };

    const onChangeIncludeSold = (event) => {
        const { target: { checked } } = event;
        const paramsClone = cloneDeep(params);
        paramsClone.active = !checked;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                table: {
                    records: [],
                },
            },
        });
    };

    const onFilterByLot = (lots) => {
        let lotsToQuery = lots;
        if (lotsToQuery.some((lot) => lot === ALL_LOTS)) {
            lotsToQuery = tableIncome.availableLots.map((lot) => lot.lotName);
        }

        const paramsClone = cloneDeep(params);
        paramsClone.lots = lotsToQuery;
        paramsClone.filters = [];

        const tableIncomeClone = cloneDeep(tableIncome);
        tableIncomeClone.columnsData = [];

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                tableIncome: tableIncomeClone,
                table: {
                    records: [],
                },
            },
        });
    };

    const openStickersDialog = () => {
        const flagsClone = cloneDeep(flags);
        flagsClone.toggleStickersDialog = true;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                flags: flagsClone,
            },
        });
    };

    const closeStickersDialog = () => {
        const flagsClone = cloneDeep(flags);
        flagsClone.toggleStickersDialog = false;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                flags: flagsClone,
            },
        });
    };

    const openTableSettingsDialog = () => {
        const flagsClone = cloneDeep(flags);
        flagsClone.toggleTableSettingsDialog = true;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                flags: flagsClone,
            },
        });
    };

    const closeTableSettingsDialog = () => {
        const flagsClone = cloneDeep(flags);
        flagsClone.toggleTableSettingsDialog = false;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                flags: flagsClone,
            },
        });
    };

    const onSortedChange = ({ sortBy, sortDirection }) => {
        const paramsClone = cloneDeep(params);
        paramsClone.sort = {
            dir: sortDirection,
            field: sortBy,
        };

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                table: {
                    records: [],
                },
            },
        });
    };

    const keyStore = new KeyStore();
    const vehicleWrite = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const hasShowCost = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_COST_READ);

    const defaultColumns = getColumns(tableIncome.customFields !== null
        ? tableIncome.customFields
        : [], classes, enableRow, onChangeQuickNote, hasShowCost);
    const loadColumns = (settings) => {
        const columnSettings = settings || tableIncome.columnSettings;
        let sorted = cloneDeep(defaultColumns);

        if (ArrayUtils.isNotEmpty(columnSettings)) {
            sorted = sorted.map((column) => {
                const clone = { ...column };
                const setting = columnSettings.find((cs) => cs.columnName === clone.dataKey);

                if (setting) {
                    clone.show = setting.isDisplayed;
                    clone.order = setting.columnOrder;
                    if (setting.columnWidth) clone.width = setting.columnWidth;
                }

                return clone;
            });
        }

        sorted = sorted.filter((column) => (
            !column.dataKey.startsWith('customField')
            || (column.dataKey.startsWith('customField') && !StringUtils.isEmpty(column.label))
        ));

        sorted.sort((a, b) => a.order - b.order);
        return sorted;
    };

    useEffect(() => {
        if (tableIncome.columnSettings !== null && tableIncome.customFields !== null && tableIncome.columns.length === 0) {
            const tableIncomeClone = cloneDeep(tableIncome);
            tableIncomeClone.columns = loadColumns();

            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    tableIncome: tableIncomeClone,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableIncome.columnSettings, tableIncome.customFields, tableIncome.columns]);

    const saveColumnSettings = async (cols, dragging = false) => {
        const input = {
            module: modules.INVENTORY,
            tableName: Tables.INVENTORY_MAIN_LIST,
            columns: cols.map(({
                dataKey, order, width, show,
            }, index) => ({
                columnName: dataKey,
                columnOrder: dragging ? index + 1 : order,
                ...(width ? { columnWidth: width } : {}),
                isDisplayed: show,
            })),
        };

        try {
            const tableIncomeClone = cloneDeep(tableIncome);
            tableIncomeClone.columns = loadColumns(input.columns);
            tableIncomeClone.columnSettings = input.columns;

            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    tableIncome: tableIncomeClone,
                },
            });

            const response = await client.mutate({
                mutation: ColumnSettingsMutation.SAVE_COLUMN_SETTINGS_BY_TABLE,
                variables: { ...input },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (response && flags.toggleTableSettingsDialog) {
                ModalUtils.successMessage(null, 'Columns updated successfully.');
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const filterData = (filters, column, records) => {
        const reorderedFilters = [];
        for (let x = 0; x < defaultColumns.length; x += 1) {
            const current = filters.find((f) => f.columnName === defaultColumns[x].dataKey);
            if (current && (!column || column !== defaultColumns[x].dataKey)) reorderedFilters.push(current);
        }

        return filterLocalData(reorderedFilters, records ?? table.backup);
    };

    const onLoadFilterValues = async (column, searchInput = null) => {
        const currentColumn = defaultColumns.find((x) => x.dataKey === column);
        const data = loadLocalFilterValues(
            filterData(params.filters, column),
            tableIncome.columnsData,
            column,
            searchInput,
            true,
            currentColumn?.isNumeric ?? false,
        );
        if (data) updateColumnsData(data);
    };

    const onResetFilters = (column, keepColumnData = false) => {
        const data = resetLocalFilters(tableIncome.columnsData, column, params.filters);

        const paramsClone = cloneDeep(params);
        paramsClone.filters = data.filters;

        const tableIncomeClone = cloneDeep(tableIncome);
        if (keepColumnData) {
            const columnsDataClone = cloneDeep(tableIncome.columnsData);
            const columnData = columnsDataClone.find((x) => x.column === column);
            columnData.selected = columnData.records;

            tableIncomeClone.columnsData = columnsDataClone;
        } else {
            tableIncomeClone.columnsData = data.columnsData;
        }

        tableIncomeClone.columns = loadColumns();
        const filteredRecords = filterData(data.filters);
        filteredRecords.forEach((x) => { x.stocks = [...new Set(filteredRecords.map((record) => record.stock))]; });

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                tableIncome: tableIncomeClone,
                table: {
                    records: filteredRecords,
                },
            },
        });
    };

    const onApplyFilters = (column, selectedValues) => {
        const data = applyLocalFilters(params.filters, column, selectedValues, true, tableIncome.columnsData);

        const paramsClone = cloneDeep(params);
        paramsClone.filters = data.filters;

        const tableIncomeClone = cloneDeep(tableIncome);
        tableIncomeClone.columnsData = data.columnsData;
        tableIncomeClone.columns = loadColumns();

        const filteredRecords = filterData(data.filters);
        filteredRecords.forEach((x) => { x.stocks = [...new Set(filteredRecords.map((record) => record.stock))]; });

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                tableIncome: tableIncomeClone,
                table: {
                    records: filteredRecords,
                },
            },
        });
    };

    const useLargeRow = shownColumns.some((c) => ['Thumbnail', 'Vehicle'].includes(c.label));
    let tableWidth = shownColumns.reduce((a, b) => b.width + a, 0);
    if (tableWrapperWidth > tableWidth) tableWidth = tableWrapperWidth - 30;

    const onColumnResized = (column, width) => {
        const clone = cloneDeep(tableIncome.columns);
        const col = clone.find((element) => element.dataKey === column);
        if (col) col.width = width;

        saveColumnSettings(clone, false);
    };

    const onColumnSorted = (oldIndex, newIndex) => {
        const clone = cloneDeep(tableIncome.columns);

        const sourceColumn = shownColumns[oldIndex];
        const sourceColumnName = sourceColumn.dataKey;
        const sourceIndex = clone.findIndex((element) => element.dataKey === sourceColumnName);

        const targetColumnName = shownColumns[newIndex].dataKey;
        const targetIndex = clone.findIndex((element) => element.dataKey === targetColumnName);

        if (sourceIndex > -1 && targetIndex > -1 && sourceIndex !== targetIndex) {
            if (sourceIndex < targetIndex) {
                clone.splice(targetIndex + 1, 0, sourceColumn);
                clone.splice(sourceIndex, 1);
            } else {
                clone.splice(targetIndex, 0, sourceColumn);
                clone.splice(sourceIndex + 1, 1);
            }

            saveColumnSettings(clone, true);
        }
    };

    let currentLots = params.lots;
    if (
        tableIncome.availableLots
        && tableIncome.availableLots.length === params.lots.length
    ) {
        currentLots = [ALL_LOTS];
    }

    const mobileToolbar = () => (
        <div className={classes.toolbarMobile}>
            <div className={classes.searchBar}>
                <InputSearch
                    key={search.inputKey}
                    initialSearch={search.value}
                    onSearch={onSearch}
                />
            </div>
            <div className={classes.topSection}>
                <div>
                    { renderNewButton(vehicleWrite) }
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.stickerButton}
                        onClick={openStickersDialog}
                    >
                        Forms
                    </Button>
                </div>
                <LotFilter onSearch={onFilterByLot} initialLots={currentLots} />
            </div>
            <Grid
                container
                direction="row"
                spacing={4}
                className={classes.bottomSection}
            >
                <Grid item>
                    <FormLabel>
                        {`Vehicles: ${numeral(table?.records.length).format('0,0')}`}
                    </FormLabel>
                </Grid>
                <Grid item>
                    <Tooltip
                        title="Results limited to most recent 2000 results"
                        placement="bottom"
                    >
                        <span>
                            <Form.Check
                                type="checkbox"
                                label="Include sold vehicles"
                                className={classes.includeSold}
                                checked={!params.active}
                                onChange={onChangeIncludeSold}
                            />
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );

    const cardListTitle = ['year', 'make', 'model'];
    const cardListDetails = [
        {
            label: 'Stock',
            key: 'stock',
            nullValue: '-',
        },
        {
            label: 'Miles',
            key: 'miles',
            nullValue: '0',
        },
        {
            label: 'Age',
            key: 'age',
            nullValue: '0',
        },
        {
            label: 'Recon',
            key: 'reconStatus',
            nullValue: '',
        },
        {
            label: 'Lot',
            key: 'lotName',
            nullValue: '-',
        },
        {
            label: 'Status',
            key: 'vehicleLocation',
            nullValue: '-',
        },
    ];

    return (
        <div
            className={clsx(classes.main, isDetail && classes.hideList)}
        >
            {isMobile && mobileToolbar()}
            {!isMobile && (
                <Header>
                    <div className={classes.toolbar}>
                        { renderNewButton(vehicleWrite) }
                        <InputSearch
                            key={search.inputKey}
                            initialSearch={search.value}
                            onSearch={onSearch}
                        />
                        <Tooltip
                            title="Results limited to most recent 2000 results"
                            placement="bottom"
                        >
                            <span>
                                <Form.Check
                                    type="checkbox"
                                    label="Include sold vehicles"
                                    className={classes.includeSold}
                                    checked={!params.active}
                                    onChange={onChangeIncludeSold}
                                />
                            </span>
                        </Tooltip>
                        <div className={classes.itemsCounter}>
                            <FormLabel>
                                {`Vehicles: ${numeral(table?.records.length).format('0,0')}`}
                            </FormLabel>
                        </div>
                    </div>
                    <div className={classes.lotWrapper}>
                        <If condition={tableIncome.columns.length > 0}>
                            <div className={classes.settingsContainer}>
                                <Tooltip
                                    title="Table Settings"
                                    placement="left-start"
                                >
                                    <SettingsIcon
                                        onClick={openTableSettingsDialog}
                                    />
                                </Tooltip>
                            </div>
                        </If>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.stickerButton}
                            onClick={openStickersDialog}
                        >
                            Forms
                        </Button>
                        <LotFilter onSearch={onFilterByLot} initialLots={currentLots} />
                    </div>
                </Header>
            )}
            <div id="stocksContainer" className={classes.container}>
                {isMobile && (
                    <InfiniteScroll
                        useWindow
                        initialLoad={false}
                        loadMore={() => null}
                        hasMore={false}
                        loader={(
                            <div
                                className={classes.circularProgressContainer}
                                key="circular-progress"
                            >
                                <CircularProgress />
                            </div>
                        )}
                    >
                        <CardList
                            itemKey="stock"
                            pathname={`/${modules.INVENTORY}/stock`}
                            pathnameParams={[
                                {
                                    param: 'stock',
                                    key: 'stock',
                                },
                            ]}
                            data={table?.records}
                            title={cardListTitle}
                            image="image"
                            details={cardListDetails}
                        />
                    </InfiniteScroll>
                )}
                {!isMobile && userInformation && shownColumns.length > 0 && (
                    <VirtualTable
                        areHeadersDraggable
                        useAdvancedFeatures
                        loading={vehiclesLoading}
                        width={tableWidth}
                        rowHeight={useLargeRow ? 95 : 35}
                        totalRecords={table?.records.length}
                        data={table?.records}
                        columns={shownColumns}
                        sort={onSortedChange}
                        sortBy={params.sort.field}
                        sortDirection={params.sort.dir}
                        filters={params.filters}
                        loadFilterValues={onLoadFilterValues}
                        resetFilters={onResetFilters}
                        applyFilters={onApplyFilters}
                        columnsData={tableIncome.columnsData}
                        onColumnSorted={onColumnSorted}
                        onColumnResized={onColumnResized}
                        onRowDoubleClick={({ index }) => enableRow(index, true)}
                    />
                )}
            </div>
            <If condition={flags.toggleStickersDialog}>
                <StickersDialog
                    writePermission={vehicleWrite}
                    selectedLots={params.lots}
                    onClose={closeStickersDialog}
                />
            </If>
            <If condition={flags.toggleTableSettingsDialog}>
                <TableSettingsDialog
                    columns={tableIncome.columns}
                    columnsSet={defaultColumns}
                    saveColumnSettings={saveColumnSettings}
                    resetFilters={onResetFilters}
                    filters={params.filters}
                    onClose={closeTableSettingsDialog}
                />
            </If>
        </div>
    );
};

export default InventoryList;
