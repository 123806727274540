import React, { useReducer, useEffect, useContext } from 'react';
import {
    makeStyles, Button, useTheme,
    useMediaQuery, Tooltip, RadioGroup,
    FormControlLabel, Radio, Grid, Typography,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import Split from 'react-split';
import ModalUtils from 'utils/ModalUtils';
import GeneralUtils from 'utils/GeneralUtils';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import {
    useMutation,
    useQuery,
    useLazyQuery,
    useSubscription,
} from '@apollo/client';
import NumberUtils from 'lib/NumberUtils';
import InputNumber from 'components/widgets/InputNumber';

import {
    EditOutlinedIcon,
    LowPriorityIcon,
    AddIcon,
    DeleteOutlineOutlinedIcon,
    CheckOutlinedIcon,
    ListAltSharpIcon,
    ThumbDownOutlinedIcon,
} from 'components/icons';
import {
    PRINTING_DOCUMENT_TYPE,
    PRINTING_DOCUMENT_SOURCE_TYPE,
    NAMED_QUERY,
} from 'utils/enum/General';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import InputSearch from 'components/widgets/InputSearch';
import GeneralQuery from 'services/graphQL/query/core/GeneralQuery';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import PartsMappingProcess from 'components/modules/purchaseParts/parts/list/PartsMappingProcess';
import PartsSubscription from 'services/graphQL/subscription/parts/PartsSubscription';
import { PartsActions } from 'utils/enum/PartsPurchaseEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import { Col, Form, Row } from 'react-bootstrap';
import DropdownQuery from 'components/widgets/DropdownQuery';
import UserContext from 'components/context/UserContext';
import Filter from 'components/widgets/Filter';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const ownStyle = makeStyles((theme) => ({
    highlightText: {
        color: theme.palette.text.red,
    },
    paddingTop20: {
        paddingTop: '20px',
    },
    filterWidth: {
        width: '170px',
        border: `solid 1px ${theme.palette.border.ghost}`,
        padding: '4px 5px',
        borderRadius: '0.2rem',
        paddingRight: '10px',
        '& div': {
            fontSize: '14px',
            fontWeight: 400,
        },
    },
    iconFilterCls: {
        position: 'relative',
        right: '25px',
        top: '6px',
        zIndex: '-1',
    },
}));

const ACTION_TYPES = {
    SET_EDITING: 'setEditing',
    SET_NEW: 'setNew',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    CLOSE_SCREEN: 'setCloseScreen',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
    SET_ACTION: 'setCBAction',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
        };
    }
    case ACTION_TYPES.SET_EDITING: {
        return {
            ...state,
            isEditing: true,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.CLOSE_SCREEN: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.SET_ACTION: {
        return {
            ...state,
            ...action.value,
        };
    }
    default: return action.value;
    }
};

const PartsList = () => {
    const keyStore = new KeyStore();
    const PARTS_WRITE_ADD = keyStore.hasPermission(Permission.PARTS_WRITE_ADD);
    const PARTS_WRITE_EDIT = keyStore.hasPermission(Permission.PARTS_WRITE_EDIT);
    const PARTS_WRITE_TRANSFER = keyStore.hasPermission(Permission.PARTS_WRITE_ADD);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        availableLots, defaultLotId, defaultLot, lotsUser,
    } = useContext(UserContext);

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
            },
            status: 'active',
            search: '',
            showAllVendors: true,
            lotName: [{
                label: defaultLot,
                value: defaultLot,
            }],
        },
        table: {
            records: [],
            totalCount: 0,
        },
        actionRecord: null,
        actionType: '',
        quantityToTransfer: 0,
        lotTransferId: 0,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        params, table, selectedRow, actionType,
        actionRecord, quantityToTransfer, lotTransferId,
        lotTransfer,
    } = state;

    if (defaultLotId > 0 && lotTransferId === 0) {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                lotTransferId: defaultLotId,
                lotTransfer: defaultLot,
            },
        });
    }

    // eslint-disable-next-line no-nested-ternary
    const actionLabel = actionType === PartsActions.INACTIVE ? PartsActions.INACTIVE.toString()
        : (PartsActions.DELETE ? PartsActions.DELETE.toString() : PartsActions.ACTIVE.toString());

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionRecord: null,
                actionType: '',
                quantityToTransfer: 0,
                lotTransferId: 0,
            },
        });
    };

    const {
        data, loading, error,
    } = useQuery(PartsQuery.GET_PARTS_INVENTORY_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                status: params.status,
                vendorId: 0,
                showAllVendors: params.showAllVendors,
                lotsName: params.lotName.map((item) => item.value),
            },
            sort: [
                { fieldName: 'description', dir: DataSort.ASC },
            ],
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [printDocument, { loading: printingDocument }] = useLazyQuery(GeneralQuery.PRINT_DOCUMENT, {
        onCompleted: (response) => {
            const result = response.printDocument;
            if (result) {
                if (result?.data?.startsWith('Request failed')) {
                    ModalUtils.errorMessage(null, 'Error printing report due to too many records');
                    return;
                }

                const {
                    documentType,
                    data: documentOutput,
                } = result;

                if (documentType === PRINTING_DOCUMENT_TYPE.SPREADSHEET) {
                    const name = `Parts-List-${DateUtils.format(new Date(), DateFormat.SHORT_DATE_WITH_DASHES)}.xlsx`;
                    GeneralUtils.downloadFile(documentOutput, name);
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [activePart, { loading: changingStatus }] = useMutation(PartsMutation.ACTIVE_PART, {
        onCompleted: (mutationData) => {
            if (mutationData?.activePart) {
                ModalUtils.successMessage(null, 'Change status sucessfully');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [deletePart, { loading: deletingPart }] = useMutation(PartsMutation.DELETE_PART, {
        onCompleted: (mutationData) => {
            if (mutationData?.deletePart) {
                ModalUtils.successMessage(null, 'Delete part sucessfully');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [transferPart, { loading: transfering }] = useMutation(PartsMutation.TRANSFER_PART, {
        onCompleted: (mutationData) => {
            if (mutationData?.transferingPart) {
                ModalUtils.successMessage(null, 'Transfer part sucessfully');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text?.trim(),
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getPartsInventoryList } = data;

            records.push(...getPartsInventoryList.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getPartsInventoryList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onActionClick = (val, actionSelected) => {
        dispatch({
            type: ACTION_TYPES.SET_ACTION,
            value: {
                actionRecord: val,
                actionType: actionSelected,
            },
        });
    };

    useSubscription(PartsSubscription.PARTS_INVENTORY_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { action: actionSubs, data: subsData, id } = subscriptionData?.data?.partsInventoryChanged;

            if (actionSubs === SubscriptionActionType.ADDED) {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                records.unshift({ ...currentData });

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount: totalCount + 1,
                    },
                });
            } else if (actionSubs === SubscriptionActionType.UPDATED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);

                const currentIndex = currentRecords.findIndex((item) => Number(item.partsInventoryId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, { ...currentData });

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                            totalCount: table.totalCount,
                        },
                    });
                }
            } else if (actionSubs === SubscriptionActionType.DELETED) {
                const currentRecords = [...table.records];

                const currentIndex = currentRecords.findIndex((item) => Number(item.partsInventoryId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                            totalCount: currentRecords.totalCount,
                        },
                    });
                }
            }
        },
    });

    const onActionConfirm = async () => {
        onCloseActionDialog();

        if (actionType === PartsActions.INACTIVE) {
            await activePart({
                variables: {
                    id: actionRecord.partsInventoryId,
                    active: false,
                },
            });
        } else if (actionType === PartsActions.ACTIVE) {
            await activePart({
                variables: {
                    id: actionRecord.partsInventoryId,
                    active: true,
                },
            });
        } else if (actionType === PartsActions.TRANSFER) {
            await transferPart({
                variables: {
                    id: actionRecord?.partsInventoryId,
                    quantity: quantityToTransfer,
                    lotName: lotTransfer,
                },
            });
        } else if (actionType === PartsActions.DELETE) {
            await deletePart({
                variables: {
                    id: actionRecord?.partsInventoryId,
                },
            });
        }
    };

    const onSelecteLotTransfer = (columnId, newValue) => {
        const lot = lotsUser?.find((c) => Number(c.lotId) === Number(newValue));
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                lotTransferId: newValue,
                lotTransfer: lot?.lotName,
            },
        });
    };

    const exportToExcel = async () => {
        try {
            let suffix = ''; let namedQuery = '';
            const { status, lotName, search } = params;

            switch (status) {
            case 'active':
                suffix = '- Active Parts';
                namedQuery = NAMED_QUERY.ACTIVE_VEHICLE_PARTS;
                break;
            case 'activezero':
                suffix = '- Active zero QOH';
                namedQuery = NAMED_QUERY.ACTIVE_VEHICLE_ZERO_QOH;
                break;
            case 'inactive':
                suffix = '- In-Active Parts';
                namedQuery = NAMED_QUERY.IN_ACTIVE_VEHICLE_PARTS;
                break;
            default:
                break;
            }

            if (!StringUtils.isEmpty(search)) {
                suffix = '';
                namedQuery = NAMED_QUERY.VEHICLE_PARTS_SEARCH;
            }

            printDocument({
                variables: {
                    title: `Parts List ${suffix}`,
                    source: {
                        content: namedQuery,
                        type: PRINTING_DOCUMENT_SOURCE_TYPE.NAMED_QUERY,
                        replacementsInQuery: [
                            {
                                variable: '@@lots',
                                replacement: lotName.map((l) => `'${l.value}'`).join(','),
                            },
                            {
                                variable: '@@search',
                                replacement: search ?? '',
                            },
                        ],
                    },
                    type: PRINTING_DOCUMENT_TYPE.SPREADSHEET,
                },
            });
        } catch (ex) {
            ModalUtils.errorMessage(null, 'Error downloading the excel file');
        }
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Part Number',
            minWidth: 120,
            width: 120,
            id: 'PartStockNumber',
            accessor: 'partStockNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Description',
            minWidth: 200,
            width: 200,
            id: 'Description',
            accessor: 'description',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Part Cost',
            minWidth: 100,
            width: 100,
            id: 'PartCost',
            accessor: 'partCost',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Value',
            minWidth: 100,
            width: 100,
            id: 'value',
            accessor: 'value',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: (cellData) => {
                const {
                    original: {
                        partCost, quantityOnHand,
                    },
                } = cellData;

                if (partCost * quantityOnHand > 0) {
                    return <span>{NumberUtils.applyCurrencyFormat(partCost * quantityOnHand)}</span>;
                }
                return <span>0</span>;
            },
        },
        {
            Header: 'List Price',
            minWidth: 100,
            width: 100,
            id: 'NetPrice',
            accessor: 'netPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'QOH',
            minWidth: 70,
            width: 70,
            id: 'QuantityOnHand',
            accessor: 'quantityOnHand',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
        },
        {
            Header: 'Lot',
            minWidth: 120,
            width: 120,
            id: 'LotName',
            accessor: 'lotName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Bin',
            minWidth: 120,
            width: 120,
            id: 'binNumber',
            accessor: 'binNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Shelf',
            minWidth: 120,
            width: 120,
            id: 'shelf',
            accessor: 'shelf',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Notes',
            minWidth: 90,
            id: 'QuickNote',
            accessor: 'quickNote',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Actions',
            width: isMobile ? 120 : 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, isMobile ? '' : classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, isMobile ? '' : classes.hidden, isMobile ? '' : 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        partsInventoryId, active, quantityOnHand,
                    },
                    original,
                } = cellData;

                return (
                    <div className={classes.buttonSpacing}>
                        {PARTS_WRITE_EDIT
                            && (
                                <Button
                                    onClick={() => history.push(`/${modules.PARTS}/edit/${partsInventoryId}`)}
                                    variant="outlined"
                                    startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                    size="small"
                                >
                                    { isMobile ? '' : 'Edit' }
                                </Button>
                            )}
                        {PARTS_WRITE_EDIT && !active && (
                            <Button
                                onClick={() => onActionClick(original, PartsActions.ACTIVE)}
                                variant="outlined"
                                startIcon={<CheckOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Active' }
                            </Button>
                        )}
                        {PARTS_WRITE_EDIT && active && (
                            <Button
                                onClick={() => onActionClick(original, PartsActions.INACTIVE)}
                                variant="outlined"
                                startIcon={<ThumbDownOutlinedIcon />}
                                size="small"
                            >
                                { isMobile ? '' : 'Inactive' }
                            </Button>
                        )}
                        { PARTS_WRITE_TRANSFER && quantityOnHand > 0 && (
                            <Button
                                onClick={() => onActionClick(original, PartsActions.TRANSFER)}
                                variant="outlined"
                                startIcon={<LowPriorityIcon />}
                                size="small"
                            >
                                { isMobile ? '' : 'Transer Part' }
                            </Button>
                        )}
                        { PARTS_WRITE_EDIT && quantityOnHand === 0 && (
                            <Button
                                onClick={() => onActionClick(original, PartsActions.DELETE)}
                                variant="outlined"
                                startIcon={<DeleteOutlineOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Delete' }
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.main}>
            <Header>
                <div className={classes.containerFilterSearch}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    <div className="d-flex-align-baseline-space-between">
                        <Typography
                            variant="h5"
                            className={classes.filterLabel}
                        >
                            Lot: &nbsp;
                        </Typography>
                        <Filter
                            showTooltip
                            useInternalSearch
                            emptyRecord="All"
                            maxWidthLabel={150}
                            className={classes.filterWidth}
                            records={availableLots}
                            selectedValues={params.lotName || []}
                            applyFilter={(record) => onFilterChange('lotName', record)}
                            onClearFilter={() => onFilterChange('lotName', [])}
                        />
                        <ArrowDropDownIcon className={classes.iconFilterCls} />
                    </div>
                    <RadioGroup row value={params.status} onChange={(e, val) => onFilterChange('status', val)}>
                        <FormControlLabel
                            value="active"
                            control={<Radio color="primary" />}
                            label="Active Parts"
                        />
                        <FormControlLabel
                            value="activezero"
                            control={<Radio color="primary" />}
                            label="Active zero QOH"
                        />
                        <FormControlLabel
                            value="inactive"
                            control={<Radio color="primary" />}
                            label="In-Active Parts"
                        />
                    </RadioGroup>
                </div>
                <div className={clsx(classes.buttonSpacing, classes.justifyContentCenter)}>
                    {PARTS_WRITE_ADD
                && (
                    <>
                        <Tooltip title="Export to excel" placement="top-start">
                            <span>
                                <Button
                                    onClick={exportToExcel}
                                    variant="outlined"
                                    startIcon={<ListAltSharpIcon />}
                                    disabled={loading || printingDocument}
                                    size="small"
                                >
                                    XLS
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="New Part" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.PARTS}/create`)}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    New Part
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                )}
                </div>
            </Header>
            <Container className={classes.containerSplit}>
                <Split
                    sizes={[55, 45]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        data={table?.records}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={table?.totalCount}
                        loadMore={loadMore}
                        rowSelected
                        enableRowHover
                        className="-highlight actionsInLine"
                        getTrProps={(_, rowInfo) => ({
                            onClick: () => {
                                if (selectedRow?.partsInventoryId === rowInfo.original.partsInventoryId) return;
                                dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: rowInfo.original });
                            },
                        })}
                    />
                    <PartsMappingProcess
                        partsInventoryId={selectedRow?.partsInventoryId}
                    />
                </Split>
            </Container>
            {actionType && actionType !== PartsActions.TRANSFER && actionRecord.partsInventoryId > 0 && (
                <ConfirmDialog
                    title={`${actionLabel} Record #${actionRecord?.partStockNumber}`}
                    description={`Are you sure you want to ${actionLabel} this record?`}
                    open={actionType !== PartsActions.TRANSFER && actionRecord.partsInventoryId > 0}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseActionDialog}
                    onClickSecondary={onCloseActionDialog}
                    onClickPrimary={onActionConfirm}
                />
            ) }
            {actionType && actionType === PartsActions.TRANSFER && actionRecord.partsInventoryId > 0 && (
                <ConfirmDialog
                    title={`Transfer part ${actionRecord.partStockNumber}`}
                    description={`From Lot: ${actionRecord.lotName}. Quantity Available: ${actionRecord.quantityOnHand}`}
                    open={actionType === PartsActions.TRANSFER}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseActionDialog}
                    onClickSecondary={onCloseActionDialog}
                    onClickPrimary={onActionConfirm}
                    disablePrimaryButton={
                        quantityToTransfer === 0 || quantityToTransfer === ''
                    || defaultLotId === 0 || lotTransferId === 0
                    || lotTransfer === actionRecord.lotName
                    || quantityToTransfer > actionRecord.quantityOnHand
                    }
                >
                    <>
                        <Grid item xs={12} className={classes.paddingTop20}>
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={4} className="required">Quantity to Transfer</Form.Label>
                                <Col xs={8}>
                                    <InputNumber
                                        className={quantityToTransfer === 0 ? 'invalid-field' : ''}
                                        size="sm"
                                        value={quantityToTransfer}
                                        thousandSeparator
                                        placeholder="Quantity"
                                        name="quantityToTransfer"
                                        max={actionRecord.quantityOnHand}
                                        onChange={(value) => dispatch({
                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                            value: {
                                                quantityToTransfer: value,
                                            },
                                        })}
                                    />
                                </Col>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={4} className="required">Lot to Transfer</Form.Label>
                                <Col xs={8}>
                                    <DropdownQuery
                                        name="lotTransferId"
                                        className={lotTransferId === 0 ? 'invalid-field' : ''}
                                        value={lotTransferId}
                                        placeHolder="select a lot"
                                        onChange={(name, newValue) => onSelecteLotTransfer(name, newValue)}
                                        dataSource={{
                                            idField: 'lotId',
                                            descriptionField: 'lotName',
                                            localData: lotsUser,
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                        </Grid>
                    </>
                </ConfirmDialog>
            )}
            <If condition={transfering}>
                <DialogActionMessage message="Transferring part..." />
            </If>
            <If condition={changingStatus}>
                <DialogActionMessage message="Changing status..." />
            </If>
            <If condition={deletingPart}>
                <DialogActionMessage message="Deleting part..." />
            </If>
        </div>
    );
};

export default PartsList;
