import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import {
    makeStyles, Button,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import InputSearch from 'components/widgets/InputSearch';
import VirtualTable from 'components/widgets/VirtualTable';
import CustomQueries from 'services/graphQL/query/CustomQueries';
import CustomQueriesMutate from 'services/graphQL/mutate/CustomQueriesMutate';

// icons
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div:nth-child(1)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            '& > div': {
                width: '250px',
            },
        },
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        minWidth: '800px',
        height: '500px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        '& > div': {
            overflow: 'hidden',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionButtonParent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    actionButton: {
        minWidth: '32px',
        '& > span > span': {
            marginLeft: 0,
            marginRight: 0,
        },
    },
}));

const QueriesSettings = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        queries: [],
        search: null,
        selectedQueryId: null,
    });

    const {
        queries,
        search,
        selectedQueryId,
    } = state;

    const {
        data: queriesData,
        loading: loadingQueries,
        error: errorLoadingQueries,
    } = useQuery(CustomQueries.GET_ALL_SAVED_QUERIES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (errorLoadingQueries) {
            ModalUtils.errorMessage(errorLoadingQueries?.graphQLErrors);
            return;
        }

        if (!loadingQueries) {
            const { getAllSavedQueries } = queriesData;

            setState((prevState) => ({
                ...prevState,
                queries: [...(getAllSavedQueries ?? [])].sort((a, b) => a.queryName.localeCompare(b.queryName)),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingQueries, errorLoadingQueries]);

    const [changeLocking, { loading: savingLocking }] = useMutation(CustomQueriesMutate.EDIT_CUSTOM_QUERY, {
        onCompleted: (response) => {
            if (response) {
                if (selectedQueryId) {
                    setState((prevState) => {
                        const clone = cloneDeep(prevState.queries);
                        const query = clone.find((x) => x.savedQuerieId === selectedQueryId);
                        if (query) query.locked = !query.locked;

                        return {
                            ...prevState,
                            queries: clone,
                        };
                    });
                }
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const onSearch = (value) => {
        setState((prevState) => ({
            ...prevState,
            search: value,
        }));
    };

    const toggleLocking = ({
        savedQuerieId,
        queryName,
        queryText,
        locked,
    }) => {
        setState((prevState) => ({
            ...prevState,
            selectedQueryId: savedQuerieId,
        }));

        changeLocking({
            variables: {
                input: {
                    savedQuerieId,
                    queryName,
                    queryText,
                    locked: !locked,
                },
            },
        });
    };

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'queryName',
            width: 600,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (<span className={classes.text}>{record.queryName?.toUpperCase()}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { locked } = record;

                return (
                    <div className={classes.actionButtonParent}>
                        <Button
                            disabled={savingLocking}
                            className={clsx(locked ? classes.containedError : classes.containedInfo, classes.actionButton)}
                            size="small"
                            startIcon={locked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                            onClick={() => toggleLocking(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const filteredQueries = queries.filter((x) => StringUtils.isEmpty(search) || x.queryName?.toLowerCase()?.includes(search?.toLowerCase()));
    return (
        <div className={classes.box}>
            <div>
                <InputSearch
                    size="sm"
                    executeWhenClearButton={() => onSearch()}
                    onSearch={onSearch}
                />
            </div>
            <div>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingQueries}
                        rowHeight={45}
                        totalRecords={filteredQueries.length}
                        data={filteredQueries}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    );
};

export default QueriesSettings;
