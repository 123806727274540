/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import NumberUtils from 'lib/NumberUtils';
import { PayType } from 'utils/enum/PayrollEnum';
import { PAY_TYPE } from 'utils/enum/ServiceInvoiceEnum';

export default class CalculationServiceMethodsHelper {
    static getLaborCostLocal(payInfo) {
        const {
            payType = '',
            salary = 0,
            hours = 0,
        } = payInfo;

        let laborCost = 0;

        const dividerSalaryRate = 2080; // TODO: this should be part of settings

        if (payType) {
            if (payType?.toLowerCase() === 'salary') laborCost = (salary / dividerSalaryRate) * hours;
            else laborCost = salary * hours;
        }

        return NumberUtils.round(laborCost);
    }

    static setCalculationsLocal(record, serviceSettings) {
        const { jobs } = record;

        const {
            hourlyLaborRate,
            customerPayHourlyRate,
            shopSuppliesPercent,
            shopSuppliesLimit,
            surplusTax,
            customTaxRateValue,
            customTaxRateEnabled,
            noTaxOnRoLabor,
            technicianSettings,
        } = serviceSettings;

        const jobsApproved = jobs.filter((c) => c.approved);

        let warrantyCovered = 0;
        let internalCovered = 0;
        let partCost = 0;

        let totalParts = 0;
        let totalLabor = 0;
        let totalSubletCost = 0;
        let totalSubletPrice = 0;
        let totalLaborCost = 0;
        let totalDiscount = 0;
        let totalCustomerPay = 0;
        let totalCustomerTax = 0;

        const defaultSurplusSalesTax = 7; // TODO: this should be in settings
        const surplusSalesTax = customTaxRateEnabled ? customTaxRateValue : (surplusTax ?? defaultSurplusSalesTax);
        const surplusTaxRate = surplusSalesTax / 100;

        jobsApproved.forEach((item) => {
            const payTypeUpper = item.payType.toUpperCase();

            if (record.inHouse || (!record.inHouse && (payTypeUpper === PAY_TYPE.WE_OWE || payTypeUpper === PAY_TYPE.AFTER_SALE_REPAIR))) {
                item.hourlyRate = hourlyLaborRate;
            } else {
                item.hourlyRate = customerPayHourlyRate;
            }

            item.laborTotal = NumberUtils.round(item.isHrsChecked ? item.hours * item.hourlyRate : item.laborTotal);

            const payInfo = technicianSettings.find((c) => Number(c.userId) === Number(item.technicianId));
            item.isFlatRate = payInfo?.payType?.toUpperCase() === PayType.FLAT_RATE.toUpperCase();
            item.laborCost = this.getLaborCostLocal({
                ...payInfo,
                hours: item.hours,
            });

            item.partsTotal = 0;
            item?.parts?.forEach((part) => {
                item.partsTotal += NumberUtils.round(part.netPrice * part.quantity);
                partCost += NumberUtils.round(part.partCost * part.quantity);
            });

            item.partsTotal = NumberUtils.round(item.partsTotal);

            if (record.laborDiscount) item.discount = 0;

            switch (payTypeUpper) {
            case PAY_TYPE.WARRANTY_PARTS_LABOR:
                warrantyCovered += item.partsTotal + item.laborTotal;
                break;
            case PAY_TYPE.WARRANTY_LABOR_ONLY:
                warrantyCovered += item.laborTotal;
                break;
            case PAY_TYPE.WARRANTY_PARTS_ONLY:
                warrantyCovered += item.partsTotal;
                break;
            case PAY_TYPE.WARRANTY_PAY_SUBLET:
                warrantyCovered += item.subletPrice;
                break;
            default:
                warrantyCovered += 0;
                break;
            }

            if ((payTypeUpper === PAY_TYPE.INTERNAL || payTypeUpper === PAY_TYPE.AFTER_SALE_REPAIR || payTypeUpper === PAY_TYPE.WE_OWE) && !record.inHouse) {
                internalCovered += item.partsTotal + item.laborTotal + item.subletPrice;
            }

            item.warrantyCovered = Number(warrantyCovered);
            item.internalCovered = Number(internalCovered);
            item.customerPay = payTypeUpper === PAY_TYPE.CUSTOMER_PAY ? Number(item.partsTotal + item.laborTotal + item.subletPrice) : 0;
            item.customerTax = (payTypeUpper === PAY_TYPE.CUSTOMER_PAY && record.isTaxable)
                ? Number(item.partsTotal + (noTaxOnRoLabor ? 0 : item.laborTotal) + item.subletPrice) * surplusTaxRate : 0;

            totalParts += Number(item.partsTotal);
            totalLabor += Number(item.laborTotal);
            totalSubletCost += Number(item.subletCost);
            totalSubletPrice += Number(item.subletPrice);
            totalLaborCost += Number(item.laborCost);
            totalDiscount += Number(item.discount);
            totalCustomerPay += Number(item.customerPay);
            totalCustomerTax += Number(item.customerTax);
        });

        const totalWarrantyCovered = NumberUtils.round(warrantyCovered);
        const totalInternalCovered = NumberUtils.round(internalCovered);
        const totalPartCost = NumberUtils.round(partCost);

        const discountAmount = NumberUtils.round(record.laborDiscount ? totalLabor * record.discountPercentage / 100 : totalDiscount);

        let shopAndFees = Number(record.fees || 0) + Number(record.shopSupplies || 0);
        let shopSupplies = record.shopSupplies || 0;

        if (shopSuppliesPercent > 0) {
            const shopSuppliesAmt = (totalParts + totalLabor + totalSubletPrice + record.fees + record.warrantyDeductable
                                    - totalWarrantyCovered - totalInternalCovered - discountAmount) * (shopSuppliesPercent / 100);

            shopSupplies = record.overrideShopSuppliesFee ? record.shopSupplies
                : NumberUtils.round((shopSuppliesAmt > shopSuppliesLimit && shopSuppliesLimit > 0)
                    ? shopSuppliesLimit : shopSuppliesAmt);

            shopAndFees = Number(record.fees) + Number(shopSupplies);
        }

        totalCustomerPay += NumberUtils.round(record.warrantyDeductable + shopAndFees);
        if (record.isTaxable) {
            totalCustomerTax += NumberUtils.round((shopAndFees - discountAmount) * surplusTaxRate);
        } else totalCustomerTax = 0;

        const totalInvoice = NumberUtils.round(totalParts + totalLabor + totalSubletPrice + shopAndFees + totalCustomerTax + record.warrantyDeductable
            - totalWarrantyCovered - totalInternalCovered - discountAmount);

        record.shopSupplies = shopSupplies;
        record.warranty = totalWarrantyCovered;
        record.discount = discountAmount;
        record.totalLabor = totalLabor;
        record.totalParts = totalParts;
        record.partsProfit = totalParts - totalPartCost;
        record.laborProfit = totalLabor - totalLaborCost;
        record.total = totalInvoice;
        record.tax = NumberUtils.round(totalCustomerTax);
        record.totalSubletCost = totalSubletCost;
        record.totalSubletPrice = totalSubletPrice;
        record.customerPay = totalCustomerPay;
        record.surplusSalesTax = surplusSalesTax;

        return record;
    }
}
