/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useReducer, useEffect, useContext,
} from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useAxios from 'axios-hooks';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import MessageUtils from 'utils/MessageUtils';
import Permission from 'utils/enum/Permissions';
import UserContext from 'components/context/UserContext';
import MessageStyles from 'styles/widgets/MessageStyles';
import MessageAdd from 'components/widgets/sms/MessageAdd';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import SelectMessageTemplate from 'components/modules/crm/widgets/SelectMessageTemplate';

// Material UI
import Message from 'components/widgets/sms/Message';
import { makeStyles, Typography, Divider } from '@material-ui/core';

// Http
import If from 'components/widgets/conditional/If';
import { FetchPolicy, HttpMethods } from 'utils/enum/Core';
import SMSQuery from 'services/graphQL/query/crm/SMSQuery';
import HttpCRMModule from 'services/httpModules/HttpCRMModule';
import SMSMutation from 'services/graphQL/mutate/crm/SMSMutation';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import SMSSubscription from 'services/graphQL/subscription/crm/SMSSubscription';
import MessageCenterMutate from 'services/graphQL/mutate/crm/MessageCenterMutate';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

const keyStore = new KeyStore();
const useStyles = makeStyles((theme) => MessageStyles.crmMessageList(theme));
const ACTION_TYPE = {
    GET_SMS: 'getSMS',
    SET_OFFSET: 'setOffset',
    ADD_MESSAGE: 'addMessage',
    SET_SMS_LIST: 'setSMSList',
    SET_TEXT_MESSAGE: 'setTextMessage',
    CHANGE_TEMPLATE: 'onChangeTemplate',
    UPDATE_MESSAGE: 'updateMessage',
    UPDATE_LOADING_SMS_PAGE: 'updateLoadingSMSPage',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.ADD_MESSAGE:
        return update(state, {
            records: { $push: [action.payload] },
            totalCount: { $set: state.totalCount + 1 },
        });
    case ACTION_TYPE.UPDATE_MESSAGE:
        const record = action.payload;
        const index = state.records.findIndex((item) => item.smsId === record.smsId);

        if (index >= 0) {
            const recordsUpdated = update(state.records, { [index]: { status: { $set: record.status }, errorCode: { $set: record.errorCode } } });

            return update(state, {
                records: { $set: recordsUpdated },
            });
        }

        return state;
    case ACTION_TYPE.SET_OFFSET:
        return { ...state, offset: action.offset };
    case ACTION_TYPE.SET_SMS_LIST:
        const templateList = (action.payload || []).map((item) => ({ label: item.templateName, value: item.messageTemplateId, subject: item.templateSubject }));

        return update(state, {
            templateList: { $set: templateList },
        });
    case ACTION_TYPE.CHANGE_TEMPLATE:
        return update(state, {
            templateSelected: { $set: action.value },
        });
    case ACTION_TYPE.GET_SMS:
        const result = [...action.records?.data]?.reverse();
        return update(state, {
            records: { $unshift: result },
            totalCount: { $set: action.records?.totalCount },
        });
    case ACTION_TYPE.SET_TEXT_MESSAGE:
        return update(state, {
            textMessage: { $set: action.value },
        });
    case ACTION_TYPE.UPDATE_LOADING_SMS_PAGE:
        return update(state, {
            loadingSMSPage: { $set: action.value },
        });
    default:
        return state;
    }
};

const MessageList = ({
    crmId, allowSendMessages, leadCode, prospectLanguage,
}) => {
    const classes = useStyles();
    const CRM_LEAD_SMS_WRITE = keyStore.hasPermission(Permission.CRM_LEAD_SMS_WRITE);
    const { userInformation } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, {
        records: [],
        totalCount: 0,
        offset: 0,
        templateSelected: null,
        textMessage: null,
        loadingSMSPage: false,
    });
    const paginate = { offset: state.offset, limit: 50 };
    /**
     * The query of the messages is not doing cache because it is necessary to have them updated,
     * the subscription is being carried out only at the moment the user enters the detail and unsubscribes when it leaves the detail.
    */
    const {
        loading, data, error,
    } = useQuery(SMSQuery.GET_SMS_BY_CRM_ID, { variables: { paginate, crmId }, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const [sendMessage] = useMutation(SMSMutation.SEND_MESSAGE_FROM_CRM);
    const [markMessageAsRead] = useMutation(MessageCenterMutate.MARK_MESSAGE_AS_READ);
    const { data: subscriptionData } = useSubscription(SMSSubscription.SMS_UPDATED, { variables: { crmId }, shouldResubscribe: true });
    const onMarkMessage = async (message) => {
        try {
            if (message.smsChatId) await markMessageAsRead({ variables: { smsChatId: message.smsChatId } });
        } catch (e) {
            ModalUtils.errorMessage(null, e.message);
        }
    };
    const [
        { loading: loadingGetPreview },
        getPreview,
    ] = useAxios({
        url: HttpCRMModule.getURLSMSTemplatePreview(state.templateSelected, leadCode),
        method: HttpMethods.GET,
    }, { manual: true });

    useEffect(() => {
        if (loading) {
            dispatch({ type: ACTION_TYPE.UPDATE_LOADING_SMS_PAGE, value: true });
        }

        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            dispatch({ type: ACTION_TYPE.UPDATE_LOADING_SMS_PAGE, value: false });
            return;
        }

        if (!loading && !isEmpty(data?.getSMSByCRMId)) {
            const { getSMSByCRMId } = data;
            dispatch({ type: ACTION_TYPE.GET_SMS, records: getSMSByCRMId });

            const messages = getSMSByCRMId.data;
            const message = messages[0] ?? {};
            onMarkMessage(message);

            if (getSMSByCRMId?.data?.length === 0) dispatch({ type: ACTION_TYPE.UPDATE_LOADING_SMS_PAGE, value: false });
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (state.records.length > 0) dispatch({ type: ACTION_TYPE.UPDATE_LOADING_SMS_PAGE, value: false });
    }, [state.records]);

    useEffect(() => {
        if (subscriptionData) {
            const record = subscriptionData.smsUpdated;
            if (record.type === SubscriptionActionType.ADDED) {
                dispatch({
                    type: ACTION_TYPE.ADD_MESSAGE,
                    payload: record.sms,
                });
                onMarkMessage(record.sms);
                return;
            }

            if (record.type === SubscriptionActionType.UPDATED) {
                dispatch({
                    type: ACTION_TYPE.UPDATE_MESSAGE,
                    payload: record.sms,
                });
            }
        }
    }, [subscriptionData]);

    const onChangeTemplate = (value) => {
        dispatch({
            type: ACTION_TYPE.CHANGE_TEMPLATE,
            value,
        });
    };

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, offset: currentOffset });
    };

    const onGetPreview = async () => {
        if (!StringUtils.isEmpty(state.templateSelected)) {
            try {
                const response = await getPreview();
                if (response?.data?.Success) {
                    // * Some templates have more than 500 characters so the length is being cut off.
                    dispatch({
                        type: ACTION_TYPE.SET_TEXT_MESSAGE,
                        value: response?.data?.Data?.slice(0, 500),
                    });
                } else {
                    ModalUtils.errorMessage(response?.data?.Messages, response?.data?.Message);
                }
            } catch (e) {
                ModalUtils.errorMessage(e?.response?.data?.errors);
            }
        }
    };

    useEffect(() => {
        if (state.templateSelected) {
            onGetPreview();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.templateSelected]);

    const onSendMessage = async (record) => {
        // TODO: When the lot selection is integrated change this to the selected lot
        const input = {
            crmId,
            text: record.text,
            lotName: userInformation?.defaultLot,
        };

        try {
            const response = await sendMessage({ variables: { input } });

            if (!response?.data?.sendMessageFromCRM) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('send', 'message'));
            }
        } catch (e) {
            ModalUtils.errorMessage(null, e.message);
        }
    };

    return (
        <div className={classes.messageList}>
            <div className={classes.toolbar}>
                <Typography
                    variant="h6"
                    className={classes.customerName}
                    color="textPrimary"
                >
                    SMS templates
                </Typography>
                <If condition={CRM_LEAD_SMS_WRITE && allowSendMessages}>
                    <SelectMessageTemplate
                        prospectLanguage={prospectLanguage}
                        onChange={onChangeTemplate}
                        disabled={loadingGetPreview}
                    />
                </If>
            </div>
            <Divider />
            <InfiniteScroll
                lengthRecord={state.records.length}
                totalRecord={state.totalCount}
                loadMore={loadMore}
                scroll="top"
                autoScrollBottom
                load={state.loadingSMSPage}
            >
                <Message
                    records={state.records}
                />
            </InfiniteScroll>
            <Divider />
            <If condition={CRM_LEAD_SMS_WRITE && allowSendMessages}>
                <MessageAdd onSubmit={onSendMessage} textMessage={state.textMessage} />
            </If>
        </div>
    );
};

MessageList.propTypes = {
    leadCode: PropTypes.number,
    allowSendMessages: PropTypes.bool,
    prospectLanguage: PropTypes.string,
    crmId: PropTypes.string.isRequired,
};

MessageList.defaultProps = {
    leadCode: null,
    allowSendMessages: false,
    prospectLanguage: 'English',
};

export default MessageList;
