const InventoryCategory = Object.freeze({
    INV_CUSTOM_FIELD_1: 'invCustomField1',
    INV_CUSTOM_FIELD_2: 'invCustomField2',
    INV_CUSTOM_FIELD_3: 'invCustomField3',
    INV_CUSTOM_FIELD_4: 'invCustomField4',
    SHOW_CUSTOM_FIELD_1: 'showCustomField1',
    SHOW_CUSTOM_FIELD_2: 'showCustomField2',
    SHOW_CUSTOM_FIELD_3: 'showCustomField3',
    SHOW_CUSTOM_FIELD_4: 'showCustomField4',
    INV_USER_TAB_NAME_1: 'invUserTabName1',
    INV_USER_TAB_NAME_2: 'invUserTabName2',
    INV_MARKET_PERCENTAGE: 'marketPercentage',
    INV_MARKET_DISTANCE: 'distance',
    INV_DEFAULT_MAX_RECON_COST: 'defaultMaximumReconCost',
    DESKING_DEAL_FEES: 'deskingDealFees',
    DESKING_DEAL_TAX_RATE: 'deskingDealTaxRate',
    REPAIRS_MANAGEMENT_ENABLE: 'repairsManagementEnable',
    TIME_CLOCK_IP: 'TimeClockIP',
    PLACE_ONLINE_ADS_DEFAULT: 'placeOnlineAdsByDefault',
    FLOOR_PLANNED_DEFAULT: 'floorPlannedByDefault',
    FLOORING_COMPANY_DEFAULT: 'flooringCompanyByDefault',
});

const Actions = Object.freeze({
    ADD: 'add',
    REMOVE: 'remove',
});

const Tabs = Object.freeze({
    DETAIL: '#detail',
    MARKET_COMPS: '#marketComps',
    REPAIR: '#repairs',
    ADVERTISING: '#advertising',
    FEATURE: '#features',
    EQUIPMENTS: '#equipment',
    RECON: '#recon',
    GPS: '#gps',
    DOCUMENTS: '#documents',
    HISTORY: '#history',
    INVENTORY_USER_TAB_ONE: '#invUserTabName1',
    INVENTORY_USER_TAB_TWO: '#invUserTabName2',
});

const AdvertisingTabs = Object.freeze({
    PHOTOS: 0,
    DESCRIPTION: 1,
});

const ReconTabs = Object.freeze({
    INSPECTION_LIST: 0,
    REPAIRS_LIST: 1,
});

const InventoryPanels = Object.freeze({
    PRICING: 'Pricing',
    BOOK_VALUE: 'Book Value',
    FLOORPLAN: 'Floorplan',
    CUSTOM_FIELDS: 'Custom Fields',
    ADVERTISING_SETUP: 'Advertising Setup',
    PURCHASE_INFO: 'Purchase Info',
    INTERACTIONS: 'Interactions',
    DEPRECIATION: 'Depreciation',
});

const InventoryColor = Object.freeze({
    EXTERIOR_COLOR: 'Exterior Color',
    INTERIOR_COLOR: 'Interior Color',
    GENERIC_EXTERIOR_COLOR: 'Generic Exterior Color',
});

const VehicleInfoProviders = Object.freeze({
    CAR_FAX: 'CARFAX',
    AUTO_CHECK: 'AUTOCHECK',
});

const Transmissions = Object.freeze({
    AUTOMATIC: 'Automatic',
    STANDARD: 'Standard',
});

const TitleStatus = Object.freeze([
    'None',
    '0-Clean',
    '1-Salvage',
    '3-Rebuild',
    '4-Manufacturer Buyback',
    '5-Water Damage',
]);

const PurchaseAgentType = Object.freeze({
    TRADE_IN: 'TRADE-IN',
});

const ReconStatus = Object.freeze({
    INSPECTION_PENDING: 'Inspection Pending',
    INSPECTION_COMPLETE: 'Inspection Complete',
    INSPECTION_APPROVED: 'Inspection Approved',
    REPAIRS_COMPLETE: 'Repairs Complete',
});

const ReconAssigneeType = {
    INDIVIDUAL: 'INDIVIDUAL',
    VENDOR: 'VENDOR',
};

const ReconCardType = {
    APPROVALS: 'APPROVALS',
    PARTS: 'PARTS',
    PARTS_REQUEST: 'PARTS_REQUEST',
};

const PartStatus = {
    ESTIMATE_UPDATED: 'Estimate Updated',
    PART_ORDERED: 'Part Ordered',
    PART_RECEIVED: 'Part Received',
    BACK_ORDERED: 'Back Ordered',
};

const MarketCompsDistanceOptions = [
    '10',
    '20',
    '30',
    '40',
    '50',
    '60',
    '70',
    '80',
    '90',
    '100',
    '300',
    '600',
    'Any',
];

const FilterSource = {
    RECON_CARD: 'Recon Card',
    APPRAISALS: 'Appraisals',
    INVENTORY_LIST: 'Inventory List',
};

const SpecialRegularExpression = {
    IMAGES_BUCKET_MATCH: /images\S+automatrix?\w+.com/ig,
};

const MinimumSalePrice = Object.freeze({
    INTERNET_PRICE: 'internetPrice',
    STICKER_PRICE: 'stickerPrice',
    MINIMUM_PRICE: 'minimumPrice',
});

const ConfirmDialogOperation = Object.freeze({
    DELETE: 'delete',
    WATERMARK: 'watermark',
    EDIT_DESCRIPTION: 'editDescription',
});

const DescriptionSentenceMapping = {
    KEY_FEATURES: '[[Key Features]]',
};

const FilterJoin = {
    AND: 'and',
    OR: 'or',
};

export {
    Actions,
    InventoryCategory,
    InventoryPanels,
    Tabs,
    InventoryColor,
    Transmissions,
    TitleStatus,
    VehicleInfoProviders,
    AdvertisingTabs,
    PurchaseAgentType,
    ReconTabs,
    ReconStatus,
    ReconAssigneeType,
    ReconCardType,
    PartStatus,
    MarketCompsDistanceOptions,
    FilterSource,
    SpecialRegularExpression,
    MinimumSalePrice,
    ConfirmDialogOperation,
    DescriptionSentenceMapping,
    FilterJoin,
};
