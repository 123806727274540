import { gql } from '@apollo/client';

export default class GeneralQuery {
    static PRINT_DOCUMENT = gql`
        query printDocument(
            $title: String,
            $source: DocumentSource!,
            $type: String!,
            $filters: [DocumentFilter!]
        ) {
            printDocument(
                title: $title,
                source: $source,
                type: $type,
                filters: $filters
            ) {
                documentType
                data
            }
        }
    `;
}
