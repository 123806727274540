import React, { Component } from 'react';

const DealTabsContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // edit mode
            clientId: '',
            lotName: null,
            editingMode: {
                isEditing: false,
                sectionName: '',
                tabName: '',
            },
        };

        this.initBind();
    }

    onChangeEditingMode(isEditing = false, sectionName = '', tabName = '') {
        this.setState({ editingMode: { isEditing, sectionName, tabName } });
    }

    setClientId(value) {
        this.setState({ clientId: value });
    }

    setLotName(value) {
        this.setState({ lotName: value });
    }

    initBind() {
        this.setLotName = this.setLotName.bind(this);
        this.setClientId = this.setClientId.bind(this);
        this.onChangeEditingMode = this.onChangeEditingMode.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                setLotName={this.setLotName}
                setClientId={this.setClientId}
                onChangeEditingMode={this.onChangeEditingMode}
            />
        );
    }
};

export default DealTabsContainer;
