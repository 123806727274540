import React, {
    useEffect,
    useReducer,
    useRef,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Button,
    Tooltip,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import update from 'immutability-helper';
import ButtonStyles from 'styles/theme/Button';
import Checkbox from '@material-ui/core/Checkbox';
import If from 'components/widgets/conditional/If';
import { FilterIcon } from 'components/icons/index';
import useOutside from 'components/hook/core/useOutside';
import InputSearch from 'components/widgets/InputSearch';
import usePrevious from 'components/hook/core/usePrevious';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        zIndex: '999',
        backgroundColor: theme.palette.background.white,
        border: `1px solid ${theme.palette.border.ghost}`,
        borderRadius: '5px',
        padding: '5px',
        top: ({ top }) => top + 26,
        width: '250px',
        maxWidth: '250px',
        left: ({ left }) => {
            const rightLimit = window.innerWidth || document.documentElement.clientWidth;
            if (left + 250 > rightLimit) {
                return rightLimit - 260;
            }
            return left;
        },
    },
    valuesContainer: {
        position: 'relative',
        height: '200px',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: '5px',
        marginTop: '5px',
        padding: '5px',
        '& > label': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    actionsContainer: {
        '&  button': {
            width: '49%',
            fontSize: '12px',
        },
        '&  button:nth-child(1)': {
            marginRight: '1%',
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        backgroundColor: theme.palette.background.white,
        zIndex: '999',
        opacity: '0.8',
    },
    text: {
        color: theme.palette.text.outerSpace,
        cursor: 'pointer',
        fontSize: '15px',
        minWidth: '26px',
        fontWeight: '500',
        lineHeight: '20px',
        maxWidth: ({ maxWidthLabel }) => maxWidthLabel,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    icon: {
        '& svg': {
            width: '13px',
            height: '15px',
            fill: theme.palette.text.waterloo,
        },
    },
    filtered: {
        fill: `${theme.palette.background.red} !important`,
    },
    ...ButtonStyles.getStyle(theme),
}));
const ACTION_TYPE = {
    ON_CLEAR: 'onClear',
    ON_FILTER: 'onFilter',
    SET_INIT_VALUE: 'setInitValue',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_CLICK_OUTSIDE: 'onClickOutSide',
    ON_VALUE_SELECTED: 'onValueSelected',
    SET_SELECTED_VALUES: 'setSelectedValues',
};

const initState = {
    open: false,
    records: [],
    prevRecords: [],
    selectedValues: [],
    prevSelectedValues: [],
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_INIT_VALUE:
        return update(state, {
            records: { $set: action.payload },
            prevRecords: { $set: action.payload },
        });
    case ACTION_TYPE.SET_SELECTED_VALUES:
        return update(state, {
            selectedValues: { $set: action.payload },
            prevSelectedValues: { $set: action.payload },
        });
    case ACTION_TYPE.ON_FILTER:
        action.callback(state.selectedValues);
        return update(state, {
            open: { $set: false },
            prevSelectedValues: { $set: state.selectedValues },
        });
    case ACTION_TYPE.ON_CLICK_OUTSIDE:
        return update(state, {
            selectedValues: { $set: state.prevSelectedValues },
            open: { $set: false },
        });
    case ACTION_TYPE.ON_CLEAR:
        return update(state, {
            selectedValues: { $set: [] },
            prevSelectedValues: { $set: [] },
            open: { $set: false },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.field]: { $set: action.payload },
        });
    case ACTION_TYPE.ON_VALUE_SELECTED:
        const { payload } = action;
        const selectedValues = state.selectedValues.map((item) => item.value);

        if (selectedValues.includes(payload.value)) {
            const record = state.selectedValues.filter((sel) => sel.value !== payload.value);

            return update(state, {
                selectedValues: { $set: record },
            });
        }
        return update(state, {
            selectedValues: { $push: [payload] },
        });
    default:
        return state;
    }
};

const getToolTipContent = (record) => {
    if (record.length > 0) {
        const result = record.map((item, index) => <div key={`selected-${index}`}>{item.label}</div>);
        return <>{result}</>;
    }

    return '';
};

const Filter = ({
    records,
    selectedValues,
    emptyRecord,
    applyFilter,
    onClearFilter,
    onSearch,
    onClearSearch,
    maxWidthLabel,
    className,
    useInternalSearch,
    showTooltip,
    showIconOnly,
    filterId,
    onOpenCallback,
    onResetFiltersCallback,
}) => {
    const element = useRef();
    const elementPosition = element.current?.getBoundingClientRect();
    const classes = useStyles({
        maxWidthLabel,
        top: elementPosition?.top,
        left: elementPosition?.left,
    });
    const [state, dispatch] = useReducer(reducer, initState);
    const previousSelectedValues = usePrevious(selectedValues);
    const onChangeValue = (value, field) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: value,
            field,
        });
    };

    useEffect(() => {
        if (!isEqual(previousSelectedValues, selectedValues)) {
            dispatch({
                type: ACTION_TYPE.SET_SELECTED_VALUES,
                payload: selectedValues,
            });
        }
    }, [selectedValues, previousSelectedValues]);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPE.SET_INIT_VALUE,
            payload: records,
        });
    }, [records]);

    const onValueSelection = (value) => {
        dispatch({
            payload: value,
            type: ACTION_TYPE.ON_VALUE_SELECTED,
        });
    };

    const onSearchValue = (value) => {
        const currentValue = value?.toUpperCase();
        const result = state.prevRecords.filter((item) => item.label.toUpperCase().includes(currentValue));
        onChangeValue(result, 'records');
    };

    const onClearSearchValue = () => {
        onChangeValue(state.prevRecords, 'records');
    };

    const onFilter = () => {
        if (useInternalSearch) onClearSearchValue();

        dispatch({
            type: ACTION_TYPE.ON_FILTER,
            callback: applyFilter,
        });
    };

    const onClickOutSide = () => {
        dispatch({
            type: ACTION_TYPE.ON_CLICK_OUTSIDE,
        });
    };

    // This method clears the filters and close the popup
    const onClear = () => {
        dispatch({
            type: ACTION_TYPE.ON_CLEAR,
        });
        onClearFilter();
    };

    const refreshRecords = (values) => {
        dispatch({
            type: ACTION_TYPE.SET_INIT_VALUE,
            payload: values,
        });
    };

    const resetFilters = (filters) => {
        dispatch({
            type: ACTION_TYPE.SET_SELECTED_VALUES,
            payload: filters,
        });
    };

    const isEqualValue = isEqual(state.selectedValues, state.prevSelectedValues);
    useOutside(element, onClickOutSide);
    const text = state.selectedValues.length === 0 ? emptyRecord : state.selectedValues.map((item) => item.label).join(', ');
    const recordChecked = state.selectedValues.map((item) => item.value);
    const title = getToolTipContent(state.selectedValues);
    const isSelected = state.selectedValues.length > 0;

    const getToogleComponent = () => (
        <div
            className={showIconOnly ? classes.icon : classes.text}
            onClick={() => {
                if (state.open) onClickOutSide();
                else {
                    if (filterId === 'quickNotes') {
                        onOpenCallback(refreshRecords);
                        onResetFiltersCallback(resetFilters);
                    }
                    onChangeValue(true, 'open');
                }
            }}
        >
            {showIconOnly ? (
                <FilterIcon className={clsx({ [classes.filtered]: isSelected })} />
            ) : text}
        </div>
    );

    return (
        <div
            ref={element}
            className={className}
        >
            <If condition={showTooltip}>
                <Tooltip
                    title={title}
                    placement="bottom-start"
                >
                    { getToogleComponent() }
                </Tooltip>
            </If>
            <If condition={!showTooltip}>
                { getToogleComponent() }
            </If>

            <If condition={state.open}>
                <div className={classes.container}>
                    <div>
                        <InputSearch
                            size="sm"
                            executeWhenClearButton={() => (useInternalSearch ? onClearSearchValue() : onClearSearch())}
                            onSearch={(value) => (useInternalSearch ? onSearchValue(value) : onSearch(value))}
                        />
                    </div>
                    <div className={classes.valuesContainer}>
                        {state.records.map((item, index) => (
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={recordChecked.includes(item.value)}
                                        onClick={() => onValueSelection(item)}
                                    />
                                )}
                                label={item.label}
                                key={index}
                            />
                        ))}
                    </div>
                    <div className={classes.actionsContainer}>
                        <Button
                            disabled={isEqualValue}
                            className={classes.containedSecondaryInfo}
                            onClick={onFilter}
                        >
                            {`Apply (${state.selectedValues.length || 0})`}
                        </Button>
                        <Button
                            className={classes.containedSecondaryInfo}
                            disabled={state.selectedValues.length === 0}
                            onClick={onClear}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            </If>
        </div>
    );
};

Filter.propTypes = {
    records: PropTypes.array,
    selectedValues: PropTypes.array,
    onClearFilter: PropTypes.func,
    applyFilter: PropTypes.func,
    emptyRecord: PropTypes.string,
    onSearch: PropTypes.func,
    onClearSearch: PropTypes.func,
    useInternalSearch: PropTypes.bool,
    maxWidthLabel: PropTypes.number,
    className: PropTypes.string,
    showTooltip: PropTypes.bool,
    showIconOnly: PropTypes.bool,
    filterId: PropTypes.string,
    onOpenCallback: PropTypes.func,
    onResetFiltersCallback: PropTypes.func,
};

Filter.defaultProps = {
    records: [],
    selectedValues: [],
    onClearFilter: () => {},
    applyFilter: () => {},
    onSearch: () => {},
    onClearSearch: () => {},
    onOpenCallback: () => {},
    onResetFiltersCallback: () => {},
    emptyRecord: 'None',
    // * * When using internal search the onSearch props is not used
    useInternalSearch: false,
    maxWidthLabel: 120,
    className: '',
    showTooltip: false,
    showIconOnly: false,
    filterId: '',
};

export default Filter;
