/* eslint-disable no-restricted-globals */
import { useApolloClient } from '@apollo/client';
import { cloneDeep } from 'lodash';
import StringUtils from 'lib/StringUtils';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import ReportQuery from 'services/graphQL/query/ReportQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import { ReconStatus, ReconCardType, FilterSource } from 'utils/enum/InventoryEnum';

const useFilterActions = () => {
    const client = useApolloClient();

    const loadLocalFilterValues = (data, columnsData, column, search, preSelectOptions = false, isNumeric = false) => {
        const clone = cloneDeep(columnsData);
        let columnData = clone.find((cd) => cd.column === column);
        const items = [...new Set(data.map((el) => String(el[column] ?? '').toUpperCase()))]
            .filter((item) => !StringUtils.isEmpty(item))
            .sort((a, b) => (isNumeric ? Number(a) - Number(b) : a.localeCompare(b)));

        const records = search ? items.filter((el) => el.toLowerCase().includes(search.toLowerCase())) : items;
        if (!columnData) {
            columnData = {
                column,
                selected: preSelectOptions ? records : [],
                pagination: false,
            };

            clone.push(columnData);
        }

        columnData.search = search;
        columnData.records = records;

        return clone;
    };

    const loadFilterValues = async (
        columnsData,
        column,
        searchInput = null,
        filterSource,
        reconCardType,
        appraisalsStartDate,
        appraisalsEndDate,
        activeVehicles,
        lots,
    ) => {
        let clone = cloneDeep(columnsData);
        let columnData = clone.find((cd) => cd.column === column);
        if (!columnData || columnData.search !== searchInput) {
            if (columnData) {
                clone = clone.filter((cd) => cd.column !== columnData.column);
            }

            columnData = {
                column,
                records: [],
                selected: columnData?.selected || [],
                search: searchInput,
                total: 0,
                start: 0,
                limit: 50,
            };

            clone.push(columnData);
        }

        try {
            const firstLoad = columnData.total === 0;
            const hasMoreData = firstLoad ? true : columnData.records.length < columnData.total;
            if (!firstLoad && !hasMoreData) return null;

            const basePayload = {
                columnName: columnData.column,
                search: columnData.search,
                paginate: { start: columnData.start, limit: columnData.limit },
            };

            let query = null;
            let queryWithoutCounter = null;
            let queryName = null;

            switch (filterSource) {
            case FilterSource.INVENTORY_LIST:
                basePayload.onlyActive = activeVehicles;
                basePayload.lots = lots;

                query = InventoryQuery.GET_DISTINCT_VALUES_BY_COLUMN;
                queryWithoutCounter = InventoryQuery.GET_DISTINCT_VALUES_BY_COLUMN_NO_COUNT;
                queryName = 'getDistinctValuesByColumn';
                break;
            case FilterSource.RECON_CARD:
                basePayload.onlyActive = true;
                basePayload.criteria = reconCardType === ReconCardType.APPROVALS ? [{
                    column: 'reconStatus',
                    value: ReconStatus.INSPECTION_COMPLETE,
                }] : [
                    {
                        column: 'reconStatus',
                        clause: 'in',
                        value: [
                            ReconStatus.INSPECTION_COMPLETE,
                            ReconStatus.INSPECTION_APPROVED,
                        ].join(','),
                    },
                ];

                query = InventoryQuery.GET_DISTINCT_VALUES_BY_COLUMN;
                queryWithoutCounter = InventoryQuery.GET_DISTINCT_VALUES_BY_COLUMN_NO_COUNT;
                queryName = 'getDistinctValuesByColumn';
                break;
            case FilterSource.APPRAISALS:
                basePayload.startDate = appraisalsStartDate;
                basePayload.endDate = appraisalsEndDate;

                query = ReportQuery.GET_DISTINCT_VALUES_BY_COLUMN_APPRAISAL_LIST;
                queryWithoutCounter = ReportQuery.GET_DISTINCT_VALUES_BY_COLUMN_NO_COUNT_APPRAISAL_LIST;
                queryName = 'getDistinctValuesByColumnAppraisalList';
                break;
            default:
                break;
            }

            const response = await client.query({
                query: firstLoad
                    ? query
                    : queryWithoutCounter,
                variables: basePayload,
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (response) {
                const { values, totalCount } = response.data?.[queryName];
                columnData.records = [...columnData.records, ...values];
                columnData.total = firstLoad ? totalCount : columnData.total;
                if (hasMoreData) columnData.start = (((columnData.start / columnData.limit) + 1) * columnData.limit);

                const updatedColumnsData = clone.map((item) => {
                    if (item.column === column) return columnData;
                    return item;
                });

                return updatedColumnsData;
            }
        } catch (error) {
            ModalUtils.errorMessage(null, 'We could not load filters for this column.');
        }

        return null;
    };

    const setValuesInSelections = (
        columnsData,
        column,
        values,
    ) => {
        const clone = cloneDeep(columnsData);
        const columnData = clone.find((cd) => cd.column === column);

        if (columnData) {
            columnData.selected = values;
            const updatedColumnsData = clone.map((item) => {
                if (item.column === column) return columnData;
                return item;
            });

            return updatedColumnsData;
        }

        return null;
    };

    const resetFilters = (
        columnsData,
        column,
        filters,
    ) => {
        const clone = cloneDeep(columnsData);
        const updatedColumnsData = !column ? [] : clone
            .filter((item) => item.column !== column);

        if (filters) return { columnsData: updatedColumnsData, filters: !column ? [] : filters.filter((item) => item.columnName !== column) };
        return updatedColumnsData;
    };

    const applyFilters = (
        filters,
        column,
        condition,
        doNotJoinValues = false,
        columnsData,
    ) => {
        const isFilteredByValue = Array.isArray(condition);
        const clone = cloneDeep(filters);

        let current = clone.find((item) => item.columnName === column);
        if (!current) {
            current = {
                columnName: column,
                ...(isFilteredByValue ? {
                    values: doNotJoinValues ? condition : condition.join(','),
                } : {
                    values: condition,
                }),
            };

            clone.push(current);
        } else {
            if (isFilteredByValue) current.values = doNotJoinValues ? condition : condition.join(',');
            if (!isFilteredByValue) current.values = condition;
        }

        const updatedFilters = clone.map((item) => {
            if (item.columnName === current.columnName) return current;
            return item;
        });

        if (columnsData) {
            const columnsDataClone = cloneDeep(columnsData);
            const columnData = columnsDataClone.find((cd) => cd.column === column);
            columnData.selected = condition;

            return {
                columnsData: columnsDataClone,
                filters: updatedFilters,
            };
        }

        return updatedFilters;
    };

    const filterLocalData = (filters = [], data) => {
        if (filters.length === 0) return data;

        let clone = cloneDeep(data);
        filters.forEach(({ columnName, values }) => {
            const isFilteredByCondition = !Array.isArray(values) && typeof values !== 'string';
            const filterPossibleOptions = [...new Set(clone.map((item) => item[columnName]).filter((val) => !StringUtils.isEmpty(val)))];
            const isSelectAllChecked = !isFilteredByCondition && filterPossibleOptions.length === (values ?? []).length;

            if (!isFilteredByCondition) {
                const valuesToFilter = (Array.isArray(values) ? values : values.split(',')).map((x) => String(x).toUpperCase());
                clone = clone.filter((el) => valuesToFilter.includes(String(el[columnName] ?? '').toUpperCase())
                || (isSelectAllChecked && StringUtils.isEmpty(el[columnName])));
            } else {
                const {
                    firstConditionSelected,
                    firstConditionValue,
                    secondConditionSelected,
                    secondConditionValue,
                    join,
                } = values;

                const conditions = [];
                if (firstConditionSelected && firstConditionValue) conditions.push({ condition: firstConditionSelected, val: firstConditionValue });
                if (secondConditionSelected && secondConditionValue) conditions.push({ condition: secondConditionSelected, val: secondConditionValue });

                if (conditions.length > 0) {
                    clone = clone.filter((el) => {
                        try {
                            let conditionToEvaluate = '';
                            conditions.forEach((item, index) => {
                                const { condition, val } = item;
                                const prefix = join === 'and' ? '&&' : '||';
                                const isNumber = !isNaN(el[columnName]);

                                if (!condition.startsWith('.')) {
                                    const onLeftCondition = isNumber ? el[columnName] : `'${String(el[columnName] ?? '').toLowerCase()}'`;
                                    const onRightCondition = isNumber ? val : `'${val?.toLowerCase()}'`;
                                    const suffix = `${onLeftCondition} ${condition} ${onRightCondition}`;
                                    conditionToEvaluate += `${index > 0 ? `${prefix} ` : ''}${suffix}`;
                                }

                                if (condition.startsWith('.')) {
                                    const suffix = condition.replace('*', `'${val?.toLowerCase()}'`)
                                        .replace('.', `'${String(el[columnName] ?? '').toLowerCase()}'.`);
                                    conditionToEvaluate += `${index > 0 ? `${prefix} ` : ''}${suffix}`;
                                }
                            });

                            // eslint-disable-next-line no-eval
                            return eval(conditionToEvaluate);
                        } catch (_) {
                            return false;
                        }
                    });
                }
            }
        });

        return clone;
    };

    return {
        loadLocalFilterValues,
        loadFilterValues,
        setValuesInSelections,
        resetFilters,
        applyFilters,
        filterLocalData,
    };
};

export default useFilterActions;
