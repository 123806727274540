import React, { useContext, useEffect, useReducer } from 'react';

import clsx from 'clsx';
import {
    Box,
    Grid,
    AppBar,
    Toolbar,
    Checkbox,
    makeStyles,
    Typography,
    FormControlLabel,
} from '@material-ui/core';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import StringUtils from 'lib/StringUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Header from 'components/widgets/Header';
import Filter from 'components/widgets/Filter';
import CallIcon from '@material-ui/icons/Call';
import Permissions from 'utils/enum/Permissions';
import If from 'components/widgets/conditional/If';
import Container from 'components/widgets/Container';
import UserContext from 'components/context/UserContext';
import MainPanel from 'components/modules/crm/calendar/MainPanel';
import NotificationBadge, { TYPE } from 'components/layout/NotificationBadge';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import ActivitiesQuery from 'services/graphQL/query/crm/ActivitiesQuery';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { ActivityType, EventStatus, EventColors } from 'utils/enum/ActivitiesEnum';
import UserQuery from 'services/graphQL/query/UserQuery';

const greyColor = 'rgb(220, 218, 217)';
const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
        background: theme.palette.background.white,
        boxShadow: '1px 2px 5px 0px #C1C1C1',
        display: 'contents',
        alignItems: 'center',
    },
    gridItem: {
        height: '100%',
        overflow: 'auto',
        padding: '16px',
        backgroundColor: theme.palette.background.white,
    },
    title: {
        color: theme.palette.text.boulderGray,
        display: 'inline-block',
        marginRight: '12px',
    },
    routeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        padding: 0,
    },
    filterPanel: {
        marginRight: '10px',
    },
    list: {
        marginLeft: theme.spacing(1),
    },
    listItemIcon: {
        minWidth: theme.spacing(4),
    },
    listItemText: {
        '& span': {
            fontWeight: 500,
            color: theme.palette.text.boulder,
            fontSize: '13px',
        },
    },
    label: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.gray,
    },
    lotContainer: {
        marginRight: theme.spacing(1.5),
    },
    filter: {
        width: 120,
    },
    defaultActivity: {
        border: 'none',
    },
    taskCheckbox: {
        '& .MuiButtonBase-root': {
            color: 'rgb(171 170 170)',
        },
    },
    callCheckbox: {
        '& .MuiButtonBase-root': {
            color: 'rgb(171 170 170)',
        },
    },
    ScheduledCheckbox: {
        '& .MuiButtonBase-root': {
            color: '#5C6BC0',
        },
    },
    ConfirmedCheckbox: {
        '& .MuiButtonBase-root': {
            color: EventColors.CONFIRMED.color,
        },
    },
    ShowCheckbox: {
        '& .MuiButtonBase-root': {
            color: EventColors.SHOW.color,
        },
    },
    NoShowCheckbox: {
        '& .MuiButtonBase-root': {
            color: EventColors.NOSHOW.color,
        },
    },
    CancelledCheckbox: {
        '& .MuiButtonBase-root': {
            color: EventColors.CANCELLED.color,
        },
    },
    checkboxItem: {
        display: 'block',
        padding: theme.spacing(0.2, 0.5),
        borderRadius: '3px',
        height: '19px',
        lineHeight: '16px',
        fontWeight: '500',
    },
    Scheduled: {
        color: EventColors.SCHEDULED.color,
        border: `1px solid ${EventColors.SCHEDULED.border}`,
    },
    Confirmed: {
        color: EventColors.CONFIRMED.color,
    },
    Show: {
        color: EventColors.SHOW.color,
    },
    NoShow: {
        color: EventColors.NOSHOW.color,
    },
    Cancelled: {
        color: EventColors.CANCELLED.color,
    },
}));
const keyStore = new KeyStore();

const calendarTypes = [{
    id: 'event',
    name: ActivityType.EVENT,
    type: 'type',
    label: 'Appointment',
    children: Object.keys(EventStatus).map((key) => ({
        id: `event-${EventStatus[key]}`,
        name: EventStatus[key],
        type: 'status',
        styles: EventColors[key],
    })),
},
{
    id: 'call',
    type: 'type',
    name: ActivityType.CALL,
},
{
    id: 'task',
    type: 'type',
    name: ActivityType.TASK,
}];

// Put the records in a hash by type and status to avoid doing a filter in other functions
const getRecordsByType = (records) => {
    const map = {};

    calendarTypes.forEach(({ name: type, children }) => {
        map[type] = {
            records: records.filter((c) => c.type.toLowerCase() === type.toLowerCase()),
        };

        if (children) {
            children.forEach(({ name: status }) => {
                map[type][status] = map[type].records.filter((c) => c.event.status.toLowerCase() === status.toLowerCase());
            });
        }
    });

    return map;
};

const ACTION_TYPE = {
    SET_RECORD: 'setRecord',
    SET_ASSIGNEE: 'setAssignee',
    ON_CHANGE_LOT: 'onChangeLot',
    ON_CHANGE_DATE: 'onChangeDate',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_TOGGLE_CHECK: 'onToggleCheck',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.ON_CHANGE_DATE: {
        const record = action.payload;

        return update(state, {
            startDate: { $set: record.startDate },
            endDate: { $set: record.endDate },
        });
    }
    case ACTION_TYPE.ON_CHANGE_VALUE: {
        keyStore.setCRMFilter({
            [action.field]: action.payload,
        });
        return update(state, {
            [action.field]: { $set: action.payload },
            records: { $set: [] },
            recordsByType: { $set: {} },
        });
    }
    case ACTION_TYPE.SET_ASSIGNEE: {
        const result = action.payload.map((item) => ({ value: item.userId, label: `${item.firstName} ${item.lastName}` }));
        return update(state, {
            assignee: { $set: result },
        });
    }
    case ACTION_TYPE.SET_RECORD: {
        const records = action.payload;
        const recordsByType = getRecordsByType(records);

        return update(state, {
            records: { $set: records },
            recordsByType: { $set: recordsByType },
        });
    }
    case ACTION_TYPE.ON_CHANGE_LOT:
        if (isEqual(state.lots, action.value)) {
            return state;
        }

        keyStore.setCRMFilter({
            lots: action.value,
            assigneeUsers: [],
        });

        return update(state, {
            lots: { $set: action.value },
            assigneeUsers: { $set: [] },
            records: { $set: [] },
            recordsByType: { $set: {} },
        });
    case ACTION_TYPE.ON_TOGGLE_CHECK: {
        const { node, parent, checked } = action.payload;
        let items = [...state.unchecked];

        // If the parent node has been checked then we need to remove all the children
        // from the unchecked list
        if (node.children) {
            items = items.filter((c) => node.children.findIndex((child) => child.id === c) === -1);
        }

        // If it's being checked we remove it from the unchecked list
        if (checked) {
            const index = items.indexOf(node.id);
            if (index !== -1) items.splice(index, 1);
        } else {
            items.push(node.id);

            if (node.children) items = items.concat(node.children.map((c) => c.id));
        }

        // If the node has a parent and all of the parent's children are unchecked we need to uncheck the parent also
        if (parent != null) {
            const uncheckedCount = parent.children.filter((c) => items.indexOf(c.id) !== -1).length;

            if (uncheckedCount === parent.children.length) items.push(parent.id);
            else {
                const index = items.indexOf(parent.id);
                if (index !== -1) items.splice(index, 1);
            }
        }

        return update(state, {
            unchecked: { $set: items },
        });
    }
    default:
        return state;
    }
};

const isIndeterminate = (node, unchecked) => {
    const children = node.children || [];
    const uncheckedCount = children.filter((c) => unchecked.indexOf(c.id) !== -1).length;

    return uncheckedCount > 0 && uncheckedCount !== node.children.length;
};

const initState = {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    nodes: calendarTypes,
    unchecked: [ActivityType.CALL.toLowerCase(), ActivityType.TASK.toLowerCase()],
    records: [],
    recordsByType: {},
    totalCount: 0,
    assigneeUsers: [],
    assignee: [],
    lots: [],
};
const Calendar = () => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    const filter = keyStore.getCRMFilter() || {};
    const [state, dispatch] = useReducer(reducer, {
        ...initState,
        lots: filter.lots || [],
        assigneeUsers: filter.assigneeUsers || [],
    });
    const { unchecked } = state;
    const CRMOpportunityManagement = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_MANAGEMENT);
    const availableLot = (userInformation?.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));
    const updateDateRange = (currentDate, selectedCalendarView) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_DATE,
            payload: {
                startDate: moment(currentDate).startOf(selectedCalendarView),
                endDate: moment(currentDate).endOf(selectedCalendarView),
            },
        });
    };
    const input = {
        active: true,
        endDate: state.endDate,
        startDate: state.startDate,
        lots: state.lots.map((item) => item.value),
        assignee: state.assigneeUsers.map((item) => item.value),
    };

    const { data, loading, error } = useQuery(ActivitiesQuery.GET_CRM_ACTIVITIES, {
        variables: input,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const lots = (state.lots.length > 0) ? state.lots.map((c) => c.value) : keyStore.getUserLots().map((c) => c.lotId);
    const { data: assignee } = useQuery(UserQuery.GET_RECORD_MANAGERS_AND_SALESPERSON_BY_LOTS, {
        variables: { lots },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onToggleCheck = (node, parent, checked) => {
        dispatch({
            type: ACTION_TYPE.ON_TOGGLE_CHECK,
            payload: {
                node,
                parent,
                checked,
            },
        });
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data.getCRMActivities)) {
            dispatch({
                type: ACTION_TYPE.SET_RECORD,
                payload: data.getCRMActivities,
            });
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (!isEmpty(assignee?.getRecordManagersAndSalespersonByLots)) {
            dispatch({
                type: ACTION_TYPE.SET_ASSIGNEE,
                payload: assignee.getRecordManagersAndSalespersonByLots,
            });
        }
    }, [assignee]);

    const getMappedRecords = (recordsByType) => {
        const { nodes } = state;
        let records = [];
        let currentSet = {};

        nodes.forEach((node) => {
            currentSet = recordsByType[node.name] || {};

            if (unchecked.indexOf(node.id) === -1 && Array.isArray(currentSet.records)) {
                if (!Array.isArray(node.children)) records = records.concat(currentSet.records);
                else {
                    node.children.forEach((child) => {
                        if (unchecked.indexOf(child.id) === -1 && Array.isArray(currentSet[child.name])) {
                            records = records.concat(currentSet[child.name]);
                        }
                    });
                }
            }
        });

        return records.map((item) => {
            const { prospect = {} } = item.opportunity;
            const customerName = `${prospect.firstName} ${prospect.lastName}`;

            switch (item.type.toUpperCase()) {
            case ActivityType.EVENT:
                const style = EventColors[item.event.status.replace(' ', '').toUpperCase()] || {};
                return {
                    id: item.activityId,
                    start: new Date(item.event.start),
                    end: new Date(item.event.end),
                    title: customerName,
                    description: item.note,
                    display: 'block',
                    record: item,
                    textColor: style.textColor,
                    className: classes[item.event.status.replace(' ', '')],
                    backgroundColor: style.backgroundColor,
                    icon: <DateRangeRoundedIcon fontSize="small" />,
                };
            case ActivityType.CALL:
                return {
                    id: item.activityId,
                    start: new Date(item.realizationDate),
                    end: new Date(item.realizationDate),
                    title: customerName,
                    description: item.note,
                    display: 'block',
                    record: item,
                    textColor: 'rgb(38, 50, 56)',
                    backgroundColor: greyColor,
                    className: classes.defaultActivity,
                    icon: <CallIcon fontSize="small" />,
                };
            case ActivityType.TASK:
                return {
                    id: item.activityId,
                    start: new Date(item.realizationDate),
                    end: new Date(item.realizationDate),
                    title: customerName,
                    description: item.note,
                    display: 'block',
                    record: item,
                    textColor: 'rgb(38, 50, 56)',
                    backgroundColor: greyColor,
                    className: classes.defaultActivity,
                    icon: <AssignmentTurnedInIcon fontSize="small" />,
                };
            default:
                throw new Error(`Activity Type ${item.type} not implemented.`);
            }
        });
    };

    const onChangeLot = (value) => {
        dispatch({ type: ACTION_TYPE.ON_CHANGE_LOT, value });
    };

    const onChangeValue = (record, field) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: record,
            field,
        });
    };

    const getLabel = (value, name) => (
        <span
            className={clsx(classes.checkboxItem, classes[name?.replace(' ', '')])}
        >
            {name === EventStatus.SHOW ? value.replace(EventStatus.SHOW, 'Showed') : value}
        </span>
    );

    const getTypeLabel = (node) => {
        const count = state.recordsByType[node.name]?.records?.length || 0;
        const value = `${StringUtils.toPascalCase(node.label || node.name.toLowerCase())} (${count})`;

        return getLabel(value, node.name.toLowerCase());
    };

    const getTypeStatusLabel = (parent, node) => {
        const count = (state.recordsByType[parent.name] || {})[node.name]?.length || 0;
        const value = `${node.name} (${count})`;

        return getLabel(value, node.name);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar className="toolbar-base-layout">
                    <NotificationBadge type={TYPE.COMMUNICATION} />
                    <NotificationBadge type={TYPE.NOTIFICATION} />
                </Toolbar>
            </AppBar>
            <Header className={classes.header}>
                <div className="d-flex-center">
                    <Typography
                        variant="h5"
                        className={classes.title}
                    >
                        Calendar
                    </Typography>
                    <div className={clsx('d-flex-align-baseline-space-between', classes.lotContainer)}>
                        <Typography
                            variant="h5"
                            className={classes.label}
                        >
                            Lot:
                        </Typography>
                        <Filter
                            showTooltip
                            useInternalSearch
                            maxWidthLabel={200}
                            records={availableLot}
                            selectedValues={state.lots}
                            applyFilter={(record) => onChangeLot(record)}
                            onClearFilter={() => onChangeLot([])}
                        />
                    </div>
                    <If condition={CRMOpportunityManagement}>
                        <div className="d-flex-align-baseline-space-between">
                            <Typography
                                variant="h5"
                                className={classes.label}
                            >
                                Assignee:
                            </Typography>
                            <Filter
                                showTooltip
                                useInternalSearch
                                maxWidthLabel={250}
                                records={state.assignee}
                                selectedValues={state.assigneeUsers}
                                applyFilter={(record) => onChangeValue(record, 'assigneeUsers')}
                                onClearFilter={() => onChangeValue([], 'assigneeUsers')}
                            />
                        </div>
                    </If>
                </div>
            </Header>
            <Container className={classes.boxContainer}>
                <Grid container className={classes.root}>
                    <Grid item xs={2} className={clsx(classes.gridItem, classes.filterPanel)}>
                        {state.nodes.map((node) => (
                            <div key={`${node.name}`}>
                                <FormControlLabel
                                    label={getTypeLabel(node)}
                                    className={classes[`${node.name.toLowerCase()}Checkbox`]}
                                    control={(
                                        <Checkbox
                                            checked={unchecked.indexOf(node.id) === -1}
                                            indeterminate={isIndeterminate(node, unchecked)}
                                            onChange={(event, checked) => onToggleCheck(node, null, checked)}
                                        />
                                    )}
                                />
                                {Array.isArray(node.children) && (
                                    <Box style={{
                                        display: 'flex', flexDirection: 'column', ml: 3, marginLeft: '24px',
                                    }}
                                    >
                                        {node.children.map((child) => (
                                            <FormControlLabel
                                                key={`${node.name}-${child.name}`}
                                                checked={unchecked.indexOf(child.id) === -1}
                                                label={getTypeStatusLabel(node, child)}
                                                className={classes[`${child.name.replace(' ', '')}Checkbox`]}
                                                control={<Checkbox onChange={(event, checked) => onToggleCheck(child, node, checked)} />}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={10} className={classes.gridItem}>
                        <MainPanel
                            updateDateRange={updateDateRange}
                            records={getMappedRecords(state.recordsByType)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Calendar;
