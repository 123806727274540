import {
    map,
    omit,
} from 'lodash';
import NumberUtils from 'lib/NumberUtils';

export default class ArrayUtils {
    /**
    * @params records Array
    * @params fields Array
    * @return Array
    */
    static omitFields(records, fields = []) {
        if (Array.isArray(fields)) {
            return map(records, (item) => omit(item, fields));
        }

        return [];
    }

    static isNotEmpty(arr) {
        return Array.isArray(arr) && arr.length > 0;
    }

    /**
    * @params arr Array
    * @params fieldName String
    * @return Array
    */
    static sortByObjectField(arr = [], fieldName) {
        if (Array.isArray(arr)) {
            return arr.slice().sort((a, b) => {
                if (a[fieldName] > b[fieldName]) return 1;
                if (b[fieldName] > a[fieldName]) return -1;
                return 0;
            });
        }

        return arr;
    }

    static processPromisesInSequence(arr = [], fn) {
        return arr.reduce(
            (p, v) => p.then((a) => fn(v).then((r) => a.concat([r]))),
            Promise.resolve([]),
        );
    }

    static getTotalByItem = (array, item, isMoney = true) => {
        let total = 0;
        if (array?.length > 0) {
            total = array.map((c) => {
                if (c?.[item] && Array.isArray(c[item])) return c[item].length;
                return c?.[item] || 0;
            })
                .reduce((a, b) => a + b, 0);
        }

        if (isMoney) return NumberUtils.applyCurrencyFormat(total);
        return total;
    };
}
