import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles,
    Tooltip,
    Button,
    DialogContent,
    Dialog,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { WATERMARK_SPOTS } from 'utils/enum/Core';
import { ConfirmDialogOperation } from 'utils/enum/InventoryEnum';
import ModalUtils from 'utils/ModalUtils';
import ImagesUtils from 'utils/ImagesUtils';
import Select from 'components/widgets/Select';
import If from 'components/widgets/conditional/If';
import PhotosTabStyle from 'styles/modules/inventory/read/PhotosTabStyle';
import ButtonStyles from 'styles/theme/Button';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Loading from 'components/widgets/Loading';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ImageList from 'components/modules/inventory/read/panels/gallery/ImageList';

const useStyles = makeStyles((theme) => PhotosTabStyle.getStyle(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const PhotosTab = ({
    canWrite,
    images,
    addImages,
    toogleReorderMode,
    isUploadingImages,
    isReorderModeOn,
    onSortEnd,
    saveImagesPosition,
    watermark,
    watermarks,
    updateWatermark,
    selectedImages,
    toogleImageSelection,
    onConfirmDialogOpen,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const hasImages = images.length > 0;
    const isReorderDisabled = images.length < 2;
    const [state, setState] = useState({
        isPreviweOpen: false,
        watermarkedImageURL: null,
    });

    const togglePreviewDialog = () => {
        setState({

            ...state,
            watermarkedImageURL: state.isPreviweOpen ? null : state.watermarkedImageURL,
            isPreviweOpen: !state.isPreviweOpen,
        });
    };

    const previewWatermark = async (index) => {
        try {
            const watermarkConfig = {
                url: watermark,
                spot: WATERMARK_SPOTS.OVERLAY,
                padding: 0,
            };

            const current = images[index];
            const file = await ImagesUtils.loadImageUrlIntoFile(current);
            const watermarkedFile = await ImagesUtils.addWatermarks(file, [watermarkConfig]);
            const localURL = URL.createObjectURL(watermarkedFile);

            setState({

                ...state,
                watermarkedImageURL: localURL,
                isPreviweOpen: true,
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const drawWatermarkComponent = () => (
        <>
            <div className={classes.watermarksContainer}>
                <Select
                    nowrap
                    size="sm"
                    name="watermark"
                    loading={false}
                    className={classes.input}
                    onChange={(_, value) => updateWatermark(value)}
                    value={watermark || ''}
                    options={watermarks.map((wm) => ({
                        value: wm.url,
                        label: wm.name,
                    }))}
                />
                <div className={classes.previewWarning}>
                    Select watermark and click
                    <VisibilityOutlinedIcon />
                    to preview
                </div>
            </div>
            <Button
                disabled={isReorderModeOn || selectedImages.length === 0 || isUploadingImages || !canWrite}
                className={clsx(classes.containedInfo, classes.actionButton)}
                onClick={() => onConfirmDialogOpen(ConfirmDialogOperation.WATERMARK)}
            >
                Watermark
            </Button>
        </>
    );

    return (
        <div className={classes.main}>
            <If condition={isUploadingImages}>
                <Loading className={classes.loader} />
            </If>
            <div className={clsx('d-flex', classes.header)}>
                <Dropzone
                    disabled={isUploadingImages || !canWrite}
                    multiple
                    accept="image/*"
                    onDrop={(acceptedFiles) => addImages(acceptedFiles)}
                    className={clsx(classes.dragzone, !canWrite ? classes.disabledDragzone : '')}
                >
                    <p className={classes.uploadText}>
                        Click or drag file to this area to upload
                    </p>
                    <InboxOutlinedIcon />
                </Dropzone>
                {watermarks.length > 0 && (
                    drawWatermarkComponent()
                )}
                <If condition={!isReorderModeOn}>
                    <Button
                        disabled={isReorderDisabled || !canWrite}
                        className={clsx(classes.containedInfo, classes.actionButton)}
                        startIcon={<OpenWithOutlinedIcon />}
                        onClick={toogleReorderMode}
                    >
                        Reorder
                    </Button>
                </If>
                <If condition={isReorderModeOn}>
                    <Button
                        className={clsx(classes.containedInfo, classes.actionButton)}
                        startIcon={<CheckOutlinedIcon />}
                        onClick={saveImagesPosition}
                    >
                        Apply
                    </Button>
                    <Button
                        className={clsx(classes.containedError, classes.actionButton)}
                        startIcon={<CloseOutlinedIcon />}
                        onClick={toogleReorderMode}
                    >
                        Cancel
                    </Button>
                </If>
                <div className={classes.counter}>
                    <Button
                        disabled={isReorderModeOn || selectedImages.length === 0 || isUploadingImages || !canWrite}
                        className={clsx(classes.containedError, classes.actionButton)}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={() => onConfirmDialogOpen(ConfirmDialogOperation.DELETE)}
                    >
                        Delete
                    </Button>
                    {hasImages && (
                        <Tooltip title="Vehicle Photos" placement="top">
                            <span className={classes.photoInfo}>
                                <CameraAltOutlinedIcon className={classes.photoIcon} />
                                {`${images.length}/60`}
                            </span>
                        </Tooltip>
                    )}
                    {!hasImages && (
                        <div className={classes.noData}>
                            No images are available
                        </div>
                    )}
                </div>
            </div>
            {watermarks.length > 0 && (
                <div className={clsx('d-flex', classes.header)}>
                    {drawWatermarkComponent()}
                </div>
            )}
            {hasImages && (
                <ImageList
                    canWrite={canWrite}
                    images={images}
                    onSortEnd={onSortEnd}
                    isReorderModeOn={isReorderModeOn}
                    selectedImages={selectedImages}
                    toogleImageSelection={toogleImageSelection}
                    previewWatermark={previewWatermark}
                    watermarksAvailable={watermarks && watermarks.length > 0}
                />
            )}
            <Dialog
                open={state.isPreviweOpen}
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Preview"
                    onClose={togglePreviewDialog}
                    toolbarSize="md"
                />
                <DialogContent className={classes.previewedImage}>
                    {state.watermarkedImageURL && (
                        <img
                            alt="Watermarked"
                            src={state.watermarkedImageURL}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

PhotosTab.propTypes = {
    images: PropTypes.array,
    addImages: PropTypes.func.isRequired,
    isUploadingImages: PropTypes.bool.isRequired,
    isReorderModeOn: PropTypes.bool.isRequired,
    toogleReorderMode: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func.isRequired,
    saveImagesPosition: PropTypes.func.isRequired,
    watermark: PropTypes.string,
    watermarks: PropTypes.array.isRequired,
    updateWatermark: PropTypes.func.isRequired,
    selectedImages: PropTypes.array,
    toogleImageSelection: PropTypes.func,
    onConfirmDialogOpen: PropTypes.func.isRequired,
    canWrite: PropTypes.bool.isRequired,
};

PhotosTab.defaultProps = {
    images: [],
    watermark: '',
    selectedImages: [],
    toogleImageSelection: () => null,
};

export default PhotosTab;
