import { gql } from '@apollo/client';

export default class CompanyMutation {
    static ADD_WATERMARK = gql`
        mutation addWatermark(
            $name: String!,
            $file: Upload!
        ) {
            addWatermark(
                name: $name,
                file: $file
            ) {
                companyWatermarkId
                url
            }
        }
    `;

    static REMOVE_WATERMARK = gql`
        mutation removeWatermark(
            $companyWatermarkId: Int!
        ) {
            removeWatermark(
                companyWatermarkId: $companyWatermarkId
            )
        }
    `;

    static UPDATE_COMPANY = gql`
        mutation updateCompany(
            $company: CompanyUpdate!
        ) {
            updateCompany(company: $company)
        }
    `;

    static ADD_COMPANY_LOGO = gql`
        mutation addCompanyLogo(
            $file: Upload!
        ) {
            addCompanyLogo(
                file: $file
            )
        }
    `;

    static REMOVE_COMPANY_LOGO = gql`
        mutation removeCompanyLogo{
            removeCompanyLogo
        }
    `;
}
