import React, { Component } from 'react';

// Components and Others
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealService from 'services/modules/DealService';

const EmploymentContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            open: false,
            openConfirm: false,
            employmentId: null,
        };

        this.initBind();
    }

    onOpen() {
        this.setState({
            open: true,
        });
    }

    onClose() {
        this.setState({
            open: false,
        });
    }

    onOpenConfirm(employmentId) {
        this.setState({
            openConfirm: true,
            employmentId,
        });
    }

    onCloseConfirm() {
        this.setState({
            openConfirm: false,
            employmentId: null,
        });
    }

    onDelete() {
        const { state: { employmentId } } = this;

        this.dealService.removePreviousEmployment(employmentId)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.removeDealPreviousEmployment) {
                    this.setState({
                        openConfirm: false,
                        employmentId: null,
                    });
                }
            });
    }

    initBind() {
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onOpenConfirm = this.onOpenConfirm.bind(this);
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onOpen={this.onOpen}
                onClose={this.onClose}
                onDelete={this.onDelete}
                onOpenConfirm={this.onOpenConfirm}
                onCloseConfirm={this.onCloseConfirm}
            />
        );
    }
};

export default EmploymentContainer;
