import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import ModalUtils from 'utils/ModalUtils';
import { makeStyles } from '@material-ui/core';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import NumberUtils from 'lib/NumberUtils';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));

const useCustomerAndVehicleMethods = ({ inHouse }) => {
    const classes = {
        ...useStyle(),
    };

    const {
        columnHeaderStyle, columnLeft, columnStyle, columnCenter, columnRight,
    } = classes;

    const [records, setRecords] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const [getCustomerData, { loading: loadingCustomerInfo }] = useLazyQuery(ServiceQuery.GET_CUSTOMER_FOR_SERVICE, {
        onCompleted: (res) => {
            setRecords(res?.getCustomersForService ?? []);
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getVehicleData, { loading: loadingVehicleInfo }] = useLazyQuery(ServiceQuery.GET_VEHICLES_BY_CUSTOMER, {
        onCompleted: (res) => {
            if (res.getVehiclesByCustomerForService) {
                setVehicles(res?.getVehiclesByCustomerForService);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const getCustomerColumns = () => {
        const columns = [
            {
                Header: 'Customer Code',
                minWidth: 80,
                accessor: 'customerCode',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'First Name',
                minWidth: 80,
                accessor: 'firstName',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Last Name',
                minWidth: 80,
                accessor: 'lastName',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Cell Phone',
                minWidth: 75,
                accessor: 'cellPhone',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Email',
                minWidth: 100,
                accessor: 'email',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'DLN',
                minWidth: 100,
                accessor: 'dln',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Is taxable',
                minWidth: 50,
                accessor: 'isTaxable',
                headerClassName: clsx(columnHeaderStyle, columnCenter),
                className: clsx(columnStyle, columnCenter),
            },
        ];

        return columns;
    };

    const getVehicleColumns = () => {
        const columns = [
            {
                Header: 'Stock #',
                minWidth: 80,
                width: 80,
                id: 'stockNumber',
                accessor: 'stockNumber',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Year',
                minWidth: 80,
                width: 80,
                id: 'year',
                accessor: 'year',
                headerClassName: clsx(columnHeaderStyle, columnCenter),
                className: clsx(columnStyle, columnCenter),
            },
            {
                Header: 'Make',
                minWidth: 120,
                width: 120,
                id: 'make',
                accessor: 'make',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Model',
                minWidth: 120,
                width: 120,
                id: 'model',
                accessor: 'model',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Trim',
                minWidth: 120,
                width: 120,
                id: 'trim',
                accessor: 'trim',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Engine',
                minWidth: 170,
                width: 170,
                id: 'engine',
                accessor: 'engine',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'VIN',
                minWidth: 150,
                id: 'vin',
                accessor: 'vin',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
            {
                Header: 'Lot',
                minWidth: 100,
                width: 100,
                id: 'lotName',
                accessor: 'lotName',
                headerClassName: clsx(columnHeaderStyle, columnLeft),
                className: clsx(columnStyle, columnLeft),
            },
        ];

        if (inHouse) {
            columns.push({
                Header: 'Maximun Recon Cost',
                minWidth: 170,
                width: 170,
                id: 'maximumReconCost',
                accessor: 'maximumReconCost',
                headerClassName: clsx(columnHeaderStyle, columnRight),
                className: clsx(columnStyle, columnRight),
                Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
            },
            {
                Header: 'Total Repairs',
                minWidth: 150,
                width: 150,
                id: 'totalRepairs',
                accessor: 'totalRepairs',
                headerClassName: clsx(columnHeaderStyle, columnRight),
                className: clsx(columnStyle, columnRight),
                Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
            });
        }

        return columns;
    };

    return {
        getCustomerData,
        loadingCustomerInfo,
        records,
        getVehicleData,
        loadingVehicleInfo,
        vehicles,
        getCustomerColumns,
        getVehicleColumns,
    };
};

export default useCustomerAndVehicleMethods;
