import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import PROVIDER from 'utils/enum/MenuEnum';
import Select from 'components/widgets/Select';
import Jodit from 'components/widgets/editor/JoditEditor';
import StringUtils from 'lib/StringUtils';
import ProductSettingUtils from 'utils/ProductSettingUtils';
import ProductSettingsStyles from 'styles/modules/settings/ProductSettingsStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import InputNumber from 'components/widgets/InputNumber';
import Dropzone from 'react-dropzone';
import ProductSettingsDialogContainer from 'components/containers/settings/products/ProductSettingsDialogContainer';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ProductSettingSchema } from 'utils/schema/settings/ProductSetting';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, Button,
    RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';

const styles = (theme) => ProductSettingsStyles.referenceDialog(theme);
const joditConfiguration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 300,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    toolbarButtonSize: 'small',
    buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'ul', 'ol',
        'indent', 'outdent', 'left', 'font', 'fontsize', 'paragraph', 'classSpan', 'brush',
        'image', 'link', 'source',
    ],
};

class ProductSettingsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { source: PROVIDER.NON_MENU };
    }

    componentDidUpdate = (prevProps) => {
        const { record: oldRecord } = prevProps;
        const { props: { vendorList, record: currentRecord } } = this;

        if (currentRecord.vendorId && oldRecord.vendorId !== currentRecord.vendorId) {
            const provider = vendorList.find((v) => v.vendorId === currentRecord.vendorId)?.provider;
            if (provider) this.updateSource(provider);
        }
    }

    updateSource = (source) => {
        this.setState({
            source,
        });

        const { onChangeValue } = this.props;
        onChangeValue('provider', source);
    };

    handleSourceChange = (event) => {
        this.setState({
            source: event.target.value,
        });

        const { onChangeValue } = this.props;
        onChangeValue('resetValues', event.target.value);
    }

    render() {
        const {
            props: {
                classes, onSave, open, onClose,
                onChangeValue, isEditing, record,
                vendorList, onUploadContract,
                isLoading, externalProducts,
            },
            state: {
                source,
            },
        } = this;
        const {
            productName, vendorId, productType, productActive, isLine1,
            description, bulletPoints, notRegulated, isTaxable, contract,
            productCode, providerProductId, term, cost, price, terms,
        } = record;
        const prefixTitle = isEditing ? 'Edit' : 'New';

        const isNonMenu = source === PROVIDER.NON_MENU;
        const isDealerProvided = source === PROVIDER.DEALER_PROVIDED;
        const isStoneEagle = source === PROVIDER.STONE_EAGLE;
        const isExpress = source === PROVIDER.FI_EXPRESS;
        const isValidDealerProvided = !isDealerProvided || (isDealerProvided && terms.length > 0 && !StringUtils.isEmpty(contract));
        const isValidExpress = !isExpress || (isExpress && providerProductId > 0 && !StringUtils.isEmpty(productCode));
        const isValidStoneEagle = !isStoneEagle || (isStoneEagle && !StringUtils.isEmpty(productCode));

        const vendors = ProductSettingUtils.getVendorOptions(vendorList, source);
        const isValidCoreData = isValidSchema(ProductSettingSchema, record);
        const { isValid, errors } = isValidCoreData;

        return isLoading ? null : (
            <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={`${prefixTitle} Product`} onClose={onClose} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1} className="am-form">
                        <Grid item xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Pricing Source</Form.Label>
                                <RadioGroup className={classes.radioGroup} value={source} onChange={this.handleSourceChange}>
                                    <FormControlLabel value={PROVIDER.STONE_EAGLE} control={<Radio />} label={PROVIDER.STONE_EAGLE} />
                                    <FormControlLabel value={PROVIDER.FI_EXPRESS} control={<Radio />} label={PROVIDER.FI_EXPRESS} />
                                    <FormControlLabel value={PROVIDER.DEALER_PROVIDED} control={<Radio />} label={PROVIDER.DEALER_PROVIDED} />
                                    <FormControlLabel value={PROVIDER.NON_MENU} control={<Radio />} label={PROVIDER.NON_MENU} />
                                </RadioGroup>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Vendor Name</Form.Label>
                                <Select
                                    name="vendorId"
                                    value={vendorId}
                                    options={vendors}
                                    placeholder="Select"
                                    className={isValidField(errors, 'vendorId') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                            {isStoneEagle && (
                                <Form.Group as={Col}>
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control
                                        className={StringUtils.isEmpty(productCode) ? 'invalid-field' : ''}
                                        value={productCode}
                                        type="text"
                                        onChange={(e) => onChangeValue('productCode', e.target.value)}
                                    />
                                </Form.Group>
                            )}
                            {isExpress && (
                                <Form.Group as={Col}>
                                    <Form.Label>Product Name</Form.Label>
                                    <Select
                                        name="providerProductId"
                                        value={providerProductId}
                                        options={externalProducts.map((p) => ({ value: p.productId, label: `${p.productId} - ${p.productName}` }))}
                                        placeholder="Select"
                                        className={providerProductId === 0 ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                        maxMenuHeight={140}
                                        onChange={(name, value) => onChangeValue(name, externalProducts.find((p) => p.productId === value))}
                                    />
                                </Form.Group>
                            )}
                            <Form.Group as={Col}>
                                <Form.Label>Product Placement</Form.Label>
                                <Select
                                    name="productType"
                                    value={productType}
                                    options={ProductSettingUtils.getProductTypes()}
                                    placeholder="Select"
                                    className={isValidField(errors, 'productType') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                                <div className={classes.lineOne}>
                                    <Form.Group as={Col} className={classes.flexCenter}>
                                        <Form.Label className={classes.labelCheck}>Line 1</Form.Label>
                                        <Form.Check
                                            onChange={(e) => onChangeValue('isLine1', e.target.checked)}
                                            checked={isLine1}
                                            className={classes.alignCheckBox}
                                        />
                                    </Form.Group>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control
                                    className={isValidField(errors, 'productName') ? 'invalid-field' : ''}
                                    value={productName}
                                    type="text"
                                    onChange={(e) => onChangeValue('productName', e.target.value)}
                                />
                            </Form.Group>
                            {!isNonMenu && (
                                <>
                                    <Form.Group as={Col}>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            value={description}
                                            type="text"
                                            onChange={(e) => onChangeValue('description', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className={classes.editor} as={Col}>
                                        <Form.Label>Bullets Points</Form.Label>
                                        <Jodit
                                            config={joditConfiguration}
                                            value={bulletPoints || ''}
                                            onChange={(newContent) => onChangeValue('bulletPoints', newContent)}
                                        />
                                    </Form.Group>
                                </>
                            )}
                            {isDealerProvided && (
                                <div className={classes.termWrapper}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Term</Form.Label>
                                        <Select
                                            className={terms.length === 0 ? 'invalid-field' : ''}
                                            name="term"
                                            value={term}
                                            options={ProductSettingUtils.getTerms()}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Cost</Form.Label>
                                        <InputNumber
                                            value={cost}
                                            placeholder="$0"
                                            showCurrency
                                            min={0}
                                            decimalScale={2}
                                            thousandSeparator
                                            size="sm"
                                            onChange={(newValue) => onChangeValue('cost', newValue)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Selling Price</Form.Label>
                                        <InputNumber
                                            value={price}
                                            placeholder="$0"
                                            showCurrency
                                            min={0}
                                            decimalScale={2}
                                            thousandSeparator
                                            size="sm"
                                            onChange={(newValue) => onChangeValue('price', newValue)}
                                        />
                                    </Form.Group>
                                </div>
                            )}
                            {isDealerProvided && (
                                <Form.Group as={Col}>
                                    <Form.Label>PDF Contract</Form.Label>
                                    <div className={classes.uploadWrapper}>
                                        <Form.Control
                                            className={StringUtils.isEmpty(contract) ? 'invalid-field' : ''}
                                            readOnly
                                            value={contract}
                                            type="text"
                                        />
                                        <Dropzone
                                            disabled={!vendorId || vendorId === 0}
                                            multiple={false}
                                            accept="application/pdf"
                                            onDrop={(acceptedFiles) => onUploadContract(acceptedFiles, vendors.find((v) => v.value === vendorId)?.label)}
                                            className={classes.dragzone}
                                        >
                                            <Button
                                                size="small"
                                            >
                                                Upload
                                            </Button>
                                        </Dropzone>
                                    </div>
                                </Form.Group>
                            )}
                            <div className={classes.checkers}>
                                {!isNonMenu && (
                                    <>
                                        <Form.Group as={Col} className={clsx(classes.flexCenter, 'notRegulated')}>
                                            <Form.Label className={classes.labelCheck}>
                                                Not Regulated
                                            </Form.Label>
                                            <Form.Check
                                                onChange={(e) => onChangeValue('notRegulated', e.target.checked)}
                                                checked={notRegulated}
                                                className={classes.alignCheckBox}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className={clsx(classes.flexCenter, 'isTaxable')}>
                                            <Form.Label className={classes.labelCheck}>Is Taxable</Form.Label>
                                            <Form.Check
                                                onChange={(e) => onChangeValue('isTaxable', e.target.checked)}
                                                checked={isTaxable}
                                                className={classes.alignCheckBox}
                                            />
                                        </Form.Group>
                                    </>
                                )}
                                <Form.Group as={Col} className={clsx(classes.flexCenter, 'active')}>
                                    <Form.Label className={classes.labelCheck}>Active</Form.Label>
                                    <Form.Check
                                        onChange={(e) => onChangeValue('productActive', e.target.checked)}
                                        checked={productActive}
                                        className={classes.alignCheckBox}
                                    />
                                </Form.Group>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    disablePrimaryButton={!isValid || !isValidDealerProvided || !isValidExpress || !isValidStoneEagle}
                    onClickSecondary={onClose}
                    onClickPrimary={() => onSave(record)}
                />
            </Dialog>
        );
    }
}

ProductSettingsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onUploadContract: PropTypes.func.isRequired,
    record: PropTypes.shape({
        productId: PropTypes.number,
        vendorId: PropTypes.number,
        productName: PropTypes.string,
        productPackage: PropTypes.string,
        productType: PropTypes.string,
        productActive: PropTypes.bool,
        isLine1: PropTypes.bool,
        notRegulated: PropTypes.bool,
        isTaxable: PropTypes.bool,
        description: PropTypes.string,
        bulletPoints: PropTypes.string,
        contract: PropTypes.string,
        productCode: PropTypes.string,
        providerProductId: PropTypes.number,
        term: PropTypes.number,
        cost: PropTypes.number,
        price: PropTypes.number,
        terms: PropTypes.array,
    }),
    isEditing: PropTypes.bool.isRequired,
    vendorList: PropTypes.array.isRequired,
    externalProducts: PropTypes.array,
};

ProductSettingsDialog.defaultProps = {
    record: {},
    externalProducts: [],
};

export default withStyles(styles)(ProductSettingsDialogContainer(ProductSettingsDialog));
