import DateUtils, { DateFormat } from 'lib/DateUtils';
import { isEmpty } from 'lodash';
import {
    AccountReceivableTransactionType, CashierDirectionOperation,
    CashierPaymentType, CashierTakePaymentStatus, ProcessType,
} from 'utils/enum/AccountingEnum';
import { LotDefaultskey } from 'utils/enum/LotsCategory';

export default class AccountReceivablesMapper {
    static mapDeals(records) {
        if (records) {
            return records.map((data) => ({
                accountNumber: data.accountNumber,
                amountDue: data.amountDue,
                tagDue: data.tags + data.title + data.registration,
                date: data.soldDate,
                financeManagerId: data.financeManagerId,
                financeManager: data.financeManager,
                salesPerson1: data.salesPerson1,
                salesPersonId1: data.salespersonId1,
                buyerCode: data.buyerCode,
                buyer: data.buyer,
                dealType: data.dealType,
                term: data.term,
                payment: data.paymentAmount,
                firstPaymentDue: data.firstPaymentDue,
                financeCompany: data.financeCompany,
                balanceDue: data.amountDue - data.collectedAmount,
                status: data.status,
            }));
        }

        return [];
    }

    static mapCashierPayments(records) {
        if (records) {
            return records.map((data) => {
                let status = CashierTakePaymentStatus.ACTIVE;
                if (data.voided) status = CashierTakePaymentStatus.VOIDED;
                else if (!isEmpty(data.status)) status = data.status;

                return {
                    transactionNumber: Number(data.transactionNumber),
                    amount: Number(data.cash) + Number(data.check) + Number(data.charge) + Number(data.cashiersCheck) + Number(data.customField),
                    date: DateUtils.format(data.dateNTime),
                    reason: data.reason,
                    memo: data.voided ? (`${data.memo} - Voided Reason: ${data.voidReason}`).trim() : data.memo,
                    userName: data.userName,
                    lotName: data.lotName,
                    customer: data.customer,
                    voided: data.voided,
                    status,
                    transactionType: data.transactionType,
                    accountNumber: data.accountNumber,
                };
            });
        }

        return [];
    }

    static mapServices(records) {
        if (records) {
            return records.map((data) => (
                {
                    invoiceNumber: data.invoiceNumber,
                    customer: data.customer,
                    advisor: data.advisor,
                    roClosed: data.roClosed,
                    vehicle: `${data.vehicleYear} - ${data.vehicleMake} - ${data.vehicleModel} - ${data.vehicleTrim}`,
                    balanceDue: data.balanceDue,
                    status: data.status,
                }
            ));
        }

        return [];
    }

    static mapCashierOpenBalances(records) {
        const helper = {};
        return records.reduce((newData, record) => {
            const key = `${record.userName}`;
            const localDate = DateUtils.format(record.dateNTime);

            if (!helper[key]) {
                helper[key] = {
                    userName: key,
                    userPicture: record.userPicture,
                    records: [{
                        cash: record.cash,
                        check: record.check,
                        charge: record.charge,
                        cashiersCheck: record.cashiersCheck,
                        customField: record.customField,
                        amount: record.direction.toLowerCase() === CashierDirectionOperation.IN.toLowerCase()
                            ? record.amount : (record.amount * -1),
                        date: localDate,
                        userName: key,
                    }],
                };

                newData.push(helper[key]);
            } else {
                const currentDate = helper[key].records.find((item) => item.date === localDate);

                if (currentDate) {
                    currentDate.cash += record.cash;
                    currentDate.check += record.check;
                    currentDate.charge += record.charge;
                    currentDate.cashiersCheck += record.cashiersCheck;
                    currentDate.customField += record.customField;
                    if (record.direction.toLowerCase() === CashierDirectionOperation.IN.toLowerCase()) currentDate.amount += record.amount;
                    else currentDate.amount -= record.amount;
                } else {
                    helper[key].records.push({
                        cash: record.cash,
                        check: record.check,
                        charge: record.charge,
                        cashiersCheck: record.cashiersCheck,
                        customField: record.customField,
                        amount: record.direction.toLowerCase() === CashierDirectionOperation.IN.toLowerCase()
                            ? record.amount : (record.amount * -1),
                        date: localDate,
                        userName: key,
                    });
                }
            }

            return newData;
        }, []);
    }

    static mapCashierCollections(records) {
        if (records) {
            return records.map((data) => (
                {
                    cashierCurrentDrawId: Number(data.cashierCurrentDrawId),
                    transactionNumber: Number(data.transactionNumber),
                    accountNumber: data.cashierData.accountNumber,
                    date: DateUtils.format(data.dateNTime, DateFormat.DATETIME_WITHOUT_SECONDS),
                    customer: data.customer,
                    userName: data.userName,
                    reason: data.reason,
                    memo: data.cashierData.memo,
                    amount: Number(data.direction.toLowerCase() === CashierDirectionOperation.IN.toLowerCase()
                        ? data.amount : (data.amount * -1)),
                }
            ));
        }

        return [];
    }

    static mapBatchOutCollections(records, customFieldData) {
        const result = {
            cash: [],
            charge: [],
            check: [],
            cashiersCheck: [],
            customField: [],
            totalCash: 0,
            totalCharge: 0,
            totalCheck: 0,
            totalCashiersCheck: 0,
            totalCustomField: 0,
        };

        if (records) {
            records.forEach((data) => {
                const item = {
                    cashierCurrentDrawId: Number(data.cashierCurrentDrawId),
                    transactionNumber: Number(data.transactionNumber),
                    accountNumber: data.cashierData.accountNumber,
                    date: DateUtils.format(data.dateNTime),
                    customer: data.customer,
                    userName: data.userName,
                    reason: data.reason,
                    transactionType: data.transactionType,
                };

                if (data.cash > 0) {
                    result.cash.push({
                        ...item,
                        amount: Number(data.cash),
                    });

                    result.totalCash += data.cash;
                }

                if (data.charge > 0) {
                    result.charge.push({
                        ...item,
                        amount: Number(data.charge),
                    });

                    result.totalCharge += data.charge;
                }

                if (data.check > 0) {
                    result.check.push({
                        ...item,
                        amount: Number(data.check),
                    });

                    result.totalCheck += Number(data.check);
                }

                if (data.cashiersCheck > 0) {
                    result.cashiersCheck.push({
                        ...item,
                        amount: Number(data.cashiersCheck),
                    });

                    result.totalCashiersCheck += Number(data.cashiersCheck);
                }

                if ((customFieldData.displayCustomField || customFieldData.hasCustomFieldValues) && data.customField > 0) {
                    result.customField.push({
                        ...item,
                        amount: Number(data.customField),
                    });

                    result.totalCustomField += data.customField;
                }
            });

            const resultData = [{
                description: CashierPaymentType.CASH,
                paymentType: CashierPaymentType.CASH,
                data: result.cash,
                total: result.totalCash,
            }, {
                description: CashierPaymentType.CHARGE,
                paymentType: CashierPaymentType.CHARGE,
                data: result.charge,
                total: result.totalCharge,
            }, {
                description: CashierPaymentType.CHECK,
                paymentType: CashierPaymentType.CHECK,
                data: result.check,
                total: result.totalCheck,
            }, {
                description: 'Cashier Check',
                paymentType: CashierPaymentType.CASHIER_CHECK,
                data: result.cashiersCheck,
                total: result.totalCashiersCheck,
            }];

            if ((customFieldData.displayCustomField || customFieldData.hasCustomFieldValues) && result.totalCustomField > 0) {
                resultData.push({
                    description: customFieldData.customFieldName,
                    paymentType: CashierPaymentType.CUSTOM_FIELD,
                    data: result.customField,
                    total: result.totalCustomField,
                });
            }

            return resultData;
        }

        return [];
    }

    static mapLinesFromBatchOutToCheck(paymentType, records, customFieldData) {
        const result = {
            debitBankAccount: 0,
            glLines: [],
            batchOutData: {
                paymentType,
                batchOutLines: [],
            },
            errorMessages: [],
        };
        const { cashierBatchOutData, cashierMappingCredit, cashierMappingDebit } = records;
        result.debitBankAccount = paymentType.toLowerCase() === CashierPaymentType.CASHIER_CHECK.toLowerCase()
            ? cashierMappingDebit.checkAccountId
            : cashierMappingDebit[`${paymentType.toLowerCase()}AccountId`];
        let line = -1;

        cashierBatchOutData.forEach((item) => {
            let creditAccount = {};
            let lotDefaultKey = '';

            switch (item.transactionType?.toLowerCase()) {
            case AccountReceivableTransactionType.DEALS.toLowerCase():
                lotDefaultKey = LotDefaultskey.CASHIER_MAPPING_DEALS_CREDIT;
                break;
            case AccountReceivableTransactionType.SERVICE.toLowerCase():
                lotDefaultKey = LotDefaultskey.CASHIER_MAPPING_SERVICE_CREDIT;
                break;
            case AccountReceivableTransactionType.OTHER.toLowerCase():
                lotDefaultKey = LotDefaultskey.CASHIER_MAPPING_OTHER_CREDIT;
                break;
            case AccountReceivableTransactionType.PARTS.toLowerCase():
                lotDefaultKey = LotDefaultskey.CASHIER_MAPPING_PARTS_CREDIT;
                break;
            default:
                break;
            }

            if (paymentType.toLowerCase() === CashierPaymentType.CUSTOM_FIELD.toLowerCase()) {
                creditAccount = cashierMappingCredit.find((c) => c.key?.toLowerCase() === LotDefaultskey.CASHIER_MAPPING_CUSTOM_FIELD.toLowerCase());
            } else if (item.transactionType?.toLowerCase() === AccountReceivableTransactionType.OTHER.toLowerCase()
                || item.transactionType?.toLowerCase() === AccountReceivableTransactionType.PARTS.toLowerCase()
            ) {
                creditAccount = cashierMappingCredit.find((c) => c.reason?.toLowerCase() === item.reason?.toLowerCase()
                                && c.key?.toLowerCase() === lotDefaultKey.toLowerCase());
            } else {
                creditAccount = cashierMappingCredit.find((c) => c.reason?.toLowerCase() === item.reason?.toLowerCase()
                            && c.type?.toLowerCase() === item.collectedType?.toLowerCase()
                            && c.key?.toLowerCase() === lotDefaultKey.toLowerCase());
            }

            if (!creditAccount) {
                if (item.transactionType?.toLowerCase() === AccountReceivableTransactionType.OTHER.toLowerCase()
                    || item.transactionType?.toLowerCase() === AccountReceivableTransactionType.PARTS.toLowerCase()
                ) {
                    result.errorMessages.push(
                        `- Missing configuration mapping for ${item.transactionType}`
                                + ` and reason: ${item.reason}.`,
                    );
                } else if (paymentType.toLowerCase() === CashierPaymentType.CUSTOM_FIELD.toLowerCase()) {
                    result.errorMessages.push(
                        `- Missing configuration mapping for Custom Field - ${customFieldData.customFieldName}`,
                    );
                } else {
                    result.errorMessages.push(
                        `- Missing configuration mapping for ${item.transactionType}`
                            + `, reason: ${item.reason} and type: ${item.collectedType}`,
                    );
                }
            } else {
                let amount = 0;
                switch (paymentType.toLowerCase()) {
                case CashierPaymentType.CASHIER_CHECK.toLowerCase():
                    amount = item.cashiersCheck;
                    break;
                case CashierPaymentType.CUSTOM_FIELD.toLowerCase():
                    amount = item.customField;
                    break;
                default:
                    amount = item[`${paymentType?.toLowerCase()}`];
                    break;
                }

                result.glLines.push(this.getLineForJournalDistribution(creditAccount.accountId, amount,
                    item.lotId, item.lotName, item.accountNumber, `${item.transactionNumber} - ${item.reason}`,
                    line, creditAccount.accountDescription, creditAccount.controlledBy, creditAccount.isControlled, item.transactionNumber, paymentType));
            }

            line -= 1;

            result.batchOutData.batchOutLines.push({
                cashierCurrentDrawId: item.cashierCurrentDrawId,
                transactionNumber: item.transactionNumber,
                amount: item.amount,
                cash: item.cash,
                check: item.check + item.cashiersCheck,
                charge: item.charge,
                customField: item.customField,
                accountNumber: item.accountNumber,
                transactionType: item.transactionType,
                collectedType: item.collectedType,
            });
        });

        return result;
    }

    static getLineForJournalDistribution(accountId, amount, lotId, lotName, controlNumber,
        memo, line, accountDescription, controlledBy, isControlled, transactionNumber, batchOutType) {
        let ptId = ProcessType.BATCH_OUT_CASH;
        switch (batchOutType) {
        case CashierPaymentType.CASH:
            ptId = ProcessType.BATCH_OUT_CASH;
            break;
        case CashierPaymentType.CHARGE:
            ptId = ProcessType.BATCH_OUT_CHARGE;
            break;
        case CashierPaymentType.CHECK:
            ptId = ProcessType.BATCH_OUT_CHECK;
            break;
        case CashierPaymentType.CASHIER_CHECK:
            ptId = ProcessType.BATCH_OUT_CASHIER_CHECK;
            break;
        case CashierPaymentType.CUSTOM_FIELD:
            ptId = ProcessType.BATCH_OUT_CUSTOM;
            break;
        default:
            break;
        }

        return {
            line,
            entryId: line,
            accountCOA: {
                accountNumber: accountId,
                description: accountDescription,
                controlledBy,
                isControlled,
            },
            credit: amount,
            debit: 0,
            controlNumber: String(controlNumber),
            lot: {
                lotId,
                lotName,
            },
            memo,
            processDetailRecordId: transactionNumber,
            processTypeId: ptId,
        };
    }

    static mapTakePaymentToSave(record) {
        if (record) {
            return {
                accountNumber: record.accountNumber,
                cash: record.cash,
                cashiersCheck: record.cashiersCheck,
                charge: record.charge,
                check: record.check,
                customer: record.customer,
                dateNTime: record.dateNTime,
                direction: record.direction,
                lotName: record.lotName,
                lotId: record.lotId,
                memo: record.memo,
                reason: record.reason,
                transactionNumber: record.transactionNumber,
                transactionType: record.transactionType,
                customField: record.customField,
            };
        }

        return [];
    }
}
