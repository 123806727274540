import React, { Component } from 'react';
import clsx from 'clsx';
import { last } from 'lodash';

// Components and Others
import PropTypes from 'prop-types';
import DealStyles from 'styles/modules/DealStyles';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { DealSection } from 'utils/enum/DealEnum';
import TradeInUtils from 'utils/TradeInUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import { Paper, Button } from '@material-ui/core';

// Container
import VehiclesTabContainer from 'components/containers/deals/read/vehicle/VehiclesTabContainer';
import VehicleItem from 'components/modules/deals/read/vehicle/VehicleItem';
import TradeInForm from 'components/modules/deals/create/vehicle/TradeInForm';
import EditFieldDialog from 'components/widgets/EditFieldDialog';
import VehicleDialog from 'components/modules/deals/read/vehicle/VehicleDialog';
import DealUtils from 'utils/DealUtils';

const styles = (theme) => DealStyles.vehiclePanel(theme, fade);

class VehiclePanel extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_VEHICLE_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_VEHICLE_WRITE);
    }

    componentDidMount() {
        const { props: { subscribeVehiclesChanged, getServicesData } } = this;

        getServicesData();
        subscribeVehiclesChanged();
    }

    getDealVins() {
        const {
            props: {
                vehicleInfo, tradeIns,
            },
        } = this;

        return [vehicleInfo.vin, ...tradeIns.map((tradeIn) => tradeIn.vehicle.vin)];
    }

    renderTradeInList = () => {
        const {
            props: {
                tradeIns, classes, tradeToEdit, hideTradeInForm, openEditMilesDialog, accountNumber,
                onEdit, onRemove, editingMode, onChangeEditingMode, deal, clientId,
            },
        } = this;

        const dealVins = this.getDealVins();

        return tradeIns.map((item, index) => {
            const dealVehicleTradeID = Number(item.dealVehicleTradeID);

            if (tradeToEdit !== dealVehicleTradeID) {
                return (
                    <Paper key={dealVehicleTradeID} square className={classes.rootPaper}>
                        <VehicleItem
                            vehicleInfo={item.vehicle}
                            dealInformation={deal}
                            title={`Trade-in ${index + 1}`}
                            tradeInData={item}
                            isTradeIn
                            onEdit={onEdit}
                            clientId={clientId}
                            onRemove={onRemove}
                            editingMode={editingMode}
                            onChangeEditingMode={onChangeEditingMode}
                            openEditMilesDialog={openEditMilesDialog}
                        />
                    </Paper>
                );
            }

            return (
                <TradeInForm
                    accountNumber={accountNumber}
                    hideTradeInForm={hideTradeInForm}
                    key={dealVehicleTradeID}
                    tradeIn={item}
                    dealVins={dealVins.filter((vin) => vin !== item.vehicle.vin)}
                    isEditing
                />
            );
        });
    }

    renderConfirmationDialog() {
        const { props: { hideConfirmationDialog, onDeleteTradeIn, removingTradeIns } } = this;

        return (
            <ConfirmDialog
                title="Confirm remove trade in"
                description="Are you sure you want to remove this trade in?"
                open
                showHeader
                titlePrimary="Yes"
                titleSecondary="No"
                variant="outlined"
                dividerFooter={false}
                onClickPrimary={onDeleteTradeIn}
                onClose={hideConfirmationDialog}
                onClickSecondary={hideConfirmationDialog}
                disablePrimaryButton={removingTradeIns}
            />
        );
    }

    render() {
        const {
            props: {
                classes, vehicleInfo, onOpenModalChangeVehicle, openEditMilesDialog,
                editingMode, onChangeEditingMode, deal, showConfirmationDialog,
                isVisibleForm, tradeIns, showTradeInForm, hideTradeInForm, accountNumber,
                showEditMilesDialog, closeEditMilesDialog, updateMiles, isSaving,
                stockToEditMiles, tradeToEditMiles, openDialogChangeVehicle, saving,
                onSelectVehicle, onCloseModalChangeVehicle, clientId, loading,
            },
            SALES_DEAL_VEHICLE_WRITE,
        } = this;
        const dealVins = this.getDealVins();
        const lastTradeIn = last(tradeIns);
        const isValidLastTradeIn = TradeInUtils.validateTradeIn(lastTradeIn);
        let milesValue = 0;
        const isNotPosted = deal.postedDate === null;

        if (stockToEditMiles > 0) {
            milesValue = vehicleInfo.miles;
        } else if (tradeToEditMiles > 0) {
            tradeIns.forEach((item) => {
                if (tradeToEditMiles === item.stockNumber) {
                    milesValue = item.vehicle.miles;
                }
            });
        }

        return (
            <Paper className={classes.container}>
                <Paper square className={`${classes.rootPaper} show-icon-when-hovering`}>
                    <VehicleItem
                        loading={loading}
                        vehicleInfo={vehicleInfo}
                        title="Sold Vehicle"
                        clientId={clientId}
                        dealInformation={deal}
                        changeVehicle={onOpenModalChangeVehicle}
                        editingMode={editingMode}
                        onChangeEditingMode={onChangeEditingMode}
                        openEditMilesDialog={openEditMilesDialog}
                    />
                </Paper>
                {this.renderTradeInList()}
                {isVisibleForm && (
                    <TradeInForm
                        hideTradeInForm={hideTradeInForm}
                        accountNumber={accountNumber}
                        dealVins={dealVins}
                    />
                )}
                {showConfirmationDialog && this.renderConfirmationDialog()}
                {DealUtils.clientIdIsWeb(clientId)
                    && SALES_DEAL_VEHICLE_WRITE
                    && !isVisibleForm
                    && isNotPosted
                    && tradeIns?.length < 2
                    && (isValidLastTradeIn || tradeIns?.length === 0) && (
                    <Button
                        disabled={editingMode.isEditing && editingMode.sectionName !== DealSection.TRADE_IN}
                        className={clsx(classes.containedInfo, classes.addButton)}
                        onClick={() => showTradeInForm()}
                    >
                        Add Trade-In
                    </Button>
                )}
                {showEditMilesDialog && (
                    <EditFieldDialog
                        title="Edit Miles"
                        fieldValue={parseInt(milesValue, 10)}
                        actionCancel={closeEditMilesDialog}
                        actionSave={updateMiles}
                        isSaving={isSaving}
                    />
                )}
                {openDialogChangeVehicle && (
                    <VehicleDialog
                        disablePrimaryButton={saving}
                        title="Change Sold Vehicle"
                        open={openDialogChangeVehicle}
                        onSelectVehicle={onSelectVehicle}
                        toggleModal={onCloseModalChangeVehicle}
                    />
                )}
            </Paper>
        );
    }
}

VehiclePanel.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    getServicesData: PropTypes.func.isRequired,
    vehicleInfo: PropTypes.shape({
        stockNumber: PropTypes.number,
        vin: PropTypes.string,
        year: PropTypes.string,
        make: PropTypes.string,
        model: PropTypes.string,
        trim: PropTypes.string,
        extColor: PropTypes.string,
        intColor: PropTypes.string,
        miles: PropTypes.number,
        tmu: PropTypes.bool,
        exempt: PropTypes.bool,
        eml: PropTypes.bool,
        engine: PropTypes.string,
        transmission: PropTypes.string,
        style: PropTypes.string,
        drivetrain: PropTypes.string,
        titleStatus: PropTypes.string,
        title: PropTypes.bool,
        titleNumber: PropTypes.string,
        spareKey: PropTypes.string,
    }).isRequired,
    tradeIns: PropTypes.arrayOf(PropTypes.object).isRequired,
    showConfirmationDialog: PropTypes.bool.isRequired,
    onDeleteTradeIn: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    hideConfirmationDialog: PropTypes.func.isRequired,
    tradeToEdit: PropTypes.number,
    hideTradeInForm: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onOpenModalChangeVehicle: PropTypes.func.isRequired,
    openEditMilesDialog: PropTypes.func.isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    accountNumber: PropTypes.number.isRequired,
    deal: PropTypes.object,
    subscribeVehiclesChanged: PropTypes.func.isRequired,
    isVisibleForm: PropTypes.bool.isRequired,
    showTradeInForm: PropTypes.func.isRequired,
    removingTradeIns: PropTypes.bool,
    showEditMilesDialog: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    updateMiles: PropTypes.func.isRequired,
    closeEditMilesDialog: PropTypes.func.isRequired,
    stockToEditMiles: PropTypes.number.isRequired,
    tradeToEditMiles: PropTypes.number.isRequired,
    openDialogChangeVehicle: PropTypes.bool,
    saving: PropTypes.bool,
    onSelectVehicle: PropTypes.func.isRequired,
    onCloseModalChangeVehicle: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
};

VehiclePanel.defaultProps = {
    tradeToEdit: null,
    deal: {},
    removingTradeIns: false,
    openDialogChangeVehicle: false,
    saving: false,
    loading: false,
};

export default withStyles(styles)(VehiclesTabContainer(VehiclePanel));
