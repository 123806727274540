export default class DescriptionTabStyle {
    static getStyle(theme) {
        return ({
            main: {
                flex: 1,
                padding: '30px',
            },
            header: {
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
                fontSize: '14px',
                fontWeight: 'bold',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    height: '60px',
                },
            },
            actionButton: {
                marginLeft: '10px',
                '& > span > span': {
                    marginLeft: '0px',
                },
            },
            descriptionContainer: {
                listStylePosition: 'inside',
                fontSize: '14px',
            },
        });
    }
}
