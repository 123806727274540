import { gql } from '@apollo/client';

export default class DealsSettingsMutate {
    static SAVE_DEALS_SETTINGS_GENERAL = gql`
        mutation saveDealSettingsGeneral($input: DealSettingsGeneralInput!, $lotName: String!) {
            saveDealSettingsGeneral(input: $input, lotName: $lotName)
        }
    `;

    static SAVE_DEALS_SETTINGS_INTEGRATIONS = gql`
        mutation saveDealSettingsIntegration($input: DealSettingsIntegrationInput!, $lotName: String!) {
            saveDealSettingsIntegration(input: $input, lotName: $lotName)
        }
    `;

    static SAVE_DEALS_SETTINGS_USER_TAB = gql`
        mutation saveDealSettingsUserTab($input: DealSettingsUserTabInput!, $lotName: String!) {
            saveDealSettingsUserTab(input: $input, lotName: $lotName)
        }
    `;

    static SAVE_DEALS_SETTINGS_MISCELLANEA = gql`
        mutation saveDealSettingsMiscellanea($input: DealSettingsMiscellaneaInput!, $lotName: String!) {
            saveDealSettingsMiscellanea(input: $input, lotName: $lotName)
        }
    `;

    static SAVE_AUTOZOOM_LOT_CONFIG = gql`
        mutation saveDealSettingAutoZoomLotConfig($input: InputAutoZoomLotConfig) {
            saveDealSettingAutoZoomLotConfig(input: $input)
        }
    `;
}
