import { gql } from '@apollo/client';

export default class ServiceSettingsQuery {
    static GET_SERVICE_DEFAULT_SETTINGS = gql`
    query getServiceDefaultSettings($lotName: String!){ 
        getServiceDefaultSettings(lotName: $lotName){
            customerPayHourlyRate
            customerSubletPriceMarkUp
            hourlyLaborRate
            internalSubletPriceMarkUp
            shopSuppliesLimit
            shopSuppliesPercent
            minimumGP
            consentFormWording
            invoiceDisclaimer
            defaultFactoryWarranty
            hideApproveDeclineButtons
            multipleOpCodes
            storageFee
            warrantyPartMarkUp
            customTaxRateEnabled
            customTaxRateValue
            allowAddInventoryPartsFromProcess
    }}`;

    static GET_MINIMUM_GP_PER_LOTS = gql`
    query getMinimumGPPerLots{ 
        getMinimumGPPerLots{
            lotName
            minimumGP
    }}`;
}
