import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import EditCustomerDialog from 'components/widgets/customer/EditCustomerDialog';
import Employment from 'components/modules/customer/read/personalTab/Employment';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddressSection from 'components/widgets/customer/AddressSection';
import PersonalTabContainer from 'components/containers/customer/read/PersonsalTabContainer';
import BasicCustomerInformation from 'components/modules/customer/read/BasicCustomerInformation';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.white,
        overflow: 'auto',
        '& .gridItem': {
            display: 'flex',
            height: '100%',
            paddingRight: theme.spacing(1),
            margin: theme.spacing(2),
        },
    },
});

class PersonalTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openEditCustomer: false,
        };

        this.toggleOpenEditCustomer = this.toggleOpenEditCustomer.bind(this);
    }

    componentDidMount() {
        const { props: { getServicesData } } = this;

        getServicesData();
    }

    componentWillUnmount() {
        const { props: { unsubscribeEditCustomer } } = this;

        unsubscribeEditCustomer();
    }

    toggleOpenEditCustomer() {
        this.setState((prevState) => ({
            openEditCustomer: !prevState.openEditCustomer,
        }));
    }

    render() {
        const {
            props: {
                classes, data, loading,
            },
        } = this;
        const { currentAddress, currentEmployment, customerId } = data;
        const {
            state: {
                openEditCustomer,
            },
        } = this;

        return (
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={4}>
                    <Grid container>
                        <Grid item xs={12} className="gridItem">
                            <BasicCustomerInformation
                                loading={loading}
                                showMessageIcon
                                record={data}
                                title="Personal"
                                showEditCustomer
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className="gridItem">
                            <AddressSection addressInformation={currentAddress} customerId={customerId} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className="gridItem">
                            <Employment record={currentEmployment} customerId={customerId} />
                        </Grid>
                    </Grid>
                </Grid>
                {openEditCustomer && <EditCustomerDialog open={openEditCustomer} record={data} toggleModal={this.toggleOpenEditCustomer} />}
            </Grid>
        );
    }
}

PersonalTab.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getServicesData: PropTypes.func.isRequired,
    unsubscribeEditCustomer: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(PersonalTabContainer(PersonalTab));
