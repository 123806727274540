import React, { useReducer, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useQuery, useMutation } from '@apollo/client';
import GridLayout, { WidthProvider } from 'react-grid-layout';

// Reducer
import BulletinBoardReducer, { INITIAL_STATE, ACTION_TYPES } from 'components/modules/home/reducer/BulletinBoardReducer';
import BulletingBoardQuery from 'services/graphQL/query/home/BulletingBoardQuery';
import BulletingBoardMutation from 'services/graphQL/mutate/home/BulletingBoardMutation';

// Style
import {
    makeStyles,
    useTheme,
    Button,
    AppBar,
    Toolbar,
} from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NotificationBadge, { TYPE } from 'components/layout/NotificationBadge';
import BulletinBoardStyles from 'styles/modules/home/BulletinBoardStyles';

// Components
import If from 'components/widgets/conditional/If';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import Loading from 'components/widgets/Loading';

// Utils
import KeyStore from 'utils/KeyStore';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';
import BulletinBoardHelper from 'utils/BulletinBoardHelper';
import { ConfirmDialogActions } from 'utils/enum/BulletinBoardEnum';

const useStyle = makeStyles((theme) => BulletinBoardStyles.board(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const ResponsiveGridLayout = WidthProvider(GridLayout);

const gridDimensionData = {
    sm: {
        columns: 6,
        rowHeight: 90,
    },
    md: {
        columns: 8,
        rowHeight: 90,
    },
    lg: {
        columns: 12,
        rowHeight: 100,
    },
    xl: {
        columns: 12,
        rowHeight: 110,
    },
    hr: {
        columns: 12,
        rowHeight: 120,
    },
};

const HomeBaseLayout = () => {
    let gridNumberColumns = 0;
    let gridRowHeight = 0;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.down('xl'));
    const isHighResolution = useMediaQuery(theme.breakpoints.up('xl'));

    if (isMobile && gridNumberColumns === 0) {
        const { sm: { columns, rowHeight } } = gridDimensionData;
        gridNumberColumns = columns;
        gridRowHeight = rowHeight;
    }

    if (isTablet && gridNumberColumns === 0) {
        const { md: { columns, rowHeight } } = gridDimensionData;
        gridNumberColumns = columns;
        gridRowHeight = rowHeight;
    }

    if (isLaptop && gridNumberColumns === 0) {
        const { lg: { columns, rowHeight } } = gridDimensionData;
        gridNumberColumns = columns;
        gridRowHeight = rowHeight;
    }

    if (isDesktop && gridNumberColumns === 0) {
        const { xl: { columns, rowHeight } } = gridDimensionData;
        gridNumberColumns = columns;
        gridRowHeight = rowHeight;
    }

    if (isHighResolution && gridNumberColumns === 0) {
        const { hr: { columns, rowHeight } } = gridDimensionData;
        gridNumberColumns = columns;
        gridRowHeight = rowHeight;
    }

    const keyStore = new KeyStore();
    const SETTINGS_BULLETIN_BOARD_WRITE = keyStore.hasPermission(Permission.SETTINGS_BULLETIN_BOARD_WRITE);
    const isComponentChangable = (SETTINGS_BULLETIN_BOARD_WRITE && !(isMobile || isTablet));

    const classes = { ...useStyle(), ...buttonStyles() };
    const availableComponents = BulletinBoardHelper.getComponentsAvailable();
    const [state, dispatch] = useReducer(BulletinBoardReducer, INITIAL_STATE);
    const {
        components,
        modifiedComponent,
        isComponentsPanelOpen,
        isComponentsPanelTopSet,
        isComponentBeingCreated,
        confirmDialog,
    } = state;

    const removeComponentFromBoard = (componentId) => {
        dispatch({
            type: ACTION_TYPES.SET_IS_CONFIRM_DIALOG_OPEN,
            value: {
                opened: true,
                description: 'Do you want to remove this component and its content?',
                actionOnProceed: ConfirmDialogActions.DELETE_COMPONENT,
                options: { componentId },
            },
        });
    };

    const [updateBulletingBoardComponent] = useMutation(BulletingBoardMutation.UPDATE_BULLETIN_BOARD_COMPONENT, {
        onCompleted: (response) => {
            if (response) {
                const { updateBulletinBoardComponent } = response;

                dispatch({
                    type: ACTION_TYPES.UPDATE_COMPONENT,
                    value: updateBulletinBoardComponent,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);

            dispatch({
                type: ACTION_TYPES.FINALIZE_COMPONENT_BOARD_CREATION,
            });
        },
    });

    const [removeBulletinBoardComponent] = useMutation(BulletingBoardMutation.REMOVE_BULLETIN_BOARD_COMPONENT, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.REMOVE_COMPONENT,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const changeGroupTitle = (componentId, title) => {
        if (!SETTINGS_BULLETIN_BOARD_WRITE) return;

        const {
            componentType,
            positionX,
            positionY,
            width,
            height,
            minimumWidth,
            minimumHeight,
            isDraggable,
            isResizable,
            isBounded,
            elements,
        } = components.find((item) => item.bulletinBoardComponentId === componentId);
        const component = {
            bulletinBoardComponentId: componentId,
            componentType,
            groupTitle: title,
            positionX,
            positionY,
            width,
            height,
            minimumWidth,
            minimumHeight,
            isDraggable,
            isResizable,
            isBounded,
        };

        dispatch({
            type: ACTION_TYPES.CREATE_COMPONENT_BOARD,
            value: { ...component, elements },
        });

        updateBulletingBoardComponent({ variables: { component } });
    };

    const layout = components.map((component) => (
        {
            i: `component-${component.bulletinBoardComponentId}`,
            x: component.positionX,
            y: component.positionY,
            w: component.width,
            h: component.height,
            minW: component.minimumWidth,
            minH: component.minimumHeight,
            isDraggable: isComponentChangable ? component.isDraggable : false,
            isResizable: isComponentChangable ? component.isResizable : false,
            isBounded: component.isBounded,
        }
    ));

    const gridElements = useMemo(() => components.map((component) => {
        const key = `component-${component.bulletinBoardComponentId}`;
        const metadata = availableComponents.find((element) => element.componentType === component.componentType);
        if (!metadata) return null;

        return (
            <div key={key}>
                {metadata.component(component, removeComponentFromBoard, changeGroupTitle, SETTINGS_BULLETIN_BOARD_WRITE)}
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [components]);

    const { data: componentsData, loading: componentsLoading, error: componentsError } = useQuery(BulletingBoardQuery.GET_BULLETIN_BOARD_COMPONENTS_ELEMENTS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (componentsError) {
            ModalUtils.errorMessage(componentsError?.graphQLErrors);
            return;
        }

        if (!componentsLoading) {
            const { getBulletinBoardComponentsAndElements } = componentsData;

            dispatch({
                type: ACTION_TYPES.SET_COMPONENTS,
                value: getBulletinBoardComponentsAndElements,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentsLoading, componentsError]);

    const openComponentsPanel = () => {
        dispatch({
            type: ACTION_TYPES.OPEN_COMPONENTS_PANEL,
        });

        const delayInMilliseconds = 100;
        setTimeout(() => dispatch({ type: ACTION_TYPES.COMPLETE_COMPONENTS_PANEL_ANIMATION }), delayInMilliseconds);
    };

    const closeComponentsPanel = () => {
        dispatch({
            type: ACTION_TYPES.CLOSE_COMPONENTS_PANEL,
        });
    };

    const checkCellsNotUsedByComponents = (cellsNeeded) => {
        let notUsed = true;
        for (let index = 0; index < layout.length; index += 1) {
            const current = layout[index];
            const {
                x,
                y,
                w,
                h,
            } = current;

            for (let positionY = y; positionY < (y + h); positionY += 1) {
                for (let positionX = x; positionX < (x + w); positionX += 1) {
                    const any = cellsNeeded.some((cell) => cell.x === positionX && cell.y === positionY);
                    if (any) {
                        notUsed = false;
                        break;
                    }
                }

                if (!notUsed) break;
            }

            if (!notUsed) break;
        }

        return notUsed;
    };

    const nextAvailableSpotInBoard = (width, height) => {
        const position = {
            positionX: -1,
            positionY: -1,
        };

        if (layout.length === 0) {
            position.positionX = 0;
            position.positionY = 0;
        } else {
            let stillRows = true;
            for (let row = 0; row < Infinity; row += 1) {
                for (let column = 0; column < gridNumberColumns; column += 1) {
                    let notUsed = checkCellsNotUsedByComponents([{ x: column, y: row }]);
                    if (notUsed && column + (width - 1) < gridNumberColumns) {
                        const cellsNeeded = [];

                        for (let positionY = row; positionY < (row + height); positionY += 1) {
                            for (let positionX = column; positionX < (column + width); positionX += 1) {
                                cellsNeeded.push({ x: positionX, y: positionY });
                            }
                        }

                        cellsNeeded.shift();
                        notUsed = checkCellsNotUsedByComponents(cellsNeeded);
                        if (notUsed) {
                            position.positionX = column;
                            position.positionY = row;

                            stillRows = false;
                            break;
                        }
                    }
                }

                if (!stillRows) break;
            }
        }

        return position;
    };

    const addComponentToBoard = ({ target: { innerHTML } }) => {
        const label = innerHTML;
        const metadata = availableComponents.find((element) => element.componentType === label);
        if (!metadata) return;

        const position = nextAvailableSpotInBoard(metadata.minimumWidth, metadata.minimumHeight);
        const component = {
            bulletinBoardComponentId: null,
            componentType: metadata.componentType,
            groupTitle: null,
            positionX: position.positionX,
            positionY: position.positionY,
            width: metadata.minimumWidth,
            height: metadata.minimumHeight,
            minimumWidth: metadata.minimumWidth,
            minimumHeight: metadata.minimumHeight,
            isDraggable: metadata.isDraggable,
            isResizable: metadata.isResizable,
            isBounded: metadata.isBounded,
        };

        dispatch({
            type: ACTION_TYPES.CREATE_COMPONENT_BOARD,
            value: { ...component, elements: [] },
        });

        updateBulletingBoardComponent({ variables: { component } });
    };

    const takeActionAfterProceed = () => {
        switch (confirmDialog.actionOnProceed) {
        case ConfirmDialogActions.DELETE_COMPONENT:
            const { componentId } = confirmDialog.options;
            removeBulletinBoardComponent({ variables: { bulletinBoardComponentId: componentId } }); break;
        default:
            break;
        }
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.SET_IS_CONFIRM_DIALOG_OPEN,
            value: {
                opened: false,
                description: '',
                actionOnProceed: '',
                options: {},
            },
        });
    };

    const movementHandler = (_layout, _oldItem, newItem) => {
        const componentLayout = newItem;
        const componentId = Number(componentLayout.i.replace('component-', ''));
        const { componentType, groupTitle, elements } = components.find((component) => component.bulletinBoardComponentId === componentId);

        const component = {
            bulletinBoardComponentId: componentId,
            componentType,
            groupTitle,
            positionX: componentLayout.x,
            positionY: componentLayout.y,
            width: componentLayout.w,
            height: componentLayout.h,
            minimumWidth: componentLayout.minW,
            minimumHeight: componentLayout.minH,
            isDraggable: componentLayout.isDraggable,
            isResizable: componentLayout.isResizable,
            isBounded: componentLayout.isBounded,
        };

        dispatch({
            type: ACTION_TYPES.CREATE_COMPONENT_BOARD,
            value: { ...component, elements },
        });

        updateBulletingBoardComponent({ variables: { component } });
    };

    const rearrangeComponentsOnSmallScreens = (grid) => {
        const columns = gridNumberColumns;
        let gridCopy = [...grid];
        if (isMobile) gridCopy = gridCopy.map((component) => ({ ...component, w: columns }));

        for (let row = 0; row < Infinity; row += 1) {
            const nonFitComponents = [];
            const componentsInRow = gridCopy.filter((component) => component.y === row);

            let highestElementThatFits = 0;
            componentsInRow.reduce((accumulated, component) => {
                const total = accumulated + component.w;
                if (total > columns) {
                    nonFitComponents.push(component);
                } else if (component.h > highestElementThatFits) {
                    highestElementThatFits = component.h;
                }

                return total;
            }, 0);

            if (nonFitComponents.length > 0) {
                let currentX = 0;
                gridCopy = gridCopy.map((component) => {
                    const nonFitComponent = nonFitComponents.find((item) => item.i === component.i);
                    if (nonFitComponent) {
                        const updatedComponent = { ...nonFitComponent, x: currentX, y: highestElementThatFits };
                        currentX += nonFitComponent.w;
                        return updatedComponent;
                    }

                    return component;
                });
            }

            if (highestElementThatFits > 0) row += (highestElementThatFits - 1);

            const rowsToCheck = 10;
            const nextRows = Array.from({ length: rowsToCheck }, (_, i) => i + (row + 1));
            const componentsInNextRows = gridCopy.filter((component) => nextRows.some((r) => r === component.y));
            if (componentsInNextRows.length === 0) break;
        }

        return gridCopy;
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar className="toolbar-base-layout">
                    <NotificationBadge type={TYPE.COMMUNICATION} />
                    <NotificationBadge type={TYPE.NOTIFICATION} />
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                <If condition={SETTINGS_BULLETIN_BOARD_WRITE}>
                    <If condition={isComponentChangable}>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, classes.actionAdd)}
                            size="small"
                            onClick={openComponentsPanel}
                        >
                            +
                        </Button>
                    </If>
                    <If condition={isComponentsPanelOpen}>
                        <div className={clsx(classes.componentsContainer, isComponentsPanelTopSet ? classes.componentsContainerAnimatedTop : null)}>
                            <Button
                                className={clsx(classes.containedError, classes.actionClosePanel)}
                                size="small"
                                onClick={closeComponentsPanel}
                            >
                                x
                            </Button>
                            {availableComponents.map((component, index) => (
                                <Button
                                    key={index}
                                    className={clsx(classes.containedSecondaryInfo, classes.component)}
                                    onClick={addComponentToBoard}
                                >
                                    {component.componentType}
                                </Button>
                            ))}
                        </div>
                    </If>
                </If>
                <ResponsiveGridLayout
                    compactType={null}
                    preventCollision
                    allowOverlap={false}
                    resizeHandles={['se']}
                    className={clsx('layout', classes.grid)}
                    layout={rearrangeComponentsOnSmallScreens(layout)}
                    cols={gridNumberColumns}
                    rowHeight={gridRowHeight}
                    margin={[5, 5]}
                    containerPadding={[20, 10]}
                    onDragStop={movementHandler}
                    onResizeStop={movementHandler}
                >
                    {gridElements}
                </ResponsiveGridLayout>
                <ConfirmDialog
                    title="Attention!"
                    description={confirmDialog.description}
                    open={confirmDialog.opened}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onConfirmDialogClose}
                    onClickSecondary={onConfirmDialogClose}
                    onClickPrimary={takeActionAfterProceed}
                />
                {isComponentBeingCreated && modifiedComponent.bulletinBoardComponentId === null && <Loading className={classes.loader} />}
            </div>
        </>
    );
};

export default HomeBaseLayout;
