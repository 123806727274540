/* eslint-disable import/prefer-default-export */
const Category = Object.freeze({
    ACCOUNTING: 'Accounting',
    BHPH: 'BHPH',
    DEALS: 'Deals',
    CRM: 'CRM',
    INVENTORY: 'Inventory',
    MENU: 'Menu',
    HR: 'HR',
});

export const LotDefaultskey = Object.freeze({
    SPLIT_AMOUNT_BETWEEN_LOTS: 'SplitAmountsBetweenLots',
    CASHIER_MAPPING_DEALS_CREDIT: 'Cashier Mapping Deals Credit',
    CASHIER_MAPPING_SERVICE_CREDIT: 'Cashier Mapping Service Credit',
    CASHIER_MAPPING_OTHER_CREDIT: 'Cashier Mapping Other Credit',
    CASHIER_MAPPING_DEBIT: 'Cashier Mapping Debit',
    CASHIER_MAPPING_CUSTOM_FIELD: 'CashierMappingCustomField',
    DATE_LOCK: 'DateLock',
    CASHIER_MAPPING_PARTS_CREDIT: 'Cashier Mapping Parts Credit',
    MENU_DISCLOSURE: 'Menu Disclosure',
    FI_GLOBAL_DEALER_ID: 'F&I Global Dealer Id',
    COMBINE_ACCOUNTING: 'combineAccounting',
    AUTOMATED_RESPONSE_DEFAULT_REPRESENTATIVE: 'Automated Response Default Rep',
    AUTOMATED_RESPONSE_START_TIME: 'Automated Response Start Time',
    AUTOMATED_RESPONSE_END_TIME: 'Automated Response End Time',
    POST_VEHICLE_ACCOUNTING: 'postVehicleAccounting',
    PUSH_NOTIFICATION_REMINDER_INTERVAL: 'pushNotificationReminderInterval',
    LATE_PUSH_NOTIFICATION_USERS: 'latePushNotificationUsers',
    LATE_PUSH_NOTIFICATION_DELAY: 'latePushNotificationDelay',
});

export default Category;
