export default class BulletinBoardStyles {
    static board(theme = {}) {
        return ({
            '@global': {
                [theme.breakpoints.down('sm')]: {
                    body: {
                        overflow: 'hidden',
                    },
                },
            },
            container: {
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                padding: '5px',
            },
            loader: {
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: '9999',
                backgroundColor: theme.palette.background.white,
                opacity: '0.5',
                width: '100%',
                height: '100%',
                marginTop: '0px',
            },
            grid: {
                height: '91.5vh !important',
                [theme.breakpoints.down('sm')]: {
                    height: '85vh !important',
                },
                overflowX: 'hidden',
                overflowY: 'auto',
                '& .react-resizable-handle-se': {
                    backgroundColor: theme.palette.background.white,
                    border: `1px solid ${theme.palette.border.ghost}`,
                    bottom: '1px',
                    right: '1px',
                    width: '15px',
                    height: '15px',
                },
            },
            actionAdd: {
                position: 'fixed',
                bottom: 5,
                right: 5,
                borderRadius: '50%',
                minWidth: '30px',
                zIndex: '1',
            },
            actionClosePanel: {
                top: '-5px',
                right: '-3px',
                zIndex: '3',
                position: 'absolute',
                minWidth: 'initial',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                padding: '10px',
            },
            componentsContainer: {
                position: 'fixed',
                top: '-120px',
                zIndex: '9999',
                width: '93.8%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                paddingTop: '15px',
                paddingBottom: '15px',
                border: `1px solid ${theme.palette.border.ghost}`,
                backgroundColor: theme.palette.background.white,
                transitionProperty: 'top',
                transitionDuration: '.5s',
                transitionTimingFunction: 'ease-in-out',
                [theme.breakpoints.up('xl')]: {
                    width: '95.5%',
                },
            },
            componentsContainerAnimatedTop: {
                top: '5px',
            },
            component: {
                width: '100px',
                height: '80px',
                borderRadius: '0',
                marginRight: '5px',
            },
        });
    }

    static messages(theme = {}) {
        return ({
            container: {
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                border: `1px solid ${theme.palette.border.ghost}`,
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
            },
            title: {
                width: '100%',
                height: '50px',
                '& input': {
                    borderRadius: '0px',
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.background.athensGray,
                },
            },
            content: {
                width: '100%',
                borderBottom: `1px solid ${theme.palette.border.ghost}`,
                overflowX: 'hidden',
                overflowY: 'auto',
                flexGrow: '1',
                '& > svg': {
                    position: 'absolute',
                    transform: 'translate(50%, -50%)',
                    top: '50%',
                    right: '50%',
                    opacity: '0.3',
                    fontSize: '60px',
                },
            },
            contentNoPermission: {
                height: '92% !important',
                borderBottom: 'initial !important',
            },
            actions: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7.5px',
                paddingBottom: '7.5px',
            },
            actionAdd: {
                height: '35px',
            },
            selector: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '15px',
                paddingBottom: '15px',
                '& > div:nth-child(1)': {
                    width: '50%',
                },
                '& > div:nth-child(2)': {
                    width: '50%',
                    textAlign: 'right',
                },
            },
            contentArea: {
                '& textarea': {
                    resize: 'none',
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    height: '150px',
                },
                '& .jodit-container': {
                    border: `1px solid ${theme.palette.border.ghost}`,
                },
            },
            uploader: {
                display: 'none',
            },
            element: {
                borderBottom: `1px solid ${theme.palette.border.ghost}`,
                marginBottom: '10px',
                minHeight: '100px',
                maxHeight: '700px',
                padding: '10px',
                overflow: 'hidden',
                paddingBottom: '30px',
                flex: 1,
                listStylePosition: 'inside',
            },
            iframe: {
                minHeight: '83vh',
                height: '100%',
                width: '100%',
            },
            elementPlainText: {
                fontSize: '13.5px',
            },
            lastModification: {
                position: 'absolute',
                bottom: 0,
                right: 0,
                color: theme?.palette?.text?.darkShark,
                fontSize: '10px',
                marginRight: '5px',
                marginBottom: '10px',
                opacity: '0.8',
                backgroundColor: theme.palette.background.white,
            },
            pdfPreview: {
                left: '0',
                top: '0',
                position: 'absolute',
                fontSize: '10px',
                marginLeft: '5px',
                marginTop: '-3px',
                backgroundColor: theme.palette.background.white,
                border: `1px solid ${theme.palette.border.ghost}`,
                '& > button': {
                    padding: '3px',
                },
                '& svg': {
                    fill: theme.palette.background.dodgerBlue,
                    width: '20px',
                },
            },
            pinned: {
                right: '0',
                top: '0',
                position: 'absolute',
                fontSize: '10px',
                marginRight: '5px',
                marginBottom: '5px',
                '& svg': {
                    fill: theme.palette.background.green,
                    width: '20px',
                },
            },
            elementActions: {
                bottom: '0',
                left: '10',
                position: 'absolute',
                backgroundColor: theme.palette.background.white,
                border: `1px solid ${theme.palette.border.ghost}`,
                '& > button': {
                    padding: '3px',
                },
                '& svg': {
                    width: '17px',
                },
                '& > button:nth-child(1) > span > svg': {
                    fill: theme.palette.background.dodgerBlue,
                },
                '& > button:nth-child(2) > span > svg': {
                    fill: theme.palette.background.red,
                },
            },
        });
    }

    static iframe(theme = {}) {
        return ({
            container: {
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                border: `1px solid ${theme.palette.border.ghost}`,
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
            },
            title: {
                width: '100%',
                height: '38px',
                '& input': {
                    borderRadius: '0px',
                    fontWeight: 'bold',
                },
            },
            content: {
                width: '100%',
                borderBottom: `1px solid ${theme.palette.border.ghost}`,
                overflow: 'hidden',
                flexGrow: '1',
                '& > svg': {
                    position: 'absolute',
                    transform: 'translate(50%, -50%)',
                    top: '50%',
                    right: '50%',
                    opacity: '0.3',
                    fontSize: '60px',
                },
            },
            elementActions: {
                top: '39px',
                left: '10',
                position: 'absolute',
                backgroundColor: theme.palette.background.white,
                border: `1px solid ${theme.palette.border.ghost}`,
                '& > button': {
                    padding: '3px',
                },
                '& svg': {
                    width: '17px',
                    fill: theme.palette.background.dodgerBlue,
                },
            },
            iframe: {
                height: '100%',
                width: '100%',
            },
            contentPreview: {
                '& > iframe': {
                    minHeight: '70vh',
                },
            },
        });
    }

    static image(theme = {}) {
        return ({
            container: {
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                border: `1px solid ${theme.palette.border.ghost}`,
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
            },
            title: {
                width: '100%',
                height: '38px',
                zIndex: 1,
                '& input': {
                    borderRadius: '0px',
                    fontWeight: 'bold',
                },
            },
            content: {
                width: '100%',
                borderBottom: `1px solid ${theme.palette.border.ghost}`,
                overflow: 'hidden',
                flexGrow: '1',
                '& > svg': {
                    position: 'absolute',
                    transform: 'translate(50%, -50%)',
                    top: '50%',
                    right: '50%',
                    opacity: '0.3',
                    fontSize: '60px',
                },
            },
            elementActions: {
                top: '39px',
                left: '10',
                position: 'absolute',
                backgroundColor: theme.palette.background.white,
                border: `1px solid ${theme.palette.border.ghost}`,
                '& > button': {
                    padding: '3px',
                },
                '& svg': {
                    width: '17px',
                    fill: theme.palette.background.dodgerBlue,
                },
            },
            innerImage: {
                width: '100%',
            },
            appBar: {
                opacity: '0.7',
                backgroundColor: theme.palette.background.white,
            },
            contentPreview: {
                minHeight: '70vh',
            },
            uploader: {
                display: 'none',
            },
        });
    }
}
