/* eslint-disable no-param-reassign */
import React from 'react';
import printJS from 'print-js';
import {
    BarChartHorizontalIcon,
    BarChartVerticalIcon,
    DonutChartIcon,
    PieChartIcon,
    LineChartIcon,
    TableChartIcon,
} from 'components/icons/index';
import KeyStore from 'utils/KeyStore';
import { cloneDeep } from 'lodash';
import {
    COMPONENT_WIDTH,
    CHART_COLUMN_DATA_TYPE,
    AGGREGATE_FUNCTIONS,
    PRINTABLE_TYPE,
} from 'utils/enum/BusinessIntelligenceEnum';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import Permission from 'utils/enum/Permissions';
import ReconCard from 'components/modules/dashboard/inventory/ReconCard';

// Icons
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import FunctionsOutlinedIcon from '@material-ui/icons/FunctionsOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';

export default {
    splitComponents(staticComponents, charts, components) {
        const keyStore = new KeyStore();
        const formattedStatic = cloneDeep(staticComponents)
            .filter((c) => !c.permission || (c.permission && keyStore.hasPermission(c.permission)))
            .map((c) => ({
                componentId: null,
                name: c.name,
                chartId: null,
                chartType: null,
                order: -1,
                width: COMPONENT_WIDTH[0],
            }));

        const formattedChart = cloneDeep(charts)
            .map((c) => ({
                componentId: null,
                name: c.label,
                chartId: c.id,
                chartType: c.type,
                order: -1,
                width: COMPONENT_WIDTH[0],
            }));

        const formattedComponents = cloneDeep(components)
            .map((c) => ({
                componentId: c.id,
                name: c.staticName || c.chart?.label,
                chartId: c.chart?.id,
                chartType: c.chart?.type,
                order: c.order,
                width: c.width,
            }))
            .filter((c) => c.chartId || formattedStatic.some((el) => c.name === el.name))
            .filter((c) => !c.chartId || formattedChart.some((el) => el.chartId === c.chartId));

        const allAvailable = [...formattedStatic, ...formattedChart];

        return {
            current: formattedComponents,
            available: allAvailable
                .filter((c) => !formattedComponents.some((fc) => (!c.chartId && fc.name === c.name) || (c.chartId && c.chartId === fc.chartId)))
                .sort((a, b) => a.name.localeCompare(b.name)),
        };
    },
    getInput(type) {
        if (['Line', 'Horizontal Bar', 'Vertical Bar'].includes(type)) {
            return [
                {
                    name: type === 'Horizontal Bar' ? 'Y-Axis' : 'X-Axis',
                    value: null,
                    dataType: null,
                    dataTypeOptions: Object.values(CHART_COLUMN_DATA_TYPE),
                },
                {
                    name: 'Values',
                    value: null,
                    dataType: null,
                    dataTypeOptions: Object.values(CHART_COLUMN_DATA_TYPE),
                    aggregate: false,
                    function: 'COUNT',
                    functionOptions: Object.values(AGGREGATE_FUNCTIONS),
                },
                {
                    name: 'Series',
                    optional: true,
                    value: null,
                    dataType: null,
                    dataTypeOptions: Object.values(CHART_COLUMN_DATA_TYPE),
                },
            ];
        }

        if (['Pie', 'Donut'].includes(type)) {
            return [
                {
                    name: 'Values',
                    value: null,
                    dataType: null,
                    dataTypeOptions: Object.values(CHART_COLUMN_DATA_TYPE),
                    aggregate: false,
                    function: 'COUNT',
                    functionOptions: Object.values(AGGREGATE_FUNCTIONS),
                },
                {
                    name: 'Labels',
                    value: null,
                    dataType: null,
                    dataTypeOptions: Object.values(CHART_COLUMN_DATA_TYPE),
                },
            ];
        }

        return [
            {
                name: 'Columns',
                value: [],
            },
        ];
    },
    getStyle(type) {
        const commonProperties = [
            {
                name: 'X-Axis',
                title: null,
                titleDefault: {
                    section: 'input',
                    propertyName: 'X-Axis',
                    target: 'value',
                },
                position: 'bottom',
                positionOptions: ['bottom', 'top'],
            },
            {
                name: 'Y-Axis',
                title: null,
                titleDefault: {
                    section: 'input',
                    propertyName: 'Values',
                    target: 'value',
                },
            },
            {
                name: 'Tooltip',
                enabled: true,
            },
            {
                name: 'Data Labels',
                enabled: false,
            },
            {
                name: 'Legend',
                enabled: false,
                position: 'bottom',
                positionOptions: ['top', 'right', 'bottom', 'left'],
            },
            {
                name: 'Grid',
                enabled: false,
                position: 'back',
                positionOptions: ['front', 'back'],
                'showX-Axis': true,
                'showY-Axis': true,
            },
        ];

        let output = [];
        if (type === 'Line') {
            output = [
                ...commonProperties,
                {
                    name: 'Stroke',
                    width: 2,
                    curve: 'straight',
                    curveOptions: ['smooth', 'straight', 'stepline'],
                    lineCap: 'butt',
                    lineCapOptions: ['butt', 'square', 'round'],
                },
                {
                    name: 'Markers',
                    size: 0,
                    strokeWidth: 2,
                    shape: 'circle',
                    shapeOptions: ['circle', 'square'],
                },
                {
                    name: 'Annotations',
                    value: [],
                },
            ];
        }

        if (['Horizontal Bar', 'Vertical Bar'].includes(type)) {
            output = [
                ...commonProperties.map((p) => {
                    if (type === 'Horizontal Bar' && p.name === 'X-Axis') {
                        p.titleDefault = {
                            section: 'input',
                            propertyName: 'Values',
                            target: 'value',
                        };
                    }

                    if (type === 'Horizontal Bar' && p.name === 'Y-Axis') {
                        p.titleDefault = {
                            section: 'input',
                            propertyName: 'Y-Axis',
                            target: 'value',
                        };
                    }

                    return p;
                }),
                {
                    name: 'Stacked',
                    enabled: false,
                },
            ];

            if (type === 'Vertical Bar') output.push({ name: 'Annotations', value: [] });
        }

        if (['Pie', 'Donut'].includes(type)) {
            output = [...commonProperties]
                .filter((p) => ['Tooltip', 'Data Labels', 'Legend'].includes(p.name))
                .map((p) => {
                    if (p.name === 'Data Labels') p.enabled = true;
                    return p;
                });
        }

        if (type === 'Table') {
            output = [
                {
                    name: 'Conditional Formatting',
                    value: [],
                },
                {
                    name: 'Display Totals',
                    value: false,
                    columnsSkipped: [],
                },
            ];
        }

        return output.sort((a, b) => a.name.localeCompare(b.name));
    },
    getStaticDashboardComponents() {
        return [
            {
                name: 'Parts List',
                component: () => <ReconCard type="PARTS" />,
                permission: Permission.DASHBOARD_RECON_MANAGE_PARTS_LIST,
            },
            {
                name: 'Recon Approvals',
                component: () => <ReconCard type="APPROVALS" />,
                permission: Permission.DASHBOARD_RECON_MANAGE_APPROVALS,
            },
        ];
    },
    getChartType() {
        return [
            {
                name: 'Line',
                libType: 'line',
                icon: <LineChartIcon />,
                configuration: {
                    input: this.getInput('Line'),
                    style: this.getStyle('Line'),
                    filters: [],
                },
            },
            {
                name: 'Horizontal Bar',
                libType: 'bar',
                icon: <BarChartHorizontalIcon />,
                configuration: {
                    input: this.getInput('Horizontal Bar'),
                    style: this.getStyle('Horizontal Bar'),
                    filters: [],
                },
            },
            {
                name: 'Vertical Bar',
                libType: 'bar',
                icon: <BarChartVerticalIcon />,
                configuration: {
                    input: this.getInput('Vertical Bar'),
                    style: this.getStyle('Vertical Bar'),
                    filters: [],
                },
            },
            {
                name: 'Pie',
                libType: 'pie',
                icon: <PieChartIcon />,
                configuration: {
                    input: this.getInput('Pie'),
                    style: this.getStyle('Pie'),
                    filters: [],
                },
            },
            {
                name: 'Donut',
                libType: 'donut',
                icon: <DonutChartIcon />,
                configuration: {
                    input: this.getInput('Donut'),
                    style: this.getStyle('Donut'),
                    filters: [],
                },
            },
            {
                name: 'Table',
                icon: <TableChartIcon />,
                configuration: {
                    input: this.getInput('Table'),
                    style: this.getStyle('Table'),
                    filters: [],
                },
            },
        ];
    },
    getGoalAutomaticOptions() {
        return [
            {
                label: 'LoggedIn User\'s ID',
                function: '[getLoggedInUserId]',
            },
        ];
    },
    printChart(type, data) {
        const properties = {};
        switch (type) {
        case PRINTABLE_TYPE.JSON:
            properties.printable = data.rows;
            properties.properties = data.columns;
            properties.gridHeaderStyle = 'border: 1px solid #CED4DA; font-size: 12px; font-weight: 400; color: #707090;';
            properties.gridStyle = 'border: 1px solid #CED4DA; font-size: 12px; padding: 5px;';
            properties.header = `<div class="header-style">${data.label}</div>`;
            properties.style = '.header-style { color: #707090; font-size: 16px; margin-bottom: 20px; }';
            properties.type = PRINTABLE_TYPE.JSON;

            break;
        case PRINTABLE_TYPE.IMAGE:
            properties.printable = data.url;
            properties.imageStyle = 'width: 100%;';
            properties.header = `<div class="header-style">${data.label}</div>`;
            properties.style = '.header-style { color: #707090; font-size: 16px; margin-bottom: 20px; }';
            properties.type = PRINTABLE_TYPE.IMAGE;

            break;
        case PRINTABLE_TYPE.PDF:
            properties.printable = data.url;
            properties.type = PRINTABLE_TYPE.PDF;

            break;
        default:
            break;
        }

        printJS({
            ...properties,
        });
    },
    calculateTextWidthOnScreen(text, font) {
        const canvas = window.tempCanvas || (window.tempCanvas = document.createElement('canvas'));
        const context = canvas.getContext('2d');

        context.font = font;
        return context.measureText(text).width;
    },
    getColumnDataType(columnName, data) {
        const samples = (data.length > 10 ? data.slice(0, 9) : data)
            .map((record) => record.find((item) => item.name === columnName)?.value)
            .filter((val) => !StringUtils.isEmpty(val) && val !== 'null');
        if (samples.length === 0) return {};

        // eslint-disable-next-line no-restricted-globals
        if (samples.every((val) => isNaN(val) && DateUtils.isValid(val))) {
            return {
                type: CHART_COLUMN_DATA_TYPE.DATE,
                icon: (<DateRangeOutlinedIcon />),
            };
        }

        // eslint-disable-next-line no-restricted-globals
        if (samples.every((val) => !isNaN(val))) {
            return {
                type: CHART_COLUMN_DATA_TYPE.NUMERIC,
                icon: (<FunctionsOutlinedIcon />),
            };
        }

        if (samples.every((val) => ['true', 'false'].includes(val.toLowerCase()))) {
            return {
                type: CHART_COLUMN_DATA_TYPE.FLAG,
                icon: (<FlagOutlinedIcon />),
            };
        }

        return {
            type: CHART_COLUMN_DATA_TYPE.ALPHANUMERIC,
            icon: (<TextFieldsOutlinedIcon />),
        };
    },
};
