/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { isValidSchema } from 'utils/schema/utils';
import DealUtils from 'utils/DealUtils';

// TODO: Check if the function passed by accessories 'onChangeStructureValue' can be performed from the container of 'DealTabContainer'
const DealStructureContainer = (WrappedComponent) => class extends Component {
    getFormValidations(dealStructure) {
        const { dealType } = dealStructure;
        const validations = isValidSchema(DealUtils.getSchemaByDealType(dealType), dealStructure);
        return validations;
    }

    render() {
        const { props, state } = this;
        const { dealStructure } = props;
        const formValidations = this.getFormValidations(dealStructure);

        return (
            <WrappedComponent
                {...props}
                {...state}
                formValidations={formValidations}
                onChangeValue={props.onChangeStructureValue}
            />
        );
    }
};

export default DealStructureContainer;
