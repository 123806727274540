export const OpportunityStatus = Object.freeze({
    OPEN: 'Open',
    SOLD: 'Sold',
    LOST: 'Lost',
});

export const ProcessStage = Object.freeze({
    NEW_EMAIL_LEAD: 'New Email Lead',
    EMAILED_BACK: 'Emailed Back',
    PHONE_UP_CALL_BACK: 'Phone-Up/Call back',
    WORKING: 'Working',
    APPOINTMENT_MADE: 'Appointment Made',
    APPOINTMENT_CONFIRMED: 'Appointment Confirmed',
    APPOINTMENT_SHOWED: 'Appointment Showed',
    DEMO: 'Demo',
    LOT_UP: 'Lot-Up',
    WRITE_UP: 'Write Up',
    IN_FINANCE: 'In Finance',
    SOLD: 'Sold',
});

export const funnelStages = Object.freeze({
    SHOWED: 'showed',
    DEMO: 'demo',
    WRITEUP: 'writeUp',
    FINANCE: 'finance',
    SOLD: 'sold',
});
