/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    makeStyles,
    FormLabel,
    FormControl,
    FormHelperText,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import Select from 'components/widgets/Select';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import useVinValidation from 'components/modules/inventory/hooks/useVinValidation';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InputTrim from 'components/modules/inventory/create/dialogs/EditVehicle/InputTrim';
import InputEngine from 'components/modules/inventory/create/dialogs/EditVehicle/InputEngine';
import VehicleMakeDropdown from 'components/widgets/inventory/VehicleMakeDropdown';

import { InventoryColor } from 'utils/enum/InventoryEnum';
import StringUtils from 'lib/StringUtils';
import InventoryHelper from 'utils/InventoryHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 20,
        [theme.breakpoints.down(780)]: {
            marginBottom: 40,
        },
        '& > div:first-child': {
            [theme.breakpoints.down(780)]: {
                marginRight: 0,
                minWidth: '100%',
            },
        },
    },
    content: {
        minWidth: 300,
        maxWidth: 300,
        marginRight: 10,
    },
    row: {
        display: 'flex',
        marginBottom: 10,

        '& > div': {
            flex: 1,
            marginRight: 5,

            '& .MuiFormLabel-root': {
                marginBottom: 5,
                textAlign: 'center',
                fontWeight: 'bold',
            },
        },
    },
    rowHorizontal: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& .MuiFormLabel-root': {
            textAlign: 'right !important',
            marginRight: 5,
        },
        '& > label:first-child': {
            [theme.breakpoints.down(780)]: {
                textAlign: 'left',
            },
        },
        '& > div:last-child': {
            minWidth: '65%',
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    transmissionMobileStyle: {
        [theme.breakpoints.down(780)]: {
            justifyContent: 'flex-start',
            paddingLeft: 15,
        },
    },
}));

const getColor = (type, colorsData, extColor, intColor) => {
    let list = colorsData
        .filter((item) => {
            const itemType = StringUtils.isEmpty(item.type)
                ? ''
                : item.type.toUpperCase();
            return itemType === type.toUpperCase();
        })
        .map((item) => {
            const values = item.description.split(' ');

            values.forEach((_, index) => {
                let value = values[index] || '';

                value = value.charAt(0).toUpperCase()
                    + value.substring(1, value.lenght).toLowerCase();

                values[index] = value;
            });

            return values.join(' ');
        });

    const { EXTERIOR_COLOR, INTERIOR_COLOR } = InventoryColor;

    if (type === EXTERIOR_COLOR || type === INTERIOR_COLOR) {
        const color = type === EXTERIOR_COLOR ? extColor || '' : intColor || '';

        if (!StringUtils.isEmpty(color) && !list.includes(color)) {
            list.unshift(color);
        }
    }

    list = [...new Set(list)];
    list = list.map((item) => ({
        value: item,
        label: item,
    }));

    return list;
};

const getColorsOptions = (colorsData, extColor, intColor) => {
    const { EXTERIOR_COLOR, INTERIOR_COLOR, GENERIC_EXTERIOR_COLOR } = InventoryColor;
    const exteriorColorsOptions = getColor(EXTERIOR_COLOR, colorsData, extColor, intColor);
    const interiorColorsOptions = getColor(INTERIOR_COLOR, colorsData, extColor, intColor);
    const genericExteriorColorsOptions = getColor(GENERIC_EXTERIOR_COLOR, colorsData, extColor, intColor);

    return {
        exteriorColorsOptions,
        interiorColorsOptions,
        genericExteriorColorsOptions,
    };
};

const EditForm = ({
    data,
    loading,
    invUserTabName,
    engineList,
    styleList,
    colorsData,
    trimList,
    allowEditTrim,
    multipleTrim,
    enterManually,
    onChange,
}) => {
    const classes = useStyles();

    const { year: inputtedYear, vin: inputtedVin } = data;
    const {
        isInvalidVINLength,
        isInvalidVINChars,
        vinNumberLength,
        notAllowedCharsInVIN,
    } = useVinValidation(inputtedYear, inputtedVin);
    const {
        data: driveTrainsData, error: driveTrainsListError, refetch,
    } = useQuery(InventoryQuery.GET_DRIVETRAINS_LIST, {
        variables: { make: data.make || '' },
    });

    useEffect(() => {
        if (driveTrainsListError) {
            ModalUtils.errorMessage(driveTrainsListError?.graphQLErrors);
            return;
        }

        if (!StringUtils.isEmpty(data.make)) {
            refetch();
        }
        // eslint-disable-next-line
    }, [data.make]);

    const {
        year, make, model, drivetrain, style, trim, engine, vin, transmission,
        genericExteriorColor, extColor, intColor, userTabURL1, userTabURL2,
    } = data;
    const invalidYear = InventoryHelper.hasStockInvalidYear(year);
    const invalidModel = StringUtils.isEmpty(model);
    const invalidStyle = StringUtils.isEmpty(style);
    const validURL1 = InventoryHelper.validateCustomTabURL(userTabURL1);
    const validURL2 = InventoryHelper.validateCustomTabURL(userTabURL2);
    const invalidURLLabel = 'The URL is not secure or invalid';

    const {
        invUserTabName1 = 'User Tab 1',
        invUserTabName2 = 'User Tab 2',
    } = invUserTabName;

    const driveTrainsList = driveTrainsData?.getDriveTrainsByMake?.driveTrains;
    const driveTrainsOptions = driveTrainsList?.map((item) => ({
        value: item,
        label: item,
    }));

    const styleOptions = styleList.map((item) => ({
        value: item,
        label: item,
    }));

    const {
        exteriorColorsOptions,
        interiorColorsOptions,
        genericExteriorColorsOptions,
    } = getColorsOptions(colorsData, extColor, intColor);

    return (
        <div className={clsx('d-flex-column', classes.root)}>
            <div className={clsx('d-flex-column', classes.content)}>
                <div className={classes.row}>
                    <FormControl>
                        <FormLabel>Year</FormLabel>
                        <InputNumber
                            value={Number(year)}
                            onChange={(newValue) => onChange('year', newValue)}
                            placeholder="0"
                            size="sm"
                            className={clsx({ 'invalid-field': invalidYear })}
                        />
                        {invalidYear && (<FormHelperText error>The Year must be higher than 1900</FormHelperText>)}
                    </FormControl>
                    <VehicleMakeDropdown
                        make={make}
                        onChange={onChange}
                    />
                </div>
                <div className={classes.row}>
                    <FormControl>
                        <FormLabel>Model</FormLabel>
                        <Form.Control
                            type="text"
                            size="sm"
                            value={model}
                            maxLength={50}
                            onChange={({ target }) => onChange('model', target.value?.toUpperCase())}
                            className={clsx({ 'invalid-field': invalidModel })}
                        />
                        {invalidModel && (<FormHelperText error>The Model field is required</FormHelperText>)}
                    </FormControl>
                    <InputTrim
                        multipleTrim={multipleTrim}
                        trimList={trimList}
                        allowEditTrim={allowEditTrim}
                        trimValue={StringUtils.isEmpty(trim) ? '' : trim}
                        onChange={(value) => onChange('trim', value)}
                    />
                </div>
                <div className={classes.row}>
                    <FormControl>
                        <FormLabel>Style</FormLabel>
                        <Select
                            name="style"
                            onChange={(_, newValue) => onChange('style', newValue)}
                            value={style}
                            options={styleOptions}
                            nowrap
                            size="sm"
                            className={clsx({ 'invalid-field': invalidStyle })}
                            loading={loading}
                        />
                        {invalidStyle && (<FormHelperText error>The Style field is required</FormHelperText>)}
                    </FormControl>
                    <FormControl>
                        <FormLabel>Drive train</FormLabel>
                        <Select
                            name="drivetrain"
                            onChange={(_, newValue) => onChange('drivetrain', newValue)}
                            value={drivetrain}
                            options={driveTrainsOptions}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <InputEngine
                        engine={engine}
                        engineList={engineList}
                        loading={loading}
                        onChange={(newValue) => onChange('engine', newValue)}
                    />
                </div>
                <div className={classes.row}>
                    <FormControl>
                        <FormLabel>VIN</FormLabel>
                        <Form.Control
                            type="text"
                            size="sm"
                            value={vin}
                            maxLength={vinNumberLength}
                            disabled={!enterManually}
                            onChange={(e) => onChange('vin', e.target.value)}
                            className={clsx({ 'invalid-field': (vin && isInvalidVINLength) })}
                        />
                        {vin && isInvalidVINLength && (<FormHelperText error>{`The VIN must be ${vinNumberLength} characters`}</FormHelperText>)}
                        {vin && isInvalidVINChars && (
                            <FormHelperText error>
                                {`The following characters are not allowed: ${notAllowedCharsInVIN.toString().toUpperCase()}`}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Transmission:</FormLabel>
                        <RadioGroup
                            row
                            value={transmission}
                            onChange={(_, newValue) => onChange('transmission', newValue)}
                            className={clsx('d-flex-justify-start-align-center', classes.transmissionMobileStyle)}
                        >
                            <FormControlLabel value="Automatic" control={<Radio color="primary" />} label="Automatic" />
                            <FormControlLabel value="Standard" control={<Radio color="primary" />} label="Standard" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Generic Exterior Color:</FormLabel>
                        <Select
                            name="genericExteriorColor"
                            onChange={(_, newValue) => onChange('genericExteriorColor', newValue)}
                            value={genericExteriorColor}
                            options={genericExteriorColorsOptions || []}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Exterior Color:</FormLabel>
                        <Select
                            name="extColor"
                            onChange={(_, newValue) => onChange('extColor', newValue)}
                            value={extColor}
                            options={exteriorColorsOptions || []}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Interior Color:</FormLabel>
                        <Select
                            name="intColor"
                            onChange={(_, newValue) => onChange('intColor', newValue)}
                            value={intColor}
                            options={interiorColorsOptions || []}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>{ `${invUserTabName1}:` }</FormLabel>
                        <div>
                            <TextField
                                value={userTabURL1}
                                onChange={({ target }) => onChange('userTabURL1', target.value)}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldSmall}
                                fullWidth
                            />
                            {!validURL1 && (<FormHelperText error>{ invalidURLLabel }</FormHelperText>)}
                        </div>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>{ `${invUserTabName2}:` }</FormLabel>
                        <div>
                            <TextField
                                value={userTabURL2}
                                onChange={({ target }) => onChange('userTabURL2', target.value)}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldSmall}
                                fullWidth
                            />
                            {!validURL2 && (<FormHelperText error>{ invalidURLLabel }</FormHelperText>)}
                        </div>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

EditForm.propTypes = {
    invUserTabName: PropTypes.object,
    data: PropTypes.object,
    loading: PropTypes.bool,
    enterManually: PropTypes.bool,
    colorsData: PropTypes.array,
    engineList: PropTypes.array,
    styleList: PropTypes.array,
    allowEditTrim: PropTypes.bool,
    multipleTrim: PropTypes.bool,
    trimList: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};

EditForm.defaultProps = {
    data: {},
    invUserTabName: {},
    loading: false,
    enterManually: false,
    allowEditTrim: false,
    multipleTrim: false,
    engineList: [],
    styleList: [],
    colorsData: [],
    trimList: [],
};

export default EditForm;
