import React, { useState } from 'react';

// Components and others
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import If from 'components/widgets/conditional/If';
import Container from 'components/widgets/Container';

import LeadWidget from 'components/modules/messageCenter/read/LeadWidget';
import DealWidget from 'components/modules/messageCenter/read/DealWidget';
import OpportunityWidget from 'components/modules/messageCenter/read/OpportunityWidget';
import MessageCenterList from 'components/modules/messageCenter/list/MessageCenterList';
import MessageCenterCustomerContext from 'components/context/MessageCenterCustomerContext';
import MessageCenterDetail from 'components/modules/messageCenter/read/MessageCenterDetail';
import ConversationPlaceholder from 'components/modules/conversation/list/ConversationPlaceholder';

// Material UI
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const keyStore = new KeyStore();
const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.boulderGray,
    },
    detail: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
    },
    customer: {
        color: theme.palette.text.butterflyBush,
        marginBottom: theme.spacing(0.5),
    },
    customerInformation: {
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.background.white,
        marginBottom: theme.spacing(2),
    },
    opportunityContainer: {
        padding: theme.spacing(1),
        height: '100%',
        overflow: 'auto',
        width: '690px',
        minWidth: '690px',
    },
    column: {
        position: 'relative',
        height: '100%',
    },
    leftSection: {
        borderLeft: '1px solid #eeeeee',
        background: '#F7FAFF',
    },
    label: {
        color: theme.palette.text.minsk,
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        marginRight: theme.spacing(1),
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
    },
}));

const MessageCenter = () => {
    const classes = useStyles();
    const { id } = useParams();
    const [customer, setCustomer] = useState({});
    let name = 'Unknown';
    let email = null;
    if (customer?.customerId != null) {
        name = `${customer.firstName || ''} ${customer.lastName || ''}`;
        email = customer.email;
    }

    const renderConversationDetails = () => {
        if (id) {
            return (
                <div className={classes.detail}>
                    <MessageCenterDetail smsChatId={id} customer={customer} />
                    <div className={clsx(classes.opportunityContainer, classes.leftSection)}>
                        <div className={classes.customerInformation}>
                            <Typography variant="h4" className={classes.customer} title={name} noWrap>{name}</Typography>
                            <If condition={email !== null}>
                                <div>
                                    <span className={classes.label}>Email: </span>
                                    <span className={classes.value}>{email}</span>
                                </div>
                            </If>
                        </div>
                        <LeadWidget
                            customerId={customer?.customerId}
                        />
                        <OpportunityWidget
                            customerId={customer?.customerId}
                        />
                        <DealWidget
                            customerId={customer?.customerId}
                        />
                    </div>
                </div>
            );
        }

        return (
            <ConversationPlaceholder />
        );
    };

    return (
        <div className={classes.main}>
            <MessageCenterCustomerContext.Provider value={{ customer, setCustomer }}>
                <Container padding={false} backgroundColor={false}>
                    {keyStore.hasPermission(Permission.CRM_MESSAGE_CENTER_READ) && (
                        <MessageCenterList conversationId={id} />
                    )}
                    {keyStore.hasPermission(Permission.CRM_MESSAGE_CENTER_READ) && renderConversationDetails()}
                </Container>
            </MessageCenterCustomerContext.Provider>
        </div>
    );
};

export default MessageCenter;
