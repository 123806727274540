/* eslint-disable no-undef */
import StringUtils from 'lib/StringUtils';
import KeyStore from 'utils/KeyStore';

const keyStore = new KeyStore();
export default class GeneralUtils {
    static downloadFile = async (url, name, buffer, fileType) => {
        let address = null;
        if (buffer && type) {
            const blob = new Blob([buffer], { fileType });
            address = URL.createObjectURL(blob);
        } else {
            const response = await fetch(url);
            const blob = await response.blob();
            address = URL.createObjectURL(blob);
        }

        if (StringUtils.isEmpty(address)) return;
        const link = document.createElement('a');
        link.href = address;
        link.download = name;
        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            }),
        );

        document.body.removeChild(link);
    };

    static sendGraphQLPersistentRequest = (body) => {
        const URL = process.env.REACT_APP_GRAPHQL_HTTP;
        const token = `Bearer ${keyStore.getToken()}`;

        fetch(
            URL,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'x-auth-type': 'oauth2',
                    authorization: token || '',
                },
                body: JSON.stringify(body),
                keepalive: true,
            },
        );
    };

    static addExternalScript = (url) => {
        const scriptName = 'externalScript';
        let script = document.getElementById(scriptName);

        if (!script) {
            script = document.createElement('script');
            script.id = scriptName;
            script.src = url;
            script.defer = true;
            document.body.appendChild(script);
        }
    };
}
