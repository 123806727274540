import React, {
    useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Fab, Grid, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import NumberUtils from 'lib/NumberUtils';
import InputControl from 'components/widgets/editorControls/InputControl';
import { AddIcon, DeleteOutlineOutlinedIcon } from 'components/icons';
import IconButton from '@material-ui/core/IconButton';
import PartsSelect from 'components/modules/purchaseParts/parts/list/PartsSelect';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const ownStyle = makeStyles((theme) => (
    {
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
            position: 'relative',
            minHeight: 200,
        },
        fab: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            margin: '20px 18px',
            width: '20px !important',
        },
        fabGreen: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.green,
            '&:hover': {
                backgroundColor: theme.palette.background.green,
            },
        },
        header: {
            fontSize: '12px',
            fontWeight: 500,
            width: 'calc(100% - 5px)',
            display: 'flex',
            backgroundColor: theme.palette.background.titanWhite,
            border: `solid 1px ${theme.palette.background.gray}`,
            padding: '4px 15px 4px 4px',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        deleteButton: {
            color: theme.palette.error.main,
        },
        deleteIconButton: {
            padding: '3px',
        },
        itemPartCls: {
            padding: '8px',
            marginBottom: '10px',
            marginRight: '5px',
        },
        labelItem: {
            padding: '6px 0px',
        },
        parListCls: {
            maxHeight: '450px',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                maxHeight: '100vh',
            },
            '& div.itemPart:nth-child(even)': {
                backgroundColor: theme.palette.background.selago,
            },
            '& .mobileOnly': {
                display: 'none',
                [theme.breakpoints.down('xs')]: {
                    display: 'block',
                },
            },
        },
        alignRight: {
            textAlign: 'right',
        },
        partsTableCls: {
            [theme.breakpoints.down('sm')]: {
                padding: 15,
            },
        },
    }
));

const keyStore = new KeyStore();

const ServiceJobsParts = (props) => {
    const SERVICE_JOBS_PARTS_ADD = keyStore.hasPermission(Permission.SERVICE_JOBS_PARTS_ADD);
    const SERVICE_JOBS_PARTS_REMOVE = keyStore.hasPermission(Permission.SERVICE_JOBS_PARTS_REMOVE);
    const SERVICE_JOBS_PARTS_OVERRIDE_PRICE = keyStore.hasPermission(Permission.SERVICE_JOBS_PARTS_OVERRIDE_PRICE);

    const {
        parts,
        onAddJobPart,
        onChangeJobValue,
        onDeletePart,
        lotName,
        editDetails,
        invoiceNumber,
        serviceJobId,
        technicianId,
    } = props;

    const classes = ownStyle();

    const {
        deleteButton, parListCls, fab, fabGreen,
        header, labelItem, deleteIconButton,
        alignRight, partsTableCls,
    } = classes;

    const {
        editAddParts,
        editDeleteParts,
        editParts,
    } = editDetails;

    const [openParts, setOpenParts] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [lastId, setServiceJobPartsIndex] = useState(0);

    const onCloseDeleteConfirm = () => {
        setIdToDelete(null);
    };

    const onDeleteConfirm = () => {
        onDeletePart(idToDelete);

        onCloseDeleteConfirm();
    };

    const handleEditorChange = (columnId, newValue, cell) => {
        onChangeJobValue(columnId, newValue, cell);
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;

        if (event && id !== 'description' && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && id !== 'description' && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    const addSelectedParts = (partsSelected) => {
        const currentIndex = (parts?.length ?? 0) * -1;

        setOpenParts(false);
        onAddJobPart(partsSelected);
        setServiceJobPartsIndex(currentIndex);
    };

    const renderPartList = (partList) => {
        const { partItem, index } = partList;
        const {
            serviceJobPartsId,
            partStockNumber,
            description,
            quantity,
            partCost,
            netPrice,
            total,
        } = partItem;

        return (
            <Paper elevation={1} className={clsx(classes.itemPartCls, 'itemPart')} variant="outlined" square key={serviceJobPartsId}>
                <Grid container spacing={1}>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={6} sm={1}>
                            <Typography className="mobileOnly" noWrap>Line</Typography>
                            <Typography className={labelItem} noWrap>{index + 1}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography className="mobileOnly" noWrap>Part Stock #</Typography>
                            <Typography className={labelItem} noWrap>{partStockNumber}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {editParts && (
                                <>
                                    <Typography className="mobileOnly" noWrap>Quantity</Typography>
                                    <InputControl
                                        name="quantity"
                                        value={quantity}
                                        className={quantity && quantity !== 0 ? '' : 'invalid-field'}
                                        editorCellObject={{ column: { id: 'quantity' }, rowData: partItem }}
                                        type="number"
                                        onChange={handleEditorChange}
                                        onKeyDown={handleEditorKeyDown}
                                        comparePropertyId={serviceJobPartsId}
                                        showCurrency={false}
                                        allowNegative={false}
                                        alignRight
                                    />
                                </>
                            )}
                            {!editParts
                            && (
                                <Typography className={clsx(labelItem, alignRight)} noWrap>{quantity}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography className="mobileOnly" noWrap>Part Cost</Typography>
                            <Typography className={clsx(labelItem, alignRight)} noWrap>{NumberUtils.applyCurrencyFormat(partCost)}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {editParts && SERVICE_JOBS_PARTS_OVERRIDE_PRICE && (
                                <>
                                    <Typography className="mobileOnly" noWrap>List Price</Typography>
                                    <InputControl
                                        name="netPrice"
                                        value={netPrice}
                                        className={netPrice && netPrice !== 0 ? '' : 'invalid-field'}
                                        editorCellObject={{ column: { id: 'netPrice' }, rowData: partItem }}
                                        type="number"
                                        onChange={handleEditorChange}
                                        onKeyDown={handleEditorKeyDown}
                                        comparePropertyId={serviceJobPartsId}
                                        allowNegative={false}
                                        alignRight
                                    />
                                </>
                            )}
                            {!(editParts && SERVICE_JOBS_PARTS_OVERRIDE_PRICE)
                            && (
                                <Typography className={clsx(labelItem, alignRight)} noWrap>{NumberUtils.applyCurrencyFormat(netPrice)}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography className="mobileOnly" noWrap>Total</Typography>
                            <Typography className={clsx(labelItem, alignRight)} noWrap>{NumberUtils.applyCurrencyFormat(total)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12} sm={10}>
                            <Typography className="mobileOnly" noWrap>Description</Typography>
                            <Typography className={labelItem} noWrap>{description}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} className={alignRight}>
                            {editDeleteParts && SERVICE_JOBS_PARTS_REMOVE && (
                                <IconButton
                                    onClick={() => setIdToDelete(serviceJobPartsId)}
                                    className={deleteIconButton}
                                >
                                    <Tooltip title="Delete line">
                                        <DeleteOutlineOutlinedIcon
                                            className={deleteButton}
                                        />
                                    </Tooltip>
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    useEffect(() => {
        if (lastId !== 0) {
            const rows = parts?.length;
            const nextElement = document.querySelector(`[aria-rowIndex="${rows}"] input.quantity-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastId]);

    return (
        <div>
            <div className={partsTableCls}>
                <span className={header}>
                    <Grid container spacing={1}>
                        <Grid item container spacing={1} xs={12}>
                            <Grid item xs={6} sm={1}>
                                <Typography color="primary" variant="h6">Line</Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography color="primary" variant="h6">Part #</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography color="primary" variant="h6" className={alignRight}>Quantity</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography color="primary" variant="h6" className={alignRight}>Cost Price</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography color="primary" variant="h6" className={alignRight}>List Price</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography color="primary" variant="h6" className={alignRight}>Total</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </span>
                <div className={parListCls}>
                    {parts?.map((partItem, index) => (
                        renderPartList({ partItem, index })
                    ))}
                    {editAddParts && SERVICE_JOBS_PARTS_ADD && (
                        <Fab
                            label="Add part"
                            className={clsx(fab, fabGreen)}
                            onClick={() => setOpenParts(true)}
                            size="small"
                            variant="extended"
                        >
                            <AddIcon />
                        </Fab>
                    ) }
                </div>
            </div>
            {openParts && editAddParts && (
                <PartsSelect
                    open={openParts}
                    onPopupClose={() => setOpenParts(false)}
                    onSelectItems={(values) => addSelectedParts(values)}
                    lotName={lotName}
                    invoiceNumber={invoiceNumber}
                    serviceJobId={serviceJobId}
                    technicianId={technicianId}
                    showRequestPartsButton
                />
            )}
            {editDeleteParts && idToDelete && (
                <ConfirmDialog
                    title="Confirm remove line"
                    description="Are you sure you want to remove this line?"
                    open
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseDeleteConfirm}
                    onClickSecondary={onCloseDeleteConfirm}
                    onClickPrimary={onDeleteConfirm}
                />
            )}
        </div>
    );
};

ServiceJobsParts.propTypes = {
    lotName: PropTypes.string,
    parts: PropTypes.arrayOf(PropTypes.object),
    onAddJobPart: PropTypes.func.isRequired,
    onDeletePart: PropTypes.func.isRequired,
    onChangeJobValue: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    identifierJobsParts: PropTypes.string,
    editDetails: PropTypes.object,
    invoiceNumber: PropTypes.number,
    serviceJobId: PropTypes.string,
    technicianId: PropTypes.number,
};

ServiceJobsParts.defaultProps = {
    lotName: '',
    parts: [],
    identifierJobsParts: '',
    editDetails: {},
    invoiceNumber: 0,
    serviceJobId: '',
    technicianId: 0,
};

const areEqual = (prevProps, nextProps) => prevProps.identifierJobsParts === nextProps.identifierJobsParts;

export default React.memo(ServiceJobsParts, areEqual);
