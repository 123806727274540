/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles, Grid, Button,
    Switch,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { Form } from 'react-bootstrap';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import LotsCategory from 'utils/enum/LotsCategory';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import CompanyQuery from 'services/graphQL/query/core/CompanyQuery';
import CompanyMutation from 'services/graphQL/mutate/CompanyMutation';
import ButtonStyles from 'styles/theme/Button';
import LotQuery from 'services/graphQL/query/LotQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import If from 'components/widgets/conditional/If';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import VirtualTable from 'components/widgets/VirtualTable';

// Icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    input: {
        fontSize: '14px',
    },
    title: {
        fontSize: '12px',
        marginLeft: '15px',
    },
    addContainer: {
        marginTop: '10px',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex',
        '& > input': {
            width: '250px',
        },
        '& > button': {
            marginLeft: '5px',
        },
    },
    deleteButton: {
        minWidth: '30px',
        '& .MuiButton-startIcon': {
            marginRight: 0,
        },
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '450px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            '& > div': {
                minWidth: '600px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    uploader: {
        display: 'none',
    },
    warning: {
        fontSize: '12px',
        fontWeight: '300',
        marginLeft: '15px',
        marginTop: '2px',
    },
    watermarkName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    toggle: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '15px',
        paddingRight: '15px',
        alignItems: 'center',
        fontSize: '12px',
        marginBottom: '10px',
        borderBottom: `solid 1px ${theme.palette.border.mercury}`,
    },
}));

const AdvertisingSettings = ({ canWrite }) => {
    const client = useApolloClient();
    const uploader = useRef(null);
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        watermarks: [],
        currentWatermark: null,
        isDeletePromptVisible: false,
        isDeletingWatermark: false,
        isUploadingWatermark: false,
        watermarkName: null,
        enableDefaultAds: false,
    });

    const {
        data: watermarksData,
        loading: watermarksLoading,
        error: watermarksError,
    } = useQuery(CompanyQuery.PULL_AVAILABLE_WATERMARKS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const { PLACE_ONLINE_ADS_DEFAULT } = InventoryCategory;
    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [PLACE_ONLINE_ADS_DEFAULT],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveInventorySettings, { loading: savingSettings }] = useMutation(InventoryMutation.SAVE_INVENTORY_SETTINGS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (watermarksError) {
            ModalUtils.errorMessage(watermarksError?.graphQLErrors);
            return;
        }

        if (!watermarksLoading) {
            const watermarks = watermarksData?.pullAvailableWatermarks;
            if (watermarks) {
                setState((prevState) => ({
                    ...prevState,
                    watermarks: watermarks.slice().sort((a, b) => a.name.localeCompare(b.name)),
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watermarksLoading, watermarksError]);

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading) {
            const settings = settingsData?.getSettings;
            if (settings) {
                const savedValue = settings.find((setting) => setting.key === PLACE_ONLINE_ADS_DEFAULT)?.value ?? 'false';
                setState((prevState) => ({
                    ...prevState,
                    enableDefaultAds: savedValue === 'true',
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    const toggleDeletePrompt = (companyWatermarkId = null) => {
        setState((prevState) => ({
            ...prevState,
            isDeletePromptVisible: !state.isDeletePromptVisible,
            currentWatermark: companyWatermarkId,
        }));
    };

    const removeWatermark = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                isDeletingWatermark: true,
            }));

            const { data } = await client.mutate({
                mutation: CompanyMutation.REMOVE_WATERMARK,
                variables: {
                    companyWatermarkId: state.currentWatermark,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                ModalUtils.successMessage(null, 'Watermark removed successfully');

                let clone = cloneDeep(state.watermarks);
                clone = clone.filter((element) => element.companyWatermarkId !== state.currentWatermark);
                setState((prevState) => ({
                    ...prevState,
                    isDeletingWatermark: false,
                    isDeletePromptVisible: false,
                    currentWatermark: null,
                    watermarks: clone,
                }));
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const onChange = (name, value) => {
        if (name === 'enableDefaultAds') {
            saveInventorySettings({
                variables: {
                    input: [
                        {
                            value: value ? 'true' : 'false',
                            key: PLACE_ONLINE_ADS_DEFAULT,
                            lotName: ALL_LOTS,
                        },
                    ],
                },
            });
        }

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const openFileDialog = () => {
        const { current } = uploader;
        if (current) current.click();
    };

    const uploadWatermark = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        const maxSizeAllowed = 307200; // 300KB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 300KB.');
            return;
        }

        if (ext !== '.png') {
            ModalUtils.errorMessage(null, 'Only png images are supported');
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingWatermark: true,
        }));

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (readerEvent) => {
            const image = new Image();
            image.src = readerEvent.target.result;
            image.onload = async () => {
                const { width } = image;
                if (width > 1920) {
                    ModalUtils.errorMessage(null, 'Maximum width allowed: 1920px');
                    return;
                }

                try {
                    const { data } = await client.mutate({
                        mutation: CompanyMutation.ADD_WATERMARK,
                        variables: {
                            name: state.watermarkName,
                            file,
                        },
                        fetchPolicy: FetchPolicy.NO_CACHE,
                    });

                    const response = data?.addWatermark;
                    if (response) {
                        const { companyWatermarkId, url } = response;
                        const clone = cloneDeep(state.watermarks);
                        clone.unshift({
                            companyWatermarkId,
                            name: state.watermarkName,
                            url,
                        });

                        setState((prevState) => ({
                            ...prevState,
                            watermarkName: null,
                            currentWatermark: null,
                            isUploadingWatermark: false,
                            watermarks: clone.slice().sort((a, b) => a.name.localeCompare(b.name)),
                        }));
                    }
                } catch (error) {
                    setState((prevState) => ({
                        ...prevState,
                        isUploadingWatermark: false,
                    }));

                    ModalUtils.errorMessage(null, error.message);
                }
            };
        };
    };

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span
                        tooltip={record.name}
                        className={classes.watermarkName}
                    >
                        {record.name}

                    </span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'URL',
            dataKey: 'url',
            width: 400,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span>{record.url}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 80,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        companyWatermarkId,
                    },
                } = cell;

                return (
                    <Button
                        disabled={state.isUploadingWatermark}
                        className={clsx(classes.containedError, classes.deleteButton)}
                        size="small"
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={() => toggleDeletePrompt(companyWatermarkId)}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Grid container className={classes.box}>
                <Grid className={classes.toggle} item xs={12}>
                    <div>
                        Place online ads by default
                    </div>
                    <div>
                        <Switch
                            disabled={savingSettings}
                            checked={state.enableDefaultAds}
                            onChange={(event) => onChange('enableDefaultAds', event.target.checked)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </Grid>
                <Grid item lg={11} xs={12}>
                    <div className={classes.title}>
                        Watermarks
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.addContainer}>
                        <Form.Control
                            placeholder="Name (60 characters max)"
                            maxLength={60}
                            disabled={!canWrite}
                            className={classes.input}
                            type="text"
                            name="watermarkName"
                            value={state.watermarkName || ''}
                            onChange={({ target: { name, value } }) => onChange(name, value)}
                        />
                        <If condition={canWrite}>
                            <input
                                ref={uploader}
                                className={classes.uploader}
                                type="file"
                                onChange={uploadWatermark}
                            />
                            <Button
                                disabled={StringUtils.isEmpty(state.watermarkName) || state.isUploadingWatermark}
                                className={classes.containedSecondaryInfo}
                                size="small"
                                onClick={openFileDialog}
                            >
                                Add
                            </Button>
                        </If>
                    </div>
                    <div className={classes.warning}>
                        PNG only, maximum width allowed: 1920px
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={watermarksLoading}
                            rowHeight={45}
                            totalRecords={state.watermarks.length}
                            data={state.watermarks}
                            columns={getColumns()}
                        />
                    </div>
                </Grid>
            </Grid>
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this watermark?"
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={removeWatermark}
                disablePrimaryButton={state.isDeletingWatermark}
                disableSecondaryButton={state.isDeletingWatermark}
            />
        </>
    );
};

AdvertisingSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default AdvertisingSettings;
