import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CompanySettingsContainer from 'components/containers/settings/company/CompanySettingsContainer';
import { Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import PhoneInput from 'components/widgets/form/PhoneInput';
import {
    Grid,
} from '@material-ui/core';
import Select from 'components/widgets/Select';
import CompanyImage from 'components/widgets/settings/CompanyImage';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty } from 'lodash';
import Dropzone from 'react-dropzone';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import CompanySettingsStyles from 'styles/modules/settings/CompanySettingsStyles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import VendorUtils from 'utils/VendorUtils';
import TimeZoneUtil from 'lib/TimeZoneUtil';
import { CompanySettingSchema } from 'utils/schema/settings/CompanySetting';
import { isValidField, isValidSchema } from 'utils/schema/utils';

const styles = (theme) => CompanySettingsStyles.listStyles(theme);

class CompanySettings extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SETTINGS_GENERAL_WRITE = keyStore.hasPermission(Permission.SETTINGS_GENERAL_WRITE);
    }

    async onAddImages(files) {
        const {
            props: {
                onUploadFile,
            },
        } = this;

        onUploadFile(files[0]);
    }

    getStates() {
        const fields = VendorUtils.getVendorStates();
        return fields.map((item) => ({ value: item, label: item }));
    }

    getTimezones() {
        const fields = TimeZoneUtil.getTimeZoneIds();
        return fields.map((item) => ({ value: item, label: item }));
    }

    openFileDialog() {
        const { current } = this.uploader;
        if (current) current.click();
    }

    validateCheckboxData(record) {
        if (record.carfaxIdEnabled && isEmpty(record.carfaxId)) return false;
        if (record.secureCloseEnabled && isEmpty(record.secureCloseDmsguid)) return false;

        return true;
    }

    render() {
        const {
            props: {
                classes, record, onChangeValue,
                onEnableDecoder, removeCompanyLogo, onSave,
            },
        } = this;

        const isValidData = isValidSchema(CompanySettingSchema, record);
        const { isValid, errors } = isValidData;
        const checkboxValid = this.validateCheckboxData(record);

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.saveButton)}
                            startIcon={<SaveIcon />}
                            disabled={!isValid || !checkboxValid}
                            onClick={() => onSave()}
                        >
                            Save Info
                        </Button>
                    )}
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Dropzone
                            multiple={false}
                            accept="image/*"
                            onDrop={(acceptedFiles) => this.onAddImages(acceptedFiles)}
                            className={classes.dragzone}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                className={clsx(classes.button, classes.editButton)}
                                startIcon={<BorderColorOutlinedIcon />}
                            >
                                Change Logo
                            </Button>
                        </Dropzone>
                    )}

                    {this.SETTINGS_GENERAL_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.removeButton)}
                            startIcon={<DeleteIcon />}
                            disabled={isEmpty(record.companyLogo)}
                            onClick={removeCompanyLogo}
                        >
                            Delete Logo
                        </Button>
                    )}
                </div>
                <Grid container spacing={1} className={classes.box}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Form.Group as={Col}>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                className={isValidField(errors, 'companyName') ? 'invalid-field' : ''}
                                value={record.companyName}
                                type="text"
                                onChange={(e) => onChangeValue('companyName', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                className={isValidField(errors, 'companyAddress') ? 'invalid-field' : ''}
                                value={record.companyAddress}
                                type="text"
                                onChange={(e) => onChangeValue('companyAddress', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                className={isValidField(errors, 'companyCity') ? 'invalid-field' : ''}
                                value={record.companyCity}
                                type="text"
                                onChange={(e) => onChangeValue('companyCity', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>State</Form.Label>
                            <Select
                                name="companyState"
                                value={record.companyState}
                                options={this.getStates()}
                                placeholder="Select"
                                className={isValidField(errors, 'companyState') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                maxMenuHeight={140}
                                onChange={onChangeValue}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Zip</Form.Label>
                            <NumberFormat
                                value={record.companyZip}
                                className={isValidField(errors, 'companyZip') ? 'form-control invalid-field' : 'form-control'}
                                placeholder="Placeholder"
                                format="#####"
                                onFocus={onEnableDecoder}
                                onValueChange={({ value }) => onChangeValue('companyZip', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                className={isValidField(errors, 'companyCountry') ? 'invalid-field' : ''}
                                value={record.companyCountry}
                                type="text"
                                onChange={(e) => onChangeValue('companyCountry', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Phone</Form.Label>
                            <PhoneInput
                                value={record.companyPhone}
                                className={isValidField(errors, 'companyPhone') ? 'invalid-field' : ''}
                                onChange={(value) => onChangeValue('companyPhone', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Fax</Form.Label>
                            <PhoneInput
                                value={record.companyFax}
                                onChange={(value) => onChangeValue('companyFax', value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Form.Group as={Col}>
                            <Form.Label>Contact Person</Form.Label>
                            <Form.Control
                                value={record.companyContact}
                                type="text"
                                onChange={(e) => onChangeValue('companyContact', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                value={record.companyEmail}
                                type="text"
                                onChange={(e) => onChangeValue('companyEmail', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                value={record.companyWebsite}
                                type="text"
                                onChange={(e) => onChangeValue('companyWebsite', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>EIN#</Form.Label>
                            <Form.Control
                                value={record.companyEin}
                                type="text"
                                onChange={(e) => onChangeValue('companyEin', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Check
                                onChange={(e) => onChangeValue('carfaxIdEnabled', e.target.checked)}
                                checked={record.carfaxIdEnabled}
                                name="carfaxIdEnabled"
                                className={classes.alignCheckBox}
                                inline
                                label="Use Carfax ID"
                            />
                            <Form.Control
                                value={record.carfaxIdEnabled ? record.carfaxId : ''}
                                type="text"
                                disabled={!record.carfaxIdEnabled}
                                className={record.carfaxIdEnabled && isEmpty(record.carfaxId) ? 'invalid-field' : ''}
                                onChange={(e) => onChangeValue('carfaxId', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Check
                                onChange={(e) => onChangeValue('secureCloseEnabled', e.target.checked)}
                                checked={record.secureCloseEnabled}
                                name="secureCloseEnabled"
                                className={classes.alignCheckBox}
                                inline
                                label="Use Secure Close ID"
                            />
                            <Form.Control
                                value={record.secureCloseEnabled ? record.secureCloseDmsguid : ''}
                                type="text"
                                disabled={!record.secureCloseEnabled}
                                className={record.secureCloseEnabled && isEmpty(record.secureCloseDmsguid) ? 'invalid-field' : ''}
                                onChange={(e) => onChangeValue('secureCloseDmsguid', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Time Zone</Form.Label>
                            <Select
                                name="companyTimeZoneId"
                                value={record.companyTimeZoneId}
                                options={this.getTimezones()}
                                placeholder="Select"
                                maxMenuHeight={140}
                                onChange={onChangeValue}
                            />
                        </Form.Group>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Form.Group as={Col}>
                            <Form.Label>Company Logo</Form.Label>
                            <Grid className={classes.companyImage}>
                                <CompanyImage
                                    image={record.companyLogo}
                                    width="100%"
                                />
                            </Grid>
                        </Form.Group>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

CompanySettings.propTypes = {
    classes: PropTypes.object.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    removeCompanyLogo: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    record: PropTypes.shape({
        companyCode: PropTypes.number,
        companyDbName: PropTypes.string,
        companyName: PropTypes.string,
        companyAddress: PropTypes.string,
        companyCity: PropTypes.string,
        companyState: PropTypes.string,
        companyZip: PropTypes.string,
        companyCountry: PropTypes.string,
        companyPhone: PropTypes.string,
        companyFax: PropTypes.string,
        companyWebsite: PropTypes.string,
        companyContact: PropTypes.string,
        companyEin: PropTypes.string,
        companyEmail: PropTypes.string,
        carfaxIdEnabled: PropTypes.bool,
        carfaxId: PropTypes.string,
        secureCloseDmsguid: PropTypes.string,
        secureCloseEnabled: PropTypes.bool,
        companyTimeZoneId: PropTypes.string,
        companyTimezone: PropTypes.number,
        companyLogo: PropTypes.string,
    }),
};

CompanySettings.defaultProps = {
    record: {},
};

export default withRouter(withStyles(styles)(CompanySettingsContainer(CompanySettings)));
