import React, { Component } from 'react';

// Components and Others
import { clone } from 'lodash';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ZipUtils from 'utils/ZipUtils';
import DealService from 'services/modules/DealService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';

const ReferenceDialogContainer = (WrappedComponent) => class extends Component {
  static propTypes = {
      isEditing: PropTypes.bool,
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
      onSave: PropTypes.func.isRequired,
      dealReferenceId: PropTypes.number,
  };

  static defaultProps = {
      isEditing: false,
      dealReferenceId: null,
  }

  constructor(props) {
      super(props);
      this.graphqlClient = new GraphQLClient();
      this.dealService = new DealService();

      this.initBind();
  }

  /* eslint-disable react/state-in-constructor */
  state = {
      record: {
          firstName: '',
          lastName: '',
          phoneNo: '',
          relation: '',
          address: '',
          address2: '',
          email: '',
          zip: null,
          city: '',
          state: '',
          county: '',
          country: '',
      },
      cities: [],
      isDecoderEnabled: false,
      isDecodingZip: false,
      isLoading: false,
  };

  componentDidMount() {
      const { props: { isEditing } } = this;
      if (isEditing) {
          this.getDealReference(true);
      }
  }

  onChangeValue(field, value) {
      this.setState(({ record }) => {
          const newRecord = { ...record };
          newRecord[field] = value;
          return { record: newRecord };
      });
  }

  onChangeZipCode(value) {
      this.setState((prevState) => ({
          record: update(prevState.record, {
              zip: { $set: value },
          }),
      }), () => {
          const currentValue = value?.toString();
          const { isDecoderEnabled } = this.state;
          if (isDecoderEnabled && currentValue?.length === 5) {
              this.decodeZip(currentValue);
          }
      });
  }

  onChangeCity(field, value) {
      this.setState(({ record, zipData }) => {
          const newRecord = { ...record };
          if (zipData && zipData.length > 0) {
              const alreadyExist = zipData.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(value));
              if (!alreadyExist) {
                  const newData = clone(zipData[0]);
                  newData.city = value;
                  zipData.unshift(newData);
              }
          }

          const countyAndState = ZipUtils.getCountryAndStateByCity(value, zipData);
          newRecord.city = value;
          if (!StringUtils.isEmpty(countyAndState.county)) newRecord.county = countyAndState.county;
          if (!StringUtils.isEmpty(countyAndState.state)) newRecord.state = countyAndState.state;

          return { record: newRecord };
      });
  }

  onEnableDecoder() {
      const { state: { isDecoderEnabled } } = this;
      if (!isDecoderEnabled) {
          this.setState({ isDecoderEnabled: true });
      }
  }

  getDealReference(ignoreCity = false) {
      const { props: { dealReferenceId } } = this;

      this.setState({ isLoading: true });
      this.dealService.getDealReference({ dealReferenceId })
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data && data.reference) {
                  const cities = [];
                  const { reference } = data;
                  const { city, zip } = reference || {};

                  if (city) {
                      cities.push(city);
                  }

                  this.setState({ record: reference, cities, isLoading: false });
                  if (zip && zip.length === 5) {
                      this.decodeZip(zip, ignoreCity);
                  }
              }
          });
  }

  decodeZip(zip = '', ignoreCity = false) {
      const input = {
          zip,
      };

      this.setState({ isDecodingZip: true });
      this.graphqlClient
          .query(CustomerQuery.DECODE_ZIP_CODE, input)
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data?.decodeZip) {
                  const { decodeZip } = data;

                  /* In order to keep the city value, we need
                    to assign it using the record from database */
                  if (ignoreCity && decodeZip.length > 0) {
                      const { state: { record } } = this;
                      const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.city));

                      if (!alreadyExist) {
                          const newData = clone(decodeZip[0]);
                          newData.city = record.city;
                          decodeZip.unshift(newData);
                      }
                  }

                  const cities = decodeZip.map((item) => item.city);
                  const zipData = decodeZip;

                  this.setState(({ record, isDecoderEnabled }) => {
                      if (isDecoderEnabled) {
                          const newRecord = {
                              ...record,
                              city: '',
                              county: '',
                              state: '',
                          };
                          return {
                              cities, zipData, record: newRecord, isDecodingZip: false,
                          };
                      }
                      return { cities, zipData, isDecodingZip: false };
                  });

                  if (decodeZip.length === 1) {
                      const currentData = decodeZip[0];

                      this.setState(({ record }) => {
                          const newRecord = {
                              ...record,
                              city: currentData.city,
                              county: currentData.county,
                              state: currentData.state,
                          };

                          return { record: newRecord };
                      });
                  }
              }
          });
  }

  initBind() {
      this.decodeZip = this.decodeZip.bind(this);
      this.onChangeCity = this.onChangeCity.bind(this);
      this.onChangeValue = this.onChangeValue.bind(this);
      this.onChangeZipCode = this.onChangeZipCode.bind(this);
      this.onEnableDecoder = this.onEnableDecoder.bind(this);
      this.getDealReference = this.getDealReference.bind(this);
  }

  render() {
      const { props, state } = this;

      return (
          <WrappedComponent
              {...props}
              {...state}
              onSave={props.onSave}
              onClose={props.onClose}
              onChangeCity={this.onChangeCity}
              onChangeValue={this.onChangeValue}
              onChangeZipCode={this.onChangeZipCode}
              onEnableDecoder={this.onEnableDecoder}
              onCloseDialogConfirm={this.onCloseDialogConfirm}
          />
      );
  }
};

export default ReferenceDialogContainer;
