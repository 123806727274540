const DataSort = Object.freeze({
    ASC: 'ASC',
    DESC: 'DESC',
});

const HttpMethods = Object.freeze({
    POST: 'POST',
    PUT: 'PUT',
    GET: 'GET',
    DELETE: 'DELETE',
});

const HttpCodes = Object.freeze({
    UNAUTHORIZED: 401,
});

const NOTIFICATION_TYPE = {
    SMS_RECEIVED: 'SMS_RECEIVED',
    EMAIL_RECEIVED: 'EMAIL_RECEIVED',
    REMINDER_EVENT: 'REMINDER_EVENT',
    REMINDER_CALL: 'REMINDER_CALL',
    REMINDER_TASK: 'REMINDER_TASK',
};

const FetchPolicy = Object.freeze({
    NETWORK_ONLY: 'network-only',
    NO_CACHE: 'no-cache',
    CACHE_FIRST: 'cache-first',
});

const ClientId = {
    WEB: 'Web',
};

const Tables = {
    INVENTORY_MAIN_LIST: 'Inventory Main List',
};

const WATERMARK_SPOTS = {
    TOP_LEFT: 'Top Left',
    TOP_CENTER: 'Top Center',
    TOP_RIGHT: 'Top Right',
    CENTER: ' Center',
    CENTER_LEFT: 'Center Left',
    CENTER_RIGHT: 'Center Right',
    BOTTOM_LEFT: 'Bottom Left',
    BOTTOM_CENTER: 'Bottom Center',
    BOTTOM_RIGHT: 'Bottom Right',
    OVERLAY: 'Overlay',
};

const ProcessTypeDescription = Object.freeze({
    PURCHASE_ORDER: 'Purchase Order',
    CHECK: 'Check',
    DEPOSIT: 'Deposit',
    BILL: 'Bill',
    PARTS_TICKET: 'Parts Ticket',
});

const DOCUMENT_REFERENCE_TYPE = {
    INVENTORY: 'Inventory',
    DEALS: 'Deals',
};

const ALL_LOTS = 'All Lots';
export {
    DataSort, HttpMethods, HttpCodes, FetchPolicy, ClientId,
    Tables, ALL_LOTS, NOTIFICATION_TYPE, WATERMARK_SPOTS,
    ProcessTypeDescription, DOCUMENT_REFERENCE_TYPE,
};
