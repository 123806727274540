import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    makeStyles,
    FormLabel,
    FormControl,
    FormHelperText,
    TextField,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    Button,
    Grid,
} from '@material-ui/core';
import InputNumber from 'components/widgets/InputNumber';
import Select from 'components/widgets/Select';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import InputTrim from 'components/modules/inventory/create/dialogs/EditVehicle/InputTrim';
import InputEngine from 'components/modules/inventory/create/dialogs/EditVehicle/InputEngine';
import DatePicker from 'react-datepicker';
import ButtonStyles from 'styles/theme/Button';
import PurchasingDialog from 'components/modules/inventory/create/dialogs/PurchasingDialog';
import VehicleMakeDropdown from 'components/widgets/inventory/VehicleMakeDropdown';

import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import { InventoryColor } from 'utils/enum/InventoryEnum';
import InventoryHelper from 'utils/InventoryHelper';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down(780)]: {
            flexDirection: 'column',
        },
        '& > div:last-child': {
            marginLeft: 50,
            [theme.breakpoints.down(780)]: {
                marginLeft: 0,
            },
        },
    },
    row: {
        display: 'flex',
        marginBottom: 10,

        '& > div': {
            flex: 1,
            marginRight: 5,

            '& .MuiFormLabel-root': {
                marginBottom: 5,
                textAlign: 'center',
            },
        },
    },
    rowHorizontal: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > label:first-child': {
            [theme.breakpoints.down(780)]: {
                textAlign: 'left',
            },
        },
        '& > div:last-child': {
            minWidth: '65%',
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    transmissionMobileStyle: {
        [theme.breakpoints.down(780)]: {
            justifyContent: 'flex-start',
            paddingLeft: 15,
        },
    },
    checkboxMobileStyle: {
        [theme.breakpoints.down(780)]: {
            justifyContent: 'center',
        },
    },
    lienHolderTitle: {
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '13px',
        marginTop: '15px',
        marginBottom: '20px',
    },
    lienHolderRow: {
        '& > div > div:nth-child(1)': {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px',
            },
        },
        '& > div > div > div': {
            marginRight: '10px',
            width: '95%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& > label': {
                textAlign: 'left !important',
                width: '114px',
                [theme.breakpoints.down('sm')]: {
                    width: 'initial',
                },
            },
            '& > input, & > div': {
                [theme.breakpoints.down('sm')]: {
                    width: '220px',
                },
            },
        },
    },
}));
const getColor = (type, colorsData, extColor, intColor) => {
    let list = colorsData
        .filter((item) => {
            const itemType = StringUtils.isEmpty(item.type)
                ? ''
                : item.type.toUpperCase();
            return itemType === type.toUpperCase();
        })
        .map((item) => {
            const values = item.description.split(' ');

            values.forEach((_, index) => {
                let value = values[index] || '';

                value = value.charAt(0).toUpperCase()
                    + value.substring(1, value.lenght).toLowerCase();

                values[index] = value;
            });

            return values.join(' ');
        });

    const { EXTERIOR_COLOR, INTERIOR_COLOR } = InventoryColor;

    if (type === EXTERIOR_COLOR || type === INTERIOR_COLOR) {
        const color = type === EXTERIOR_COLOR ? extColor || '' : intColor || '';

        if (!StringUtils.isEmpty(color) && !list.includes(color)) {
            list.unshift(color);
        }
    }

    list = [...new Set(list)];
    list = list.map((item) => ({
        value: item,
        label: item,
    }));

    return list;
};
const getColorsOptions = (colorsData, extColor, intColor) => {
    const { EXTERIOR_COLOR, INTERIOR_COLOR, GENERIC_EXTERIOR_COLOR } = InventoryColor;
    const exteriorColorsOptions = getColor(EXTERIOR_COLOR, colorsData, extColor, intColor);
    const interiorColorsOptions = getColor(INTERIOR_COLOR, colorsData, extColor, intColor);
    const genericExteriorColorsOptions = getColor(GENERIC_EXTERIOR_COLOR, colorsData, extColor, intColor);

    return {
        exteriorColorsOptions,
        interiorColorsOptions,
        genericExteriorColorsOptions,
    };
};

const EditVehicleForm = ({
    data, driveTrainsList,
    onChange, styleList, engineList,
    titleStatusList, invUserTabName,
    availableLots, purchasingAgentList,
    purchasedFromList, colorsData,
    multipleTrim, trimList, allowEditTrim,
    loading, decodeNewVin, oldVIN,
    invalidVINLength, invalidVINChars,
    vinNumberLength, notAllowedCharsInVIN,
    vinYearStandardization, onCreate,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        isPurchasingDialogOpen: false,
        title: '',
        field: '',
    });

    const {
        year, make, model, drivetrain, style, trim, engine,
        vin, miles, tmu, exempt, eml, genericExteriorColor,
        extColor, intColor, userTabURL1, userTabURL2, transmission,
        lotName, wholesale, spareKey, titleStatus, tag, tagExpires,
        titleNumber, title, pricing = {}, lienHolder,
    } = data;
    const { purchasedDate, purchasedFrom, purchasedAgent } = pricing;
    const invalidYear = InventoryHelper.hasStockInvalidYear(year);
    const invalidModel = StringUtils.isEmpty(model);
    const invalidStyle = StringUtils.isEmpty(style);
    const validURL1 = InventoryHelper.validateCustomTabURL(userTabURL1);
    const validURL2 = InventoryHelper.validateCustomTabURL(userTabURL2);
    const invalidURLLabel = 'The URL is not secure or invalid';

    const driveTrainsOptions = driveTrainsList.map((item) => ({
        value: item,
        label: item,
    }));
    const styleOptions = styleList.map((item) => ({
        value: item,
        label: item,
    }));
    const titleStatusOptions = titleStatusList.map((item) => ({
        value: item,
        label: item,
    }));

    const purchasedFromOptions = [...purchasedFromList.map((item) => ({
        value: item.purchasedFrom,
        label: item.purchasedFrom,
    })), {
        label: (
            <Button
                className={classes.containedInfo}
                size="medium"
                fullWidth
                // eslint-disable-next-line no-use-before-define
                onClick={() => openPurchasingDialog('Add Purchased From', 'purchasedFrom')}
            >
                Add
            </Button>
        ),
        value: '-1',
        isDisabled: true,
    }];

    const purchasingAgentOptions = [...purchasingAgentList.map((item) => ({
        value: item.agent,
        label: item.agent,
    })), {
        label: (
            <Button
                className={classes.containedInfo}
                size="medium"
                fullWidth
                // eslint-disable-next-line no-use-before-define
                onClick={() => openPurchasingDialog('Add Purchasing Agent', 'purchasedAgent')}
            >
                Add
            </Button>
        ),
        value: '-1',
        isDisabled: true,
    }];

    const availableLotsOptions = availableLots.map((item) => ({
        value: item.lotName,
        label: item.lotName,
    }));
    const { invUserTabName1, invUserTabName2 } = invUserTabName;
    const {
        exteriorColorsOptions,
        interiorColorsOptions,
        genericExteriorColorsOptions,
    } = getColorsOptions(colorsData, extColor, intColor);
    const onBlurVinDecode = ({ target }) => {
        if (year < vinYearStandardization) return;
        const { value } = target;

        if (value !== oldVIN && !StringUtils.isEmpty(value)) {
            decodeNewVin(value);
        }
    };

    const openPurchasingDialog = (titleInput, fieldInput) => {
        setState({
            ...state,
            isPurchasingDialogOpen: true,
            title: titleInput,
            field: fieldInput,
        });
    };

    const onClose = () => {
        setState({
            ...state,
            isPurchasingDialogOpen: false,
        });
    };

    return (
        <div className={clsx('d-flex', classes.root)}>
            <div className="d-flex-column flex-1">
                <div className={classes.row}>
                    <FormControl>
                        <FormLabel>Year</FormLabel>
                        <InputNumber
                            value={Number(year)}
                            onChange={(newValue) => onChange('year', newValue)}
                            placeholder="0"
                            size="sm"
                            className={clsx({ 'invalid-field': invalidYear })}
                        />
                        {invalidYear && (<FormHelperText error>The Year must be higher than 1900</FormHelperText>)}
                    </FormControl>
                    <VehicleMakeDropdown
                        make={make}
                        onChange={onChange}
                    />
                    <FormControl>
                        <FormLabel>Model</FormLabel>
                        <TextField
                            value={model}
                            onChange={({ target }) => onChange('model', target.value?.toUpperCase())}
                            variant="outlined"
                            size="small"
                            className={clsx({ 'invalid-field': invalidModel }, classes.textFieldSmall)}
                        />
                        {invalidModel && (<FormHelperText error>The Model field is required</FormHelperText>)}
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <InputTrim
                        multipleTrim={multipleTrim}
                        trimList={trimList}
                        allowEditTrim={allowEditTrim}
                        trimValue={StringUtils.isEmpty(trim) ? '' : trim}
                        onChange={(value) => onChange('trim', value)}
                    />
                    <FormControl>
                        <FormLabel>Style</FormLabel>
                        <Select
                            name="style"
                            onChange={(_, newValue) => onChange('style', newValue)}
                            value={style}
                            options={styleOptions}
                            nowrap
                            size="sm"
                            className={clsx({ 'invalid-field': invalidStyle })}
                            loading={loading}
                        />
                        {invalidStyle && (<FormHelperText error>The Style field is required</FormHelperText>)}
                    </FormControl>
                    <FormControl>
                        <FormLabel>Drivetrain</FormLabel>
                        <Select
                            name="drivetrain"
                            onChange={(_, newValue) => onChange('drivetrain', newValue)}
                            value={StringUtils.isEmpty(drivetrain) ? '' : drivetrain}
                            options={driveTrainsOptions}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <InputEngine
                        engine={StringUtils.isEmpty(engine) ? '' : engine}
                        engineList={engineList}
                        loading={loading}
                        onChange={(newValue) => onChange('engine', newValue)}
                    />
                </div>
                <div className={classes.row}>
                    <FormControl>
                        <FormLabel>VIN</FormLabel>
                        <TextField
                            value={StringUtils.isEmpty(vin) ? '' : vin.toUpperCase()}
                            onChange={({ target }) => onChange('vin', target.value)}
                            variant="outlined"
                            size="small"
                            className={classes.textFieldSmall}
                            onBlur={onBlurVinDecode}
                        />
                        {invalidVINLength && (<FormHelperText error>{`The VIN must be ${vinNumberLength} characters`}</FormHelperText>)}
                        {invalidVINChars && (
                            <FormHelperText error>
                                {`The following characters are not allowed: ${notAllowedCharsInVIN.toString().toUpperCase()}`}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl>
                        <FormLabel>Miles</FormLabel>
                        <InputNumber
                            value={miles}
                            onChange={(newValue) => onChange('miles', newValue)}
                            placeholder="0"
                            size="sm"
                            thousandSeparator
                        />
                    </FormControl>
                </div>
                <div
                    className={
                        clsx(classes.row, 'd-flex-justify-end', classes.checkboxMobileStyle)
                    }
                >
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={tmu}
                                onChange={(_, value) => onChange('tmu', value)}
                                name="tmu"
                                color="primary"
                            />
                        )}
                        label="TMU"
                    />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={exempt}
                                onChange={(_, value) => onChange('exempt', value)}
                                name="exempt"
                                color="primary"
                            />
                        )}
                        label="Exempt"
                    />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={eml}
                                onChange={(_, value) => onChange('eml', value)}
                                name="eml"
                                color="primary"
                            />
                        )}
                        label="EML"
                    />
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Transmission:</FormLabel>
                        <RadioGroup
                            row
                            value={transmission}
                            onChange={(_, newValue) => onChange('transmission', newValue)}
                            className={clsx('d-flex-justify-space-around', classes.transmissionMobileStyle)}
                        >
                            <FormControlLabel value="Automatic" control={<Radio color="primary" />} label="Automatic" />
                            <FormControlLabel value="Standard" control={<Radio color="primary" />} label="Standard" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Generic Exterior Color:</FormLabel>
                        <Select
                            name="genericExteriorColor"
                            onChange={(_, newValue) => onChange('genericExteriorColor', newValue)}
                            value={genericExteriorColor}
                            options={genericExteriorColorsOptions || []}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Exterior Color:</FormLabel>
                        <Select
                            name="extColor"
                            onChange={(_, newValue) => onChange('extColor', newValue)}
                            value={extColor}
                            options={exteriorColorsOptions || []}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Interior Color:</FormLabel>
                        <Select
                            name="intColor"
                            onChange={(_, newValue) => onChange('intColor', newValue)}
                            value={intColor}
                            options={interiorColorsOptions || []}
                            nowrap
                            size="sm"
                            loading={loading}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>{ `${invUserTabName1}:` }</FormLabel>
                        <div>
                            <TextField
                                value={userTabURL1}
                                onChange={({ target }) => onChange('userTabURL1', target.value)}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldSmall}
                                fullWidth
                            />
                            {!validURL1 && (<FormHelperText error>{ invalidURLLabel }</FormHelperText>)}
                        </div>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>{ `${invUserTabName2}:` }</FormLabel>
                        <div>
                            <TextField
                                value={userTabURL2}
                                onChange={({ target }) => onChange('userTabURL2', target.value)}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldSmall}
                                fullWidth
                            />
                            {!validURL2 && (<FormHelperText error>{ invalidURLLabel }</FormHelperText>)}
                        </div>
                    </FormControl>
                </div>
            </div>
            <div className="d-flex-column flex-1">
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Purchased From:</FormLabel>
                        <Select
                            sorted
                            nowrap
                            size="sm"
                            name="purchasedFrom"
                            loading={loading}
                            options={purchasedFromOptions}
                            value={purchasedFrom}
                            onChange={(name, value) => onChange(name, value)}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Purchasing Agent:</FormLabel>
                        <Select
                            sorted
                            nowrap
                            size="sm"
                            name="purchasedAgent"
                            loading={loading}
                            options={purchasingAgentOptions}
                            value={purchasedAgent}
                            onChange={(name, value) => onChange(name, value)}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Purchased Date:</FormLabel>
                        <DatePicker
                            size="small"
                            className="form-control form-control-sm"
                            onChange={(date) => onChange('purchasedDate', date)}
                            selected={purchasedDate ? DateUtils.toLocal(purchasedDate).toDate() : ''}
                            popperContainer={CalendarContainer}
                            maxDate={DateUtils.toLocal(new Date()).toDate()}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Lot:</FormLabel>
                        <Select
                            name="lotName"
                            onChange={(_, newValue) => onChange('lotName', newValue)}
                            value={lotName}
                            options={availableLotsOptions}
                            nowrap
                            size="sm"
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Spare Key:</FormLabel>
                        <TextField
                            inputProps={{ maxLength: 15 }}
                            value={StringUtils.isEmpty(spareKey) ? '' : spareKey}
                            onChange={({ target }) => onChange('spareKey', target.value)}
                            variant="outlined"
                            size="small"
                            className={classes.textFieldSmall}
                        />
                    </FormControl>
                </div>
                <div
                    className={
                        clsx(classes.row, 'd-flex-justify-end', classes.checkboxMobileStyle)
                    }
                >
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={wholesale}
                                onChange={(_, value) => onChange('wholesale', value)}
                                name="wholesale"
                                color="primary"
                            />
                        )}
                        label="Wholesale"
                    />
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Title:</FormLabel>
                        <div className="d-flex-center">
                            <Checkbox
                                checked={title}
                                onChange={(_, value) => onChange('title', value)}
                                name="title"
                                color="primary"
                            />
                            <TextField
                                value={titleNumber}
                                onChange={({ target }) => onChange('titleNumber', target.value)}
                                variant="outlined"
                                size="small"
                                className={clsx(classes.textFieldSmall, 'flex-1')}
                                disabled={!title}
                            />
                        </div>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Title Status:</FormLabel>
                        <Select
                            name="titleStatus"
                            onChange={(_, newValue) => onChange('titleStatus', newValue)}
                            value={titleStatus}
                            options={titleStatusOptions}
                            nowrap
                            size="sm"
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Tag:</FormLabel>
                        <TextField
                            value={StringUtils.isEmpty(tag) ? '' : tag}
                            onChange={({ target }) => onChange('tag', target.value)}
                            variant="outlined"
                            size="small"
                            className={classes.textFieldSmall}
                        />
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.rowHorizontal}>
                        <FormLabel>Tag Expires at:</FormLabel>
                        <DatePicker
                            size="small"
                            className="form-control form-control-sm"
                            onChange={(date) => onChange('tagExpires', date)}
                            selected={tagExpires ? DateUtils.toLocal(tagExpires).toDate() : ''}
                            popperContainer={CalendarContainer}
                            disabled={StringUtils.isEmpty(tag)}
                        />
                    </FormControl>
                </div>
                <div className={clsx(classes.row, classes.lienHolderTitle)}>
                    Lien Holder
                </div>
                <div className={clsx(classes.row, classes.lienHolderRow)}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Name:</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 100 }}
                                    value={lienHolder.name ?? ''}
                                    onChange={({ target }) => onChange('name', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Address:</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    value={lienHolder.address ?? ''}
                                    onChange={({ target }) => onChange('address', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className={clsx(classes.row, classes.lienHolderRow)}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Zip:</FormLabel>
                                <InputNumber
                                    value={lienHolder.zip}
                                    onChange={(value) => onChange('zip', (value || '').toString(), 'lienHolder')}
                                    decimalScale={0}
                                    max={99999}
                                    size="sm"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>City:</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 35 }}
                                    value={lienHolder.city ?? ''}
                                    onChange={({ target }) => onChange('city', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className={clsx(classes.row, classes.lienHolderRow)}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>County:</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    value={lienHolder.county ?? ''}
                                    onChange={({ target }) => onChange('county', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>State:</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 5 }}
                                    value={lienHolder.state ?? ''}
                                    onChange={({ target }) => onChange('state', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className={clsx(classes.row, classes.lienHolderRow)}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Phone:</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 12 }}
                                    value={lienHolder.phone ?? ''}
                                    onChange={({ target }) => onChange('phone', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Note:</FormLabel>
                                <TextField
                                    value={lienHolder.notes ?? ''}
                                    onChange={({ target }) => onChange('notes', target.value, 'lienHolder')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldSmall}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className={clsx(classes.row, classes.lienHolderRow)}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Pay Off:</FormLabel>
                                <InputNumber
                                    value={lienHolder.payOff ?? 0}
                                    onChange={(value) => onChange('payOff', parseFloat(value), 'lienHolder')}
                                    showCurrency
                                    thousandSeparator
                                    placeholder="0.00"
                                    size="sm"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.rowHorizontal}>
                                <FormLabel>Pay Off good till:</FormLabel>
                                <DatePicker
                                    className="form-control form-control-sm"
                                    selected={DateUtils.toLocal(lienHolder.payOffGoodTill)?.toDate()}
                                    placeholderText="mm/dd/yyyy"
                                    onChange={(date) => onChange('payOffGoodTill', date, 'lienHolder')}
                                    popperContainer={CalendarContainer}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PurchasingDialog
                isPurchasingDialogOpen={state.isPurchasingDialogOpen}
                title={state.title}
                field={state.field}
                onCreate={onCreate}
                onClose={onClose}
            />
        </div>
    );
};

EditVehicleForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    invUserTabName: PropTypes.object.isRequired,
    data: PropTypes.object,
    driveTrainsList: PropTypes.array,
    styleList: PropTypes.array,
    engineList: PropTypes.array,
    titleStatusList: PropTypes.array,
    availableLots: PropTypes.array,
    purchasedFromList: PropTypes.array,
    purchasingAgentList: PropTypes.array,
    colorsData: PropTypes.array,
    trimList: PropTypes.array,
    multipleTrim: PropTypes.bool,
    allowEditTrim: PropTypes.bool,
    loading: PropTypes.bool,
    decodeNewVin: PropTypes.func.isRequired,
    oldVIN: PropTypes.string,
    invalidVINLength: PropTypes.bool.isRequired,
    invalidVINChars: PropTypes.bool.isRequired,
    vinNumberLength: PropTypes.number.isRequired,
    notAllowedCharsInVIN: PropTypes.array.isRequired,
    vinYearStandardization: PropTypes.number.isRequired,
};

EditVehicleForm.defaultProps = {
    data: {},
    driveTrainsList: [],
    styleList: [],
    engineList: [],
    titleStatusList: [],
    availableLots: [],
    purchasedFromList: [],
    purchasingAgentList: [],
    colorsData: [],
    trimList: [],
    multipleTrim: false,
    allowEditTrim: false,
    loading: false,
    oldVIN: '',
};

export default EditVehicleForm;
