import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    makeStyles,
    FormLabel,
    FormControl,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import PurchasingForm from 'components/modules/inventory/create/createForm/PurchasingForm';
import PricingForm from 'components/modules/inventory/create/createForm/PricingForm';
import AdvertisingForm from 'components/modules/inventory/create/createForm/AdvertisingForm';
import FloorplanForm from 'components/modules/inventory/create/createForm/FloorplanForm';
import LienHolderForm from 'components/modules/inventory/create/createForm/LienHolderForm';
import EditForm from 'components/modules/inventory/create/createForm/EditForm';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

import DatePicker from 'react-datepicker';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import { TitleStatus } from 'utils/enum/InventoryEnum';

const useStyles = makeStyles((theme) => ({
    content: {
        background: theme.palette.background.white,
        marginTop: 15,
        padding: theme.spacing(1.2),
        overflow: 'auto',
        [theme.breakpoints.down(780)]: {
            flexDirection: 'column',
        },
    },
    lotPanel: {
        minWidth: 320,
        marginRight: 10,
        [theme.breakpoints.down(780)]: {
            minWidth: '100%',
            marginRight: 0,
            marginBottom: 20,
        },
    },
    row: {
        display: 'flex',
        marginBottom: 10,

        '& > div': {
            flex: 1,
            marginRight: 5,

            '& .MuiFormLabel-root': {
                marginRight: 5,
                textAlign: 'center',
                fontWeight: 'bold',
            },
        },
    },
    rowHorizontal: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& > label:first-child': {
            [theme.breakpoints.down(780)]: {
                textAlign: 'left',
            },
        },
        '& > div:last-child': {
            minWidth: '65%',
            maxWidth: '65%',
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
}));

const VehicleForm = ({
    vinWasDecoded,
    loading,
    enterManually,
    data,
    onChange,
    onCreate,
    availableLots,
    flooringCompanies,
    purchasedFromList,
    purchasingAgentList,
    providersOptions,
    invUserTabName,
    styleList,
    engineList,
    colorsData,
    allowEditTrim,
    multipleTrim,
    trimList,
    defaultReconCost,
}) => {
    const classes = useStyles();
    const {
        miles, lotName, isWholesale, spareKey, titleStatus, tag,
        tagExpires, titleNumber, title, isTMU, isExempt, isEML,
    } = data;
    const availableLotsOptions = availableLots.map((item) => ({
        value: item.lotName,
        label: item.lotName,
    }));
    const titleStatusOptions = TitleStatus.map((item) => ({
        value: item,
        label: item,
    }));

    if (vinWasDecoded || enterManually) {
        return (
            <div className={clsx('d-flex', 'flex-1', classes.content)}>
                <EditForm
                    data={data}
                    invUserTabName={invUserTabName}
                    loading={loading}
                    colorsData={colorsData}
                    engineList={engineList}
                    styleList={styleList}
                    allowEditTrim={allowEditTrim}
                    multipleTrim={multipleTrim}
                    trimList={trimList}
                    enterManually={enterManually}
                    onChange={onChange}
                />
                <div className={clsx('d-flex-column', classes.lotPanel)}>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Lot:</FormLabel>
                            <Select
                                name="lotName"
                                onChange={(_, newValue) => onChange('lotName', newValue)}
                                value={lotName}
                                options={availableLotsOptions}
                                nowrap
                                size="sm"
                            />
                        </FormControl>
                    </div>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Miles:</FormLabel>
                            <div>
                                <InputNumber
                                    value={miles}
                                    onChange={(newValue) => onChange('miles', newValue)}
                                    placeholder="0"
                                    size="sm"
                                    thousandSeparator
                                />
                            </div>
                        </FormControl>
                    </div>
                    <div
                        className={
                            clsx(classes.row, 'd-flex-justify-end')
                        }
                    >
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isTMU}
                                    onChange={(_, value) => onChange('isTMU', value)}
                                    name="isTMU"
                                    color="primary"
                                />
                            )}
                            label="TMU"
                        />
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isExempt}
                                    onChange={(_, value) => onChange('isExempt', value)}
                                    name="isExempt"
                                    color="primary"
                                />
                            )}
                            label="Exempt"
                        />
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isEML}
                                    onChange={(_, value) => onChange('isEML', value)}
                                    name="isEML"
                                    color="primary"
                                />
                            )}
                            label="EML"
                        />
                    </div>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Title:</FormLabel>
                            <div className="d-flex-center">
                                <Checkbox
                                    checked={title}
                                    onChange={(_, value) => onChange('title', value)}
                                    name="title"
                                    color="primary"
                                />
                                <TextField
                                    value={titleNumber}
                                    onChange={({ target }) => onChange('titleNumber', target.value)}
                                    variant="outlined"
                                    size="small"
                                    className={clsx(classes.textFieldSmall, 'flex-1')}
                                    disabled={!title}
                                />
                            </div>
                        </FormControl>
                    </div>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Title Status:</FormLabel>
                            <Select
                                name="titleStatus"
                                onChange={(_, newValue) => onChange('titleStatus', newValue)}
                                value={titleStatus}
                                options={titleStatusOptions}
                                nowrap
                                size="sm"
                            />
                        </FormControl>
                    </div>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Tag:</FormLabel>
                            <TextField
                                value={tag}
                                onChange={({ target }) => onChange('tag', target.value)}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldSmall}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Tag Expires at:</FormLabel>
                            <DatePicker
                                size="small"
                                className="form-control form-control-sm"
                                onChange={(date) => onChange('tagExpires', date)}
                                selected={tagExpires ? DateUtils.toLocal(tagExpires).toDate() : ''}
                                popperContainer={CalendarContainer}
                                disabled={StringUtils.isEmpty(tag)}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.row}>
                        <FormControl className={classes.rowHorizontal}>
                            <FormLabel>Spare Key:</FormLabel>
                            <TextField
                                inputProps={{ maxLength: 15 }}
                                value={spareKey}
                                onChange={({ target }) => onChange('spareKey', target.value)}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldSmall}
                            />
                        </FormControl>
                    </div>
                    <div
                        className={
                            clsx(classes.row, 'd-flex-justify-end')
                        }
                    >
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isWholesale}
                                    onChange={(_, value) => onChange('isWholesale', value)}
                                    name="isWholesale"
                                    color="primary"
                                />
                            )}
                            label="Wholesale"
                        />
                    </div>
                </div>
                <div className={clsx('flex-1')}>
                    <PurchasingForm
                        purchasedFromList={purchasedFromList}
                        purchasingAgentList={purchasingAgentList}
                        providersOptions={providersOptions}
                        loading={loading}
                        data={data.purchasingInfo}
                        onChange={onChange}
                        onCreate={onCreate}
                        defaultReconCost={defaultReconCost}
                    />
                    <PricingForm
                        data={data.pricingInfo}
                        onChange={onChange}
                    />
                    <AdvertisingForm
                        data={data.advertisingInfo}
                        onChange={onChange}
                    />
                    <FloorplanForm
                        data={data.floorPlanInfo}
                        onChange={onChange}
                        flooringCompanies={flooringCompanies}
                    />
                    <LienHolderForm
                        data={data.lienHolderInfo}
                        onChange={onChange}
                    />
                </div>
            </div>
        );
    }

    return null;
};

VehicleForm.propTypes = {
    vinWasDecoded: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.object,
    enterManually: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    availableLots: PropTypes.array,
    flooringCompanies: PropTypes.array.isRequired,
    purchasedFromList: PropTypes.array,
    purchasingAgentList: PropTypes.array,
    providersOptions: PropTypes.array,
    invUserTabName: PropTypes.object,
    colorsData: PropTypes.array,
    engineList: PropTypes.array,
    styleList: PropTypes.array,
    allowEditTrim: PropTypes.bool,
    multipleTrim: PropTypes.bool,
    trimList: PropTypes.array,
    defaultReconCost: PropTypes.number,
};

VehicleForm.defaultProps = {
    vinWasDecoded: false,
    loading: false,
    data: {},
    enterManually: false,
    allowEditTrim: false,
    multipleTrim: false,
    trimList: [],
    availableLots: [],
    purchasedFromList: [],
    purchasingAgentList: [],
    providersOptions: [],
    invUserTabName: {},
    styleList: [],
    engineList: [],
    colorsData: [],
    defaultReconCost: 0,
};

export default VehicleForm;
