/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { v1 as uuid } from 'uuid';
import {
    makeStyles,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
import {
    FORMATTING_CONDITION,
} from 'utils/enum/BusinessIntelligenceEnum';
import { Form } from 'react-bootstrap';
import { FilterJoin } from 'utils/enum/InventoryEnum';
import If from 'components/widgets/conditional/If';
import Select from 'components/widgets/Select';
import InputSearch from 'components/widgets/InputSearch';
import ButtonStyles from 'styles/theme/Button';
import StringUtils from 'lib/StringUtils';
import useOutside from 'components/hook/core/useOutside';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => InventoryListStyle.advancedColumnFilter(theme));
const AdvancedColumnFilter = ({
    column,
    columns,
    filters,
    position,
    containerTop,
    containerLeft,
    containerRight,
    loadFilterValues,
    applyFilters,
    resetFilters,
    columnsData,
    toggleFilterBox,
    useAdvancedFeatures,
}) => {
    const elementRef = useRef();
    useOutside(elementRef, (event) => {
        const parent = event.target.parentElement;
        if (
            parent
            && (
                parent instanceof SVGElement
                || (parent instanceof HTMLDivElement && parent.dataset.isFilter)
                || (
                    parent instanceof HTMLDivElement
                    && (
                        (parent.parentElement?.className ?? '').trim() === 'css-26l3qy-menu'
                        || (parent.className ?? '').trim() === 'css-26l3qy-menu'
                    )
                )
            )
        ) return;

        toggleFilterBox();
    }, true);

    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        isLoadingData: false,
        selectedValues: [],
        selectedTab: 0,
        condition: {
            firstConditionSelected: null,
            firstConditionValue: null,
            secondConditionSelected: null,
            secondConditionValue: null,
            join: FilterJoin.AND,
        },
    });

    const {
        isLoadingData,
        selectedValues,
        selectedTab,
        condition,
    } = state;

    const data = columnsData.find((cd) => cd.column === column);
    const isFilteredByValue = Array.isArray(data?.selected);

    const currentTableColumn = columns.find((c) => c.dataKey === column);
    const isColumnNumeric = currentTableColumn?.isNumeric ?? false;
    const columnWidth = currentTableColumn?.width ?? 0;
    const onScroll = async ({ target: { scrollTop, scrollHeight, offsetHeight } }) => {
        if (!data) return;

        const loadMore = offsetHeight + scrollTop >= scrollHeight;
        if (loadMore && (data.records.length < data.total)) {
            setState((prevState) => ({
                ...prevState,
                isLoadingData: true,
            }));

            await loadFilterValues(column, useAdvancedFeatures ? data?.search : null);
            setState((prevState) => ({
                ...prevState,
                isLoadingData: false,
            }));
        }
    };

    const onValueSelection = (value) => {
        let clone = [...selectedValues];
        if (clone.includes(value)) {
            clone = clone.filter((sel) => sel !== value);
        } else {
            clone = [...clone, value];
        }

        setState((prevState) => ({
            ...prevState,
            selectedValues: clone,
        }));

        if (useAdvancedFeatures) applyFilters(column, clone);
    };

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            selectedValues: [],
        }));

        if (!data || data?.pagination === false) {
            const fetchData = async () => {
                setState((prevState) => ({
                    ...prevState,
                    isLoadingData: true,
                }));

                await loadFilterValues(column, useAdvancedFeatures ? data?.search : null);
                setState((prevState) => ({
                    ...prevState,
                    isLoadingData: false,
                }));
            };

            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column]);

    useEffect(() => {
        const { selected } = data ?? {};
        if (selected) {
            if (isFilteredByValue) {
                setState((prevState) => ({
                    ...prevState,
                    selectedTab: 0,
                    selectedValues: selected,
                    condition: {
                        firstConditionSelected: null,
                        firstConditionValue: null,
                        secondConditionSelected: null,
                        secondConditionValue: null,
                        join: FilterJoin.AND,
                    },
                }));

                return;
            }

            setState((prevState) => ({
                ...prevState,
                selectedTab: 1,
                selectedValues: [],
                condition: selected,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const setActiveTab = (index) => {
        setState((prevState) => ({
            ...prevState,
            selectedTab: index,
        }));
    };

    const handleSelectAll = (allSelected) => {
        const values = allSelected ? [] : data?.records;

        setState((prevState) => ({
            ...prevState,
            selectedValues: values,
        }));
        applyFilters(column, values);
    };

    const onChangeCondition = (name, value) => {
        const clone = cloneDeep(condition);
        clone[name] = value;

        setState((prevState) => ({
            ...prevState,
            condition: clone,
        }));
    };

    const applyConditions = () => {
        applyFilters(column, condition);
        toggleFilterBox();
    };

    const clearConditions = () => {
        const payload = {
            firstConditionSelected: null,
            firstConditionValue: null,
            secondConditionSelected: null,
            secondConditionValue: null,
            join: FilterJoin.AND,
        };

        setState((prevState) => ({
            ...prevState,
            condition: payload,
        }));

        resetFilters(column, true);
    };

    const getAvailableConditions = () => {
        const conditions = { ...FORMATTING_CONDITION };
        if (!isColumnNumeric) {
            delete conditions.GREATER_THAN;
            delete conditions.GREATER_THAN_OR_EQUAL_TO;
            delete conditions.LESS_THAN;
            delete conditions.LESS_THAN_OR_EQUAL_TO;
        }

        return Object.keys(conditions)
            .map((key) => ({
                value: conditions[key],
                label: StringUtils.toPascalCase(key.replace(/_/g, ' ').toLowerCase()),
            }));
    };

    const isConditionApplyButtonEnabled = (
        !StringUtils.isEmpty(condition.firstConditionSelected)
        && !StringUtils.isEmpty(condition.firstConditionValue)
    )
    && (
        StringUtils.isEmpty(condition.secondConditionSelected)
        || (
            !StringUtils.isEmpty(condition.secondConditionSelected)
            && !StringUtils.isEmpty(condition.secondConditionValue)
        )
    );

    const filtersApplied = filters.some((item) => item.columnName === column);
    const areAllRecordsSelected = (data?.records ?? []).filter((r) => !selectedValues.includes(r)).length === 0;

    const filterComponentWidth = 250;
    const isFilterBiggerThanColumnWidth = filterComponentWidth > columnWidth;
    return (
        <div
            ref={elementRef}
            className={classes.container}
            style={{
                position,
                top: `${containerTop}px`,
                ...(isFilterBiggerThanColumnWidth ? { left: `${containerLeft}px` } : { right: `${containerRight}px` }),
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <If condition={isLoadingData}>
                <div className={classes.loading}>
                    Loading...
                </div>
            </If>
            {useAdvancedFeatures && (
                <div className={classes.tabs}>
                    <div className={selectedTab === 0 ? classes.activeTab : ''} onClick={() => setActiveTab(0)}>Filter By Value</div>
                    <div>|</div>
                    <div className={selectedTab === 1 ? classes.activeTab : ''} onClick={() => setActiveTab(1)}>Filter By Condition</div>
                </div>
            )}
            {selectedTab === 0 && (
                <>
                    <div>
                        <InputSearch
                            size="sm"
                            initialSearch={data?.search || ''}
                            executeWhenClearButton={() => loadFilterValues(column, null)}
                            onSearch={(val) => {
                                const isNew = (data?.search !== val);
                                if (isNew) loadFilterValues(column, StringUtils.isEmpty(val) ? null : val);
                            }}
                        />
                    </div>
                    <div
                        className={classes.valuesContainer}
                        onScroll={onScroll}
                    >
                        {useAdvancedFeatures && (
                            <div key={uuid()}>
                                <input
                                    type="checkbox"
                                    checked={areAllRecordsSelected}
                                    onChange={() => handleSelectAll(areAllRecordsSelected)}
                                />
                                <span>SELECT ALL</span>
                            </div>
                        )}
                        {data?.records.map((val) => (
                            <div key={uuid()}>
                                <input
                                    type="checkbox"
                                    checked={selectedValues.some((v) => v.toLowerCase() === val.toLowerCase())}
                                    onChange={() => onValueSelection(val)}
                                />
                                <span>{val}</span>
                            </div>
                        ))}
                    </div>
                    {!useAdvancedFeatures && (
                        <div className={classes.actionsContainer}>
                            <Button
                                className={classes.containedGreen}
                                disabled={selectedValues.length === 0}
                                onClick={() => {
                                    applyFilters(column, selectedValues);
                                }}
                            >
                                Apply
                            </Button>
                            <Button
                                className={classes.containedInfo}
                                disabled={!isFilteredByValue || (isFilteredByValue && !filtersApplied)}
                                onClick={() => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        selectedValues: [],
                                    }));

                                    resetFilters(column);
                                }}
                            >
                                Clear
                            </Button>
                        </div>
                    )}
                </>
            )}
            {selectedTab === 1 && useAdvancedFeatures && (
                <div className={classes.conditionFiltering}>
                    <div>
                        <span>Show items where the value</span>
                        <Select
                            nowrap
                            size="sm"
                            name="firstConditionSelected"
                            loading={false}
                            className={classes.input}
                            onChange={(_, newValue) => onChangeCondition('firstConditionSelected', newValue)}
                            value={condition.firstConditionSelected || ''}
                            options={getAvailableConditions()}
                        />
                        <Form.Control
                            className={classes.input}
                            type="text"
                            value={condition.firstConditionValue || ''}
                            onKeyDown={(e) => e.stopPropagation()}
                            onChange={({ target }) => onChangeCondition('firstConditionValue', target.value)}
                        />
                    </div>
                    <div>
                        <RadioGroup row value={condition.join} onChange={({ target }) => onChangeCondition('join', target.value)}>
                            <FormControlLabel value={FilterJoin.AND} control={<Radio />} label="And" />
                            <FormControlLabel value={FilterJoin.OR} control={<Radio />} label="Or" />
                        </RadioGroup>
                    </div>
                    <div>
                        <Select
                            nowrap
                            size="sm"
                            name="secondConditionSelected"
                            loading={false}
                            className={classes.input}
                            onChange={(_, newValue) => onChangeCondition('secondConditionSelected', newValue)}
                            value={condition.secondConditionSelected || ''}
                            options={getAvailableConditions()}
                        />
                        <Form.Control
                            className={classes.input}
                            type="text"
                            value={condition.secondConditionValue || ''}
                            onKeyDown={(e) => e.stopPropagation()}
                            onChange={({ target }) => onChangeCondition('secondConditionValue', target.value)}
                        />
                    </div>
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedGreen}
                            disabled={!isConditionApplyButtonEnabled}
                            onClick={applyConditions}
                        >
                            Apply
                        </Button>
                        <Button
                            className={classes.containedInfo}
                            disabled={isFilteredByValue || (!isFilteredByValue && !filtersApplied)}
                            onClick={clearConditions}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

AdvancedColumnFilter.propTypes = {
    column: PropTypes.string,
    columns: PropTypes.array,
    position: PropTypes.string,
    containerTop: PropTypes.number,
    containerLeft: PropTypes.number,
    containerRight: PropTypes.number,
    loadFilterValues: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    columnsData: PropTypes.array,
    filters: PropTypes.array,
    toggleFilterBox: PropTypes.func.isRequired,
    useAdvancedFeatures: PropTypes.bool,
};

AdvancedColumnFilter.defaultProps = {
    column: null,
    columns: [],
    position: 'fixed',
    containerTop: 0,
    containerLeft: 0,
    containerRight: 0,
    columnsData: [],
    filters: [],
    useAdvancedFeatures: false,
};

export default AdvancedColumnFilter;
