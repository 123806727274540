import { PartsTicketStatus } from 'utils/enum/PartsPurchaseEnum';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_AMOUNT: 'setAmount',
    SET_RECORD_VALUE: 'setRecordValue',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_DEFAULT_LOT: 'setDefaultLot',
    SET_STATE_VALUES: 'setStateValues',
    SET_SAVE_AND_NEW_PARTS_TICKET: 'setSaveAndNewPartsTicket',
    SET_PARTS_SETTINGS: 'setPartsSettings',
};

const PartsTicketReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return { ...state, record: action.value, isDirty: action?.isDirty ?? state.isDirty };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_RECORD_VALUE: {
        return { ...state, [action.value.key]: action.value.value };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_DEFAULT_LOT: {
        return {
            ...state,
            record: {
                ...state.record,
                lotId: action.value.lotId,
                lotName: action.value.lotName,
            },
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_SAVE_AND_NEW_PARTS_TICKET: {
        return {
            ...action.value,
            record: {
                ticketNumber: 0,
                customerCode: 0,
                customerName: '',
                customerPhone: '',
                customerEmail: '',
                customerAddress: '',
                lotId: state.record.lotId,
                lotName: state.record.lotName,
                status: PartsTicketStatus.NEW,
                isTaxable: false,
                salesType: state.record.salesType,
                counterPerson: state.record.counterPerson,
                discountPercent: 0,
                ticketOpen: new Date(),
                ticketClose: null,
                quickNote: '',
            },
            isDirty: false,
            couldLostData: false,
            isDirtyFiles: false,
            showPartsTicketPopup: true,
            clerkList: state.clerkList,
        };
    }
    case ACTION_TYPES.SET_PARTS_SETTINGS: {
        return {
            ...state,
            partsSettings: {
                ...state.partsSettings,
                ...action.payload,
            },
        };
    }
    default:
        return state;
    }
};

export default PartsTicketReducer;
