import PhoneUtils from 'lib/PhoneUtils';
import DateUtils from 'lib/DateUtils';

export default class CustomerMapper {
    // This mapper format some fields and add default values
    static mapCustomerData(data = {}) {
        const currentAddress = data.currentAddress || {};
        const currentEmployment = data.currentEmployment || {};

        return {
            customerCode: data.customerCode,
            customerId: data.customerId || '',
            firstName: data.firstName || '',
            middleName: data.middleName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            cellPhone: PhoneUtils.formatPhone(data.cellPhone),
            workPhone: PhoneUtils.formatPhone(data.workPhone),
            homePhone: PhoneUtils.formatPhone(data.homePhone),
            dob: DateUtils.setTimeToZero(data.dob),
            ssn: data.ssn || '',
            dln: data.dln || '',
            isBusiness: data.isBusiness,
            allowCallCellPhone: data.allowCallCellPhone,
            allowTextCellPhone: data.allowTextCellPhone,
            allowCallHomePhone: data.allowCallHomePhone,
            allowCallWorkPhone: data.allowCallWorkPhone,
            isTaxable: data.isTaxable,
            language: data.language,
            currentAddress: {
                customerAddressId: currentAddress.customerAddressId,
                customerId: currentAddress.customerId,
                parentId: currentAddress.parentId,
                address1: currentAddress.address1 || '',
                address2: currentAddress.address2 || '',
                phone: PhoneUtils.formatPhone(currentAddress.phone),
                housingStatus: currentAddress.housingStatus || '',
                mortgageOrRent: currentAddress.mortgageOrRent || '',
                start: DateUtils.format(currentAddress.start, 'L'),
                end: DateUtils.format(currentAddress.end, 'L'),
                totalMonths: currentAddress.totalMonths || 0,
                city: currentAddress.city || '',
                state: currentAddress.state || '',
                county: currentAddress.county || '',
                zipCode: currentAddress.zipCode || '',
                country: currentAddress.country || '',
            },
            currentEmployment: {
                customerId: currentEmployment.customerId,
                customerEmploymentId: currentEmployment.customerEmploymentId,
                parentId: currentEmployment.parentId,
                status: currentEmployment.status,
                employer: currentEmployment.employer,
                title: currentEmployment.title,
                start: currentEmployment.start,
                end: currentEmployment.end,
                monthlyIncome: currentEmployment.monthlyIncome,
                workPhone: currentEmployment.workPhone,
                otherIncomeMonthly: currentEmployment.otherIncomeMonthly,
                otherIncomeSource: currentEmployment.otherIncomeSource,
                address1: currentEmployment.address1,
                address2: currentEmployment.address2,
                city: currentEmployment.city,
                state: currentEmployment.state,
                county: currentEmployment.county,
                zipCode: currentEmployment.zipCode,
                country: currentEmployment.country,
                totalMonths: currentEmployment.totalMonths,
            },
        };
    }

    static mapAddressData(record) {
        if (record) {
            return {
                address1: record.address1,
                address2: record.address2,
                city: record.city,
                state: record.state,
                county: record.county,
                zipCode: record.zipCode,
                country: record.country,
                start: DateUtils.isValid(record.start) ? DateUtils.toLocal(record.start).toDate() : '',
                end: DateUtils.isValid(record.end) ? DateUtils.toLocal(record.end).toDate() : '',
                mortgageOrRent: record.mortgageOrRent,
                housingStatus: record.housingStatus,
                phone: record.phone,
                isCurrentAddress: record.isCurrentAddress || false,
            };
        }

        return {};
    }

    static mapEmploymentData(record) {
        if (record) {
            return ({
                employer: record.employer,
                title: record.title,
                workPhone: record.workPhone,
                monthlyIncome: record.monthlyIncome,
                otherIncomeMonthly: record.otherIncomeMonthly,
                otherIncomeSource: record.otherIncomeSource,
                end: DateUtils.isValid(record.end) ? DateUtils.toLocal(record.end).toDate() : '',
                start: DateUtils.isValid(record.start) ? DateUtils.toLocal(record.start).toDate() : '',
                isCurrentEmployment: record.isCurrentEmployment || false,
                address1: record.address1,
                address2: record.address2,
                zipCode: record.zipCode,
                city: record.city,
                county: record.county,
                state: record.state,
                country: record.country,
            });
        }

        return {};
    }
}
