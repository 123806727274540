import { gql } from '@apollo/client';

export default class DealSubscription {
    static DEAL_BUYERS_EDITED = gql`
        subscription dealBuyersEdited($accountNumber: Int!) {
            dealBuyersEdited(accountNumber: $accountNumber) {
                deal {
                    postedDate
                    stage
                }
                buyer {
                    customerCode
                    customerId
                    firstName
                    middleName
                    lastName
                    email
                    cellPhone
                    homePhone
                    workPhone
                    dob
                    ssn
                    dln
                    currentAddressId
                    currentEmploymentId
                    isBusiness
                    allowCallCellPhone
                    allowTextCellPhone
                    allowCallHomePhone
                    allowCallWorkPhone
                }
                coBuyer {
                    customerCode
                    customerId
                    firstName
                    lastName
                    email
                    cellPhone
                    homePhone
                    workPhone
                    dob
                    ssn
                    dln
                    currentAddressId
                    currentEmploymentId
                    isBusiness
                    allowCallCellPhone
                    allowTextCellPhone
                    allowCallHomePhone
                    allowCallWorkPhone
                }
                buyerAddress {
                    dealPreviousAddressId
                    customerAddressId
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                    phone
                    housingStatus
                    mortgageOrRent
                    totalMonths
                    isCurrentAddress
                    start
                    end
                }
                coBuyerAddress {
                    dealPreviousAddressId
                    customerAddressId
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                    phone
                    housingStatus
                    mortgageOrRent
                    totalMonths
                    isCurrentAddress
                    start
                    end
                }
                buyerEmployments {
                    dealPreviousEmploymentId
                    customerEmploymentId
                    employer
                    title
                    totalMonths
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    zipCode
                    county
                    country
                    status
                    isCurrentEmployment
                }
                coBuyerEmployments {
                    dealPreviousEmploymentId
                    customerEmploymentId
                    employer
                    title
                    totalMonths
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    zipCode
                    county
                    country
                    status
                    isCurrentEmployment
                }
            }
        }
    `;

    static UPDATED_DEAL_LIST = gql`
        subscription updatedDeal($filter: DealListFilter) {
            updatedDeal(filter: $filter) {
                type
                deal {
                    accountNumber
                    dealType
                    flag
                    status
                    dealFunded
                    postedDate
                    soldDate
                    financeCompany
                    frontEndProfit
                    backEndProfit
                    totalProfit
                    notes
                    lotName
                    tradeInCode1
                    tradeInCode2
                    hasTrade1
                    hasTrade2
                    cashDownPayment
                    clientId
                    sentToTagAgencyOn
                    transferStatus
                    vehicle {
                        stockNumber
                        year
                        make
                        model
                        trim
                        extColor
                        miles
                        thumbnail
                    }
                    buyer {
                        customerCode
                        firstName
                        middleName
                        lastName
                    }
                    coBuyer {
                        customerCode
                        firstName
                        middleName
                        lastName
                    }
                    salesPerson1 {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    salesPerson2 {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    financeManager {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    salesManager {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    bdcAgent {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                }
            }
        }
    `;

    static DEAL_CHANGED = gql`
        subscription dealsChanged($accountNumber: Int!) {
            dealsChanged(accountNumber: $accountNumber) {
                deal {
                    dealType
                    stockNumber
                    status
                    soldDate
                    salesPrice
                    financeAmount
                    totalFees
                    totalProductsPrice
                    totalDeferredDownPayment
                    totalTradeAllowance
                    totalTradePayOff
                    buyRate
                    interestRate
                    reserveAmount
                    taxableAmount
                    salesTax
                    cashDownPayment
                    discount
                    balanceDue
                    paymentAmount
                    discountOverride
                    reserveOverride
                    registerOutState
                    term
                    firstPaymentDueDays
                    firstPaymentDue
                    tagPlate
                    tagPlateType
                    tags
                    assigned
                    paymentFrequency
                    dealerFee
                    inspectionFee
                    tagAgencyFee
                    userField1
                    userField2
                    userField3
                    userField4
                    userField5
                    userField6
                    userField7
                    registration
                    title
                    totalTitleRegistration
                    financeCompany
                    taxExempt
                    salesTaxOverride
                    inventoryTaxOverride
                    dealerInventoryTax
                    state
                    county
                    city
                    country
                    lotName
                    floorPlanCost
                    isResidualPercent
                    isUpFrontLeaseTaxes
                    mbi
                    msrp
                    leaseOveragePerMile
                    plannedAnnualMileage
                    contractAnnualMileage
                    leaseEndingOverage
                    terminationFee
                    rebates
                    reservePercent
                    residualPercent
                    residualValue
                    docStamp
                    tiPayoff1
                    tiPayoff2
                    bankOriginAmount
                    regZAPR
                    loanContractId
                    portfolioId
                    clientId
                    postedDate
                    netDue
                    salesTaxRate
                    paymentOverride
                    finalPaymentAmount
                    finalPaymentDue
                    leadSource
                    originalFinanceCharge
                    balanceDueOverride
                    balanceDueOverrideAmount
                    monthlyLeaseTax
                    totalGrossCapitalizedCost
                    leaseTotalDueAtSigning
                    backEndTax
                }
                deferredPayments {
                    deferredDownPaymentId
                    paymentDate
                    paymentNumber
                    paymentAmount
                }
            }
        }
    `;

    static UPDATED_DEAL_VEHICLES = gql`
        subscription updatedDealVehicles($dealId: Int!) {
            updatedDealVehicles(dealId: $dealId) {
                deal {
                    status
                    postedDate
                }
                vehicle {
                    thumbnail
                    stockNumber
                    vin
                    year
                    make
                    model
                    trim
                    extColor
                    intColor
                    miles
                    tmu
                    exempt
                    eml
                    engine
                    transmission
                    style
                    drivetrain
                    titleStatus
                    title
                    titleNumber
                    spareKey
                }
                tradeIns {
                    dealVehicleTradeID
                    dealId
                    tradeNumber
                    name
                    address
                    city
                    state
                    zip
                    county
                    notes
                    phone
                    stockNumber
                    allowance
                    acv
                    payOff
                    payOffGoodTill
                    noSalesTaxCredit
                    vehicle {
                        stockNumber
                        thumbnail
                        vin
                        year
                        make
                        model
                        trim
                        extColor
                        intColor
                        miles
                        tmu
                        exempt
                        eml
                        engine
                        transmission
                        style
                        drivetrain
                        titleStatus
                        title
                        titleNumber
                        spareKey
                    }
                }
            }
        }
    `;

    static DEAL_REFERENCE_LIST = gql`
        subscription dealReferenceList($accountNumber: Int!) {
            dealReferenceList(accountNumber: $accountNumber) {
                type
                reference: data {
                  dealReferenceId
                  accountNumber
                  firstName
                  lastName
                  email
                  relation
                  phoneNo
                }
            }
        }
    `;

    static PRODUCTS_UPDATED = gql`
        subscription productsUpdated($dealId: Int!) {
            productsUpdated(dealId: $dealId) {
                added {
                    type
                    price
                    cost
                    dealId
                    term
                    policyNumber
                    dealProductId
                    addToSellingPrice
                    vendor {
                    vendorId
                    vendorName
                    }
                    product {
                    productId
                    productName
                    }
                }
                updated {
                    type
                    price
                    cost
                    dealId
                    term
                    policyNumber
                    dealProductId
                    addToSellingPrice
                    vendor {
                        vendorId
                        vendorName
                    }
                    product {
                        productId
                        productName
                    }
                }
                deleted
            }
        }
    `;

    static DEAL_RECAP_UPDATED = gql`
        subscription dealRecapUpdated($dealId: Int!) {
            dealRecapUpdated(dealId: $dealId) {
                purchasedPrice
                pack
                repairs
                estimatedFloorPlanCost
                weOwe
                vehicleCost
                vehicleGross
                fees
                tradeEquity
                lenderDiscount
                frontEndGross
                salesRep1
                salesRep2
                bdcAgent
                salesManager
                financeManager
                totalCommissions
                vsc
                gap
                products
                reserve
                backEndGross
                totalDealProfit
                commission1Override
                commission2Override
                salesManagerOverride
                bdcAgentOverride
                financeCommissionOverride
                salesPerson1Employee {
                    firstName
                    middleName
                    lastName
                }
                salesPerson2Employee {
                    firstName
                    middleName
                    lastName
                }
                financeManagerEmployee {
                    firstName
                    middleName
                    lastName
                }
                salesManagerEmployee {
                    firstName
                    middleName
                    lastName
                }
                bdcAgentEmployee {
                    firstName
                    middleName
                    lastName
                },
            }
        }
    `;

    static CUSTOMER_ADDRESS_HISTORY = gql`
        subscription customerAddressHistory($accountNumber: Int! $customerId: ID!) {
            customerAddressHistory(accountNumber: $accountNumber customerId: $customerId){
                type
                data{
                    customerAddressId
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                    phone
                    housingStatus
                    mortgageOrRent
                    totalMonths
                }
            }
        }
    `;

    static CUSTOMER_EMPLOYMENT_HISTORY = gql`
        subscription customerEmploymentHistory($accountNumber: Int! $customerId: ID!) {
            customerEmploymentHistory(accountNumber: $accountNumber customerId: $customerId) {
                type
                data {
                    customerEmploymentId
                    status
                    employer
                    title
                    totalMonths
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                }
            }
        }
    `;

    static DEAL_SUMMARY_UPDATED = gql`
    subscription dealSummaryUpdated($dealId: Int!) {
        dealSummaryUpdated(dealId: $dealId) {
            accountNumber
            stockNumber
            lotName
            status
            vehicle {
                stockNumber
                year
                make
                model
                trim
                extColor
                miles
                thumbnail
                style
            }
            buyer {
                customerCode
                firstName
                middleName
                lastName
            }
            coBuyer {
                customerCode
                firstName
                middleName
                lastName
            }
            salesPerson1 {
                userId
                firstName
                middleName
                lastName
                picture
            }
            salesPerson2 {
                userId
                firstName
                middleName
                lastName
                picture
            }
            financeManager {
                userId
                firstName
                middleName
                lastName
                picture
            }
            salesManager {
                userId
                firstName
                middleName
                lastName
                picture
            }
            bdcAgent {
                userId
                firstName
                middleName
                lastName
                picture
            },
        }
    }
    `;
}
