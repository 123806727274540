/* eslint-disable no-param-reassign */
import React, { useReducer, useEffect, useRef } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Language } from 'utils/enum/Customer';
import { Form } from 'react-bootstrap';
import update from 'immutability-helper';
import { useMutation, useQuery } from '@apollo/client';
import Label from 'components/widgets/Label';
import Jodit from 'components/widgets/editor/JoditEditor';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import EmailTemplateMutation from 'services/graphQL/mutate/setting/EmailTemplateMutation';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, useTheme, useMediaQuery,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import { Communication } from 'utils/enum/SubModule';
import Select from 'components/widgets/Select';
import EmailTemplateQuery from 'services/graphQL/query/setting/EmailTemplateQuery';
import { FetchPolicy } from 'utils/enum/Core';
import Loading from 'components/widgets/Loading';

let joditInstance = null;
const getConfiguration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 400,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    placeholder: 'Type a message',
    buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'ul', 'ol',
        'indent', 'outdent', 'left', 'font', 'fontsize', 'paragraph', 'classSpan', 'brush',
        'image', 'link', 'source',
    ],
    events: {
        afterInit(editor) {
            joditInstance = editor;
        },
    },
};
const TEMPLATE_TYPE_OPTION = {
    EMAIL: 'Email',
    SMS: 'SMS',
};
const templateTypeOption = Object.keys(TEMPLATE_TYPE_OPTION).map((item) => ({
    label: TEMPLATE_TYPE_OPTION[item],
    value: TEMPLATE_TYPE_OPTION[item],
}));

const useStyles = makeStyles((theme) => ({
    dialog: {
        height: ({ isMobile }) => (isMobile ? 'auto' : '800px'),
    },
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    containerFields: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        width: '200px',
        minWidth: '200px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '& label': {
            textAlign: 'center',
            fontWeight: 500,
            display: 'block',
            margin: '8px 0',
        },
        position: 'relative',
    },
    listFields: {
        overflow: 'auto',
        '& li': {
            borderTop: '1px solid #e8e8e8',
            padding: theme.spacing(1, 2),
            fontSize: '14px',
            cursor: 'pointer',
        },
    },
    select: {
        width: 100,
    },
    htmlContainer: {
        flex: 1,
        border: '1px solid #e8e8e8',
        marginRight: theme.spacing(2),
        '& .jodit-container': {
            height: '100% !important',
        },
    },
    body: {
        overflow: 'hidden',
        display: 'flex',
        padding: theme.spacing(0.5),
        height: '100%',
    },
    group: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '12px',
        '&.form-group': {
            marginBottom: '0px',
        },
        '& label': {
            display: 'block',
            minWidth: 'fit-content',
            marginRight: '12px',
            marginBottom: '0px',
        },
    },
    textArea: {
        marginRight: theme.spacing(2),
        resize: 'none',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    invalidTemplateText: {
        border: '1px solid red',
    },
    loading: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 9,
        background: theme.palette.background.white,
    },
}));

const initState = {
    templateName: '',
    templateSubject: '',
    templateText: '',
    language: 'English',
    active: true,
    fields: [],
    templateType: TEMPLATE_TYPE_OPTION.EMAIL,
};

const ACTION_TYPES = {
    SET_FIELDS: 'setFields',
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_INITIAL_STATE: 'setInitialState',
    ON_CHANGE_TEMPLATE_TYPE: 'onChangeTemplateType',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.ON_CHANGE_VALUE: {
        return update(state, {
            [action.field]: { $set: action.payload },
        });
    }
    case ACTION_TYPES.SET_FIELDS: {
        return update(state, {
            fields: { $set: action.payload },
        });
    }
    case ACTION_TYPES.ON_CHANGE_TEMPLATE_TYPE: {
        return update(state, {
            templateType: { $set: action.payload },
            templateText: { $set: '' },
        });
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        const record = action.payload;
        return update(state, {
            templateName: { $set: record.templateName },
            templateType: { $set: record.templateType },
            templateSubject: { $set: record.templateSubject },
            templateText: { $set: record.templateText },
            messageTemplateId: { $set: record.messageTemplateId },
            language: { $set: record.language },
            active: { $set: record.active },
        });
    }
    default:
        return state;
    }
};

// * Remove refetch props when real time is integrated
const TemplateForm = ({
    open, record, onClose,
    isEditing, refetch,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const textareaRef = useRef();
    const classes = useStyles({ isMobile });
    const [state, dispatch] = useReducer(reducer, initState);
    const isSMSTemplate = state.templateType.toLowerCase() === Communication.SMS;
    const [updateCRMTemplate] = useMutation(EmailTemplateMutation.UPDATE_CRM_TEMPLATE);
    const [createCRMTemplate] = useMutation(EmailTemplateMutation.CREATE_CRM_TEMPLATE);
    const {
        data, error, loading,
    } = useQuery(EmailTemplateQuery.GET_CRM_TEMPLATE_FIELDS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VALUE,
            field,
            payload: value,
        });
    };
    const onChangeTemplateType = (value) => {
        if (state.templateType !== value) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_TEMPLATE_TYPE,
                payload: value,
            });
        }
    };

    useEffect(() => {
        if (isEditing) {
            dispatch({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                payload: record,
            });
        }
    }, [record, isEditing]);

    const onUpdate = async () => {
        try {
            const { fields, ...rest } = state;
            const response = await updateCRMTemplate({ variables: { input: rest } });

            if (response.data?.updateCRMTemplate) {
                onClose();
                // TODO: Remove this when implement real-time
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onCreate = async () => {
        try {
            const { fields, ...rest } = state;
            const response = await createCRMTemplate({ variables: { input: rest } });

            if (response.data?.createCRMTemplate) {
                onClose();
                // TODO: Remove this when implement real-time
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    useEffect(() => {
        if (!loading && data?.getCRMTemplateFields) {
            dispatch({
                type: ACTION_TYPES.SET_FIELDS,
                payload: data.getCRMTemplateFields.map((item) => item.displayAs),
            });
        }
    }, [data, loading, error]);

    const insertAtCursor = (input, textToInsert) => {
        const { value } = input;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const currentValue = value.slice(0, start) + textToInsert + value.slice(end);
        input.value = currentValue;
        input.selectionEnd = start + textToInsert.length;
        input.selectionStart = input.selectionEnd;

        onChangeValue('templateText', currentValue);
    };

    const onSelectField = (value) => {
        if (isSMSTemplate) {
            insertAtCursor(textareaRef.current, value);
        } else {
            joditInstance.selection.insertHTML(value);
        }
    };

    const title = `${isEditing ? 'Edit' : 'New'} Template`;
    const isValidData = StringUtils.isEmpty(state.templateText) || StringUtils.isEmpty(state.templateName);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                className: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title={title} onClose={onClose} iconSize="sm" />
            <DialogContent className={classes.dialogContent}>
                <div className={classes.header}>
                    <Form.Group className={classes.group}>
                        <Form.Label className="required">Template Name:</Form.Label>
                        <Form.Control
                            type="text"
                            size="sm"
                            maxLength={70}
                            placeholder="Template Name"
                            value={state.templateName || ''}
                            onChange={(e) => onChangeValue('templateName', e.target.value)}
                            className={clsx({ 'invalid-field': StringUtils.isEmpty(state.templateName) })}
                        />
                    </Form.Group>
                    {!isSMSTemplate && (
                        <Form.Group className={classes.group}>
                            <Form.Label className="required">Subject:</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                placeholder="Subject"
                                value={state.templateSubject}
                                maxLength={70}
                                onChange={(e) => onChangeValue('templateSubject', e.target.value)}
                            />
                        </Form.Group>
                    )}
                    {isEditing && (
                        <Form.Group className={classes.group}>
                            <Form.Label>Type:</Form.Label>
                            <Label color={isSMSTemplate ? 'success' : 'error'}>{state.templateType}</Label>
                        </Form.Group>
                    )}
                    {!isEditing && (
                        <Form.Group className={classes.group}>
                            <Form.Label>Type:</Form.Label>
                            <Select
                                size="sm"
                                name="templateType"
                                maxMenuHeight={100}
                                className={classes.select}
                                value={state.templateType}
                                options={templateTypeOption}
                                onChange={(_, value) => onChangeTemplateType(value)}
                            />
                        </Form.Group>
                    )}
                    <Form.Group className={classes.group}>
                        <Form.Label>Language:</Form.Label>
                        <Select
                            size="sm"
                            name="language"
                            maxMenuHeight={100}
                            className={classes.select}
                            value={state.language}
                            onChange={(name, value) => onChangeValue(name, value)}
                            options={Object.values(Language).map((l) => ({
                                value: l,
                                label: l,
                            }))}
                        />
                    </Form.Group>
                    <Form.Check
                        label="Active"
                        id="radio"
                        checked={state.active}
                        onChange={(e) => onChangeValue('active', e.target.checked)}
                    />
                </div>
                <div className={classes.body}>
                    {!isSMSTemplate && (
                        <div className={clsx(classes.htmlContainer, { [classes.invalidTemplateText]: StringUtils.isEmpty(state.templateText) })}>
                            <Jodit
                                value={state.templateText}
                                config={getConfiguration}
                                onChange={(newContent) => onChangeValue('templateText', newContent)}
                            />
                        </div>
                    )}
                    {isSMSTemplate && (
                        <Form.Control
                            as="textarea"
                            maxLength={550}
                            ref={textareaRef}
                            value={state.templateText}
                            className={clsx({ 'invalid-field': StringUtils.isEmpty(state.templateText) }, classes.textArea)}
                            placeholder="Enter your message"
                            onChange={(e) => onChangeValue('templateText', e.target.value)}
                        />
                    )}
                    <div className={classes.containerFields}>
                        <Form.Label>Fields</Form.Label>
                        <ul className={classes.listFields}>
                            {loading && <Loading className={classes.loading} />}
                            {state.fields.map((item, index) => {
                                const field = isSMSTemplate ? item.replace('[', '<').replace(']', '>') : item;
                                return (
                                    <li
                                        key={index}
                                        onDoubleClick={() => onSelectField(field)}
                                    >
                                        {field}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                disablePrimaryButton={isValidData}
                onClickPrimary={isEditing ? onUpdate : onCreate}
            />
        </Dialog>
    );
};

TemplateForm.propTypes = {
    record: PropTypes.object,
    isEditing: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

TemplateForm.defaultProps = {
    isEditing: false,
    record: {},
};

export default TemplateForm;
