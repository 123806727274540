import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import PhoneUtils from 'lib/PhoneUtils';
import StringUtils from 'lib/StringUtils';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerName } from 'utils/enum/ConversationEnum';
import {
    ListItem, ListItemAvatar, Avatar, Typography,
} from '@material-ui/core';
import If from 'components/widgets/conditional/If';
import { DIRECTION, STATUS } from 'utils/enum/MessageCenter';
import { modules } from 'utils/enum/modules';
import { Communication } from 'utils/enum/SubModule';

const useStyles = makeStyles((theme) => ({
    bodyConversation: {
        display: 'flex',
        width: '100%',
    },
    listItem: {
        backgroundColor: theme.palette.background.secondary,
        marginBottom: theme.spacing(3),
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3, 1.5),
        '&:last-child': {
            marginBottom: 0,
        },
        flexDirection: 'column',
        border: '2px solid transparent',
    },
    customerDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    customerName: {
        color: theme.palette.text.grayLabel,
        textTransform: 'capitalize',
    },
    nameLastConnection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cellPhone: {
        fontSize: '13px',
    },
    conversationSelected: {
        border: `2px solid rgb(${theme.palette.rgb.purple}, 0.5)`,
    },
    avatar: {
        backgroundColor: '#9668D3',
    },
    boldText: {
        fontWeight: 'bold',
        color: theme.palette.text.purpure,
    },
    newMessage: {
        width: 8,
        height: 8,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(24, 144, 255)',
    },
    lotname: {
        fontSize: '13px',
        position: 'absolute',
        bottom: '10px',
        right: '15px',
        padding: '2px 4px',
        background: '#dfdff6',
        borderRadius: '3px',
        color: '#777294',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
    },
}));

const MessageItem = ({ record, conversationId }) => {
    const classes = useStyles();
    const { smsChatId, sms } = record;
    const currentDate = DateUtils.toLocal(record.lastUpdated);
    const date = DateUtils.fromNow(currentDate);
    const { lotName } = sms.opportunity;
    const customerName = StringUtils.isEmpty(record.customer?.firstName) ? CustomerName.ANONYMOUS.toLowerCase() : record.customer.firstName;
    const shortCustomer = StringUtils.getCharacterFirstTwoWord(customerName);
    const isSelected = conversationId === smsChatId ? classes.conversationSelected : '';
    const cellPhone = record.customer?.cellPhone || PhoneUtils.formatPhone(record.cellPhone);
    const unreadMessage = sms.direction === DIRECTION.INCOMING && sms.status !== STATUS.READ;

    return (
        <ListItem
            className={clsx(classes.listItem, isSelected)}
            component={RouterLink}
            key={smsChatId}
            to={`/${modules.COMMUNICATION}/${Communication.SMS}/${record.smsChatId}`}
        >
            <div className={classes.bodyConversation}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        {shortCustomer}
                    </Avatar>
                </ListItemAvatar>
                <div className={classes.customerDetails}>
                    <div className={classes.nameLastConnection}>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            noWrap
                            className={clsx(classes.customerName, { [classes.boldText]: unreadMessage })}
                        >
                            {customerName}
                        </Typography>
                        <Typography
                            variant="caption"
                        >
                            {date}
                        </Typography>
                    </div>
                    <div className="d-flex-center-space-between">
                        <Typography
                            variant="subtitle2"
                            className={clsx(classes.cellPhone, { [classes.boldText]: unreadMessage })}
                            noWrap
                        >
                            {cellPhone}
                        </Typography>
                        <If condition={unreadMessage}>
                            <span className={classes.newMessage} />
                        </If>
                    </div>
                    <If condition={lotName !== null}><div className={classes.lotname}>{lotName}</div></If>
                </div>
            </div>
        </ListItem>
    );
};

MessageItem.propTypes = {
    record: PropTypes.object.isRequired,
    conversationId: PropTypes.string.isRequired,
};

export default MessageItem;
