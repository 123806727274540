import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';
import DateUtils from 'lib/DateUtils';
import { MESSAGE_STATUS, ERROR_MESSAGE } from 'utils/enum/core/Core';
import MessageStyles from 'styles/widgets/MessageStyles';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {
    makeStyles, Avatar, Typography, Tooltip,
} from '@material-ui/core';
import CreatedOnWithAvatar from 'components/modules/crm/activities/CreatedOnWithAvatar';
import { isFinite } from 'lodash';

const useStyle = makeStyles((theme) => MessageStyles.ShortMessageService(theme));
const getFailedMessage = (errorCode) => {
    const currentErrorCode = Number(errorCode);
    return isFinite(currentErrorCode) ? ERROR_MESSAGE[currentErrorCode] : null;
};

const ShortMessageService = ({ record }) => {
    const classes = useStyle();
    const isOutgoing = record.direction === 'Outgoing';
    const avatar = isOutgoing ? <CreatedOnWithAvatar record={record} hiddenDate className={classes.avatarOutgoing} /> : <Avatar className={classes.avatar} />;

    return (
        <div className={clsx(classes.main, { [classes.boxMessageOut]: isOutgoing })}>
            {avatar}
            <div className={clsx(classes.message, classes[`message${record.direction}`])}>
                <Typography
                    className={clsx(classes.text, classes[`text${record.direction}`])}
                >
                    <Linkify
                        options={{ target: '_blank' }}
                    >
                        {record.message}
                    </Linkify>
                    {record.status?.toUpperCase() === MESSAGE_STATUS.FAILED.toUpperCase()
                    && <Tooltip title={getFailedMessage(record.errorCode)}><PriorityHighIcon fontSize="small" className={classes.iconFail} /></Tooltip>}
                </Typography>
                <div className={classes.flexEnd}>
                    <Typography
                        className={clsx(classes.dateTime, classes[`text${record.direction}`])}
                    >
                        {DateUtils.fromNow(record.createdOn)}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

ShortMessageService.propTypes = {
    record: PropTypes.object.isRequired,
};

export default ShortMessageService;
