import React, { useContext, useState } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import ArrowForward from '@material-ui/icons/ArrowForward';
import UserContext from 'components/context/UserContext';

// Material UI
import {
    DialogContent, Dialog, Typography, Button, IconButton, Grid,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contentList: {
        flex: 1,
        overflow: 'auto',
        '& .customer-item': {
            border: `1px solid ${theme.palette.divider}`,
            fontSize: 14,
            borderBottom: 0,
            paddingRight: '16px',
        },
        '& .customer-item:last-child': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: 1,
        },
    },
    dialogWidth: {
        '& > div > div': {
            width: '600px',
        },
    },
    dialogContent: {
        padding: '15px 30px',
    },
    textItem: {
        fontWeight: 'bold',
        padding: theme.spacing(1),
        cursor: 'pointer',
    },
    noHover: {
        color: 'black',
        backgroundColor: 'transparent !important',
    },
}));

const ListLotToTransfer = ({ record, lotName, onSelectLot }) => {
    const classes = useStyles();
    // * * Improve this when using the lotId
    const listLot = record.filter((item) => item.lotName.trim().toLowerCase() !== lotName.trim().toLowerCase());

    return (
        <div className={classes.contentList}>
            { listLot.map((item) => (
                <Grid
                    container
                    className="show-icon-when-hovering customer-item"
                    justify="center"
                    alignItems="center"
                    key={item.lotId}
                >
                    <Grid item xs={10} container>
                        <Typography className={classes.textItem}>{item.lotName}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        container
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button
                                size="small"
                                className="show-hidden-item"
                                onClick={() => {
                                    onSelectLot(item);
                                }}
                            >
                                Transfer
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                disableRipple
                                aria-label="delete"
                                className={classes.noHover}
                                size="small"
                            >
                                <ArrowForward fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

ListLotToTransfer.propTypes = {
    record: PropTypes.array.isRequired,
    lotName: PropTypes.string.isRequired,
    onSelectLot: PropTypes.func.isRequired,
};

export { ListLotToTransfer };

const TransferLot = ({
    open, module, recordId, lotName,
    disableConfirmDialogPrimaryButton,
    disableConfirmDialogSecondaryButton,
    onTransfer, closeDialog, customTitle,
}) => {
    const classes = useStyles();
    const [lot, setLot] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const { userInformation } = useContext(UserContext);
    const userAvailableLots = userInformation?.lots || [];
    const description = `Do you want to transfer ${module} #${recordId}
        from ${lotName} to ${lot.lotName}?`;

    const onSelectLot = (value) => {
        setOpenConfirm(true);
        setLot(value);
    };

    return (
        <Dialog
            open={open}
            className={classes.dialogWidth}
            fullWidth
            maxWidth="xl"
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                title={customTitle || `Transfer ${module} To Another Lot`}
                onClose={closeDialog}
                iconSize="sm"
                titleVariant="h4"
            />
            <DialogContent className={classes.dialogContent} dividers>
                <ListLotToTransfer record={userAvailableLots} lotName={lotName} onSelectLot={onSelectLot} />
            </DialogContent>
            <DialogActions
                hiddenPrimaryButton
                onClickSecondary={closeDialog}
            />
            <ConfirmDialog
                title="Confirm transfer"
                description={description}
                open={openConfirm}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="No"
                onClose={() => setOpenConfirm(false)}
                onClickSecondary={() => setOpenConfirm(false)}
                onClickPrimary={() => {
                    onTransfer(lot);
                }}
                disablePrimaryButton={disableConfirmDialogPrimaryButton}
                disableSecondaryButton={disableConfirmDialogSecondaryButton}
            />
        </Dialog>
    );
};

TransferLot.propTypes = {
    customTitle: PropTypes.string,
    open: PropTypes.bool.isRequired,
    module: PropTypes.string.isRequired,
    lotName: PropTypes.string.isRequired,
    recordId: PropTypes.number.isRequired,
    onTransfer: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    disableConfirmDialogPrimaryButton: PropTypes.bool,
    disableConfirmDialogSecondaryButton: PropTypes.bool,
};

TransferLot.defaultProps = {
    customTitle: null,
    disableConfirmDialogPrimaryButton: false,
    disableConfirmDialogSecondaryButton: false,
};

export default TransferLot;
