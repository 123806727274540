/* eslint-disable no-undef */
import { has, find, map } from 'lodash';

// TODO: Create a singleton for this class;
export default class KeyStore {
    constructor() {
        this.tokenName = 'token';
        this.userId = 'userId';
        this.memoScope = 'memoScope';
        this.userLots = 'userLots';
        this.selectedLot = 'selectedLot';
        this.timeZones = 'timeZones';
        this.stage = 'stage';
        this.includeDeactivatedDeal = 'includeDeactivatedDeal';
        this.crmFilter = 'crmFilter';
        this.calendarFilter = 'calendarFilter';
        this.sidebarStatus = 'sideBarStatus';
        this.payrollChecksSplitSizes = 'payrollChecksSplitSizes';
        this.technicianCommissionSplitSize = 'technicianCommissionSplitSize';
        this.miscCommissionSplitSize = 'miscCommissionSplitSize';
        this.hoursWorkedSplitSize = 'hoursWorkedSplitSize';
        this.meteorToken = 'meteorToken';
        this.hashToken = 'hashToken';
    }

    save(record) {
        try {
            const {
                token, userId, scopes, lots, meteorToken, hashToken,
            } = record;
            const selectedLot = find(lots, { isDefault: 1 }) || {};
            localStorage.setItem(this.tokenName, token);
            localStorage.setItem(this.userId, userId);
            localStorage.setItem(this.memoScope, JSON.stringify(this.mapUserPermission(scopes)));
            localStorage.setItem(this.userLots, JSON.stringify(lots));
            localStorage.setItem(this.selectedLot, JSON.stringify(selectedLot));
            localStorage.setItem(this.meteorToken, meteorToken);
            localStorage.setItem(this.hashToken, hashToken);
            return true;
        } catch (e) {
            return false;
        }
    }

    setStage(stage = '') {
        localStorage.setItem(this.stage, JSON.stringify(stage));
    }

    removeStage() {
        localStorage.removeItem(this.stage);
    }

    getStage() {
        return JSON.parse(localStorage.getItem(this.stage));
    }

    getMeteorToken() {
        return localStorage.getItem(this.meteorToken);
    }

    getHashToken() {
        return localStorage.getItem(this.hashToken);
    }

    mapUserPermission(record) {
        const scopes = {};

        if (record) {
            record.forEach((x) => {
                scopes[x] = 1;
            });
        }

        return scopes;
    }

    getToken() {
        return localStorage.getItem(this.tokenName);
    }

    clear() {
        localStorage.removeItem(this.tokenName);
        localStorage.removeItem(this.userId);
        localStorage.removeItem(this.memoScope);
        localStorage.removeItem(this.userLots);
        localStorage.removeItem(this.selectedLot);
        localStorage.removeItem(this.timeZones);
        localStorage.removeItem(this.stage);
        localStorage.removeItem(this.crmFilter);
        localStorage.removeItem(this.calendarFilter);
        localStorage.removeItem(this.payrollChecksSplitSizes);
        localStorage.removeItem(this.technicianCommissionSplitSize);
        localStorage.removeItem(this.miscCommissionSplitSize);
        localStorage.removeItem(this.hoursWorkedSplitSize);
        localStorage.removeItem(this.hashToken);
        localStorage.removeItem(this.meteorToken);
    }

    /**
     * @Deprecated
     * @TODO: Remove the use of this method and use hasPermission
     */
    static isAllowed(scope) {
        return has(new KeyStore().getMemoScope(), scope);
    }

    hasPermission(scope) {
        return has(this.getMemoScope(), scope);
    }

    getUserId() {
        return parseInt(localStorage.getItem(this.userId), 10);
    }

    getMemoScope() {
        return JSON.parse(localStorage.getItem(this.memoScope));
    }

    getUserLots() {
        return JSON.parse(localStorage.getItem(this.userLots)) || [];
    }

    getSelectedLot() {
        return JSON.parse(localStorage.getItem(this.selectedLot));
    }

    getLotsName() {
        const currentLots = this.getUserLots();
        const lots = map(currentLots, (item) => item.lotName);

        return lots;
    }

    setTimeZones(timeZones) {
        localStorage.setItem(this.timeZones, timeZones);
    }

    getTimeZones() {
        localStorage.getItem(this.timeZones);
    }

    exists() {
        return !!this.getToken();
    }

    setSelectedLot(record) {
        localStorage.setItem(this.selectedLot, JSON.stringify(record));
    }

    setCRMFilter(record = {}) {
        const result = this.getCRMFilter() ? { ...this.getCRMFilter(), ...record } : record;
        localStorage.setItem(this.crmFilter, JSON.stringify(result));
    }

    getCRMFilter() {
        return JSON.parse(localStorage.getItem(this.crmFilter));
    }

    getCalendarFilter() {
        return JSON.parse(localStorage.getItem(this.crmFilter));
    }

    setCalendarFilter(record = {}) {
        const result = this.getCalendarFilter() ? { ...this.getCalendarFilter(), ...record } : record;
        localStorage.setItem(this.calendarFilter, JSON.stringify(result));
    }

    isSidebarOpened() {
        return localStorage.getItem(this.sidebarStatus) === 'true' || false;
    }

    setSidebarCollapsed(value) {
        localStorage.setItem(this.sidebarStatus, value);
    }

    getPayrollChecksSplitSize() {
        return JSON.parse(localStorage.getItem(this.payrollChecksSplitSizes));
    }

    setPayrollChecksSplitSize(sizes = [70, 30]) {
        localStorage.setItem(this.payrollChecksSplitSizes, JSON.stringify(sizes));
    }

    getTechnicianCommissionSplitSize() {
        return JSON.parse(localStorage.getItem(this.technicianCommissionSplitSize));
    }

    setTechnicianCommissionSplitSize(sizes = [70, 30]) {
        localStorage.setItem(this.technicianCommissionSplitSize, JSON.stringify(sizes));
    }

    getMiscCommissionSplitSize() {
        return JSON.parse(localStorage.getItem(this.miscCommissionSplitSize));
    }

    setMiscCommissionSplitSize(sizes = [70, 30]) {
        localStorage.setItem(this.miscCommissionSplitSize, JSON.stringify(sizes));
    }

    getHoursWorkedSplitSize() {
        return JSON.parse(localStorage.getItem(this.hoursWorkedSplitSize));
    }

    setHoursWorkedSplitSize(sizes = [70, 30]) {
        localStorage.setItem(this.hoursWorkedSplitSize, JSON.stringify(sizes));
    }
}
