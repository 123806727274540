import { gql } from '@apollo/client';

export default class CustomerMutate {
    static SAVE_VEHICLE_WISH = gql`
        mutation saveVehicleWish($conversationId: ID! $input: VehicleWishInput!) {
            saveVehicleWish(conversationId: $conversationId, input: $input) {
                vehicleWishId
            }
        }
    `;

    static UPDATE_VEHICLE_WISH = gql`
        mutation updateVehicleWish($vehicleWishId: ID! $input: VehicleWishInput!) {
            updateVehicleWish(vehicleWishId: $vehicleWishId, input: $input)
        }
    `;

    static REMOVE_VEHICLE_WISH = gql`
        mutation removeVehicleWish($vehicleWishId: ID!) {
            removeVehicleWish(vehicleWishId: $vehicleWishId)
        }
    `;

    static SET_FAVORITE_VEHICLE = gql`
        mutation setFavoriteVehicle($conversationId: ID!, $vehicleWishId: ID!, $isFavorite: Boolean!) {
            setFavoriteVehicle(conversationId: $conversationId, vehicleWishId: $vehicleWishId, isFavorite: $isFavorite)
        }
    `;

    static SAVE_CUSTOMER_INFORMATION = gql`
        mutation saveCustomerInformation($conversationId: ID!, $input:CustomerInformationInput!) {
            saveCustomerInformation(conversationId: $conversationId, input: $input)
        }
    `;

    static SET_CURRENT_ADDRESS = gql`
        mutation setCurrentAddress($customerId: ID!, $customerAddressId: ID!) {
            setCurrentAddress(customerId: $customerId, customerAddressId: $customerAddressId)
        }
    `;

    static SAVE_CUSTOMER_ADDRESS = gql`
        mutation createCustomerAddress($customerId: ID!, $input: CustomerAddressCreateInput!) {
            createCustomerAddress(customerId: $customerId, input: $input)
        }
    `;

    static DELETE_CUSTOMER_ADDRESS = gql`
        mutation deleteCustomerAddress($customerAddressId: ID!) {
            deleteCustomerAddress(customerAddressId: $customerAddressId)
        }
    `;

    static UPDATE_ADDRESS = gql`
        mutation updateCustomerAddress($customerId: ID!, $customerAddressId: ID!, $input: CustomerAddressCreateInput!) {
            updateCustomerAddress(customerId: $customerId, customerAddressId:$customerAddressId, input: $input)
        }
    `;

    static CREATE_CUSTOMER = gql`
        mutation createCustomer($input: CustomerInput!) {
            createCustomer(input: $input) {
                customerCode
                customerId
            }
        }
    `;

    static CREATE_FULL_CUSTOMER = gql`
        mutation createFullCustomer($input: CustomerFullInput!) {
            createFullCustomer(input: $input) {
                customerCode
                customerId
            }
        }
    `;

    static UPDATE_CUSTOMER = gql`
        mutation updateCustomer($customerId: ID!, $input: CustomerInput!) {
            updateCustomer(customerId: $customerId, input: $input)
        }
    `;

    static DELETE_CUSTOMER_EMPLOYMENT = gql`
        mutation deleteCustomerEmployment($customerEmploymentId: ID!) {
            deleteCustomerEmployment(customerEmploymentId: $customerEmploymentId)
        }
    `;

    static SET_CURRENT_EMPLOYMENT = gql`
        mutation setCurrentEmployment($customerId: ID!, $customerEmploymentId: ID!) {
            setCurrentEmployment(customerId: $customerId, customerEmploymentId: $customerEmploymentId)
        }
    `;

    static CREATE_CUSTOMER_EMPLOYMENT =gql`
        mutation createCustomerEmployment ($customerId: ID!, $input: CustomerEmploymentCreateInput!) {
            createCustomerEmployment(customerId: $customerId, input: $input)
        }
    `;

    static UPDATE_CUSTOMER_EMPLOYMENT = gql`
        mutation updateCustomerEmployment($customerId: ID!, $customerEmploymentId: ID!, $input: CustomerEmploymentUpdateInput!) {
            updateCustomerEmployment(customerId: $customerId, customerEmploymentId: $customerEmploymentId, input: $input)
        }
    `
}
