import { gql } from '@apollo/client';

export default class InventoryQuery {
    static GET_VEHICLE = gql`
        query getVehicleByStock($stockNumber: Int!) {
            getVehicleByStock(stockNumber: $stockNumber) {
                thumbnail
                stockNumber
                vin
                year
                make
                model
                trim
                extColor
                intColor
                miles
                tmu
                exempt
                eml
                engine
                transmission
                style
                drivetrain
                titleStatus
                title
                titleNumber
                spareKey
            }
        }
    `;

    static DECODE_VIN = gql`
        query decodeVin($vin: String!) {
            decodeVin(vin: $vin) {
                year
                make
                model
                trim
                exteriorColor {
                    colorName
                }
                drivetrain
                engines
                bodyStyle
            }
        }
    `;

    static GET_VEHICLES = gql`
        query getVehicles($filter: InventoryFilter!, $paginate: DataPaging!){
            getVehicles(filter: $filter, paginate: $paginate) {
                vehicles {
                    stockNumber
                    thumbnail
                    year
                    make
                    model
                    trim
                    vin
                    style
                    minimumPrice
                    miles
                    stickerPrice
                    minimumSalePrice
                    internetPrice
                }
                totalCount
            }
        }
    `;

    static GET_VEHICLE_LIST = gql`
        query listInventory($stockNumber: Int, $active: Boolean!) {
            listInventory(stockNumber: $stockNumber, active: $active) {
                year
                make
                model
                trim
                color: extColor
                thumbnail
            }
        }
    `;

    static GET_RANDOM_VIN = gql`
        query getRandomVIN{
            getRandomVIN
        }
    `;
}
