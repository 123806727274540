import { gql } from '@apollo/client';

export default class SettingsMutation {
    static CREATE_PURCHASED_FROM = gql`
        mutation createPurchasedFrom($name: String!) {
            createPurchasedFrom(name: $name)
        }
    `;

    static CREATE_PURCHASING_AGENT = gql`
        mutation createPurchasingAgent($name: String!) {
            createPurchasingAgent(name: $name)
        }
    `;

    static SAVE_MENU_SETTING = gql`
        mutation saveSideMenuSetting($userSettingId: ID, $key: String!, $value: String!) {
            saveSideMenuSetting(userSettingId: $userSettingId, key: $key, value: $value)
        }
    `;
}
