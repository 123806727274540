import CameraIcon from 'assets/inventory/camera-20.png';

export default class InventoryListStyle {
    static inventoryList(theme) {
        return ({
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            container: {
                height: '100%',
                padding: '10px',
                overflow: 'auto',
                background: theme.palette.background.default,
                '& > div': {
                    backgroundColor: 'initial',
                },
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        padding: '7px',
                    },
                    '& .DragHandleIcon': {
                        color: theme.palette.background.black,
                    },
                },
                '& .ReactVirtualized__Table__row': {
                    borderBottom: `0.5px solid ${theme.palette.border.ghost}`,
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
            hideList: {
                display: 'none',
            },
            colorColumn: {
                textTransform: 'capitalize',
            },
            columnStyle: {
                alignItems: 'center',
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
            },
            tags: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
            },
            imageLink: {
                display: 'block',
                textAlign: 'center',
                width: '100%',
                position: 'relative',
                border: '1px solid #FAFAFA',
            },
            toolbarMobile: {
                padding: '10px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
            topSection: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '20px',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    '& > div:nth-child(2)': {
                        paddingTop: '20px',
                    },
                },
                '& > div': {
                    display: 'flex',
                },
                '& > div > button': {
                    marginLeft: 10,
                },
            },
            bottomSection: {
                [theme.breakpoints.down('sm')]: {
                    marginTop: '-23px',
                },
            },
            searchBar: {
                position: 'absolute',
                top: 0,
                zIndex: 10,
                width: '77%',
                marginTop: '10px',
                marginLeft: 55,
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px',
                '& > *': {
                    marginRight: 10,
                },
                '& > button': {
                    minWidth: 104,
                },
            },
            lotWrapper: {
                marginTop: '5px',
                marginBottom: '5px',
                display: 'flex',
            },
            settingsContainer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '20px',
                '& > svg': {
                    fontSize: '30px',
                    fill: theme.palette.secondaryInfo.dark,
                    cursor: 'pointer',
                },
            },
            itemsCounter: {
                color: 'rgba(0, 0, 0, 0.65)',
                minWidth: 110,
                marginLeft: 15,
                height: 'fit-content',
            },
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
            },
            includeSold: {
                fontSize: 14,
                minWidth: 156,
            },
            circularProgressContainer: {
                textAlign: 'center',
            },
            stickerButton: {
                marginRight: '20px',
            },
            filterContainer: {
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                '& > div': {
                    position: 'absolute',
                    left: '5px',
                    width: '15%',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    '& svg': {
                        width: '13px',
                        height: '15px',
                        fill: theme.palette.text.waterloo,
                    },
                },
            },
            filtered: {
                fill: `${theme.palette.background.red} !important`,
            },
            negativeMarkUp: {
                fontWeight: 'bold',
                color: theme.palette.text.red,
            },
            progressContainer: {
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '100%',
                '& > div': {
                    width: '100%',
                    height: '15px',
                    border: `0.5px solid ${theme.palette.border.mercuryLight}`,
                    backgroundColor: theme.palette.background.white,
                },
            },
        });
    }

    static vehicleColors() {
        return ({
            colorsContainer: {
                textAlign: 'center',
                overflow: 'hidden',
            },
            colorColumn: {
                textTransform: 'capitalize',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
        });
    }

    static quickNoteEditable() {
        return ({
            content: {
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            circularProgress: {
                position: 'absolute',
                zIndex: 1,
            },
            truncate: {
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            input: {
                '& input': {
                    height: '8px',
                },
            },
        });
    }

    static vehicleWidget() {
        return ({
            mainInformation: {
                '& span': {
                    display: 'block',
                },
            },
            main: {
                display: 'flex',
                position: 'relative',
            },
            data: {
                flex: 1,
                lineHeight: '1.3',
                color: '#656586 !important',
            },
            model: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '155px',
            },
            notAdvertised: {
                color: 'white',
                backgroundColor: '#e87b2e',
                borderRadius: '5px',
                paddingTop: '1px',
                fontSize: '10px',
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
            },
            yearWholesaleContainer: {
                display: 'flex',
                width: '150px',
                justifyContent: 'space-between',
            },
            wholesaleLabel: {
                color: '#7f0202',
                fontWeight: 'bold',
            },
            secondColumn: {
                textAlign: 'right',
                '& > div:nth-child(2)': {
                    marginTop: '5px',
                },
            },
            bold: {
                fontWeight: 'bold',
            },
            stockCell: {
                color: '#515EA2',
            },
            photoInfo: {
                fontSize: 12,
                display: 'inline-block',
                verticalAlign: 'middle',
                whiteSpace: 'nowrap',
                marginTop: '5px',
                '& > .icon': {
                    padding: '9px 10px',
                    display: 'inline-block',
                    verticalAlign: 'sub',
                    backgroundImage: `url(${CameraIcon})`,
                    backgroundRepeat: 'no-repeat',
                    marginRight: 5,
                },
            },
            primaryColor: {
                color: '#656586 !important',
                textDecoration: 'none',
                '&:hover': {
                    color: '#40a9ff !important',
                },
            },
        });
    }

    static lotFilter() {
        return ({
            select: {
                width: 250,
                position: 'relative',
                paddingBottom: 0,
                paddingLeft: '5px',
            },
            doneButton: {
                border: '2px solid #3F51B5',
                flex: '.8',
                margin: 'auto',
                backgroundColor: 'rgb(63, 81, 181)',
                color: '#FFF',
                '&:hover': {
                    border: '2px solid #3F51B5',
                    backgroundColor: 'rgb(63, 81, 181)',
                },
            },
            doneButtonEqual: {
                flex: '.8',
                margin: 'auto',
            },
            buttonContainer: {
                height: 44,
                width: '100%',
                position: 'sticky',
                bottom: 0,
                left: 0,
                boxShadow: '1px -1px 15px #ddd',
                background: '#fff',
                display: 'flex',
            },
            menuItemStyle: {
                height: 35,
            },
        });
    }

    static stickersDialog(theme) {
        return ({
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            content: {
                padding: '20px 10px',
                '& > div > div:nth-child(1)': {
                    [theme.breakpoints.down(600)]: {
                        justifyContent: 'space-between',
                    },
                },
            },
            searchContainer: {
                justifyContent: 'space-between',
                [theme.breakpoints.down(960)]: {
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                },
            },
            EditFormRow: {
                justifyContent: 'space-between',
            },
            inputSearch: {
                maxWidth: 250,
                minWidth: 190,
                [theme.breakpoints.down(960)]: {
                    marginBottom: '10px',
                },
            },
            downSection: {
                marginTop: '20px',
                '& > div:nth-child(1)': {
                    [theme.breakpoints.down(600)]: {
                        width: '100%',
                    },
                },
            },
            label: {
                fontSize: '1.10rem',
                fontWeight: '500',
                lineHeight: '1.6',
                letterSpacing: '0.0075em',
            },
            formsContainer: {
                height: '550px',
                position: 'relative',
                overflowX: 'auto',
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    marginLeft: '0px',
                    padding: '7px 5px',
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            tableGrid: {
                [theme.breakpoints.down(600)]: {
                    width: '100%',
                },
            },
            tableContainer: {
                minWidth: '700px',
                overflow: 'hidden',
            },
            formsListContainer: {
                height: '92%',
                overflow: 'auto',
                marginTop: '10px',
                position: 'relative',
                '& > .loading-table': {
                    marginTop: '0px',
                },
            },
            noForms: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '30px',
                textAlign: 'justify',
            },
            listItemIcon: {
                minWidth: '34px',
            },
            formName: {
                '& > span': {
                    color: theme.palette.text.outerSpace,
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    lineHeight: '1.43',
                    letterSpacing: '0.01071em',
                },
            },
            buttonProgress: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
            },
            tableHeader: {
                textAalign: 'left',
                color: theme.palette.text.waterloo,
                borderRight: `1px solid ${theme.palette.border.ghost}`,
                height: '100%',
                alignItems: 'center',
            },
        });
    }

    static formsDialog(theme) {
        return ({
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            formsDialogContent: {
                padding: '20px',
            },
            innerSections: {
                height: '400px',
            },
            middleSection: {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            },
            label: {
                fontSize: '1.10rem',
                fontWeight: '500',
                lineHeight: '1.6',
                letterSpacing: '0.0075em',
            },
            paper: {
                height: '100%',
                overflowY: 'auto',
            },
            buttonWrapper: {
                position: 'relative',
                paddingRight: '36px',
            },
        });
    }

    static warrantiesDialog(theme) {
        return ({
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            dialogContent: {
                height: '550px',
                padding: '10px 0',
            },
            dialogContentTop: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& > div:nth-child(1)': {
                    width: '293px',
                },
            },
            fieldsContainer: {
                marginTop: '10px',
            },
            label: {
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '1.6',
                letterSpacing: '0.0075em',
            },
            innerDialogContent: {
                height: '80px',
                padding: '10px',
            },
            extraFields: {
                '& input': {
                    height: '15px',
                },
            },
            innerSection: {
                marginTop: '15px',
            },
        });
    }

    static tableSettingsDialog(theme) {
        return ({
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            main: {
                [theme.breakpoints.down('sm')]: {
                    overflowY: 'initial',
                },
            },
            tableSettingsDialogContent: {
                padding: '20px',
                [theme.breakpoints.down('sm')]: {
                    '& > div': {
                        flexDirection: 'column',
                    },
                    '& > div > div': {
                        padding: '0px !important',
                    },
                },
            },
            innerSections: {
                height: '400px',
            },
            middleSection: {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    height: '150px',
                },
            },
            label: {
                fontSize: '1.10rem',
                fontWeight: '500',
                lineHeight: '1.6',
                letterSpacing: '0.0075em',
            },
            paper: {
                height: '100%',
                overflowY: 'auto',
                '& > ul > div': {
                    paddingTop: '5px',
                    paddingBottom: '5px',
                },
            },
            actionsContainer: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: '42px',
                paddingRight: '42px',
                '& > div > span > button': {
                    marginRight: '5px',
                    '& svg': {
                        width: '20px',
                        height: '20px',
                        fill: theme.palette.background.white,
                    },
                },
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'initial',
                    '& > div:nth-child(1) > button': {
                        marginTop: '5px',
                        width: '143px',
                    },
                    '& > div:nth-child(2)': {
                        marginTop: '5px',
                        marginLeft: '0px',
                        '& > button': {
                            width: '143px',
                        },
                    },
                },
            },
        });
    }

    static advancedColumnFilter(theme) {
        return ({
            container: {
                zIndex: '999',
                backgroundColor: theme.palette.background.white,
                border: `1px solid ${theme.palette.border.ghost}`,
                borderRadius: '5px',
                padding: '10px',
                width: '250px',
            },
            valuesContainer: {
                position: 'relative',
                height: '200px',
                overflowX: 'hidden',
                overflowY: 'auto',
                marginBottom: '5px',
                marginTop: '5px',
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                '& > div': {
                    width: '100%',
                    fontSize: '12px',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '10px',
                    flexWrap: 'nowrap',
                    fontWeight: 'normal',
                    '& > input': {
                        marginRight: '10px',
                        cursor: 'pointer',
                        width: '17px',
                        height: '17px',
                    },
                    '& > span': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
            },
            actionsContainer: {
                '&  button': {
                    width: '49%',
                    fontSize: '12px',
                    borderRadius: 0,
                },
                '&  button:nth-child(1)': {
                    marginRight: '1%',
                },
            },
            loading: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
                backgroundColor: theme.palette.background.white,
                zIndex: '999',
                opacity: '0.8',
            },
            tabs: {
                display: 'flex',
                justifyContent: 'space-around',
                fontSize: '12px',
                marginBottom: '10px',
                marginTop: '5px',
                width: '100%',
                fontWeight: 'normal',
            },
            activeTab: {
                fontWeight: 'bold',
            },
            conditionFiltering: {
                marginTop: '15px',
                '& > div:nth-child(1), & > div:nth-child(3)': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    '& > span': {
                        marginBottom: '5px',
                    },
                    '& > div': {
                        width: '100%',
                        marginBottom: '5px',
                    },
                },
                '& > div:nth-child(3)': {
                    marginBottom: '10px',
                },
                '& > div:nth-child(2)': {
                    display: 'flex',
                    justifyContent: 'center',
                    '& > div > label > span:nth-child(2)': {
                        fontSize: '12px',
                    },
                },
            },
            input: {
                fontSize: '13px',
                '& > div:nth-child(1)': {
                    fontSize: '13px !important',
                    fontWeight: 'normal',
                },
            },
            '@global': {
                '.css-26l3qy-menu': {
                    minWidth: '150px',
                },
                '.css-26l3qy-menu div, .css-2b097c-menu div': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
                '.css-26l3qy-menu > div, .css-2b097c-menu > div': {
                    maxHeight: '130px',
                    overflowX: 'hidden',
                },
            },
        });
    }
}
