/* eslint-disable */
import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import { clone } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import ZipUtils from 'utils/ZipUtils';
import { DefaultCountry } from 'utils/enum/Customer';

// GraphQL
import CustomerMap from 'services/mapData/CustomerMap';
import GraphqlClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerService from 'services/modules/CustomerService';

const CreateCustomerContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);

        const newRecord = this.getInitState();
        const cities = [];
        if (newRecord && newRecord.city) {
            cities.push(newRecord.city);
        }

        this.graphqlClient = new GraphqlClient();
        this.customerService = new CustomerService();
        this.state = {
            zipData: [],
            record: newRecord,
            listCity: cities,
            openConfirm: false,
            isDecoderEnabled: false,
            isDecodingZip: false,
        };

        this.initBind();
    }

    onEnableDecoder() {
        const { state: { isDecoderEnabled } } = this;
        if (!isDecoderEnabled) {
            this.setState({ isDecoderEnabled: true });
        }
    }

    onChangeValue(field, value, section = 'generalInfo') {
        this.setState(({ record }) => {
            const newRecord = { ...record };
            newRecord[section][field] = value;
            return { record: newRecord };
        }, () => {
            if (field === 'zipCode' && value.length === 5) {
                const { state: { isDecoderEnabled } } = this;
                if (isDecoderEnabled) {
                    this.decodeZip(value, section);
                }
            }
        });
    }

    onSave() {
        const { state: { record }, props: { toggleCreateCustomer, onSetCustomer } } = this;

        delete record.address.listCity;
        delete record.employment.listCity;

        if(StringUtils.isEmpty(record.generalInfo.email)) record.generalInfo.email = null;

        const input = {
            generalInfo: {
                ...record.generalInfo,
                dob: DateUtils.getOnlyDate(record.generalInfo?.dob),
            },
            address: record.address,
            employment: record.employment,
        };

        this.customerService.createFullCustomer(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);

                    return;
                }

                if (data && data.createFullCustomer) {
                    const { createFullCustomer: { customerCode } } = data;
                    if (onSetCustomer) {
                        this.customerService.getCustomerAndAddress({customerCode})
                        .then((response) => {
                            const { data, graphQLErrors } = response;
                            if (graphQLErrors) {
                                ModalUtils.errorMessage(graphQLErrors);
            
                                return;
                            }
                            onSetCustomer(data);
                            toggleCreateCustomer();
                        });
                    }
                }
            });
    }

    decodeZip(zip = '', section) {
        const input = {
            zip,
        };

        this.setState({ isDecodingZip: true });
        this.graphqlClient
            .query(CustomerQuery.DECODE_ZIP_CODE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.decodeZip) {
                    const { decodeZip } = data;
                    const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                    if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                    const zipData = decodeZip;

                    this.setState(({ record, isDecoderEnabled }) => {
                        if (isDecoderEnabled) {
                            const newRecord = { ...record };
                            newRecord[section].city = '';
                            newRecord[section].county = '';
                            newRecord[section].state = '';
                            newRecord[section].listCity = listCity;
                            return {
                                listCity, zipData, record: newRecord, isDecodingZip: false,
                            };
                        }
                        return { listCity, zipData, isDecodingZip: false };
                    });

                    if (decodeZip.length === 1) {
                        const currentData = decodeZip[0];

                        this.setState(({ record }) => {
                            const newRecord = { ...record };
                            newRecord[section].city = currentData.city;
                            newRecord[section].county = currentData.county;
                            newRecord[section].state = currentData.state;

                            return { record: newRecord };
                        });
                    }
                }
            });
    }

    onChangeCity(field, value, section = 'address') {
        this.setState(({ record, zipData }) => {
            const newRecord = { ...record };
            if (zipData && zipData.length > 0) {
                const alreadyExist = zipData.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(value));
                if (!alreadyExist) {
                    const newData = clone(zipData[0]);
                    newData.city = value;
                    zipData.unshift(newData);
                }
            }

            const countyAndState = ZipUtils.getCountryAndStateByCity(value, zipData);
            newRecord[section].city = value;
            if (!StringUtils.isEmpty(countyAndState.county)) newRecord[section].county = countyAndState.county;
            if (!StringUtils.isEmpty(countyAndState.state)) newRecord[section].state = countyAndState.state;

            return { record: newRecord };
        });
    }

    getInitState() {
        return {
            generalInfo: {
                firstName: '',
                middleName: '',
                lastName: '',
                dob: null,
                ssn: '',
                dln: '',
                email: null,
                cellPhone: '',
                workPhone: '',
                homePhone: '',
                isBusiness: false,
                isTaxable: true,
            },
            address: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                county: '',
                zipCode: '',
                country: DefaultCountry.COUNTRY,
                start: '',
                end: null,
                mortgageOrRent: null,
                housingStatus: '',
                phone: '',
                listCity: [],
                action: '',
            },
            employment: {
                employer: '',
                title: '',
                workPhone: '',
                monthlyIncome: null,
                otherIncomeMonthly: 0,
                otherIncomeSource: '',
                end: null,
                start: '',
                isCurrentEmployment: true,
                address1: '',
                address2: '',
                zipCode: null,
                city: '',
                county: '',
                state: '',
                country: '',
                listCity: [],
            },
        };
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.decodeZip = this.decodeZip.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onEnableDecoder = this.onEnableDecoder.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onChangeValue={this.onChangeValue}
                onChangeCity={this.onChangeCity}
                onEnableDecoder={this.onEnableDecoder}
                decodeZip={this.decodeZip}
                onSave={this.onSave}
            />
        );
    }
};

CreateCustomerContainer.propTypes = {
    toggleCreateCustomer: PropTypes.func.isRequired,
    onSetCustomer: PropTypes.func,
};

CreateCustomerContainer.defaultProps = {
    onSetCustomer: null,
};

export default CreateCustomerContainer;
