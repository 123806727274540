import React from 'react';

// Material UI
import clsx from 'clsx';
import { Nav } from 'react-bootstrap';
import KeyStore from 'utils/KeyStore';
import StringUtils from 'lib/StringUtils';
import { modules } from 'utils/enum/modules';
import Permissions from 'utils/enum/Permissions';
import If from 'components/widgets/conditional/If';
import { AppBar, Toolbar } from '@material-ui/core';
import { Communication } from 'utils/enum/SubModule';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams, useHistory } from 'react-router-dom';
import NotificationBadge, { TYPE } from 'components/layout/NotificationBadge';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '48px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #eeeeee',
    },
    tab: {
        height: '100%',
    },
    navLink: {
        textDecoration: 'none',
        border: 'none !important',
        borderBottom: '2px solid transparent !important',
        color: `${theme.palette.text.minsk} !important`,
        fontWeight: '500',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '&.active': {
            borderBottom: `2px solid ${theme.palette.background.fruitSalad} !important`,
        },
    },
    nav: {
        border: 'none',
        height: '48px',
        '& .nav-item': {
            borderBottom: '2px solid transparent',
        },
        '& .active.nav-item': {
            borderBottom: '2px solid #4CAF50 !important',
        },
    },
}));
const keyStore = new KeyStore();
const Communications = () => {
    const classes = useStyles();
    const history = useHistory();
    const { submodule } = useParams();
    const urlSMS = `/${modules.COMMUNICATION}/${Communication.SMS}`;
    const urlMail = `/${modules.COMMUNICATION}/${Communication.MAIL}`;
    const CRM_MAILBOX_READ = keyStore.hasPermission(Permissions.CRM_MAILBOX_READ);
    const CRM_MESSAGE_CENTER_READ = keyStore.hasPermission(Permissions.CRM_MESSAGE_CENTER_READ);

    if (StringUtils.isEmpty(submodule)) {
        if (CRM_MESSAGE_CENTER_READ) {
            history.push(urlSMS);
        } else if (CRM_MAILBOX_READ) {
            history.push(urlMail);
        }
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar className="toolbar-base-layout">
                    <NotificationBadge type={TYPE.COMMUNICATION} />
                    <NotificationBadge type={TYPE.NOTIFICATION} />
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                <div className="d-flex">
                    <Nav variant="tabs" className={classes.nav}>
                        <If condition={CRM_MESSAGE_CENTER_READ}>
                            <Nav.Item className={clsx({ active: submodule === Communication.SMS })}>
                                <Link className={clsx(classes.navLink, 'nav-tabs nav-link')} to={urlSMS}>
                                    SMS
                                </Link>
                            </Nav.Item>
                        </If>
                        <If condition={CRM_MAILBOX_READ}>
                            <Nav.Item className={clsx({ active: submodule === Communication.MAIL })}>
                                <Link className={clsx(classes.navLink, 'nav-tabs nav-link')} to={urlMail}>
                                    Mailbox
                                </Link>
                            </Nav.Item>
                        </If>
                    </Nav>
                </div>
            </div>
        </>
    );
};

export default Communications;
