/* eslint-disable no-param-reassign */
import { round } from 'lodash';

export const ACTION_TYPES = {
    SET_FETCHED_RECORDS: 'setFetchedRecords',
    SET_RECORDS: 'setRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    SET_ON_DELETE: 'setOnDelete',
    SET_STATE_VALUES: 'setStateValues',
    VALIDATE_ADD_LINE: 'validateAddLine',
    CLEAR_LINES: 'clearLines',
    ADD_SELECTED_PARTS: 'addSelectedParts',
};

const PartsTicketDetailReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_FETCHED_RECORDS: {
        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.ADD_SELECTED_PARTS:
    {
        const parts = action.value;
        const { records, partsTicketPartId } = state;
        let currentIndex = partsTicketPartId;

        parts.forEach((data) => {
            currentIndex -= 1;
            const recordExist = records.find((c) => c.partInventoryId === data.partsInventoryId);
            if (recordExist) {
                records.forEach((item) => {
                    if (item.partInventoryId === data.partsInventoryId) {
                        item.quantity += 1;
                        item.discount = round((item.quantity * item.netPrice * ((action.discountPercent ?? 0) / 100)), 2);
                        item.bin = data.binNumber;
                        item.partCost = Number(data.partCost);
                        item.total = Number(item.quantity * item.netPrice);
                    }
                });

                return;
            }

            const discount = round((data.netPrice * ((action.discountPercent ?? 0) / 100)), 2);

            records.push({
                partsTicketPartId: currentIndex,
                ticketNumber: action.ticketNumber,
                partInventoryId: data.partsInventoryId,
                partNumber: data.partStockNumber,
                bin: data.binNumber,
                description: data.description,
                quantity: 1,
                listPrice: data.listPrice, // we should not use anymore
                netPrice: Number(data.netPrice), // this is the real listPrice
                partCost: Number(data.partCost),
                discount,
                total: Number(data.netPrice),
            });
        });

        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records,
            partsTicketPartId: currentIndex,
            openPartsSelect: false,
        };
    }
    case ACTION_TYPES.SET_RECORDS: {
        const newAmount = action.value.reduce((sum, next) => sum + next.amount, 0);
        if (action.notifyChange) action.notifyChange(newAmount);
        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records: action.value,
            partsTicketPartId: action?.newPartsTicketDetailId ?? state.partsTicketPartId,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const keyValue = 'partsTicketPartId';
        const {
            value, columnId, cell, discountPercent,
        } = action;

        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;

                if (columnId === 'quantity' || columnId === 'netPrice') {
                    newItem.discount = round((newItem.quantity * newItem.netPrice * ((discountPercent ?? 0) / 100)), 2);
                    newItem.total = Number(newItem.quantity * newItem.netPrice);
                }
            }

            return newItem;
        });

        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        return {
            ...state,
            idToDelete: action.value,
            isModalDeleteOpen: !state.isModalDeleteOpen,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.VALIDATE_ADD_LINE: {
        const { rowIndex, event } = action;

        if (rowIndex === state.records.length - 1) action.method(state.records, event);

        return {
            ...state,
        };
    }
    case ACTION_TYPES.CLEAR_LINES:
    {
        return {
            ...state,
            records: [],
            partsTicketPartId: -1,
        };
    }
    default:
        return state;
    }
};

export default PartsTicketDetailReducer;
