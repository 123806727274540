/* eslint-disable no-undef */
import 'moment-timezone';
import moment from 'moment';

class UserUtils {
    constructor() {
        if (!UserUtils.instance) {
            UserUtils.instance = this;
        }

        return UserUtils.instance;
    }

    getUserTimeZone(timeZones) {
        const { companyTimeZone, lotTimeZone } = { ...timeZones };
        const clientTimeZone = moment.tz.guess();

        if (lotTimeZone?.timeZoneId) return lotTimeZone?.timeZoneId;
        if (companyTimeZone?.timeZoneId) return companyTimeZone?.timeZoneId;
        return clientTimeZone;
    }
}

// Reference https://www.sitepoint.com/javascript-design-patterns-singleton/
const instance = new UserUtils();
Object.freeze(instance);

export default instance;
