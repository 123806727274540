import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
    Hidden,
    Drawer,
} from '@material-ui/core';
import DetailTab from 'components/modules/inventory/read/DetailTab';
import RepairsExpensesTab from 'components/modules/inventory/read/RepairsExpensesTab';
import CustomTabs from 'components/modules/inventory/read/CustomTabs';
import EquipmentTab from 'components/modules/inventory/read/EquipmentTab';
import HistoryTab from 'components/modules/inventory/read/HistoryTab';
import AdvertisingTab from 'components/modules/inventory/read/AdvertisingTab';
import KeyFeatureTab from 'components/modules/inventory/read/KeyFeatureTab';
import ReconTab from 'components/modules/inventory/read/ReconTab';
import MarketCompsTab from 'components/modules/inventory/read/MarketCompsTab';
import FilesManager from 'components/widgets/FilesManager';
import GpsTab from 'components/modules/inventory/read/GpsTab';

import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import StringUtils from 'lib/StringUtils';
import { Tabs as TABS } from 'utils/enum/InventoryEnum';
import { DOCUMENT_REFERENCE_TYPE } from 'utils/enum/Core';
import InventoryHelper from 'utils/InventoryHelper';

const useStyles = makeStyles((theme) => ({
    main: {
        height: '100%',
        background: theme.palette.background.white,
        position: 'relative',
        '& div.MuiTabs-root div.MuiTabs-flexContainer > a': {
            padding: '10px',
            minWidth: '65px',
            maxWidth: 'initial',
            '& > span': {
                fontSize: '12px',
            },
        },
    },
    appBar: {
        left: 0,
        position: 'sticky',
    },
    content: {
        overflow: 'auto',
        [theme.breakpoints.up('md')]: {
            maxHeight: '85vh',
        },
    },
}));
const finishEditing = () => { };
const getVehicleData = (record) => {
    const pricing = record.detail?.pricing || {};
    const { purchasedPrice, repairs, cost } = pricing;

    return {
        purchasedPrice,
        repairs,
        cost,
    };
};

const TabsContent = ({
    record, stockNumber, setRepairs, setEditedSection,
    openDrawer, toggleDrawer, setVehicleDetail, invUserTabName,
    setVehicleImages, defaultReconCost, onReload, blockingStatus,
    currentUserId, changeBlockingStatus,
}) => {
    const classes = useStyles();
    const location = useLocation();

    const keyStore = new KeyStore();
    const INVENTORY_RECON_READ = keyStore.hasPermission(Permission.INVENTORY_RECON_READ);
    const INVENTORY_RECON_WRITE = keyStore.hasPermission(Permission.INVENTORY_RECON_WRITE);
    const INVENTORY_MARKETCOMPS_READ = keyStore.hasPermission(Permission.INVENTORY_MARKETCOMPS_READ);
    const INVENTORY_MARKETCOMPS_WRITE = keyStore.hasPermission(Permission.INVENTORY_MARKETCOMPS_WRITE);
    const INVENTORY_DOCUMENTS_READ = keyStore.hasPermission(Permission.INVENTORY_DOCUMENTS_READ);
    const INVENTORY_DOCUMENTS_WRITE = keyStore.hasPermission(Permission.INVENTORY_DOCUMENTS_WRITE);
    const INVENTORY_DOCUMENTS_DELETE = keyStore.hasPermission(Permission.INVENTORY_DOCUMENTS_DELETE);

    const {
        userTabURL1,
        userTabURL2,
        images,
        thumbnail,
        detail = {},
    } = record;

    const hasUserTabURL1 = !StringUtils.isEmpty(userTabURL1);
    const hasUserTabURL2 = !StringUtils.isEmpty(userTabURL2);

    const availableTabs = { ...TABS };
    if (!INVENTORY_MARKETCOMPS_READ) delete availableTabs.MARKET_COMPS;
    if (!INVENTORY_RECON_READ) delete availableTabs.RECON;
    if (!INVENTORY_DOCUMENTS_READ) delete availableTabs.DOCUMENTS;
    if (!hasUserTabURL1) delete availableTabs.INVENTORY_USER_TAB_ONE;
    if (!hasUserTabURL2) delete availableTabs.INVENTORY_USER_TAB_TWO;

    const selectedTab = InventoryHelper.getSelectedTab(location.hash);
    const tabValue = Object.values(availableTabs).indexOf(selectedTab);
    const { invUserTabName1, invUserTabName2 } = invUserTabName;
    const {
        vin, trim, year,
        description,
    } = detail;

    const customTabs = (orientation, variation, onChange) => (
        <Tabs
            value={tabValue}
            orientation={orientation}
            indicatorColor="primary"
            textColor="primary"
            variant={variation}
            onChange={onChange}
        >
            <Tab
                wrapped
                label="DETAIL"
                href={TABS.DETAIL}
            />
            {INVENTORY_MARKETCOMPS_READ && (
                <Tab
                    wrapped
                    label="MARKET COMPS"
                    href={TABS.MARKET_COMPS}
                />
            )}
            <Tab
                wrapped
                label="REPAIRS"
                href={TABS.REPAIR}
            />
            <Tab
                wrapped
                label="ADVERTISING"
                href={TABS.ADVERTISING}
            />
            <Tab
                wrapped
                label="KEY FEATURES"
                href={TABS.FEATURE}
            />
            <Tab
                wrapped
                label="EQUIPMENT"
                href={TABS.EQUIPMENTS}
            />
            {INVENTORY_RECON_READ && (
                <Tab
                    wrapped
                    label="RECON"
                    href={TABS.RECON}
                />
            )}
            <Tab
                wrapped
                label="GPS"
                href={TABS.GPS}
            />
            {INVENTORY_DOCUMENTS_READ && (
                <Tab
                    wrapped
                    label="DOCUMENTS"
                    href={TABS.DOCUMENTS}
                />
            )}
            <Tab
                wrapped
                label="HISTORY"
                href={TABS.HISTORY}
            />
            {hasUserTabURL1 && (
                <Tab
                    wrapped
                    label={invUserTabName1}
                    href={TABS.INVENTORY_USER_TAB_ONE}
                />
            )}
            {hasUserTabURL2 && (
                <Tab
                    wrapped
                    label={invUserTabName2}
                    href={TABS.INVENTORY_USER_TAB_TWO}
                />
            )}
        </Tabs>
    );

    return (
        <div className={classes.main}>
            <Hidden smDown>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.appBar}
                >
                    {customTabs('horizontal', 'standard', finishEditing())}
                </AppBar>
            </Hidden>
            <Hidden mdUp>
                <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>

                    {customTabs('vertical', 'scrollable', toggleDrawer(false))}
                </Drawer>
            </Hidden>
            <div className={classes.content}>
                {selectedTab === TABS.DETAIL && (
                    <DetailTab
                        onReload={onReload}
                        stockNumber={stockNumber}
                        record={record}
                        setVehicleDetail={setVehicleDetail}
                        setEditedSection={setEditedSection}
                        defaultReconCost={defaultReconCost}
                        blockingStatus={blockingStatus}
                        currentUserId={currentUserId}
                        changeBlockingStatus={changeBlockingStatus}
                    />
                )}
                {selectedTab === TABS.MARKET_COMPS && INVENTORY_MARKETCOMPS_READ && record.detail && (
                    <MarketCompsTab
                        record={record}
                        setVehicleDetail={setVehicleDetail}
                        canWrite={INVENTORY_MARKETCOMPS_WRITE}
                    />
                )}
                {selectedTab === TABS.REPAIR && (
                    <RepairsExpensesTab
                        stockNumber={stockNumber}
                        setRepairs={setRepairs}
                        vehicleData={getVehicleData(record)}
                    />
                )}
                {selectedTab === TABS.ADVERTISING && (
                    <AdvertisingTab
                        stockNumber={stockNumber}
                        description={description}
                        thumbnail={thumbnail}
                        images={images}
                        setVehicleDetail={setVehicleDetail}
                        setVehicleImages={setVehicleImages}
                    />
                )}
                {selectedTab === TABS.FEATURE && (
                    <KeyFeatureTab stockNumber={stockNumber} />
                )}
                {selectedTab === TABS.EQUIPMENTS && (
                    <EquipmentTab
                        stockNumber={stockNumber}
                        vin={vin}
                        trim={trim}
                        year={year}
                    />
                )}
                {selectedTab === TABS.RECON && INVENTORY_RECON_READ && (
                    <ReconTab
                        stockNumber={stockNumber}
                        record={record}
                        defaultReconCost={defaultReconCost}
                        canWrite={INVENTORY_RECON_WRITE}
                    />
                )}
                {selectedTab === TABS.GPS && (
                    <GpsTab
                        onReload={onReload}
                        record={record}
                        canWrite={INVENTORY_RECON_WRITE}
                    />
                )}
                {selectedTab === TABS.DOCUMENTS && INVENTORY_DOCUMENTS_READ && (
                    <FilesManager
                        referenceId={stockNumber}
                        referenceType={DOCUMENT_REFERENCE_TYPE.INVENTORY}
                        maxFileSize={6291456} // 6MB
                        allowedFiles="image/png, image/jpeg, application/pdf"
                        pullDocumentsQuery={InventoryQuery.PULL_AVAILABLE_DOCUMENTS}
                        getDocumentUploadSignedURL={InventoryQuery.GET_DOCUMENT_UPLOAD_SIGNED_URL}
                        getDocumentSecureURL={InventoryQuery.GET_DOCUMENT_SECURE_URL}
                        createDocumentsMutation={InventoryMutation.CREATE_DOCUMENTS}
                        editDocumentNameMutation={InventoryMutation.EDIT_DOCUMENT_NAME}
                        deleteMutation={InventoryMutation.DELETE_DOCUMENT}
                        canWrite={INVENTORY_DOCUMENTS_WRITE}
                        canDelete={INVENTORY_DOCUMENTS_DELETE}
                    />
                )}
                {selectedTab === TABS.HISTORY && (
                    <HistoryTab
                        stockNumber={stockNumber}
                    />
                )}
                {selectedTab === TABS.INVENTORY_USER_TAB_ONE && hasUserTabURL1 && (
                    <CustomTabs url={userTabURL1} title={invUserTabName1} />
                )}
                {selectedTab === TABS.INVENTORY_USER_TAB_TWO && hasUserTabURL2 && (
                    <CustomTabs url={userTabURL2} title={invUserTabName2} />
                )}
            </div>
        </div>
    );
};

TabsContent.defaultProps = {
    invUserTabName: {},
    defaultReconCost: 0,
    blockingStatus: null,
    currentUserId: 0,
    changeBlockingStatus: () => null,
};

TabsContent.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    record: PropTypes.object.isRequired,
    setRepairs: PropTypes.func.isRequired,
    setVehicleImages: PropTypes.func.isRequired,
    openDrawer: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    setVehicleDetail: PropTypes.func.isRequired,
    setEditedSection: PropTypes.func.isRequired,
    invUserTabName: PropTypes.object,
    defaultReconCost: PropTypes.number,
    onReload: PropTypes.func.isRequired,
    blockingStatus: PropTypes.object,
    currentUserId: PropTypes.number,
    changeBlockingStatus: PropTypes.func,
};

export default TabsContent;
