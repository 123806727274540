import { gql } from '@apollo/client';

export default class AccountingSettingsQuery {
    static GET_CUSTOMER_BANKING_SETTINGS_LIST = gql`
        query getCustomerBankingSettings($isRepoRefund: Boolean, $isPaymentRefund: Boolean){
            getCustomerBankingSettings(isRepoRefund: $isRepoRefund, isPaymentRefund: $isPaymentRefund) {
                customers {
                    customerCode
                    customerName
                    address
                    accountNumber
                    customerRefundAmount
                }
                mapSetting {
                    accountId
                    accountDescription
                    controlledBy
                } 
            }
        }
    `;

    static GET_INVENTORY_PURCHASED_CREDIT_ACCOUNTS = gql`
    query getInventoryPurchasedCreditAccounts{
        getInventoryPurchasedCreditAccounts
    }
    `;

    static GET_ACCOUNTING_DEFAULT_SETTINGS = gql`
    query getAccountingDefaultSettings{ 
        getAccountingDefaultSettings{
        accountsPayable
        defaultBankChecks
        defaultCheckType
        accountingLockDate
        editableCheckNumbers
        postVehicleAccounting
    }}`;
}
