import { orderBy } from 'lodash';
import DealMap from 'services/mapData/DealMap';
import LotQuery from 'services/graphQL/query/LotQuery';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

export default class DealService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Create a Deal
     * @param {Object} deal Information of the deal to insert.
     * @param {number} deal.buyerCode
     * @param {number} deal.stockNumber
     * @param {number} [deal.coBuyerCode]
     * @param {string} [deal.lotName]
     * @param {Object} deal.dealStructure dealStructure - main structure of the deal
     * @param {string} deal.dealStructure.dealType
     * @param {number} deal.dealStructure.price
     * @param {number} deal.dealStructure.cashDownPayment
     * @param {Object[]} deal.dealStructure.deferredDownPayment deferredDownPayment - array of deferredDownPayment structure
     * @param {number} deal.dealStructure.deferredDownPayment.paymentNumber
     * @param {number} deal.dealStructure.deferredDownPayment.amount
     * @param {string} deal.dealStructure.deferredDownPayment.dueDate
     * @returns {Promise<Object>}
     */
    async create(deal) {
        return this.graphqlClient.mutate(DealsMutate.CREATE_DEAL, { input: deal });
    }

    /**
     * Retrieve a deal
     * @param {object} input
     * @param {number} input.accountNumber
     * @returns {Promise<Object>}
     */
    async get(input) {
        return this.graphqlClient.query(DealsQuery.GET_DEAL, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getDeal } = data;
                const newData = DealMap.mapDetail(getDeal);

                return {
                    data: { ...newData },
                };
            });
    }

    /**
     * Get a list of deals with pagination, sorting and optional filter
     * @param {Object} paginate
     * @param {Object} sort
     * @param {Object} filter
     * @returns {Promise<any|void>}
     */
    async getList(paginate, sort, filter = {}) {
        const input = {
            paginate,
            sort,
            filter,
        };

        return this.graphqlClient
            .query(DealsQuery.GET_DEALS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getDeals } = data;

                return {
                    data: getDeals,
                };
            });
    }

    /**
     * update a Deal
     * @param {Object} input
     * @param {number} input.accountNumber
     * @param {Object} input.deal dealStructure - main structure of the deal
     */
    async update(input) {
        return this.graphqlClient
            .mutate(DealsMutate.UPDATE_DEAL, input);
    }

    /**
     * Create a tradeIn
     * @param {Object} data
     * @param {number} data.dealId
     * @param {Object} data.input input - structure of tradeIn
     * @param {number} data.input.acv
     * @param {string} data.input.address
     * @param {number} data.input.allowance
     * @param {string} data.input.city
     * @param {string} data.input.name
     * @param {boolean} data.input.noSalesTaxCredit
     * @param {string} data.input.notes
     * @param {number} data.input.payOff
     * @param {boolean} data.input.payOffGoodTill
     * @param {string} data.input.phone
     * @param {string} data.input.state
     * @param {Object} data.input.vehicle
     * @param {string} data.input.zip
    */
    async createTradeIn(data) {
        return this.graphqlClient
            .mutate(DealsMutate.CREATE_TRADE_IN, data);
    }

    /**
     * Save a product
     * @param {Object} input
     * @param {Object[]} input.data
     * @param {boolean} input.data.addToSellingPrice
     * @param {number} input.data.cost
     * @param {number} input.data.dealId
     * @param {string} input.data.dealProductId
     * @param {string} input.data.policyNumber
     * @param {number} input.data.price
     * @param {number} input.data.productId
     * @param {string} input.data.type
     * @param {number} input.data.vendorId
    */
    async saveProduct(input) {
        return this.graphqlClient
            .mutate(DealsMutate.SAVE_PRODUCT, input);
    }

    /**
     * Save we owe
     * @param {Object[]} data
     * @param {number} data.dealNumber
     * @param {Object[]} data.data
     * @param {Object[]} data.deleted
    */
    async saveWeOwe(data) {
        return this.graphqlClient
            .mutate(DealsMutate.SAVE_WE_OWE, data);
    }

    async getWeOweList(accountNumber) {
        const input = {
            dealNumber: accountNumber,
        };

        return this.graphqlClient
            .query(DealsQuery.GET_DEAL_WEOWE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getWeOweList } = data;
                return {
                    data: getWeOweList || [],
                };
            });
    }

    /**
     * Deal Subscribe
     * @param {number} accountNumber
     * @param {function} callback
     */
    async dealSubscribe(callback, accountNumber) {
        const input = {
            accountNumber,
        };

        const responseSubscription = (record) => {
            const { data } = record;

            if (data && data.dealsChanged) {
                const { dealsChanged } = data;
                const newData = DealMap.mapDetail(dealsChanged);

                return callback({
                    data: { ...newData },
                });
            }

            return callback({});
        };

        return this.graphqlClient.subscribe(responseSubscription, DealSubscription.DEAL_CHANGED, input)
            .then((response) => response);
    }

    async getVehicleByStock(stockNumber) {
        const input = {
            stockNumber,
        };

        return this.graphqlClient.query(DealsQuery.GET_VEHICLE_MILES, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return { ...data };
            });
    }

    async getInsurance(dealId) {
        const input = {
            dealId,
        };

        return this.graphqlClient
            .query(DealsQuery.GET_INSURANCE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return response;
                }

                const { getInsurance } = data;
                return {
                    data: getInsurance,
                };
            });
    }

    /**
     * Deal Flag
     * @param {number} dealId
     * @param {string} flag
     */
    async setDealFlag(data) {
        return this.graphqlClient
            .mutate(DealsMutate.FLAG_DEAL, data);
    }

    /**
     * Remove a trade-in by id
     * @param {Object} input
     * @param {number} input.dealVehicleTradeId
     * @returns {Promise<*|FetchResult<any>>}
     */
    async removeTradeIn(input) {
        return this.graphqlClient
            .mutate(DealsMutate.REMOVE_TRADE_IN, input);
    }

    /**
     * Delete Product
     * @param {object} input
     * @param {uniqueidentifier} input.dealProductId
     * @returns {Promise<Object>}
    */
    async deleteProduct(input) {
        return this.graphqlClient
            .mutate(DealsMutate.DELETE_PRODUCT, input);
    }

    /**
     * Get a list of stage transitions
     * @returns {Promise<*>}
     */
    async getStagesTransitionList() {
        return this.graphqlClient
            .query(DealsQuery.GET_STAGES_TRANSITION_LIST);
    }

    /**
     * Transfer a deal to another lot
     * @param dealId
     * @param lotName
     * @returns {Promise<*|FetchResult<any>>}
     */
    async transferDeal(dealId, lotName) {
        const input = {
            dealId,
            lotName,
        };

        return this.graphqlClient
            .mutate(DealsMutate.TRANSFER_DEAL, input);
    }

    async getDealReferenceList(input) {
        return this.graphqlClient.query(DealsQuery.GET_DEAL_REFERENCES, input);
    }

    /**
     * @param {Object} input
     * @param {string} input.lotName Account lot name
    */
    async lotClosingDate(input) {
        return this.graphqlClient
            .query(LotQuery.LOT_CLOSING_DATE, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.accountNumber
     * @param {string} input.postDate
    */
    async postAccount(input) {
        return this.graphqlClient
            .mutate(DealsMutate.POST_ACCOUNT, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.dealId
    */
    async deactivateDeal(input) {
        return this.graphqlClient
            .mutate(DealsMutate.DEACTIVATE_DEAL, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.dealId
     * @param {number} input.stockNumber
    */
    async changeDealVehicle(input) {
        return this.graphqlClient
            .mutate(DealsMutate.CHANGE_DEAL_VEHICLE, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.accountNumber
    */
    async getDealVehicles(input) {
        return this.graphqlClient
            .query(DealsQuery.GET_DEAL_VEHICLES, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getDealVehicles } = data;

                return {
                    data: {
                        ...getDealVehicles,
                    },
                };
            });
    }

    /**
     * Update trade-in by id
     * @param {Object} input
     * @param {number} input.dealVehicleTradeId
     * @param {Object} input.input
     * @returns {Promise<*|FetchResult<any>>}
     */
    async updateTradeIn(input) {
        return this.graphqlClient.mutate(DealsMutate.UPDATE_TRADE_IN, input);
    }

    /**
     * Set a new deal status
     * @param {Object} input
     * @param {number} input.accountNumber
     * @param {string} input.stageId
     * @returns {Promise<*|FetchResult<any>>}
     */
    async setDealStatus(input) {
        return this.graphqlClient
            .mutate(DealsMutate.SET_DEAL_STATUS, input);
    }

    /**
     * @param {Object} input
    */
    async createReference(input) {
        return this.graphqlClient
            .mutate(DealsMutate.CREATE_REFERENCE, input);
    }

    /**
    * @param {Object} input
    */
    async updateReference(input) {
        return this.graphqlClient
            .mutate(DealsMutate.UPDATE_REFERENCE, input);
    }

    /**
    * @param {Object} input
    */
    async deleteReference(input) {
        return this.graphqlClient

            .mutate(DealsMutate.DELETE_REFERENCE, input);
    }

    async getDealReference(input) {
        return this.graphqlClient.query(DealsQuery.GET_REFERENCE, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.dealId
     * @param {string} input.customerId
    */
    async assignBuyer(input) {
        return this.graphqlClient.query(DealsMutate.ASSIGN_BUYER, input);
    }

    /**
     * @param {Object} input
     * @param {Object} input.paginate
     * @param {numeral} input.paginate.start
     * @param {numeral} input.paginate.limit
     * @param {Object} input.filter
     * @param {string} input.filter.searchTerm
     * @param {bool} input.filter.excludeBusiness
    */
    async getCustomers(input) {
        return this.graphqlClient
            .query(DealsQuery.GET_CUSTOMERS, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.dealId
     * @param {string} input.customerId
    */
    async assignCoBuyer(input) {
        return this.graphqlClient.mutate(DealsMutate.ASSIGN_CO_BUYER, input);
    }

    /**
     * add previous employment on deal
     * @param {Object[]} data
     * @param {number} data.dealId - DealId(AccountNumber)
     * @param {string} data.customerEmploymentId
     * @param {string} data.customerId
    */
    async addPreviousEmployment(data) {
        return this.graphqlClient
            .mutate(DealsMutate.ADD_EMPLOYMENT_TO_PREVIOUS_LIST, data);
    }

    /**
     * add previous employment on deal
     * @param {String} dealPreviousEmploymentId
    */
    async removePreviousEmployment(dealPreviousEmploymentId) {
        return this.graphqlClient
            .mutate(DealsMutate.REMOVE_EMPLOYMENT_FROM_PREVIEWS, { dealPreviousEmploymentId });
    }

    /**
     * add address to previous list on deal
     * @param {Object} data
     * @param {number} data.accountNumber
     * @param {string} data.customerAddressId
     * @param {string} data.customerId
    */
    async addAddressToPreviousList(data) {
        return this.graphqlClient
            .mutate(DealsMutate.ADD_ADDRESS_TO_PREVIOUS_LIST, data);
    }

    /**
     * get buyer by accountNumber
     * @param {number} accountNumber
    */
    async getBuyer(accountNumber) {
        const input = {
            accountNumber,
        };

        return this.graphqlClient.query(DealsQuery.GET_DEAL_CUSTOMER, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                if (!data || !data.getDealCustomer) {
                    return { data };
                }

                const {
                    getDealCustomer: {
                        buyer, buyerAddress, buyerEmployments, deal,
                        coBuyer, coBuyerAddress, coBuyerEmployments,
                    },
                } = data;

                // Buyer map
                const currentBuyerEmployments = orderBy(buyerEmployments, ['isCurrentEmployment'], ['desc']);
                const currentBuyerAddress = orderBy(buyerAddress, ['isCurrentAddress'], ['desc']);

                // Co Buyer map
                const currentCoBuyerEmployments = orderBy(coBuyerEmployments, ['isCurrentEmployment'], ['desc']);
                const currentCoBuyerAddress = orderBy(coBuyerAddress, ['isCurrentAddress'], ['desc']);

                const newData = {
                    buyer: buyer || {},
                    buyerAddress: currentBuyerAddress || [],
                    buyerEmployments: currentBuyerEmployments || [],
                    coBuyer: coBuyer || {},
                    coBuyerAddress: currentCoBuyerAddress || [],
                    coBuyerEmployments: currentCoBuyerEmployments || [],
                    deal,
                };

                return {
                    data: { ...newData },
                };
            });
    }

    /**
     * To fetch a list of products
     * @param {object} args
     * @param {number} args.dealId
    */
    getProducts(args) {
        return this.graphqlClient
            .query(DealsQuery.GET_PRODUCTS, args);
    }

    /**
     * To fetch a list of products
     * @param {object} input
     * @param {number} input.dealId
     */
    async getDealRecap(input) {
        return this.graphqlClient
            .query(DealsQuery.GET_DEAL_RECAP, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const newRecord = DealMap.mapDealRecap(data?.getDealRecap);

                return { data: newRecord };
            });
    }

    /**
     * remove address to previous list on deal
     * @param {string} dealPreviousAddressId
    */
    async removeAddressToPreviousList(dealPreviousAddressId) {
        return this.graphqlClient
            .mutate(DealsMutate.REMOVE_ADDRESS_FROM_PREVIOUS, {
                dealPreviousAddressId,
            });
    }

    /**
     * Deal Subscribe
     * @param {Object} input
     * @param {number} input.dealId
     * @param {function} callback
    */
    async dealRecapSubscribe(callback, input) {
        const responseSubscription = (record) => {
            const { data } = record;

            if (data && data.dealRecapUpdated) {
                const newRecord = DealMap.mapDealRecap(data?.dealRecapUpdated);

                return callback({
                    data: newRecord,
                });
            }

            return callback({});
        };

        return this.graphqlClient.subscribe(responseSubscription, DealSubscription.DEAL_RECAP_UPDATED, input)
            .then((response) => response);
    }

    /**
     * @param {Object} input
     * @param {Object} input.paginate
     * @param {numeral} input.paginate.start
     * @param {numeral} input.paginate.limit
     * @param {string} input.filter
    */
    async getDealFromCRM(input) {
        return this.graphqlClient
            .query(DealsQuery.GET_DEAL_FROM_CRM, input);
    }

    /**
     * @param {Object} input
     * @param {numeral} input.init
     * @param {numeral} input.limit
     * @param {string} input.filter
     * @param {Boolean} input.isNeo
    */
    async getDealFromNEO(input) {
        return this.graphqlClient
            .query(DealsQuery.GET_DEAL_FROM_NEO, input);
    }

    async createDealFromNEO(deal) {
        return this.graphqlClient.mutate(DealsMutate.CREATE_DEAL_FROM_NEO, { input: deal });
    }

    /**
     * @param {Object} input
     * @param {Object} input.filter
     * @param {String} input.filter.searchTerm
     * @param {Boolean} input.filter.includeDeactivated
     * @param {String} input.filter.status
     * @param {Object} input.paginate
     * @param {Number} input.paginate.init
     * @param {Number} input.paginate.limit
     * @param {Object} input.sort
     * @param {String} input.sort.columnName
     * @param {String} input.sort.dir
    */
    async getDeals(input) {
        return this.graphqlClient.query(DealsQuery.GET_DEALS, input);
    }

    /**
     * Set a new deal status
     * @param {Object} input
     * @param {number} input.dealId
     * @param {string} input.customerAddressId
     * @returns {Promise<*|FetchResult<any>>}
     */
    async setDealCurrentAddress(input) {
        return this.graphqlClient
            .mutate(DealsMutate.SET_DEAL_CURRENT_ADDRESS, input);
    }

    async removeCoBuyer(dealId) {
        return this.graphqlClient
            .mutate(DealsMutate.REMOVE_COBUYER, { dealId });
    }

    async swapBuyerCoBuyer(dealId) {
        return this.graphqlClient
            .mutate(DealsMutate.SWAP_BUYER_COBUYER, { dealId });
    }

    /**
    * @param {Object} input
    */
    async updateInsurance(input) {
        return this.graphqlClient
            .mutate(DealsMutate.UPDATE_INSURANCE, input);
    }

    async createCustomerFromNEO(customerApplicationId, isBuyer) {
        return this.graphqlClient
            .mutate(DealsMutate.CREATE_CUSTOMER_FROM_NEO, { customerApplicationId, isBuyer });
    }

    async sendToRFC(targetCompanyCode, accountNumber) {
        return this.graphqlClient
            .mutate(DealsMutate.SEND_TO_RFC, { targetCompanyCode, accountNumber });
    }

    async getLeadSources() {
        return this.graphqlClient.query(DealsQuery.GET_LEAD_SOURCE_LIST)
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const leadSourceList = DealMap.mapLeadSource(data?.getLeadSourceList);

                return {
                    data: leadSourceList,
                };
            });
    }

    async refetchNeo() {
        return this.graphqlClient.query(DealsQuery.PROCESS_NEO_APPLICATION)
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return {
                    data,
                };
            });
    }
}
