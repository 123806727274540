import React, { PureComponent } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Select from 'components/widgets/Select';
import Header from 'components/widgets/Header';
import DealStyles from 'styles/modules/DealStyles';
import Container from 'components/widgets/Container';
import ExpandRow from 'components/modules/lead/list/ExpandRow';
import { withRouter } from 'react-router-dom';
import DealItems from 'components/modules/deals/list/DealItems';
import InfiniteScroll from 'components/widgets/InfiniteScroll';

import DealListContainer from 'components/containers/deals/list/DealListContainer';
import PostToAccounting from 'components/modules/deals/create/dealTab/PostToAccounting';
import { Accordion } from 'components/widgets/Accordion';
import { Form } from 'react-bootstrap';
import InputSearch from 'components/widgets/InputSearch';
import LotFilter from 'components/modules/inventory/list/LotFilter';
import TransferLot from 'components/widgets/transfer/TransferLot';
import TransferToRFC from 'components/widgets/transfer/TransferToRFC';

// Material UI
import { Button } from '@material-ui/core';
import {
    withStyles, fade,
} from '@material-ui/core/styles';
import { SwapHorizIcon } from 'components/icons';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { DealStatus, PostedStatus, TagAgencyStatus } from 'utils/enum/DealEnum';
import DealTransferAccept from 'components/widgets/transfer/DealTransferAccept';
import SecureCloseDialog from 'components/widgets/transfer/SecureCloseDialog';

const styles = (theme) => DealStyles.dealList(theme, fade);

const ALL_LOTS = 'All Lots';

const filterSortDealStatus = (record) => {
    const statusToDisplay = [DealStatus.QUOTE, DealStatus.FNI, DealStatus.ACCOUNTING, DealStatus.ARCHIVED];
    return record.length > 0 ? statusToDisplay.map((currentStatus) => record.find((item) => currentStatus === item?.stageFrom)) : [];
};

const postedFilterOptions = Object.keys(PostedStatus).map((item) => ({ value: PostedStatus[item], label: PostedStatus[item] }));
const tagAgencyFilterOptions = Object.keys(TagAgencyStatus).map((item) => ({ value: TagAgencyStatus[item], label: TagAgencyStatus[item] }));

class DealList extends PureComponent {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_WRITE);
        this.SALES_DEAL_TRANSFER_TO_RFC = keyStore.hasPermission(Permission.SALES_DEAL_TRANSFER_TO_RFC);
    }

    componentDidMount() {
        const { props } = this;

        props.getServicesData();
        props.getUserAvailableLots();
        props.getStagesTransitionList();
    }

    getBackground(record) {
        const { props: { classes } } = this;

        if (record.flag) {
            return classes[`rowFlagged${record.flag}`];
        }

        return classes.backgroundRow;
    }

    renderList() {
        const {
            props: {
                classes, contentList: { records, totalCount },
                load, loadMore, toggleModal, stagesTransitionList, stages,
                onOpenModal, timeZone, toggleTransferToRFCDialog,
                toogleSecureCloseDialog, secureCloseEnabled,
            },
        } = this;

        return (
            <InfiniteScroll
                className={classes.box}
                lengthRecord={records.length}
                totalRecord={totalCount}
                loadMore={loadMore}
                load={load}
            >
                {
                    records.map((item) => {
                        const rowBackground = item.flag ? classes[`rowFlagged${item.flag}`] : classes.backgroundRow;

                        return (
                            <Accordion
                                square
                                elevation={0}
                                className={clsx(classes.rowExpan, rowBackground)}
                                key={item.accountNumber}
                            >
                                <ExpandRow>
                                    <DealItems
                                        record={item}
                                        stages={stages}
                                        toggleModal={toggleModal}
                                        onOpenModal={onOpenModal}
                                        timeZone={timeZone}
                                        stagesTransitionList={stagesTransitionList}
                                        toggleTransferToRFCDialog={toggleTransferToRFCDialog}
                                        toogleSecureCloseDialog={toogleSecureCloseDialog}
                                        secureCloseEnabled={secureCloseEnabled}
                                    />
                                </ExpandRow>
                            </Accordion>
                        );
                    })
                }
            </InfiniteScroll>
        );
    }

    render() {
        const {
            props: {
                classes, openDealDialog,
                toggleModal, userAvailableLots,
                selectedRecord, transferDeal,
                open, onCloseModal, newDeals,
                contentList, onChangeStatus, stages,
                onChangeDeletedDeal, onSearch,
                setInitFilter, onFilterByLot,
                onFilterByPostedStatus, onFilterByTagAgency,
                openTransferToRFCDialog, toggleTransferToRFCDialog,
                sendToRFC, toggleAcceptDealDialog, openAcceptDealDialog,
                sendingToRFC, openSecureCloseDialog, toogleSecureCloseDialog,
            },
        } = this;
        const currentStage = filterSortDealStatus(stages);

        const lotOptions = [ALL_LOTS, ...userAvailableLots];

        return (
            <div className={classes.main}>
                <Header>
                    <div className="d-flex-center">
                        {this.SALES_DEAL_WRITE && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={newDeals}
                            >
                                New
                            </Button>
                        )}
                        <InputSearch
                            size="sm"
                            forceSearch
                            onSearch={onSearch}
                            customClasses={classes.search}
                            initialSearch={contentList.search}
                            executeWhenClearButton={setInitFilter}
                        />
                        <Form.Check
                            inline
                            label="Include deleted deals"
                            name="deals"
                            type="checkbox"
                            id="deals-1"
                            checked={contentList.includeDeactivated}
                            onChange={(e) => onChangeDeletedDeal(e.target.checked)}
                        />
                        <div className="d-flex-center">
                            <Form.Group
                                className="form-check form-check-inline mb-0"
                            >
                                {currentStage.map((item, index) => (
                                    <Form.Check
                                        key={index}
                                        type="radio"
                                        name="radio"
                                        id={`radio${index}`}
                                        label={item.stageFrom}
                                        value={item.stageFrom}
                                        checked={item.stageFrom === contentList.stage}
                                        onChange={() => onChangeStatus(item.stageFrom)}
                                    />
                                ))}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="d-flex-center">
                        {this.SALES_DEAL_TRANSFER_TO_RFC && (
                            <Button
                                className={classes.sentIcon}
                                size="small"
                                variant="contained"
                                onClick={toggleAcceptDealDialog}
                            >
                                <SwapHorizIcon />
                            </Button>
                        )}
                        <Select
                            sorted
                            size="sm"
                            name="tagAgencyStatus"
                            value={contentList.tagAgencyStatus}
                            placeholder="Select"
                            className={classes.select}
                            options={tagAgencyFilterOptions}
                            onChange={onFilterByTagAgency}
                        />
                        <Select
                            sorted
                            size="sm"
                            name="postedStatus"
                            value={contentList.postedStatus}
                            placeholder="Select"
                            className={classes.select}
                            options={postedFilterOptions}
                            onChange={onFilterByPostedStatus}
                        />
                        <LotFilter onSearch={onFilterByLot} initialLots={lotOptions} />
                    </div>
                </Header>
                <Container className={classes.boxContainer}>
                    <div className={classes.listContainer}>
                        { this.renderList() }
                    </div>
                </Container>
                {openDealDialog && (
                    <TransferLot
                        module="deal"
                        open={openDealDialog}
                        onTransfer={transferDeal}
                        closeDialog={toggleModal}
                        lotName={selectedRecord.lotName}
                        recordId={selectedRecord.accountNumber}
                    />
                )}
                {openTransferToRFCDialog && (
                    <TransferToRFC
                        onSend={sendToRFC}
                        sendingToRFC={sendingToRFC}
                        closeDialog={toggleTransferToRFCDialog}
                        recordId={selectedRecord.accountNumber}
                    />
                )}
                {openAcceptDealDialog && (
                    <DealTransferAccept closeDialog={toggleAcceptDealDialog} stage={contentList.stage} />
                )}
                {open && <PostToAccounting open={open} onCloseModal={onCloseModal} record={selectedRecord} />}
                {openSecureCloseDialog && (
                    <SecureCloseDialog closeDialog={toogleSecureCloseDialog} accountNumber={selectedRecord.accountNumber} />
                )}
            </div>
        );
    }
}

DealList.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    getServicesData: PropTypes.func.isRequired,
    getUserAvailableLots: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    transferDeal: PropTypes.func.isRequired,
    newDeals: PropTypes.func.isRequired,
    contentList: PropTypes.shape({
        records: PropTypes.array.isRequired,
        limit: PropTypes.number,
        totalCount: PropTypes.number,
        includeDeactivated: PropTypes.bool.isRequired,
        stage: PropTypes.string,
        search: PropTypes.string.isRequired,
        postedStatus: PropTypes.string,
        tagAgencyStatus: PropTypes.string,
    }).isRequired,
    onChangeStatus: PropTypes.func.isRequired,
    onChangeDeletedDeal: PropTypes.func.isRequired,
    onFilterByLot: PropTypes.func.isRequired,
    load: PropTypes.bool,
    openDealDialog: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    userAvailableLots: PropTypes.array,
    selectedRecord: PropTypes.object,
    getStagesTransitionList: PropTypes.func.isRequired,
    stagesTransitionList: PropTypes.array.isRequired,
    stages: PropTypes.array,
    open: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    timeZone: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    setInitFilter: PropTypes.func.isRequired,
    onFilterByPostedStatus: PropTypes.func.isRequired,
    onFilterByTagAgency: PropTypes.func.isRequired,
    openTransferToRFCDialog: PropTypes.bool.isRequired,
    sendToRFC: PropTypes.func.isRequired,
    toggleTransferToRFCDialog: PropTypes.func.isRequired,
    toggleAcceptDealDialog: PropTypes.func.isRequired,
    openAcceptDealDialog: PropTypes.bool.isRequired,
    sendingToRFC: PropTypes.bool.isRequired,
    openSecureCloseDialog: PropTypes.bool.isRequired,
    toogleSecureCloseDialog: PropTypes.func.isRequired,
    secureCloseEnabled: PropTypes.bool.isRequired,
};

DealList.defaultProps = {
    load: false,
    stages: [],
    userAvailableLots: [],
    selectedRecord: {},
    timeZone: '',
};

export default withRouter(withStyles(styles)(DealListContainer(DealList)));
