import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BuyerContentTabs from 'components/modules/deals/read/buyer/BuyerContentTabs';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        background: theme.palette.background.default,
        '& .gridItem': {
            display: 'flex',
            width: '100%',
            height: '100%',
            paddingRight: theme.spacing(1),
        },
        '& .gridItem:last-child': {
            padding: 0,
        },
    },
});

class BuyerTab extends Component {
    render() {
        const {
            classes, accountNumber, editingMode, onChangeEditingMode,
            clientId,
        } = this.props;

        return (
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={12} className="gridItem">
                    <BuyerContentTabs
                        clientId={clientId}
                        editingMode={editingMode}
                        accountNumber={accountNumber}
                        onChangeEditingMode={onChangeEditingMode}
                    />
                </Grid>
            </Grid>
        );
    }
}

BuyerTab.propTypes = {
    accountNumber: PropTypes.number.isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    clientId: PropTypes.string.isRequired,
};

export default withStyles(styles)(BuyerTab);
